/* istanbul ignore file */
import { Route, AppId, createPageRoute } from '@lumapps/router';

export const contentTemplateRoute: Route = createPageRoute({
    slug: 'content-type/:uid/templates',
    legacyId: 'app.admin.template',
    appId: AppId.backOffice,
});

const contentCreationRoute: Route = {
    path: '/content/create/custom/:uid',
    legacyId: 'app.front.content-create',
    appId: AppId.legacy,
};

const contentCreationCustomTemplateRoute: Route = {
    path: '/content/create/custom/:uid/:template',
    legacyId: 'app.front.content-create',
    appId: AppId.legacy,
};

export const adminContentTemplates = (uid: string): Route => ({
    ...contentTemplateRoute,
    params: { customContentType: uid, uid },
});

export const contentCreation = (uid: string): Route => ({
    ...contentCreationRoute,
    params: { type: 'custom', customContentType: uid, uid },
});

export const contentCreationCustomTemplate = (uid: string, template: string): Route => ({
    ...contentCreationCustomTemplateRoute,
    params: { type: 'custom', customContentType: uid, uid, template },
});

export const copyTemplateRoute = (templateId: string, customContentTypeId: string): Route => ({
    legacyId: 'app.front.content-duplicate',
    path: '/content/duplicate/:templateId',
    params: {
        templateId,
        customContentTypeId,
    },
    query: {
        customContentType: customContentTypeId,
        contentType: 'content',
        isTemplate: 'true',
    },
    appId: AppId.legacy,
});

export const editTemplateRoute = (templateId: string): Route => ({
    legacyId: 'app.front.content-edit',
    // The param names should be the same as in the legacy state.js $stateParams
    path: '/content/edit/:key',
    params: {
        contentType: 'content',
        key: templateId,
        isTemplate: 'true',
    },
    query: {
        isTemplate: 'true',
    },
    appId: AppId.legacy,
});

export const newTemplateRoute = (customContentTypeId: string): Route => ({
    legacyId: 'app.front.content-create',
    path: '/content/create/template/:customContentTypeId',
    params: {
        customContentTypeId,
    },
    appId: AppId.legacy,
});
