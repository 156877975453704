/* istanbul ignore file */
import { useEffect, useCallback } from 'react';

import noop from 'lodash/noop';

import { get } from '@lumapps/constants';
import { contentSelector } from '@lumapps/contents/ducks/selectors';
import { useContent } from '@lumapps/contents/hooks/useContent';
import { isContentPage } from '@lumapps/contents/utils/isContentPage';
import { customerSelector } from '@lumapps/customer/ducks/selectors';
import { getCurrentInstance } from '@lumapps/instance/ducks/selectors';
import { useSelector } from '@lumapps/redux/react';
import { LOADING_PAGE_ID } from '@lumapps/router/constants';
import { analyticsPropsSelector, currentPageSelector, routerIsLoading } from '@lumapps/router/ducks/selectors';
import { SEARCH_PAGE_ID } from '@lumapps/search/constants';
import { useSearchQueryInParam } from '@lumapps/search/hooks/useSearchQuery';
import { useTranslate } from '@lumapps/translations';
import { getDefaultUserDirectoryAttributes } from '@lumapps/user-directory/ducks/selectors';
import { connectedUserSelector, hasAcceptedCookiesSelector } from '@lumapps/user/ducks/selectors';
import { getCookie } from '@lumapps/utils/cookies/cookie_utils';
import { requestOnIdleCallback } from '@lumapps/utils/function/requestIdleCallback';

import {
    ALLOW_CONNECTED_AS_ANALYTICS,
    CLIENT_DATA_LAYER_NAME,
    GA4_TAG_NAME,
    GA_TAG_NAME,
    GTM_EVENT_NAME,
    GA4_USER_PROP_PREFIX,
} from '../../constants';
import { analyticsInfoSelector } from '../../ducks/selectors';
import {
    AnalyticsUserDirAttribute,
    ConvertedAnalyticsUserDirAttribute,
    ConvertedGA4Attributes,
    GA4UserProps,
    GAAttributes,
    Ga4AttributeName,
} from '../../types';
import { getMetadataAttributes } from './getMetadataAttributes';
import { loadGAAndGTMScripts } from './loadScripts';

/** Memory used to avoid recomputing props */
let convertedGA4AttributesMemory: { attributes: ConvertedGA4Attributes; userProps: GA4UserProps } | undefined;

/** Convert attributes to reduce size because GA4 attributes names are limited in size */
export const convertAttributeForGA4 = (attributes: object): ConvertedGA4Attributes => {
    return Object.fromEntries(
        Object.entries(attributes).map(([key, val]) => [
            key.replace('lumapps_user_dir', GA4_USER_PROP_PREFIX).replace('lumapps_', 'la_') as Ga4AttributeName,
            val,
        ]),
    );
};

export const convertUserDirCodeValues = (
    userDirCodeValues: AnalyticsUserDirAttribute[] = [],
): ConvertedAnalyticsUserDirAttribute => {
    const userDirAttributes: ConvertedAnalyticsUserDirAttribute = {};

    userDirCodeValues?.forEach((userDirAttr) => {
        const { code } = userDirAttr;
        if (code) {
            userDirAttributes[`lumapps_user_dir_${code}`] = userDirAttr.value;
        }
    });

    return userDirAttributes;
};

/** Memory setter, only used when sending page view */
const setConvertedAttributesMemory = (attributes: ConvertedGA4Attributes) => {
    // Take only la_user props
    const userProps = Object.fromEntries(
        Object.entries(attributes).filter(([key]) => key.startsWith(GA4_USER_PROP_PREFIX)),
    );
    const filteredAttributes = Object.fromEntries(
        Object.entries(attributes).filter(([key]) => !key.startsWith(GA4_USER_PROP_PREFIX)),
    );
    convertedGA4AttributesMemory = {
        attributes: filteredAttributes,
        userProps,
    };
    return convertedGA4AttributesMemory;
};

/** Set user_properties for GA4 */
const setGA4UserProps = () => {
    if (window[GA4_TAG_NAME] && convertedGA4AttributesMemory?.userProps) {
        window[GA4_TAG_NAME]('set', 'user_properties', convertedGA4AttributesMemory.userProps);
    }
};

/**
 * Send GA3/GA4 page view
 * Make sure to send all the information available
 * along with it (user props ...)
 */
const sendGAPageView = (attributes: object) => {
    // GA3
    if (window[GA_TAG_NAME]) {
        window[GA_TAG_NAME]('send', 'pageview');
    }

    // GA4
    if (window[GA4_TAG_NAME]) {
        const convertedAttributes = convertAttributeForGA4(attributes);
        setConvertedAttributesMemory(convertedAttributes);
        setGA4UserProps();
        window[GA4_TAG_NAME]('event', 'page_view', convertedGA4AttributesMemory?.attributes);
    }
};

/**
 * Send GA4 search event
 * Make sure to send all the information available
 * along with it (user props ...)
 */
const sendGA4SearchEvent = (searchTerm: string) => {
    if (window[GA4_TAG_NAME]) {
        window[GA4_TAG_NAME]('event', 'search', {
            search_term: searchTerm,
            ...(convertedGA4AttributesMemory?.attributes || {}),
        });
    }
};

let lastContentIdViewed: string | undefined;
let lastSearchQuery: string | undefined;

const { isDev, isIntegrationTest } = get();

const isGoogleAnalyticsDisabled = !isIntegrationTest && isDev;

/**
 * Load GTM script and send event when a page is visited.
 * Load GA script and send event when a page is visited.
 * Needs to be in a router to work.
 *
 * If we are in a dev environment or in an integration test, we avoid this hook all together.
 */
const useGoogleAnalyticsAndTagManager = isGoogleAnalyticsDisabled
    ? noop
    : () => {
          const { googleAnalytics, googleTagManager, userDirCodeValues } = useSelector(analyticsInfoSelector);
          const { translateObject } = useTranslate();
          const hasAcceptedCookies = useSelector(hasAcceptedCookiesSelector);
          const instance = useSelector(getCurrentInstance);
          const user = useSelector(connectedUserSelector);
          const customer = useSelector(customerSelector);
          const content = useSelector(contentSelector);
          const currentPage = useSelector(currentPageSelector);
          const isLoadingCurrentPage = useSelector(routerIsLoading);
          const userDirectoryAttributes = useSelector(getDefaultUserDirectoryAttributes);
          const isOnContentPage = isContentPage(currentPage);
          const { isLayout } = useContent();
          const query = useSearchQueryInParam();
          const analyticsProps = useSelector(analyticsPropsSelector);

          // We do not use requestOnIdleCallback here because we want
          // to ensure that the scripts loaded by loadGAAndGTMScripts
          // are loaded synchornously before going to the next step of the hook.
          if (hasAcceptedCookies) {
              loadGAAndGTMScripts({
                  user,
                  instanceGA: googleAnalytics,
                  instanceGTM: googleTagManager,
                  isUsingAnalyticsMocks: isIntegrationTest,
              });
          }

          /** GOOGLE TAG MANAGER */

          /**
           * Construct and send GTM custom event: LumAppsGTM
           */
          const fillDataLayer = (layer: any[], attribs: any, errorMsg: string) => {
              const gtmEventToSend = {
                  attributes: attribs,
                  event: GTM_EVENT_NAME,
              };

              try {
                  layer.push(gtmEventToSend);
              } catch (e) {
                  // eslint-disable-next-line no-console
                  console.warn(errorMsg);
              }
          };

          const sendGoogleTagManagerLumAppsEvent = useCallback(() => {
              requestOnIdleCallback(async () => {
                  if (
                      !hasAcceptedCookies ||
                      !customer.id ||
                      !instance?.id ||
                      !user?.id ||
                      (user?.isConnectedAs && !getCookie(ALLOW_CONNECTED_AS_ANALYTICS)) ||
                      !currentPage ||
                      currentPage === LOADING_PAGE_ID ||
                      isLoadingCurrentPage
                  ) {
                      return;
                  }

                  /**
                   * Avoid re-sending the event or sending it too early
                   * (ie, with not all information)
                   */
                  const currentContentId: string | undefined = content?.id;
                  const isSameContentAsLastTime =
                      Boolean(lastContentIdViewed) &&
                      Boolean(currentContentId) &&
                      currentContentId === lastContentIdViewed;

                  if (isOnContentPage && (!currentContentId || isSameContentAsLastTime)) {
                      return;
                  }
                  lastContentIdViewed = currentContentId;
                  // Search page
                  if (currentPage === SEARCH_PAGE_ID) {
                      if (!query) {
                          return;
                      }

                      const isSameSearchAsLastTime = lastSearchQuery && query && lastSearchQuery === query;
                      if (isSameContentAsLastTime) {
                          return;
                      }
                      if (isSameSearchAsLastTime) {
                          return;
                      }
                      lastSearchQuery = query;
                  }

                  /**
                   * Attributes to send to GTM.
                   * Even if a key has no value, it is required to explicitly set `undefined` for these keys to exist in GTM.
                   *
                   * For now, only contents have been migrated so only contents are treated here.
                   * Please keep in mind that once communities and posts have been migrated, we should take a look at this to be
                   *     sure it works for them too.
                   */
                  const userLang1 = user.lang || undefined;
                  const userLang2 = (user.langs || []).join(';') || undefined;
                  const pageRoute = window.location.pathname;

                  const shouldFetchMetadataAttrs = Boolean(
                      isOnContentPage && content?.metadata && content?.metadata.length > 0,
                  );
                  const metdataAttrs = await getMetadataAttributes(shouldFetchMetadataAttrs, content?.id);

                  const attributes: GAAttributes = {
                      /** Content */
                      ...(isOnContentPage && {
                          lumapps_content_comments: content?.comments || 0,
                          lumapps_content_id: content?.id || undefined,
                          lumapps_content_internal_type: content?.type || undefined,
                          lumapps_content_is_homepage: content?.isHomepage,
                          lumapps_content_likes: content?.likes || 0,
                          lumapps_content_publication_date: content?.publicationDate,
                          lumapps_content_slug: translateObject(content?.slug, instance.defaultLang) || undefined,
                          lumapps_content_title: translateObject(content?.title, instance.defaultLang) || undefined,
                          lumapps_content_is_layout: isLayout,
                      }),

                      /** Customer */
                      lumapps_customer_id: customer.id,
                      lumapps_customer_name: customer.name || undefined,

                      /** Route */
                      lumapps_route: pageRoute,
                      page_location: pageRoute,

                      /** Instance */
                      lumapps_site_default_lang: instance.defaultLang || undefined,
                      lumapps_site_ga: instance.googleAnalytics || googleAnalytics || undefined,
                      lumapps_site_gtm: instance.googleTagManager || googleTagManager || undefined,
                      lumapps_site_id: instance.id,
                      lumapps_site_name: instance.name || undefined,
                      lumapps_site_slug: instance.slug || undefined,

                      /** User */
                      lumapps_user_is_admin:
                          user.isSuperAdmin || (user.instancesSuperAdmin || []).includes(instance.id),
                      lumapps_user_primary_language: userLang1,
                      lumapps_user_secondary_language: userLang2,
                      lumapps_user_unique_id: user.id,

                      lumapps_user_lang_1: userLang1,
                      lumapps_user_lang_2: userLang2,

                      /**
                       * Content types, metadata and tags.
                       * These fields require a request to be filled so we let them `undefined` until further notice.
                       * If we would be to request them - which we don't want to -, please note that these fields are
                       *     only relevant for a content but not for a community or a post.
                       */
                      lumapps_content_type_id: content?.customContentType,
                      lumapps_content_type_name:
                          translateObject(content?.customContentTypeDetails?.name, instance.defaultLang) || undefined,
                      lumapps_content_tags:
                          content?.customContentTypeTags && content?.customContentTypeDetails
                              ? content?.customContentTypeTags
                                    .map((tagId) => {
                                        // Find the tag to get the name
                                        const tag = content?.customContentTypeDetails?.tags.find(
                                            (tagDetail) => tagDetail.id === tagId,
                                        );
                                        if (tag) {
                                            return translateObject(tag.name, instance.defaultLang);
                                        }

                                        return '';
                                    })
                                    .filter(Boolean)
                              : [],
                      lumapps_content_tags_ids: content?.customContentTypeTags,

                      /** Search */
                      lumapps_search_term: query,

                      /** User directory attributes */
                      ...userDirectoryAttributes,

                      /** Content metdata */
                      ...metdataAttrs,

                      /** Custom attributes - for Video/Playlist for now */
                      ...analyticsProps,

                      /** User directory values */
                      ...convertUserDirCodeValues(userDirCodeValues),
                  };

                  if ((googleTagManager || window[GA4_TAG_NAME]) && window[CLIENT_DATA_LAYER_NAME]) {
                      const errorMsg =
                          'Google Tag Manager or google analytics4 using client identifier seems to be unproperly set.';
                      fillDataLayer(window[CLIENT_DATA_LAYER_NAME], attributes, errorMsg);
                  }

                  sendGAPageView(attributes);
              });
          }, [
              content,
              customer,
              hasAcceptedCookies,
              instance,
              isOnContentPage,
              isLayout,
              query,
              translateObject,
              user,
              userDirectoryAttributes,
              userDirCodeValues,
              googleTagManager,
              googleAnalytics,
              currentPage,
              isLoadingCurrentPage,
              analyticsProps,
          ]);

          useEffect(() => {
              sendGoogleTagManagerLumAppsEvent();
          }, [sendGoogleTagManagerLumAppsEvent]);
      };

export { useGoogleAnalyticsAndTagManager, sendGAPageView, sendGA4SearchEvent };
