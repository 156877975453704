import React from 'react';

import { get as getConfig } from '@lumapps/constants';
import { customerIdSelector } from '@lumapps/customer/ducks/selectors';
import { AvatarProps } from '@lumapps/lumx/react';
import { useSelector } from '@lumapps/redux/react';
import { useTranslate, GLOBAL } from '@lumapps/translations';

import { MinimumViableUser } from '../types';
import { getUserFullName } from '../utils/getUserFullName';
import { getUserProfilePictureUrl } from '../utils/getUserProfilePicture';

export type UseUserAvatarResult = AvatarProps & {
    /** retrieve a user's profile picture */
    getUserProfilePicture: () => string;
};

export type UseUserAvatarOptions = {
    /** override the avatar's URL */
    customUserAvatar?: string;
};

const { userLang } = getConfig();

/**
 * Hook in order to retrieve the necessary props for displaying a user's avatar.
 * @param user
 * @param options
 * @returns UseUserAvatarResult
 */
export const useUserAvatar = (user?: MinimumViableUser, options: UseUserAvatarOptions = {}): UseUserAvatarResult => {
    const customerId = useSelector(customerIdSelector);
    const { translateKey } = useTranslate();
    const { customUserAvatar } = options;

    return React.useMemo(() => {
        const calculatedProfilePicture =
            customUserAvatar || getUserProfilePictureUrl({ id: user?.id, customer: customerId });
        const fullName = getUserFullName(user, userLang);

        return {
            alt: fullName && fullName !== '' ? fullName : translateKey(GLOBAL.UNKNOWN_USER),
            image: calculatedProfilePicture,
            getUserProfilePicture: () => calculatedProfilePicture,
        };
    }, [customerId, customUserAvatar, translateKey, user]);
};
