import React from 'react';

import { usePersistentStore } from '@lumapps/cache/hooks/usePersistentStore';
import { fetchAndStoreQuickSearchItems } from '@lumapps/quick-search/api/fetchAndStoreQuickSearchItems';
import { SearchInteraction } from '@lumapps/quick-search/types';
import {
    SEARCH_HISTORY_CACHE_KEY,
    SEARCH_INTERACTIONS_CACHE_KEY,
    MAX_SEARCH_HISTORY_SUGGEST_RESULTS,
    MAX_SEARCH_INTERACTIONS_SUGGEST_RESULTS,
} from '@lumapps/search/constants';
import { BaseSearchResult, SearchSuggestion, SearchSuggestionType } from '@lumapps/search/types';
import { escapeRegExp } from '@lumapps/utils/string/escapeRegExp';
import { normalizeAccentsNDiacriticsChars } from '@lumapps/utils/string/normalizeAccentsNDiacriticsChars';

import { useQueriesSuggestions } from './useQueriesSuggestions';
import { useResultSuggestions } from './useResultSuggestions';
import { useResultToSuggestionConverter } from './useResultToSuggestionConverter';

/**
 * Filter all suggestions from the user query
 * @param suggestions list to filter
 * @param query the user query
 */
export const filterSuggestions = (suggestions: SearchSuggestion[], query: string, maxResults: number) => {
    if (!query) {
        const results = [...suggestions].sort((a, b) => b.counterClick - a.counterClick);

        return results.slice(0, maxResults);
    }

    // Remove accent to be able to find "clé" if the query is "cle" and vice versa
    const normalizedQuery = normalizeAccentsNDiacriticsChars(query.toLowerCase());
    // Get suggestions where the query match the user query
    const result = suggestions.filter((q) => {
        if (q && q.query) {
            return normalizeAccentsNDiacriticsChars(q.query.toLowerCase()).indexOf(normalizedQuery) >= 0;
        }

        return -1;
    });

    // Only return top X results. We can store 50 items but only display 5
    if (result.length > maxResults) {
        return result.slice(0, maxResults);
    }

    return result;
};

export const findSuggestions = ({
    query = '',
    queries,
    interactions,
    others,
}: {
    query?: string;
    queries: SearchSuggestion[];
    interactions: SearchSuggestion[];
    others: SearchSuggestion[];
}) => {
    // filter from the query
    const escapedQuery = escapeRegExp(query);
    const filteredQueries = filterSuggestions(queries, escapedQuery, MAX_SEARCH_HISTORY_SUGGEST_RESULTS);
    const filteredInteractions = filterSuggestions(interactions, escapedQuery, MAX_SEARCH_INTERACTIONS_SUGGEST_RESULTS);
    const res = [...filteredQueries, ...filteredInteractions, ...others];

    return res.map((suggestion) => {
        // Suggest from server may have no label
        const label = ((suggestion.label ? suggestion.label : suggestion.query) as string) || '';

        return {
            ...suggestion,
            label,
        };
    });
};

/**
 * get suggestions from HISTORY, INTERACTED RESULTS, and autocomplete
 * @param instanceId the current instance id
 * @param query the user query. Used to filter suggestions
 */
export type UseSuggestionsParams = {
    canFetch: boolean;
    instanceId: string;
    query?: string;
    recommendations?: BaseSearchResult[] | null;
};
export const useSuggestions = (params: UseSuggestionsParams): SearchSuggestion[] => {
    const [queries = []] = usePersistentStore<SearchSuggestion[]>(
        `${SEARCH_HISTORY_CACHE_KEY}-${params.instanceId}`,
        [],
    );
    const [{ interactions = [] }] = usePersistentStore<SearchInteraction>(
        `${SEARCH_INTERACTIONS_CACHE_KEY}-${params.instanceId}`,
        { interactions: [], ttl: Date.now().toString() },
    );

    // Fetch QuickSearch items
    React.useEffect(() => {
        if (params.canFetch) {
            fetchAndStoreQuickSearchItems({
                siteId: params.instanceId,
            });
        }
    }, [params.instanceId, params.canFetch]);

    const queriesSuggestions = useQueriesSuggestions();
    const resultsSuggestions = useResultSuggestions({ instanceId: params.instanceId });
    const recommendations = useResultToSuggestionConverter(params.recommendations || [], {
        instanceId: params.instanceId,
        type: SearchSuggestionType.RECOMMENDATION,
    });

    const suggestionsBasedOnQuery = React.useMemo(
        () => (params.query ? [...queriesSuggestions, ...resultsSuggestions] : []),
        [params.query, queriesSuggestions, resultsSuggestions],
    );

    const suggestions = React.useMemo(
        () => [...suggestionsBasedOnQuery, ...recommendations],
        [recommendations, suggestionsBasedOnQuery],
    );

    const filteredSuggestions = React.useMemo(() => {
        return findSuggestions({ query: params.query, queries, interactions, others: suggestions });
    }, [params.query, queries, interactions, suggestions]);

    return filteredSuggestions;
};
