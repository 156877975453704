import { isFeatureEnabled } from '@lumapps/features';
import { createSelector } from '@lumapps/redux/reselect';
import { FrontOfficeStore } from '@lumapps/redux/types';

import { DISABLE_QUICK_POST_FF } from '../constants';

const postSelector = (state: FrontOfficeStore) => state.post.root;

export const singlePostSelector = (id: string) => createSelector(postSelector, (post) => post.entities[id]);

export const editedPostSelector = createSelector(postSelector, (post) => post.editedPost);

export const isDialogOpenSelector = createSelector(postSelector, (post) => post.isPostDialogOpen);

export const isPostSavingSelector = createSelector(postSelector, (post) => post.saving);

export const isQuickPostDisabled = isFeatureEnabled(DISABLE_QUICK_POST_FF);
