import React, { FormEvent, ReactNode } from 'react';

import { margin, useClassnames } from '@lumapps/classnames';
import { Button, ButtonProps, Emphasis, Toolbar, FlexBox, Orientation, Size } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import './index.scss';

export interface FiltersFormProps {
    /** callback to be executed once the clear button is clicked. */
    onClearAll?: () => void;
    /** callback on clear all filters */
    onClearAllFilters?: () => void;
    /** Callback triggered when filters are selected */
    onFiltering: (value?: string) => void;
    /** Props to forward to the dropdown */
    filterProps?: Partial<ButtonProps>;
    /**
     * Components to render for each filter.
     * Should be generated via the `useFilters` hook.
     */
    filtersToRender: ReactNode[];
}
const FORM_ID = 'filters-form';
const CLASSNAME = 'filters-form';
export const FiltersForm: React.FC<FiltersFormProps> = ({
    onClearAll,
    filtersToRender,
    filterProps,
    onClearAllFilters,
    onFiltering,
}) => {
    const { translateKey } = useTranslate();
    const { element } = useClassnames(CLASSNAME);
    const onCancelFilters = () => {
        if (onClearAll) {
            onClearAll();
            if (onClearAllFilters) {
                onClearAllFilters();
            }
        }
    };

    const handleSubmit = (event?: FormEvent) => {
        event?.preventDefault();

        return onFiltering();
    };
    const handleKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSubmit(); // submit form when "enter" key is pressed
        }
    };
    return (
        <form id={FORM_ID} onSubmit={handleSubmit} noValidate>
            <FlexBox orientation={Orientation.vertical} gap={Size.huge}>
                {filtersToRender}

                <Toolbar
                    className={element('actions')}
                    after={
                        <>
                            {onClearAll ? (
                                <Button
                                    emphasis={Emphasis.medium}
                                    onClick={onCancelFilters}
                                    className={margin('right')}
                                >
                                    {translateKey(GLOBAL.CLEAR)}
                                </Button>
                            ) : null}

                            <Button form={FORM_ID} type="submit" onKeyDown={handleKeyDown} {...filterProps}>
                                {translateKey(GLOBAL.APPLY)}
                            </Button>
                        </>
                    }
                />
            </FlexBox>
        </form>
    );
};
