import React, { RefObject, useMemo } from 'react';

import trimStart from 'lodash/trimStart';

import { classnames, useClassnames } from '@lumapps/classnames';
import { Combobox } from '@lumapps/combobox/components/Combobox';
import { ComboboxOptionSkeleton } from '@lumapps/combobox/components/ComboboxOption/ComboboxOptionSkeleton';
import { ComboboxOptionAction } from '@lumapps/combobox/components/ComboboxOptionAction';
import { useDataAttributes } from '@lumapps/data-attributes';
import { mdiClose } from '@lumapps/lumx/icons';
import { Emphasis, IconButton, Size, SkeletonCircle, Theme } from '@lumapps/lumx/react';
import { SearchSuggestion, SearchSuggestionType } from '@lumapps/search/types';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { ALL_SPACES_PATTERN } from '@lumapps/utils/string/constants';

import { DATA_NAMESPACE, NAMESPACE } from '../../constant';
import { QUICK_SEARCH } from '../../keys';
import { generateSuggestionId } from '../../utils/generateSuggestionId';
import { groupSuggestions } from '../../utils/groupSuggestions';
import { SearchBoxOption } from '../SearchBoxOption';
import { SearchForOption } from '../SearchBoxOption/SearchForOption';
import { SearchBoxShortcuts } from '../SearchBoxShortcuts';
import { SearchboxInput } from './SearchboxInput';

interface SharedSearchBoxInputProps {
    /** Ref to set on the input component  */
    inputRef: RefObject<HTMLInputElement>;
    /** Id of the input */
    id: string;
    /** theme override */
    theme?: Theme;
    /** True if the suggestions are still being loaded. */
    isLoading: boolean;
    /** The callback to be executed once the user changes the query string. */
    onQueryChange: (s: string) => void;
    /** Callback executed when the listbox opens */
    onOpen: () => void;
    /** Callback executed when the listbox closed */
    onClose: () => void;
    /** Callback executed when the input is focused */
    onFocus: () => void;
    /** String that will be used to initialize the text field. */
    searchQuery: string;
    /** List of suggestions to be displayed. */
    suggestions: SearchSuggestion[];
    /** Custom? className */
    className: string;
    /** Custom placeholder */
    placeholder: string;
    /** is the contextual search box FF activated ? */
    hasContextualSearchBox: boolean;
    /** Callback that will be triggered when a suggestion is selected. */
    onSuggestionSelected: (suggestion: SearchSuggestion) => void;
    /** Callback that will be triggered when a delete button is clicked upon. */
    onDeleteSuggestion?: (suggestion: SearchSuggestion) => void;
    /** Callback to trigger a search with the given query. */
    triggerSearchQuery: (query: string) => void;
}

/**
 * Search box that uses the new "Combobox" pattern.
 */
export const SearchBoxCombobox = ({
    id,
    className,
    placeholder,
    searchQuery,
    isLoading,
    suggestions,
    hasContextualSearchBox,
    inputRef,
    onOpen,
    onQueryChange,
    onSuggestionSelected,
    onFocus,
    onDeleteSuggestion,
    triggerSearchQuery,
    theme,
}: SharedSearchBoxInputProps) => {
    const { translateKey, translateAndReplace } = useTranslate();
    const { element } = useClassnames(NAMESPACE);
    const { block: suggestionsBlock, element: suggestionsElement } = useClassnames('searchbox-suggestions');
    const { get } = useDataAttributes(DATA_NAMESPACE);

    const displaySearchFor = trimStart(searchQuery, ALL_SPACES_PATTERN).length > 0;
    const groupedSuggestions = useMemo(() => groupSuggestions(suggestions, isLoading), [isLoading, suggestions]);

    const handleOptionSelect = (suggestion?: SearchSuggestion) => {
        if (suggestion) {
            onSuggestionSelected(suggestion);
        }
    };

    const handleDelete = (suggestion: SearchSuggestion) => {
        if (onDeleteSuggestion) {
            onDeleteSuggestion(suggestion);
            inputRef.current?.focus?.();
        }
    };

    return (
        <Combobox<SearchSuggestion>
            id={id}
            autoFilter={false}
            openOnFocus
            onOpen={onOpen}
            inputValue={searchQuery}
            onInputChange={onQueryChange}
        >
            <div
                className={classnames(
                    className,
                    // Add the lumx-autocomplete class to make sure we don't break potential customizations
                    'lumx-autocomplete',
                )}
                {...get({ element: 'input' })}
            >
                <SearchboxInput
                    theme={theme}
                    hideToggle
                    inputRef={inputRef}
                    onFocus={onFocus}
                    placeholder={placeholder}
                    clearButtonProps={{
                        label: translateKey(GLOBAL.CLEAR_SEARCH),
                    }}
                />
            </div>
            <Combobox.List
                className={element(
                    !hasContextualSearchBox ? 'suggestions-with-max-width' : 'suggestions',
                    undefined,
                    suggestionsBlock(),
                )}
                popoverProps={{
                    fitToAnchorWidth: 'width',
                }}
                footer={<SearchBoxShortcuts className={element('popover-footer')} />}
                {...get({
                    element: 'suggestions',
                })}
            >
                {displaySearchFor && (
                    <SearchForOption
                        query={searchQuery}
                        onSelect={() => {
                            triggerSearchQuery(searchQuery);
                            handleOptionSelect();
                        }}
                    />
                )}
                {groupedSuggestions.map((section, index) => (
                    <Combobox.Section
                        key={section.type}
                        title={translateKey(section.translationKey)}
                        withDivider={index === 0 ? displaySearchFor : true}
                        isLoading={section.type === SearchSuggestionType.SUGGESTION && isLoading}
                        renderItemSkeleton={<ComboboxOptionSkeleton before={<SkeletonCircle size={Size.s} />} />}
                    >
                        {section.suggestions.map((suggestion) => {
                            const suggestionUniqueId = generateSuggestionId(suggestion);
                            return (
                                <SearchBoxOption
                                    id={suggestionUniqueId}
                                    className={suggestionsElement('item')}
                                    key={suggestionUniqueId}
                                    suggestion={suggestion}
                                    onSelect={handleOptionSelect}
                                    after={
                                        [SearchSuggestionType.HISTORY, SearchSuggestionType.INTERACTION].includes(
                                            suggestion.type,
                                        ) && (
                                            <ComboboxOptionAction
                                                as={IconButton}
                                                onClick={() => handleDelete(suggestion)}
                                                icon={mdiClose}
                                                emphasis={Emphasis.low}
                                                size={Size.s}
                                                label={translateAndReplace(
                                                    QUICK_SEARCH.REMOVE_OPTION_FROM_SUGGESTIONS,
                                                    {
                                                        OPTION_NAME: suggestion.label,
                                                    },
                                                )}
                                                {...get({ element: 'suggestion-history', action: 'delete' })}
                                            />
                                        )
                                    }
                                    {...get({
                                        element: 'suggestion',
                                        action: suggestion.type,
                                        type: suggestion.item?.entityType,
                                        position: index + 1,
                                    })}
                                />
                            );
                        })}
                    </Combobox.Section>
                ))}
            </Combobox.List>
        </Combobox>
    );
};
