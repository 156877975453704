import { mdiTextBoxOutline } from '@lumapps/lumx/icons';
import { ObjectValues } from '@lumapps/utils/types/ObjectValues';

import { CONTENTS } from './keys';

export const PAGE_CONTENT_ID_PREFIX = 'content-';

/** The icon to be used on custom content type without icon defined */
export const CONTENT_TYPE_ICON_FALLBACK = mdiTextBoxOutline;

export const CONTENT_PUBLICATION_STATUS = {
    DRAFT: 'draft',
    PUBLISHED: 'published',
    SCHEDULED: 'scheduled',
    EXPIRED: 'expired',
    ARCHIVE: 'archived',
    UNARCHIVE: 'unarchived',
    DELETED: 'deleted',
    REFUSED: 'refused',
    TO_VALIDATE: 'to-validate',
    EXPIRES_SOON: 'expires-soon',
} as const;

export type ContentStatusWithNotification = Pick<
    typeof CONTENT_PUBLICATION_STATUS,
    'EXPIRED' | 'SCHEDULED' | 'DRAFT' | 'TO_VALIDATE' | 'REFUSED' | 'EXPIRES_SOON' | 'ARCHIVE'
>;

type ContentStatusNotificationInfo = {
    [K in ObjectValues<ContentStatusWithNotification>]: {
        title: string;
        description: string;
        notificationType: 'warning' | 'info';
    };
};

export const CONTENT_PUBLICATION_STATUS_WITH_NOTIFICATION: ContentStatusNotificationInfo = {
    [CONTENT_PUBLICATION_STATUS.EXPIRED]: {
        title: CONTENTS.CONTENT_UNPUBLISHED,
        description: CONTENTS.EXPIRED_STATUS_NOTIFICATION,
        notificationType: 'warning',
    },
    [CONTENT_PUBLICATION_STATUS.ARCHIVE]: {
        title: CONTENTS.CONTENT_UNPUBLISHED,
        description: CONTENTS.ARCHIVED_STATUS_NOTIFICATION,
        notificationType: 'warning',
    },
    [CONTENT_PUBLICATION_STATUS.SCHEDULED]: {
        title: CONTENTS.CONTENT_NOT_PUBLISHED_YET,
        description: CONTENTS.SCHEDULED_STATUS_NOTIFICATION,
        notificationType: 'warning',
    },
    [CONTENT_PUBLICATION_STATUS.DRAFT]: {
        title: CONTENTS.CONTENT_UNPUBLISHED,
        description: CONTENTS.DRAFT_STATUS_NOTIFICATION,
        notificationType: 'warning',
    },
    [CONTENT_PUBLICATION_STATUS.TO_VALIDATE]: {
        title: CONTENTS.CONTENT_NOT_PUBLISHED_YET,
        description: CONTENTS.TO_VALIDATE_STATUS_NOTIFICATION,
        notificationType: 'warning',
    },
    [CONTENT_PUBLICATION_STATUS.REFUSED]: {
        title: CONTENTS.CONTENT_NOT_PUBLISHED_YET,
        description: CONTENTS.REFUSED_STATUS_NOTIFICATION,
        notificationType: 'warning',
    },
    [CONTENT_PUBLICATION_STATUS.EXPIRES_SOON]: {
        title: CONTENTS.CONTENT_EXPIRING_SOON,
        description: CONTENTS.EXPIRES_SOON_STATUS_NOTIFICATION,
        notificationType: 'info',
    },
};
