/* eslint-disable no-use-before-define */
import React, { ReactNode } from 'react';

import { RenderingType } from '@lumapps/communities/types';
import { Theme, ThumbnailProps } from '@lumapps/lumx/react';
import { MediaSource } from '@lumapps/medias/types';
import { PartialBy } from '@lumapps/utils/types/PartialBy';
import { BlockArticlePreview } from '@lumapps/widget-article-list/types';
import { BlockCommunityPreview } from '@lumapps/widget-community-list/types';
import { BlockPagePreview } from '@lumapps/widget-content-list/type';
import { BlockStructuredContent } from '@lumapps/widget-contribution/type';
import { BlockAddDirectoryUserEntry, BlockDirectoryEntry } from '@lumapps/widget-directory-entries/types';
import { BlockEventPreview } from '@lumapps/widget-event-list/type';
import { BlockEventMetadata } from '@lumapps/widget-event-metadata/type';
import { BlockFeaturedImage } from '@lumapps/widget-featured-image/type';
import { BlockHtml } from '@lumapps/widget-html/type';
import { BlockImagePreview } from '@lumapps/widget-image-gallery/types';
import { BlockIntro } from '@lumapps/widget-intro/type';
import { BlockPersonalFeedPreview } from '@lumapps/widget-personal-feed-preview/types';
import type { BlockPlayVideoInterface as BlockPlayVideo } from '@lumapps/widget-play/types';
import { type BlockPlayVideoPlaylistInterface as BlockPlayVideoPlaylist } from '@lumapps/widget-playlist/types';
import { BlockPost } from '@lumapps/widget-post-list/types';
import { BlockLearningPreview } from '@lumapps/widget-recommended-training-courses/types';
import { BlockRemote } from '@lumapps/widget-remote/type';
import { BlockResourceCreationInfo } from '@lumapps/widget-resource-metadata/type';
import { BlockStreamItem } from '@lumapps/widget-stream/types';
import { BackgroundStyles, BorderStyles, InnerSpacingStyles, WidgetContentStyles } from '@lumapps/widget-styles/types';
import { BlockSummary } from '@lumapps/widget-summary/types';
import { BlockTitle } from '@lumapps/widget-title/type';
import { BlockUserProfilePreview } from '@lumapps/widget-user-list/types';

import { LegacyWidget } from './legacy';

export type WidgetLoadingState =
    | 'error'
    | 'loading'
    | 'loadingmore'
    | 'loadingcontent'
    | 'restricted'
    | 'empty'
    | 'loaded'
    | 'notmigrated'
    | 'cancelled';

/** Base widget block. */
export interface Block {
    /** Widget block type. */
    type?: string;
    /** Widget block's path. */
    path?: string[];
    /** Parent widget. */
    widget?: any;
    /** The block's theme */
    theme?: Theme;
    /** Widget content style (if applicable) */
    style?: WidgetContentStyles;
}

/** Container block variant. */
export enum ContainerBlockVariant {
    grouped = 'GROUPED',
    ungrouped = 'UNGROUPED',
}

/** SlideshowVariants block variant. */
export enum SlideshowBlockVariant {
    cover = 'COVER',
    side = 'SIDE',
}

export const isUngroupedContainerBlock = (block: any) => block?.variant === ContainerBlockVariant.ungrouped;

/** Base widget container block. */
export interface BaseArrayContainerBlock extends Block {
    /** Widget block container variant. */
    variant?: ContainerBlockVariant;
    /** Widget block container has separator. */
    hasSeparator?: boolean;
    /** Widget block container items. */
    items: Blocks[];
    /** Items per page. Useful for a11y. Not all widgets are exposing it for now. */
    maxItemsPerPage?: number;
}

/** Enum of all possible array container. */
export type ArrayContainerBlock = BlockCascade | BlockList | BlockGrid | BlockSlideshow;

/** Is a block which contains array of block */
export const isArrayContainerBlock = (block: Block): block is ArrayContainerBlock =>
    ['BlockCascade', 'BlockList', 'BlockGrid', 'BlockSlideshow'].includes(block.type || '');

export interface BaseSingleContainerBlock extends Block {
    /** Widget block container. */
    container: Blocks;
}

/** Enum of all possible single container. */
export type SingleContainerBlock = BlockTabFilteredContainer;

/** Is a block which contains a block container */
export const isSingleContainerBlock = (block: Block): block is BaseSingleContainerBlock =>
    ['BlockTabFilteredContainer', 'BlockAugmentedContainer', 'BlockPersonalFeedPreview'].includes(block.type || '');

/** Base props for resource container blocks, the given resource type as key   */
export type BaseResourceContainerBlock<ResourceTypeKey extends string = string> = Block & {
    resourceId: string;
    resourceType: ResourceTypeKey;
} & { [K in ResourceTypeKey]: Blocks };

/** Enum of all possible resource container. */
export type ResourceContainerBlock = BlockStreamItem;

/** Is a block which contains a block container */
export const isResourceContainerBlock = (block: Block): block is BaseResourceContainerBlock =>
    ['BlockStreamItem'].includes(block.type || '');

/** No results block. */
export interface BlockNoResults extends Block {
    /** No result block type */
    type: 'BlockNoResults';
    /** The placeholder text to display in edit mode. */
    placeholderText?: string;
}

/** List block orientation. */
export enum BlockListOrientation {
    vertical = 'VERTICAL',
    horizontal = 'HORIZONTAL',
}

/** List block. */
export interface BlockList extends BaseArrayContainerBlock {
    type: 'BlockList';
    orientation?: BlockListOrientation;
    contentStyles?: BackgroundStyles & BorderStyles & InnerSpacingStyles;

    onLoadMore?(): void;
}

export interface BlockTagFilter {
    type: string;
    tags: TagReference[];
    isNoFilterAllowed: boolean;
}

/** TabFilteredContainer block */
export interface BlockTabFilteredContainer extends BaseSingleContainerBlock {
    type: 'BlockTabFilteredContainer';
    filter: BlockTagFilter;
    container: BlockList | BlockGrid;
    header?: BlockAddDirectoryUserEntry;
}

export type PostListFilterType =
    | 'AUTHOR_EMAIL'
    | 'HAS_RELEVANT_COMMENT'
    | 'POST_CATEGORIES'
    | 'POST_STATUSES'
    | 'POST_TYPES'
    | 'SEARCH_QUERY'
    | 'SITE_IDS';

export type EventListFilterType = 'FROM_DATE' | 'TO_DATE' | 'SEARCH_TERM' | 'LIST_ORDER_DIR';
export type ArticleListFilterType = 'SEARCH_TERM' | 'SORT';
export type StreamWidgetFilterType = 'RESOURCE_TYPES';

export type AllAvailableFilterTypes =
    | PostListFilterType
    | EventListFilterType
    | ArticleListFilterType
    | StreamWidgetFilterType;

export interface ContainerFilter {
    type: AllAvailableFilterTypes;
    predefinedValues?: string[];
    defaultValue?: string;
}

export interface BlockContainerFilters {
    availableFilters: ContainerFilter[];
}

export interface BlockAugmentedContainer extends BaseSingleContainerBlock {
    type: 'BlockAugmentedContainer';
    header?: BlockAddDirectoryUserEntry;
    container: BlockList | BlockGrid | BlockCascade | BlockSlideshow | BlockNoResults;
    filters?: BlockContainerFilters;
}

/** Cascade block. */
export interface BlockCascade extends BaseArrayContainerBlock {
    type: 'BlockCascade';
    children?: ReactNode;
    columns?: number;
    contentStyles?: BackgroundStyles & BorderStyles & InnerSpacingStyles;
    loadingState: WidgetLoadingState;
    onLoadMore?(): void;
    /** The component that will be used as a loader when the list loads more items. Default: <Progress /> */
    loadingStateRenderer?: React.FC;
}

/** Slideshow Block. */
export interface BlockSlideshow extends Omit<BaseArrayContainerBlock, 'variant'> {
    /** Slideshow block type */
    type: 'BlockSlideshow';
    /** Whether the slideshow should play automatically. */
    isAutoplayEnabled: boolean;
    /** The delay in seconds before navigating automatically to the next page. */
    autoplayDelay?: number;
    /** Whether navigation controls should be displayed. */
    navigation: boolean;
    /** The number of items that should be visible at any one time. */
    visibleItems?: number;
    /** The widget content style. Passed when ungrouped. */
    contentStyles?: BackgroundStyles & BorderStyles & InnerSpacingStyles;
    /** The widget variant */
    variant?: ContainerBlockVariant | SlideshowBlockVariant;
    /** Item min width to adapt the number of visible items to the available space */
    itemMinWidth?: number;
    children: ReactNode;
}

export const isBlockSlideshow = (block: Block): block is BlockSlideshow => block.type === 'BlockSlideshow';

/** User Block variant. */
export enum BlockUserVariant {
    regular = 'REGULAR',
    pictureOnly = 'PICTURE_ONLY',
}

/** User Block. */
export interface BlockUser extends Block {
    /** User block type */
    type: 'BlockUser';
    /** The user's id. */
    userId: string;
    /** The user's first name. */
    firstName?: string;
    /** The user's last name. */
    lastName?: string;
    /** The user's full name. */
    fullName?: string;
    /** The user's email. */
    email?: string;
    /** The url of the user's picture. */
    picture?: string;
    /** The display variant */
    variant?: BlockUserVariant;
    /** The primary field value */
    profilePrimaryFieldValues?: string[];
}

/** User list block. */
export interface BlockUserList extends Block {
    type: 'BlockUserList';
    items: BlockUser[];
}

/**
 * The type of content the BlockReactions can share.
 * At the moment we can only share posts, but it will probably evolve.
 */
export enum BlockReactionsShareTypes {
    post = 'post',
}

/** Reactions Block.
 * API specification file: https://github.com/lumapps/core/blob/master/specs/generated/cms/contribution_api_v1.yaml
 */
export interface BlockReactions extends Block {
    /** Reaction block type */
    type: 'BlockReactions';
    /** The type of the associated resource. Used for the like call. */
    resourceType: string;
    /** The id of the associated resource. Used for the like call. */
    resourceId: string;
    /** The number of likes for the associated resource. */
    likes?: number;
    /** Whether the current user has liked the associated resource. */
    isLiked?: boolean;
    /** Whether the associated resource is likable or not. */
    isLikable?: boolean;
    /** The number of comments for the associated resource. */
    comments?: number;
    /** Whether the current content is followed by the current user. */
    isFollowed?: boolean;
    /** Whether modifications of the current content will notify the current user. */
    areNotificationsEnabled?: boolean;
    /** Whether the comment button should be visible or not. */
    isCommentCountVisible?: boolean;
    /** Whether the initial like data is stale (need re-fetch on mount) */
    isLikeStateStale?: boolean;
}

/** Grid block. */
export interface BlockGrid extends BaseArrayContainerBlock {
    type: 'BlockGrid';
    columns?: number;
    contentStyles?: BackgroundStyles & BorderStyles & InnerSpacingStyles;
}

export type TagType = 'LEGACY' | 'FOLKSONOMY';

/** Tag reference. */
export interface TagReference {
    tagId: string;
    name: string;
    type: TagType;
}

/** Block Tag list. */
export interface BlockTagReferenceList {
    items: TagReference[] | undefined;
}

/** Reference of a site, will be in blocks that need this information.  */
export interface SiteReference {
    id?: string;
    siteId: string;
    name: string;
    slug: string;
}

/** Metadata reference. */
export interface MetadataReference {
    /** Id of the metadata */
    metadataId: string;
    /** Name of the metadata */
    name: string;
    /** Id of the root parent metadata */
    rootId?: string;
    /** Name of the root parent metadata */
    rootName?: string;
}

export interface UserReference {
    /** The user email */
    email?: string;
    /** The user ID */
    userId: string;
    /** The user full name */
    fullName: string;
    /** The user first name */
    firstName?: string;
    /** The user last name */
    lastName?: string;
    /** The user avatar url */
    pictureUrl?: string;
    /** The user primary field values */
    profilePrimaryFieldValues?: string[];
}

export interface NgiCommunityReference {
    /** The community ID */
    communityId: string;
    /** The community full name */
    name: string;
    /** The community slug */
    slug: string;
    /** The community rendering type */
    renderingType?: RenderingType;
}

/** Image block. */
export interface BlockImage extends Block {
    /** The block type.  */
    type: 'BlockImage';
    /** The url of the image.  */
    url: string;
    /** The X coordinate of the focal point.  */
    focalX?: number;
    /** The Y coordinate of the focal point.  */
    focalY?: number;
    /** The X coordinate of the crop origin.  */
    cropX?: number;
    /** The Y coordinate of the crop origin.  */
    cropY?: number;
    /** The height of the image, used when the image is cropped. */
    height?: number;
    /** The width of the image, used when the image is cropped. */
    width?: number;
    /** The value of the scale on X axis.  */
    scaleX?: number;
    /** The value of the scale on Y axis.  */
    scaleY?: number;
    /** The angle the image should be rotated.  */
    rotate?: number;
    /** The fit to use when displaying this image. */
    fit?: 'auto' | 'contain' | 'cover';
    /** The ratio to apply to the thumbnail when in cover mode. */
    coverRatio?: ThumbnailProps['aspectRatio'];
    /** The variant of the thumbnail's corners. */
    cornerVariant?: ThumbnailProps['variant'];
}

export interface MediaPreview {
    /** The media's id. */
    mediaId: string;
    /** The media's title. */
    title: string;
    /** The media's description. */
    description?: string;
    /** The media file's name. */
    fileName: string;
    /** The media file's url. */
    url: string;
    /** The media file's type. */
    mimeType: string;
    /** The media file's height. */
    height?: number;
    /** The media image block */
    thumbnail?: BlockImage;
    /** Source of the media */
    source: MediaSource;
    /** is hausmann media */
    isSecure?: boolean;
    /** The media's uuid. */
    uuid: string;
    /** The media creation date */
    createdAt?: string;
}

export interface BlockClientComputed extends Block {
    type: 'BlockClientComputed';
    widgetType?: string;
    properties?: LegacyWidget['properties'];
    legacyWidgetId?: LegacyWidget['legacyWidgetId'];
    legacyWidgetType?: LegacyWidget['widgetType'];
    isMainWidget?: LegacyWidget['isMainWidget'];
}

export const isBlockClientComputed = (block: any): block is BlockClientComputed =>
    block && block.type === 'BlockClientComputed';

export enum LinkResourceType {
    ARTICLE = 'ARTICLE',
    EVENT = 'EVENT',
    PLAY_VIDEO = 'PLAY_VIDEO',
}

export interface LinkAttachment {
    url: string;
    image?: BlockImage;
    images?: string[];
    resourceThumbnailId?: string;
    description: string;
    title: string;
    code?: number;
    videoId?: string;
    embedUrl?: string;
    resourceId?: string;
    resourceType?: LinkResourceType;
    thumbnailIndex?: number;
    lang?: string;
    // Event attachment properties
    startsAt?: string;
    endsAt?: string;
    isLoading?: boolean;
}

/** Union type of all types of blocks. */
export type Blocks = Block &
    (
        | BlockList
        | BlockCascade
        | BlockSlideshow
        | BlockGrid
        | BlockNoResults
        | BlockHtml
        | BlockUser
        | BlockReactions
        | BlockRemote
        | BlockPagePreview
        | BlockDirectoryEntry
        | BlockAddDirectoryUserEntry
        | BlockCommunityPreview
        | BlockPost
        | BlockTitle
        | BlockTabFilteredContainer
        | BlockIntro
        | BlockFeaturedImage
        | BlockImagePreview
        | BlockClientComputed
        | BlockStructuredContent
        | BlockSummary
        | BlockResourceCreationInfo
        | BlockUserList
        | BlockEventMetadata
        | BlockUserProfilePreview
        | BlockEventPreview
        | BlockAugmentedContainer
        | BlockArticlePreview
        | BlockStreamItem
        | BlockPlayVideo
        | BlockPlayVideoPlaylist
        | BlockPersonalFeedPreview
        | BlockLearningPreview
    );

/**
 * Block components inherit props from the block but do not need to be provided with `type` or `items` (for array
 * container blocks) or `container` (in single container blocks).
 * This type make these props optional contextually.
 */
export type BlockComponentProps<B extends Block = Block> = PartialBy<
    B extends ArrayContainerBlock
        ? PartialBy<B, 'items'>
        : B extends SingleContainerBlock
        ? PartialBy<B, 'container'>
        : B,
    'type'
>;

export type BlockComponent<B extends Block = Block> = React.FC<BlockComponentProps<B>>;

export interface CommunityInfo {
    /** The community's id */
    communityId: string;
    /** The community's name. */
    name: string;
    /** The community's slug. */
    slug: string;
    /** Whether the community uses secured storage. */
    hasSecureStorage: boolean;
    /** The docpath identifying the community's storage container, if any. */
    storageDocPath?: string;
    /** Community rendering type */
    renderingType?: RenderingType;
}
