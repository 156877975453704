import { BackendSearchSort } from '../types';

/** Get the default value of a given search engine
 * If no sort orders are returned by backend, an empty string is returned
 */
export const getDefaultSort = (sortOrders?: BackendSearchSort[] | null) => {
    if (sortOrders && sortOrders.length > 0) {
        return sortOrders[0].operatorName;
    }
    return '';
};
