import type { Campaign } from '@lumapps/campaigns/types';
import type { Tag } from '@lumapps/folksonomy/types';
import {
    SCHEDULE_OFFSET_DIRECTION,
    SCHEDULE_OFFSET_UNIT,
    type BaseField,
    type BaseForm,
    type BaselineScheduleConfiguration,
    type Channel,
    type IncludedSegment,
    type NotificationRichTextValue,
    type Resource,
    type RichTextRenderingSettings,
    type ScheduleConfiguration,
    type ScheduleConfigurationImmediately,
    type SchedulingType,
} from '@lumapps/journeys/types';
import type { Filters } from '@lumapps/lumx-filter-and-sort/hooks/useQueryParamsSearchAndFilterState';
import { GLOBAL, type TranslatableObject } from '@lumapps/translations';
import type { User } from '@lumapps/user/types';

import type { BROADCAST_ACTIONS } from './constants';
import { BROADCASTS } from './keys';

/**
 * Statuses that the broadcast can have.
 */
export enum BROADCAST_STATUS {
    DRAFT = 'draft',
    SCHEDULED = 'scheduled',
    SENT = 'sent',
    ARCHIVED = 'archived',
}

/**
 * All sorting options for boradcast list
 */
export enum BROADCAST_SORTING_OPTIONS {
    SCHEDULE_ASC = 'scheduledAtOrSentAt',
    SCHEDULE_DESC = '-scheduledAtOrSentAt',
    UPDATE_ASC = 'updatedAt',
    UPDATE_DESC = '-updatedAt',
}

/**
 * Filters and API parameters that can be used when listing the broadcasts
 */
export interface BroadcastListApiParams {
    /** Filter on 1 or more campaigns from their IDs */
    campaignIds?: string[];
    /** API cursor */
    cursor?: string;
    /** number of broadcasts to be retrieved per call */
    maxResults?: number;
    /** used to not retrieve undated broadcasts */
    nullScheduledAtAndSentAtOnly?: boolean;
    /** Schedule or Sent date time to end with, with format YYYY-MM-DDTHH:mm:ss+HH:mm */
    scheduledAtOrSentAtMax?: string;
    /** Schedule or Sent date time to start from, with format YYYY-MM-DDTHH:mm:ss+HH:mm */
    scheduledAtOrSentAtMin?: string;
    /** Filter on 1 or more sites from their IDs */
    siteIds?: string[];
    /** sorting options  */
    sort?: BROADCAST_SORTING_OPTIONS;
    /** Filter on 1 or more broadcast status from the lifecycle */
    statuses?: BROADCAST_STATUS[];
    /** Filter on the broadcasts title */
    title?: string;
}

export interface BroadcastCalendarFilters extends Filters, Pick<BroadcastListApiParams, 'statuses' | 'title'> {
    /** focus date on format 'YYYY-MM-DD' */
    focusDate?: string;
}

export type BroadcastTimeFrameFilter = Pick<
    BroadcastListApiParams,
    'scheduledAtOrSentAtMin' | 'scheduledAtOrSentAtMax'
>;

/**
 * Filters and API parameters that can be used when listing the dates with
 * scheduled/sent/configured broadcasts for a timeline visualization
 */
export interface BroadcastTimelineApiParams {
    /** Filter on 1 or more campaigns from their IDs */
    campaignIds?: string[];
    /** Date to start from, with format YYYY-MM-DD */
    fromDate?: string;
    /** Maximum amount of returned dates, between 1 and 100 */
    maxResults?: number;
    /** Starts at 1 */
    page?: number;
    /** If true, reverses the results in regards with the sorting, see API doc for examples */
    reversePageResults?: boolean;
    /** Filter on 1 or more sites from their IDs */
    siteIds?: string[];
    /** Which broadcasts attribute(s) to use in order to sort the returned dates */
    sort?: string;
    /** Filter on 1 or more broadcast status from the lifecycle */
    statuses?: BROADCAST_STATUS[];
    /** Filter on the broadcasts title */
    title?: string;
    /** Date to end with, with format YYYY-MM-DD */
    toDate?: string;
    /** UTC offset of the current user with format +01:00, -07:00, ... */
    utcOffset: string;
}

export interface BroadcastTimelineFilters
    extends Pick<BroadcastTimelineApiParams, 'statuses' | 'title' | 'campaignIds'> {
    /** focus date on format 'YYYY-MM-DD' */
    focusDate: string;
}

export const LABELS_KEYS_FOR_BROADCAST_SORT: Record<string, string> = {
    status: GLOBAL.STATUS,
    scheduledAt: BROADCASTS.SCHEDULE_DATE,
};

export interface BroadcastSite {
    id: string;
    name: string;
}

/**
 * Broadcast entity type. There might be some other properties
 * in the Broadcast definition returned by the backend, but here
 * we are just listing the ones that are interesting for the frontend.
 */
export interface Broadcast {
    id?: string;
    /** Title of the broadcast */
    title?: string;
    /** Description of the broadcast */
    description?: string;
    /** Configuration of the resource that is going to be, or has been, sent in the broadcast */
    resource?: Resource;
    /** Broadcast status in the lifecycle */
    status: BROADCAST_STATUS;
    /** When the broadcast has been sent, with format YYYY-MM-DDTHH:mm:ssZ */
    sentAt?: string;
    /** When the broadcast was created, , with format YYYY-MM-DDTHH:mm:ssZ */
    createdAt: string;
    /** When the broadcast was last updated, with format YYYY-MM-DDTHH:mm:ssZ */
    updatedAt: string;
    /** Information on the user who last updated the broadcast */
    updatedBy?: Partial<User>;
    /** Information on the user who created the broadcast */
    createdBy?: Partial<User>;
    /** Configuration of the Channel on which the broadcast is going to be, ore has been, sent */
    channelConfiguration?: Channel;
    /** Attribute used for the translation pattern */
    sourceLanguage?: string;
    /** The computed date when the broadcast is going to be, or has been, scheduled */
    scheduledAt?: string;
    /** Configuration of the scheduling of the broadcast */
    scheduleConfiguration?: ScheduleConfiguration;
    /** Some details about the site where the broadcast has been created */
    site?: BroadcastSite;
    /** ID of the site where the broadcast has been created */
    siteId?: string;
    /** List of tagz of the post, linked to the Folksonomy feature flag. */
    tagz?: Tag[];
    /** The linked campaign id, if any. */
    campaignId?: Campaign['id'];
    /** The campaign data returned from the backend, if any. We don't need to send it back, only campaignId is necessary */
    campaign?: Pick<Campaign, 'id' | 'status' | 'title' | 'baselineDates'>;
    /** all settings for rich text (mostly background colors) */
    richTextRenderingSettings?: RichTextRenderingSettings;
    /** rich content if available */
    richText?: TranslatableObject<NotificationRichTextValue>;
}

/**
 * The data from a broadcast that are injected in a form
 * They are voluntarily different from the broadcast entity as the form is much simpler
 * and does not depend on the API.
 * There are 2 mappers in utils from Broadcast -> FormBroadcast & FormBroadcast -> Broadcast
 */
export interface FormBroadcast extends BaseForm {
    /** what resource is attached to the broadcast */
    attachment: Resource;
    /** which campaign it is linked to */
    campaign: Pick<Campaign, 'baselineDates' | 'id' | 'title' | 'status'>;
    /** broadcast description */
    description: string;
    /** unique broadcast id */
    id: string;
    /** rich content if available */
    richText?: TranslatableObject<NotificationRichTextValue>;
    /** all settings for rich text (mostly background colors) */
    richTextRenderingSettings?: RichTextRenderingSettings;
    /** if the broadcast is scheduled relatively to baseline date, the baseline id */
    scheduleBaselineDateId: string;
    /** name of the baseline date */
    scheduleBaselineDateName: string;
    /** The computed date when the broadcast is going to be, or has been, scheduled */
    scheduledAt: string;
    /** if scheduled on a specific/absolute date, that date */
    scheduleDate: string;
    /** if scheduling depends on a baseline date, is it supposed to be sent before or after? */
    scheduleOffsetDirection: SCHEDULE_OFFSET_DIRECTION;
    /** if scheduling depends on a baseline date, is it supposed to be planned in days or weeks around this date */
    scheduleOffsetUnit: SCHEDULE_OFFSET_UNIT;
    /** if scheduling depends on a baseline date, how many days/weeks before/after will it be sent */
    scheduleOffsetValue: number;
    /** the approximate time the broadcast should be sent (ie 09:00) */
    scheduleTime: string;
    /** the timezone to display (ie Europe/Paris) */
    scheduleTimezone: string;
    /** type of scheduling (immediately, absolute date...) */
    scheduleType: SchedulingType;
    /** segments included in the audience */
    segments: IncludedSegment[];
    /** current site id */
    siteId: string;
    /** for translations purpose */
    sourceLanguage: string;
    /** the broadcast status */
    status: BROADCAST_STATUS;
    /** list of tagz, linked to the Folksonomy feature flag */
    tagz: Tag[];
    /** title of the broadcast to appear everywhere in the back-office */
    title: string;
}

export interface BroadcastWithScheduledOption extends Omit<Broadcast, 'scheduleConfiguration'> {
    scheduleConfiguration: ScheduleConfiguration;
}

export interface BroadcastWithScheduledDate extends Omit<Broadcast, 'scheduleConfiguration'> {
    // all types but "immediately"
    scheduleConfiguration: Exclude<ScheduleConfiguration, ScheduleConfigurationImmediately>;
}

export interface BroadcastWithBaselineDate extends Omit<Broadcast, 'scheduleConfiguration'> {
    scheduleConfiguration: BaselineScheduleConfiguration;
}

export interface BroadcastField extends BaseField {
    broadcast?: Broadcast;
}

/**
 * When coming from API (broadcast has been created), some data are not optional anymore
 */
export interface ViewBroadcast
    extends Omit<Broadcast, 'id' | 'title' | 'createdBy' | 'updatedBy'>,
        Required<Pick<Broadcast, 'id' | 'title' | 'createdBy' | 'updatedBy'>> {}

/**
 * Entity representing a date in which there is at least 1 broadcast
 * that is configured, scheduled or has been sent
 */
export interface BroadcastTimelineEntry {
    /** Date, with RFC3339 format: YYYY-MM-DD */
    date: string;
    /** List of 1 or 2 broadcasts that were or are going to be sent on this date */
    items: ViewBroadcast[];
    /** Whether there are additional broadcast that can be loaded in this date or not */
    more: boolean;
}

/**
 * A response sent by the server when requesting a list of dates containing
 * broadcasts for the timeline view
 */
export interface BroadcastTimelineResponse {
    /** Page number, starts at 1 */
    page: number;
    /** Whether there are additional elements that can be loaded or not */
    more: boolean;
    /** List of days containing at least 1 broadcast */
    items: BroadcastTimelineEntry[];
}

/**
 * Callback to select a broadcast card in timeline
 * The actual function is implemented in useBroadcastDrawer
 */
export type OnSelectBroadcast = (
    /** the whole broadcast object */
    broadcast: ViewBroadcast | null,
    /** React ref to the broadcast card to store in order to focus it again when closing the drawer */
    ref?: React.Ref<HTMLAnchorElement | HTMLElement>,
    /** if true, the callback selects the broadcast instead of toggling selection */
    isSelectionForced?: boolean,
) => void;

/**
 * Currying callback to be triggered when an action has been performed on a broadcast in some specific contexts!
 * - timeline
 * - calendar
 * - drawer
 */
export type OnBroadcastActionPerformed = (
    broadcastId: string,
    isSelectionForced: boolean,
) => (action: BROADCAST_ACTIONS) => void;
