/**
 * Insert a given item in a list to a given position.
 *
 * @param  array             A list of items.
 * @param  toInsert          The item you want to insert.
 * @param  insertionIndex    The position where you want to insert the item.
 * @return The list with the inserted item.
 *
 */
export function insertAt<T = any>(array: T[], toInsert: T, insertionIndex: number) {
    const newArray = [...array];

    if (insertionIndex < 0) {
        return newArray;
    }

    // Insert it in its new position.
    newArray.splice(Math.min(insertionIndex, newArray.length), 0, toInsert);

    return newArray;
}
