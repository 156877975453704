import { get } from '@lumapps/constants';
import { homePageView } from '@lumapps/contents/routes';
import { isFeatureEnabled } from '@lumapps/features';
import { getCurrentInstanceName, getCurrentInstanceParentId, getInstanceLogo } from '@lumapps/instance/ducks/selectors';
import { NavigationHeaderProps } from '@lumapps/lumx-back-office/components/NavigationHeader/NavigationHeader';
import { createSelector } from '@lumapps/redux/reselect';
import { createUrl } from '@lumapps/router/utils';
import { isUserProfileReactEnabled as isUserUserProfileReactEnabledSelector } from '@lumapps/user-profile/ducks/selector';
import { socialProfile } from '@lumapps/user-profile/routes';
import { getPictureUrl } from '@lumapps/user/ducks/selectors';

const { defaultLogo, publicPath } = get();

const isMainNavInheritanceFeatureFlagEnabled = isFeatureEnabled('main-nav-inheritance');

const getBackofficeNavigationHeader = createSelector(
    getInstanceLogo,
    getPictureUrl,
    getCurrentInstanceName,
    getCurrentInstanceParentId,
    isMainNavInheritanceFeatureFlagEnabled,
    isUserUserProfileReactEnabledSelector,
    (logo, avatar, name, parentInstance, isMainNavInheritanceEnabled, isUserProfileReactEnabled) => {
        return {
            fallbackHomeImageSrc: `${publicPath}${defaultLogo}`,
            homeImageSrc: logo,
            // No need for layout V2 route since this will reload the page:
            homeLinkUrl: createUrl(homePageView({ isV2Compatible: false })),
            avatarImageSrc: avatar,
            userProfileRoute: socialProfile({ isUserSpaceEnabled: isUserProfileReactEnabled }),
            siteName: name,
            parentInstance,
            isMainNavInheritanceEnabled,
        } as NavigationHeaderProps;
    },
);

const isMainNavInheritanceEnabled = createSelector(
    isMainNavInheritanceFeatureFlagEnabled,
    getCurrentInstanceParentId,
    (isFeatureFlagEnabled, currentInstanceParentId) => {
        return isFeatureFlagEnabled && Boolean(currentInstanceParentId);
    },
);

export { getBackofficeNavigationHeader, isMainNavInheritanceEnabled };
