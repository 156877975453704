import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';

import { Route, addQueryParamsToUrl } from '@lumapps/router';
import { AppId, URL_PREFIX } from '@lumapps/router/constants';

/** Content preview route */
export const contentPreviewRoute: Route = {
    appId: AppId.frontOffice,
    path: `/${URL_PREFIX}/content/preview/:id`,
};

/** Get parameterized content preview route */
export const contentPreview = (contentId: string, queryParams: { lang?: string; token?: string } = {}): Route => {
    const query = addQueryParamsToUrl('', {
        modes: 'disabled-header',
        ...omitBy(queryParams, isUndefined),
    });

    return {
        ...contentPreviewRoute,
        params: { id: contentId },
        query,
    };
};
