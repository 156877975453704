import React from 'react';

import { useQuery, UseQueryOptions, UseQueryResult } from '@lumapps/base-api/react-query';
import { get as getConfig } from '@lumapps/constants';
import { get, userDirectoryQueryKeys } from '@lumapps/user-directory/api';
import {
    useSocialProfileRoute,
    UseSocialProfileRouteProps,
    UseSocialProfileRouteResponse,
} from '@lumapps/user-profile/hooks/useSocialProfileRoute';

import { MinimumViableUser } from '../types';
import { getUserFullName } from '../utils/getUserFullName';
import { useUserAvatar, UseUserAvatarOptions, UseUserAvatarResult } from './useUserAvatar';
import { useUserFields, UseUserFieldsOptions, DEFAULT_FIELDS } from './useUserFields';

export type UseUserResult = UseQueryResult<MinimumViableUser> & {
    /** retrieve the user's full name */
    getUserFullName: () => string;
    /** retrieve the user's profile picture */
    getUserProfilePicture: () => string;
    /** calculated user fields */
    userFields?: string[];
    /** properties for the provided user's avatar */
    avatarProps: UseUserAvatarResult;
    /** props related to displaying a link towards the user's profile */
    userProfile: UseSocialProfileRouteResponse;
    /** user minimum viable user */
    user?: MinimumViableUser;
};

export type UseUserOptions = UseQueryOptions & UseUserFieldsOptions & UseUserAvatarOptions & UseSocialProfileRouteProps;

const { userLang } = getConfig();

/**
 * Hooks that retrieves information from a given user, as well as enhances it with other calculated informations:
 * - User fields dependening on the organization / api profile fields
 * - Retrieve information in order to create a user's avatar.
 * - Retrieve information in order to create a link towards the user's profile.
 * - Retrieve the user's full name
 * - Retrieve the user's profile picture
 * @returns useUser
 */
export const useUser = (user: MinimumViableUser, options?: UseUserOptions): UseUserResult => {
    const userProfile = useSocialProfileRoute({
        ...options,
        routeParams: { ...options?.routeParams, userId: user.id },
    });

    const query = useQuery<MinimumViableUser>({
        enabled: false,
        ...options,
        placeholderData: user,
        queryKey: userDirectoryQueryKeys.get({ uid: user.id, email: user.email }),
        queryFn: async () => {
            const { data } = await get({ uid: user.id, email: user.email });

            return data;
        },
    });

    const finalUser = query.data || user;

    const userFields = useUserFields(finalUser, options);
    const userAvatar = useUserAvatar(finalUser, options);

    return React.useMemo(() => {
        return {
            ...query,
            user: finalUser,
            getUserFullName: () => getUserFullName(finalUser, userLang),
            getUserProfilePicture: userAvatar.getUserProfilePicture,
            avatarProps: userAvatar,
            userFields,
            userProfile,
        };
    }, [finalUser, query, userAvatar, userFields, userProfile]);
};

export { DEFAULT_FIELDS };
