import { GLOBAL, useTranslate } from '@lumapps/translations';

export interface UseFilterLabelOptions {
    /** list of selected filters */
    selectedFilter?: string[];
    /** override the label displayed for the chip that will open the dropdown  */
    label?: string;
}

export const useFilterLabel = ({ selectedFilter, label }: UseFilterLabelOptions) => {
    const { translateKey, pluralize, translateAndReplace } = useTranslate();
    const isSelected = selectedFilter && selectedFilter.length > 0;
    const baseFilterLabel = !label ? translateKey(GLOBAL.FILTERS) : label;
    const filtersLabel = isSelected
        ? `${baseFilterLabel} ${translateAndReplace(pluralize(GLOBAL.ACTIVE_FILTERS, selectedFilter.length), {
              number: selectedFilter.length,
          })}`
        : baseFilterLabel;

    return {
        baseFilterLabel,
        filtersLabel,
    };
};
