import React from 'react';

import { classnames } from '@lumapps/classnames';
import { InstancePickerFieldMultiple } from '@lumapps/instance-pickers/components/InstancePickerFieldMultiple';
import { INSTANCE } from '@lumapps/instance/keys';
import { Instance } from '@lumapps/instance/types';
import { useTranslate } from '@lumapps/translations';

import { Filter } from '../Filter';
import { MultipleSelectFilterProps } from '../MultipleSelectFilter';

import './index.scss';

const CLASSNAME = 'instance-multiple-filter';
/**
 * Component that implements the instance filter used for the `Filters` pattern.
 *
 * @family Filters
 * @param InstanceMultipleFilterProps
 * @returns InstanceMultipleFilter
 */
export const InstanceMultipleFilter: React.FC<Omit<MultipleSelectFilterProps<Instance>, 'options'>> = ({
    selectTextFieldProps,
    onSelectedMultiple,
    selection,
    onChange,
    mode,
    ...props
}) => {
    const { translateKey } = useTranslate();

    return (
        <Filter {...props} hideTitle id="instances-filter" title={translateKey(INSTANCE.INSTANCES_TITLE)} mode={mode}>
            <InstancePickerFieldMultiple
                selectedInstances={selection}
                selectTextFieldProps={{
                    label: translateKey(INSTANCE.INSTANCES_TITLE),
                    ...selectTextFieldProps,
                    className: classnames(CLASSNAME, selectTextFieldProps?.className),
                }}
                onChange={(instances) => {
                    onChange?.(instances);
                    onSelectedMultiple(instances);
                }}
            />
        </Filter>
    );
};
