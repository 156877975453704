import { SearchDocumentType } from '@lumapps/analytics-tracking/types';

import { BaseSearchResult } from '../types';

/**
 * Check if a search results is a lumapps search result or coming from an extension
 * @param result search result to check
 * @returns the type of search result as string
 */
export const getResultType = (result: BaseSearchResult) => {
    const sourceUid = result.metadata?.sourceUid as SearchDocumentType;
    return Object.values(SearchDocumentType).includes(sourceUid) ? sourceUid : SearchDocumentType.EXTENSION;
};
