import { PAGE_CONTENT_ID_PREFIX } from '../constants';

/**
 * Check if the page id is from a content page or not.
 * @param currentPage identifier of the page
 * @returns true if the id match the content page pattern
 */
export const isContentPage = (currentPage: string) => {
    return currentPage.startsWith(PAGE_CONTENT_ID_PREFIX);
};
