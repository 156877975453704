import React from 'react';

import { SelectTextField, SelectTextFieldProps } from '@lumapps/combobox/components/SelectTextField';
import { useFetchSites } from '@lumapps/instance/hooks/useFetchSites/useFetchSites';
import { useInstanceSelectMultiple } from '@lumapps/instance/hooks/useInstanceSelectMultiple';
import { Instance } from '@lumapps/instance/types';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

export interface InstancePickerFieldMultipleProps {
    /**
     * list of instance ids that should be selected by default.
     * Providing this prop will trigger an onChange when the details
     * for those instances are fetched. If you already have the details
     * for those instances, please just use the selectedInstances prop.
     */
    defaultInstancesIds?: string[];
    /**
     * Currently selected Instances.
     */
    selectedInstances?: Instance[];
    /**
     * callback when selected instances change
     */
    onChange: (instances: Instance[]) => void;
    /**
     * additional props for the select field component
     */
    selectTextFieldProps?: Partial<SelectTextFieldProps<Instance>>;
}

/**
 * Select Multiple field in order to select multiple instances (AKA a site)
 *
 * @family Pickers
 * @param InstancePickerFieldMultipleProps
 * @returns InstancePickerFieldMultiple
 */
export const InstancePickerFieldMultiple: React.FC<InstancePickerFieldMultipleProps> = ({
    defaultInstancesIds = [],
    onChange,
    selectTextFieldProps,
    selectedInstances = [],
}) => {
    const {
        sites,
        onOpen,
        status: searchStatus,
        getValueName,
        getValueId,
        onSearch,
        onInfiniteScroll,
        setIsOpen,
    } = useInstanceSelectMultiple();

    const hasDefaultInstancesSelected = defaultInstancesIds.length > 0;
    const { fetch, status } = useFetchSites({
        ids: defaultInstancesIds,
        userFavoritesOnly: false,
    });

    /**
     * We disable the component if we are currently fetching the provided instances.
     */
    const isSelectDisabled =
        hasDefaultInstancesSelected && (status === BaseLoadingStatus.initial || status === BaseLoadingStatus.loading);

    React.useEffect(() => {
        if (status === BaseLoadingStatus.initial && hasDefaultInstancesSelected) {
            fetch({ onFetch: (instances: Instance[]) => onChange(instances) });
        }
    }, [hasDefaultInstancesSelected, fetch, onChange, status]);

    return (
        <SelectTextField<Instance>
            autoFilter={false}
            getOptionId={getValueId}
            getOptionName={getValueName}
            {...selectTextFieldProps}
            selectionType="multiple"
            onSearch={onSearch}
            isDisabled={isSelectDisabled}
            onLoadMore={onInfiniteScroll}
            onChange={onChange}
            options={sites}
            value={selectedInstances}
            status={searchStatus}
            onOpen={() => {
                setIsOpen(true);

                if (onOpen) {
                    onOpen();
                }
            }}
        />
    );
};
