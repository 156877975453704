import React from 'react';

import { onInitialStateLoaded } from './onInitialStateLoaded';
import { didRequestFinish } from './utils';

declare const window: any;
/**
 * This hook adds the different listeners in order to retrieve the information from the
 * initial request. If the request has finished, we trigger the initial state loaded, and if it has not finished,
 * we add a listener to the request and trigger the onLoad when it is the right time.
 */
const useInitialState = () => {
    const [initialState, setInitialState] = React.useState<Record<string, any> | undefined>();

    React.useEffect(() => {
        if (didRequestFinish(window.initRequest)) {
            onInitialStateLoaded(setInitialState);
        } else {
            window.initRequest.addEventListener('load', () => onInitialStateLoaded(setInitialState));
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, []);

    return initialState || null;
};

export { useInitialState };
