import { learningFilledImage } from '@lumapps/lumx/custom-icons';
import { ColorPalette } from '@lumapps/lumx/react';
import { getUserFullName } from '@lumapps/user/utils/getUserFullName';

import { NOTIFICATIONS_CENTER } from '../../keys';
import { NotificationTypes, NotificationMeta, NotificationDescriptionParams } from '../../types';
import { getContentTitle } from '../contentUtils';
import { getLearningRoute } from '../linksUtils';

const getLearningDescription = (params: NotificationDescriptionParams, notificationType: string): string => {
    const { notification, senderDetails, translation, currentLanguage } = params;
    const learningTitle = getContentTitle(translation, notification);
    const authorName = getUserFullName(senderDetails, currentLanguage);

    switch (notificationType) {
        case NotificationTypes.LearningDuel:
            if (notification?.reason) {
                return translation.translateAndReplace(NOTIFICATIONS_CENTER.LEARNING_DUEL, {
                    AUTHOR_NAME: authorName,
                    CHALLENGER_MESSAGE: notification.reason,
                });
            }
            return translation.translateAndReplace(NOTIFICATIONS_CENTER.LEARNING_DUEL_DEFAULT, {
                AUTHOR_NAME: authorName,
            });

        case NotificationTypes.LearningReminder:
            return translation.translateAndReplace(NOTIFICATIONS_CENTER.LEARNING_REMINDER, {
                AUTHOR_NAME: authorName,
                LEARNING_TITLE: learningTitle,
            });
        default:
            return translation.translateAndReplace(NOTIFICATIONS_CENTER.LEARNING_CONGRATULATION, {
                AUTHOR_NAME: authorName,
                LEARNING_TITLE: learningTitle,
            });
    }
};

const notificationTypeLearning: Record<string, NotificationMeta> = {
    [NotificationTypes.LearningCongratulation]: {
        hasThumbnail: true,
        iconProps: {
            color: ColorPalette.grey,
            customIcon: learningFilledImage,
        },
        getDescription: (params) => getLearningDescription(params, NotificationTypes.LearningCongratulation),
        getRoute: getLearningRoute,
    },
    [NotificationTypes.LearningReminder]: {
        hasThumbnail: true,
        iconProps: {
            color: ColorPalette.grey,
            customIcon: learningFilledImage,
        },
        getDescription: (params) => getLearningDescription(params, NotificationTypes.LearningReminder),
        getRoute: getLearningRoute,
    },
    [NotificationTypes.LearningDuel]: {
        hasThumbnail: true,
        iconProps: {
            color: ColorPalette.grey,
            customIcon: learningFilledImage,
        },
        getDescription: (params) => getLearningDescription(params, NotificationTypes.LearningDuel),
        getRoute: getLearningRoute,
    },
    [NotificationTypes.LearningCourseBeginning]: {
        hasThumbnail: false,
        iconProps: {
            color: ColorPalette.blue,
            customIcon: learningFilledImage,
        },
        getDescription: (params) => getLearningDescription(params, NOTIFICATIONS_CENTER.LEARNING_COURSE_BEGINNING),
        getRoute: getLearningRoute,
    },
    [NotificationTypes.LearningCourseEndingToday]: {
        hasThumbnail: false,
        iconProps: {
            color: ColorPalette.blue,
            customIcon: learningFilledImage,
        },
        getDescription: (params) => getLearningDescription(params, NOTIFICATIONS_CENTER.LEARNING_COURSE_ENDING_TODAY),
        getRoute: getLearningRoute,
    },
    [NotificationTypes.LearningCourseEndingSoon]: {
        hasThumbnail: false,
        iconProps: {
            color: ColorPalette.blue,
            customIcon: learningFilledImage,
        },
        getDescription: (params) => getLearningDescription(params, NOTIFICATIONS_CENTER.LEARNING_COURSE_ENDING_SOON),
        getRoute: getLearningRoute,
    },
};

export { notificationTypeLearning };
