import { Route, createPageRoute, AppId } from '@lumapps/router';

const logoutRoute: Route = createPageRoute({
    slug: 'logout',
    legacyId: 'logout',
    appId: AppId.legacy,
});

const loginRoute: Route = createPageRoute({
    slug: 'login',
    legacyId: 'login',
    appId: AppId.legacy,
});

export { loginRoute, logoutRoute };
