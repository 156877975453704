import isEmpty from 'lodash/isEmpty';

import { addBaseSlugToSearchUrl } from '@lumapps/search/utils';

import { ASK_AI_ADMIN_ROOT_PATH } from './constants';
import {
    ACTIONABLE_ITEM_TYPES,
    ActionableItemPayload,
    AskAiDetailedFeedback,
    AskAiDirectoryEntryType,
    AskAiFeedbackTypes,
    AskAiNegativeFeedback,
} from './types';

export const getAskAiAdminPath = (path: string) => `${ASK_AI_ADMIN_ROOT_PATH}/${path}`;

/** Whether the given feedback is a negative feedback. */
export const isNegativeAskAiFeedback = (feedback: AskAiDetailedFeedback): feedback is AskAiNegativeFeedback =>
    feedback.type === AskAiFeedbackTypes.DISLIKED;

/** check if basic info are available for actionableItem and if actionable item is supported */
export const isActionableItemValid = (actionableItem: ActionableItemPayload) => {
    const hasPayload = !isEmpty(actionableItem.payload);
    if (hasPayload) {
        if (
            actionableItem.type === ACTIONABLE_ITEM_TYPES.MICRO_APP ||
            actionableItem.type === ACTIONABLE_ITEM_TYPES.USER_CARD
        ) {
            return Boolean(actionableItem.payload.id);
        }
        if (actionableItem.type === ACTIONABLE_ITEM_TYPES.IMAGES) {
            const hasImage = actionableItem.payload.images.length > 0;
            return !!hasImage;
        }
        if (actionableItem.type === ACTIONABLE_ITEM_TYPES.DIRECTORY_ENTRY) {
            // Do not display directory entry if there is no url
            const isValidLink =
                actionableItem.payload.directoryEntryType === AskAiDirectoryEntryType.DIRECTORY_ENTRY_LINK &&
                Boolean(actionableItem.payload.url);
            // Do not display directory entry with microapp if there is no resource Id
            const isValidApp =
                actionableItem.payload.directoryEntryType === AskAiDirectoryEntryType.DIRECTORY_ENTRY_MICROAPP &&
                Boolean(actionableItem.payload.resourceId);
            return isValidApp || isValidLink;
        }
    }
    return false;
};

/**
 * As the source urls comes from the search service,
 * the urls are the same and must be formatted as such.
 * Only /serve/ (media) urls are treated as special urls.
 * We use this instead of checking of the result type because we sometimes have
 * external sources that don't tag results as "media".
 */
export const formatSearchResultUrl = (url: string) => (url?.includes('/serve/') ? url : addBaseSlugToSearchUrl(url));
