import React from 'react';

import { useSelector } from '@lumapps/redux/react';

import { connectedUserSelector, isConnected } from '../../ducks/selectors';
import { UserProfileDropdown as UI, UserProfileDropdownProps } from './UserProfileDropdown';

export const UserProfileDropdown: React.FC<Omit<UserProfileDropdownProps, 'isConnected' | 'user'>> = (props) => {
    const isUserConnected = useSelector(isConnected);
    const user = useSelector(connectedUserSelector);

    return <UI {...props} isUserConnected={isUserConnected} user={user} />;
};
