import React, { RefObject } from 'react';

import { DropdownProps, Popover, Placement } from '@lumapps/lumx/react';
import { useSelector } from '@lumapps/redux/react';

import { isConnected } from '../../ducks/selectors';
import { MinimumViableUser } from '../../types';
import { UserCard, UserCardProps } from '../UserCard';

import './index.scss';

export interface UserCardPopoverProps extends UserCardProps {
    /** user to display */
    user: MinimumViableUser;
    /** element that the card will be anchored to */
    anchorRef: RefObject<HTMLElement>;
    /** determines whether the card is currently open or not */
    isDetailsOpen: boolean;
    /** whether the user card should display the email or not */
    hideEmail?: boolean;
    /** whether the user card should display the email or not */
    showFollow?: boolean;
    /** callback to be executed */
    onClose: DropdownProps['onClose'];
    /** whether the user card is loading or not */
    isLoading?: boolean;
    /** callback on mouse enter */
    onMouseEnter?: () => void;
    /** optional user block classname */
    className?: string;
    /** optional popover placement */
    placement?: Placement;
    /** whether the user card should display actions or not */
    hideActions?: boolean;
}

const NAMESPACE = 'user-card-popover';
const UserCardPopover: React.FC<UserCardPopoverProps> = ({
    anchorRef,
    isDetailsOpen,
    onMouseEnter,
    onClose,
    placement,
    ...props
}) => {
    const isCurrentUserConnected = useSelector(isConnected);

    const onMouseLeave = () => {
        if (onClose) {
            onClose();
        }
    };

    if (!isCurrentUserConnected) {
        return null;
    }

    return (
        <Popover
            anchorRef={anchorRef}
            isOpen={isDetailsOpen}
            onClose={onClose}
            offset={{ away: 8 }}
            placement={placement}
            className={NAMESPACE}
        >
            <UserCard
                shouldFetchUser
                {...props}
                wrapperProps={{
                    onMouseLeave,
                    onMouseEnter,
                }}
            />
        </Popover>
    );
};

export * from '../../hooks/useUserCardPopover';

export { UserCardPopover };
