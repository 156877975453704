import React from 'react';

import { COMMUNITIES } from '@lumapps/communities/keys';
import { communitiesAdminRoute } from '@lumapps/community-admin/routes';
import { useDataAttributes } from '@lumapps/data-attributes';
import { Menu } from '@lumapps/lumx-menu/components/Menu';
import { mdiGoogleCirclesExtended } from '@lumapps/lumx/icons';
import { Link as RouterLink } from '@lumapps/router';
import { useTranslate } from '@lumapps/translations';

export interface CommunitiesModuleSettingsMenuEntryProps {
    isAllowed: boolean;
    scope: string;
}

export const CommunitiesModuleSettingsMenuEntry: React.FC<CommunitiesModuleSettingsMenuEntryProps> = ({
    isAllowed,
    scope,
}) => {
    const { translateKey } = useTranslate();
    const { get } = useDataAttributes(scope);

    if (!isAllowed) {
        return null;
    }

    return (
        <Menu.Item
            icon={mdiGoogleCirclesExtended}
            as={RouterLink}
            to={communitiesAdminRoute}
            {...get({ element: 'communities' })}
        >
            {translateKey(COMMUNITIES.NAV)}
        </Menu.Item>
    );
};
