import React from 'react';

import { get as getConfig } from '@lumapps/constants';
import { AppId } from '@lumapps/constants/app';

import { MinimumViableUser, UserAccountType } from '../types';
import { useUser, UseUserOptions } from './useUser';

const DELAY = 500;

export interface UseUserCardPopoverResult {
    /**
     * Function to be called when the mouse leaves the user card popover.
     */
    onMouseLeave: () => void;
    /**
     * Function to be called when the mouse enters the user card popover.
     */
    onMouseEnter: () => void;
    /**
     * Boolean indicating whether the user data is currently loading.
     */
    isLoadingUser: boolean;
    /**
     * The user profile data to be displayed in the card.
     */
    userProfile: MinimumViableUser;
    /**
     * Boolean indicating whether the user card should be displayed.
     */
    showUserCard: boolean;
}

export interface UseUserCardPopoverOptions extends UseUserOptions {
    delay?: number;
}

const { applicationId } = getConfig();

/**
 * A hook to manage the state and behavior of a user card popover.
 * It handles hovering, loading, and displaying user information.
 *
 * @param user The base user object to display in the popover.
 * @returns Options for the user card popover, including event handlers, loading state, and user profile data.
 */
export const useUserCardPopover = (
    user: MinimumViableUser,
    options?: UseUserCardPopoverOptions,
): UseUserCardPopoverResult => {
    const { delay, enabled = true, ...restOfOptions } = options || {};
    const [isHovered, setIsHovered] = React.useState<boolean>(false);
    const [showUserCard, setShowUserCard] = React.useState<boolean>(false);
    const baseUser = {
        accountType: UserAccountType.GOOGLE,
        fullName: '',
        id: '',
        email: '',
        lastName: '',
        firstName: '',
        jobTitle: '',
        ...user,
    };

    const { data, isLoading } = useUser(baseUser, {
        staleTime: 2 * 60 * 1000, // 2 minutes,
        cacheTime: 5 * 60 * 1000, // 5 minutes
        ...restOfOptions,
        enabled: enabled && showUserCard,
    });

    const isWebview = applicationId === AppId.webview;
    // Disable on webview app or if neither the id nor the email is provided.
    const isUserCardDisplayable = !isWebview && (Boolean(user?.email) || Boolean(user?.id));

    /**
     * Handler function to set the hovered state to true.
     */
    const onMouseEnter = () => {
        setIsHovered(true);
    };

    /**
     * Handler function to set the hovered state to false.
     */
    const onMouseLeave = () => {
        setIsHovered(false);
    };

    /**
     * When either the trigger element or the popover itself hover status changes,
     * change status after given delay and clear previous timeout.
     */
    React.useEffect(() => {
        const openTimer = setTimeout(() => {
            if (isUserCardDisplayable) {
                setShowUserCard(isHovered);
            }
        }, delay || DELAY);

        // clear timeout
        return () => clearTimeout(openTimer);
    }, [isHovered, isUserCardDisplayable, delay]);

    return {
        onMouseLeave,
        onMouseEnter,
        isLoadingUser: isLoading,
        userProfile: data || baseUser,
        showUserCard,
    };
};
