import React, { lazy, useEffect, useMemo, useReducer, useState } from 'react';

import { instanceIdSelector } from '@lumapps/instance/ducks/selectors';
import { useSelector } from '@lumapps/redux/react';
import { useParams } from '@lumapps/router';
import { getParameterByName } from '@lumapps/router/utils';
import { Suspense } from '@lumapps/utils/components/Suspense';
import { useDetectMobileAppWebView } from '@lumapps/utils/hooks/useDetectMobileAppWebView';

import { AssistantContext } from '../../context';
import { isDigitalAssistantEnabled } from '../../ducks/selectors';
import { ConversationStatusTypes, actions, initialState, reducer } from '../../ducks/slice';
import { startConversation } from '../../ducks/thunks';
import { AssistantWidgetLoading } from '../AssistantWidgetLoading';

const AssistantWidget = lazy(
    () =>
        import(
            /* webpackChunkName: 'assistant-main-window' */
            '../AssistantWidget'
        ),
);

export const DigitalAssistant: React.FC<{ isOpen?: boolean; fullScreen?: boolean }> = (props) => {
    const { chatbotId } = useParams<{ chatbotId: string }>();
    const [state, dispatch] = useReducer(reducer, initialState);
    const [conversationStarted, setConversationStarted] = useState<boolean>(false);
    const isMobileWebview = useDetectMobileAppWebView();
    const isEnabled = useSelector(isDigitalAssistantEnabled);
    const appIdFromUrl = getParameterByName('digital-assistant') || undefined;
    const isHistoryHidden = getParameterByName('no-history') === 'true';
    const restartConversation = getParameterByName('restart') === 'true';
    const siteId = useSelector(instanceIdSelector);

    const assistantContextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

    const { isOpen } = props;

    useEffect(() => {
        if (isEnabled && !conversationStarted) {
            if (!state.appID && (appIdFromUrl || chatbotId)) {
                dispatch(actions.setAppID(appIdFromUrl || chatbotId));
            }
            // initialize the conversation
            startConversation(
                {
                    appId: appIdFromUrl || chatbotId,
                    siteId,
                },
                { isHistoryHidden, restartConversation },
                dispatch,
            );
            setConversationStarted(true);
        }
    }, [
        appIdFromUrl,
        chatbotId,
        conversationStarted,
        isEnabled,
        isHistoryHidden,
        restartConversation,
        siteId,
        state.appID,
    ]);

    return isEnabled && state.status === ConversationStatusTypes.live && (!isMobileWebview || isOpen) ? (
        <AssistantContext.Provider value={assistantContextValue}>
            <Suspense loadingFallback={<AssistantWidgetLoading />}>
                <AssistantWidget {...props} />
            </Suspense>
        </AssistantContext.Provider>
    ) : null;
};
