import { BaseSearchResult } from '@lumapps/search/types';
import { getExtensionFromName } from '@lumapps/utils/file/getExtensionFromName';

import { buildGenericDocumentCustomMetadata, getIconFromType } from '../../helpers';
import { isCommentSearchResultMimeType } from '../../utils/isCommentSearchResultMimeType';
import { isExternalResultUrl } from '../../utils/isExternalResultUrl';
import { props as contentMetadataProps } from '../ContentMetadata/props';
import { ContentMetadataPropsOptions } from '../ContentMetadata/types';
import { useProps } from '../GenericResult/props';

const props = (
    result: BaseSearchResult,
    baseProps: ReturnType<typeof useProps>,
    options: ContentMetadataPropsOptions,
) => {
    const { translate } = options;
    const { url, name, snippet, onClick, flag, quickViewUrl, onQuickViewClick, thumbnail } = baseProps;
    const bProps = {
        url,
        name,
        snippet,
        flag,
        onClick,
        quickViewUrl,
        onQuickViewClick,
    };

    const metadata = contentMetadataProps(result, options);

    const { genericDocument, position, tagz } = result;

    if (!genericDocument) {
        return bProps;
    }

    const iconName = getIconFromType(genericDocument.mimeType, getExtensionFromName(name));

    const customMetadata = buildGenericDocumentCustomMetadata(genericDocument, translate);

    const shouldOpenInNewTab = isExternalResultUrl(url);

    /**
     * If the search result is of comment type, add the anchor to the comment section
     */
    const urlAnchor = isCommentSearchResultMimeType(genericDocument.mimeType) ? '#comments' : undefined;

    /** As comments have the actual comment as title, we need to truncate the title */
    const truncateTitle = isCommentSearchResultMimeType(genericDocument.mimeType);

    return {
        ...bProps,
        truncateTitle,
        urlAnchor,
        position,
        image: genericDocument.thumbnail,
        id: genericDocument.id,
        author: genericDocument.author,
        kind: genericDocument.source,
        color: genericDocument.color,
        fields: genericDocument.fields,
        mimeType: genericDocument.mimeType,
        shouldOpenInNewTab,
        customMetadata,
        metadata,
        iconName,
        thumbnail,
        tagz: tagz || [],
        isPromoted: Boolean(result.isPromoted),
    };
};

export { props };
