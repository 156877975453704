import { Route, createPageRoute, AppId } from '@lumapps/router';
import { GLOBAL } from '@lumapps/translations';

export const userSettingsPrefix = 'user/settings';

export enum USER_SETTINGS_VIEWS {
    GENERAL = 'general',
    NOTIFICATIONS = 'notifications',
    INTEGRATIONS = 'integrations',
}

export const USER_SETTINGS_VIEWS_TITLES: Record<USER_SETTINGS_VIEWS, string> = {
    [USER_SETTINGS_VIEWS.GENERAL]: GLOBAL.GENERAL,
    [USER_SETTINGS_VIEWS.INTEGRATIONS]: GLOBAL.INTEGRATIONS,
    [USER_SETTINGS_VIEWS.NOTIFICATIONS]: GLOBAL.NOTIFICATIONS,
};

export const userSettingsRoute: Route = createPageRoute({
    slug: 'user/settings/:view',
    legacyId: 'app.front.user.settings',
    appId: AppId.frontOffice,
});

/**
 * Parameters for redirecting to user settings page
 */
export interface UserSettingsRouteParams {
    /**
     * The view to redirect to.
     */
    view?: USER_SETTINGS_VIEWS;
}
export interface UserSettingsQueryParams {
    unsubscribeAll: string;
}

export const getUserSettingsRoute = (view?: USER_SETTINGS_VIEWS, query?: UserSettingsQueryParams): Route => {
    const queryParams: Record<string, string> = {};

    // not an actual filter but the param to unsubscribe all email from url/email
    if (query?.unsubscribeAll) {
        queryParams.unsubscribeAll = query.unsubscribeAll;
    }

    return {
        ...userSettingsRoute,
        params: {
            view,
        },
        query: queryParams,
    };
};
