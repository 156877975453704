import { isFeatureEnabled } from '@lumapps/features';

import { LEARNING_ALPHA_FF, LEARNING_FF, LEARNING_BETA_FF, LEARNING_TRAINING_PAGE_REACT } from '../constants';

export const isLearningAlphaEnabled = isFeatureEnabled(LEARNING_ALPHA_FF);

export const isLearningBetaEnabled = isFeatureEnabled(LEARNING_BETA_FF);

export const isLearningEnabled = isFeatureEnabled(LEARNING_FF);

export const isLearningTrainingPageReactEnabled = isFeatureEnabled(LEARNING_TRAINING_PAGE_REACT);
