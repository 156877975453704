import { Route, createPageRoute, AppId } from '@lumapps/router';

const historyRoute: Route = createPageRoute({
    slug: 'reading-history',
    appId: AppId.frontOffice,
});

const history = (): Route => ({
    ...historyRoute,
    params: {},
});

export { history, historyRoute };
