import React from 'react';

import debounce from 'lodash/debounce';

import { useClassnames, element } from '@lumapps/classnames';
import { REAL_SIZE_FOR_LUMX_SIZE } from '@lumapps/lumx/constants';
import { mdiChevronLeft, mdiChevronRight } from '@lumapps/lumx/icons';
import { IconButton, FlexBox, FlexBoxProps } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import './index.scss';

type InheritedProps = React.ComponentPropsWithoutRef<'div'>;

export interface ArrowSideScrollContainerProps extends InheritedProps {
    children: React.ReactNode;
    scrollZoneProps?: FlexBoxProps;
}

export const CLASSNAME = 'lumx-arrow-side-scroll-container';

// Warning: Keep those constants in sync with the SCSS

const PADDING = 8;
const ARROW_WIDTH = REAL_SIZE_FOR_LUMX_SIZE.m;
const ARROW_GRADIENT_MARGIN = REAL_SIZE_FOR_LUMX_SIZE.s;
const ARROW_FULL_WIDTH = ARROW_WIDTH + ARROW_GRADIENT_MARGIN;

/**
 * A scrollable horizontal FlexBox but with scrollbars replaced by left/right arrow buttons.
 *
 * Warning: you **must** wrap the items in the container with `ArrowSideScrollContainer.Item` and
 *   they **must** support className forwarding. (see stories for example)
 *
 * @family Layouts
 */
export const ArrowSideScrollContainer = ({
    children,
    scrollZoneProps,
    ...forwardProps
}: ArrowSideScrollContainerProps) => {
    const { block, element } = useClassnames(CLASSNAME);
    const ref = React.useRef<HTMLDivElement>(null);
    const { translateKey } = useTranslate();
    const [scrollZone, setScrollZone] = React.useState<HTMLDivElement | null>(null);
    const [showArrowLeft, setShowArrowLeft] = React.useState(false);
    const [showArrowRight, setShowArrowRight] = React.useState(false);

    React.useEffect(() => {
        if (!scrollZone) {
            return undefined;
        }

        const updateScrollState = debounce(
            () => {
                const hasScroll = scrollZone.scrollWidth > scrollZone.clientWidth;

                setShowArrowLeft(hasScroll && Math.floor(scrollZone.scrollLeft) > 0);
                setShowArrowRight(
                    hasScroll && Math.ceil(scrollZone.scrollLeft) + scrollZone.clientWidth < scrollZone.scrollWidth,
                );
            },
            100,
            { leading: true, trailing: true },
        );

        // Update scroll state on resize
        const observer = new ResizeObserver(updateScrollState);
        observer.observe(scrollZone);

        // Update scroll state on scroll
        scrollZone.addEventListener('scroll', updateScrollState);

        // Scroll focused element into view
        const scrollTargetIntoView = (event: FocusEvent) => {
            const target = event.target as HTMLElement;
            // Skip if focusing the scroll zone or ig not a direct child
            if (target === scrollZone || !scrollZone.contains(target)) {
                return;
            }

            // Scroll target item into view
            requestAnimationFrame(() => {
                target.scrollIntoView?.({ inline: 'start', block: 'nearest' });
            });
        };
        scrollZone.addEventListener('focus', scrollTargetIntoView, { capture: true });

        return () => {
            scrollZone.removeEventListener('focus', scrollTargetIntoView);
            scrollZone.removeEventListener('scroll', updateScrollState);
            observer.disconnect();
        };
    }, [scrollZone]);

    // Callback on arrow left/right click
    const [onScrollLeft, onScrollRight] = React.useMemo(() => {
        function scrollSide(direction: 1 | -1) {
            return () => {
                if (!scrollZone) {
                    return;
                }

                // Estimated scroll page width removing the paddings and arrows
                const scrollPageWidth = scrollZone.clientWidth - PADDING * 2 - ARROW_FULL_WIDTH * 2;
                const offsetLeft = direction * scrollPageWidth;
                const maxLeft = scrollZone.scrollWidth - scrollZone.clientWidth;

                // Scroll a page to the right/left
                scrollZone.scrollTo({
                    left: Math.min(scrollZone.scrollLeft + offsetLeft, maxLeft),
                });
            };
        }
        return [scrollSide(-1), scrollSide(1)];
    }, [scrollZone]);

    return (
        <div {...forwardProps} ref={ref} className={block([forwardProps.className])}>
            {showArrowLeft && (
                <IconButton
                    className={element('arrow', { left: true })}
                    label={translateKey(GLOBAL.PREVIOUS)}
                    icon={mdiChevronLeft}
                    emphasis="low"
                    onClick={onScrollLeft}
                />
            )}
            <FlexBox
                {...scrollZoneProps}
                ref={setScrollZone}
                className={element('scroll', [scrollZoneProps?.className])}
                style={{
                    ...scrollZoneProps?.style,
                    // Enable/disable arrow left/right masking gradient
                    '--_arrow-right-alpha': showArrowRight ? '0' : '1',
                    '--_arrow-left-alpha': showArrowLeft ? '0' : '1',
                }}
                orientation="horizontal"
                tabIndex={0}
            >
                {children}
            </FlexBox>
            {showArrowRight && (
                <IconButton
                    className={element('arrow', { right: true })}
                    label={translateKey(GLOBAL.NEXT)}
                    icon={mdiChevronRight}
                    emphasis="low"
                    onClick={onScrollRight}
                />
            )}
        </div>
    );
};

/** Wraps items in the ArrowSideScrollContainer and injects the item className. */
ArrowSideScrollContainer.Item = function Item({ children }: { children: React.ReactElement }) {
    const { element } = useClassnames(CLASSNAME);
    const child = React.Children.only(children);
    return React.cloneElement(child, { className: element('item', [child.props.className]) });
};

export const ARROW_SIDE_SCROLL_CONTAINER_ITEM_CLASSNAME = element(CLASSNAME)('item');
