import { error } from '@lumapps/notifications';
import { useDispatch } from '@lumapps/redux/react';
import { notAllowedRoute } from '@lumapps/router';
import { GLOBAL } from '@lumapps/translations';

/**
 * Handles `#not-allowed` URL hash by displaying a "Not Allowed" toaster message
 * and then removing it from the URL.
 */
export const useHandleNotAllowed = (isEnabled = true) => {
    const dispatch = useDispatch();
    if (!isEnabled || window.location.hash !== notAllowedRoute.anchor) {
        return;
    }
    // Display a generic "Not Allowed" toaster message
    dispatch(error({ translate: GLOBAL.ERROR_NOT_AUTHORIZED }));
    // Clear the URL hash
    window.history.replaceState(null, '', ' ');
};
