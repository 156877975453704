import React from 'react';

import { padding, classnames } from '@lumapps/classnames';
import { ARROW_SIDE_SCROLL_CONTAINER_ITEM_CLASSNAME } from '@lumapps/lumx-layouts/components/ArrowSideScrollContainer';
import { ColorPalette, Text, Typography, FlexBox, Orientation, Alignment, GapSize } from '@lumapps/lumx/react';

import { FILTERS_MODE } from '../../constants';
import { UnifiedFilterProps } from '../Filter/types';
import { FiltersDropdown } from '../FiltersDropdown';
import { GroupedFilter } from '../GroupedFilter';

/**
 * Type of filter used when the filtering mode is set to exposed facet.
 * This means that each filter will be rendered individually on the page in a separate popover.
 * @family Filters
 * @param UnifiedFilterProps
 * @returns ExposedFacetFilter
 */
export const ExposedFacetFilter: React.FC<UnifiedFilterProps> = ({
    id,
    title,
    children,
    className,
    isFieldset,
    titleId,
    onClearClick,
    onClose,
    onFilter,
    triggerProps,
    helper,
    shouldDisplayOptionsDirectly,
    tooltipProps,
    ...props
}) => {
    if (!onClearClick || !onClose || !onFilter) {
        return null;
    }

    const Wrapper = helper ? FlexBox : React.Fragment;
    const wrapperProps = helper
        ? {
              className: ARROW_SIDE_SCROLL_CONTAINER_ITEM_CLASSNAME,
              orientation: Orientation.horizontal,
              gap: 'regular' as GapSize,
              hAlign: Alignment.center,
          }
        : {};

    return (
        <Wrapper {...wrapperProps}>
            {/**
             * /!\ This is not a good practice. /!\
             * In order to be accessible, this should be a `label` linked to the filter using `htmlFor`.
             * However since the Filter already doesn't follow an accessible pattern, linking a label to it might
             * actually be more confusing.
             * */}

            {helper ? (
                <Text
                    as="p"
                    color={ColorPalette.dark}
                    colorVariant="L2"
                    typography={Typography.body1}
                    className={padding('bottom')}
                >
                    {helper}
                </Text>
            ) : null}

            {!shouldDisplayOptionsDirectly ? (
                <FiltersDropdown
                    onClearClick={onClearClick}
                    onClose={onClose}
                    onFilter={onFilter}
                    onClearAll={onClearClick}
                    mode={FILTERS_MODE.EXPOSED_FACETS}
                    {...props}
                    dropdownProps={{
                        ...props.dropdownProps,
                        usePortal: true,
                    }}
                    label={title}
                    title={title}
                    triggerProps={{
                        ...triggerProps,
                        className: classnames(triggerProps?.className, ARROW_SIDE_SCROLL_CONTAINER_ITEM_CLASSNAME),
                        before: undefined,
                    }}
                    tooltipProps={tooltipProps}
                >
                    <GroupedFilter
                        hideTitle
                        isFieldset={isFieldset}
                        title={title}
                        id={id}
                        className={className}
                        titleId={titleId}
                    >
                        {children}
                    </GroupedFilter>
                </FiltersDropdown>
            ) : (
                children
            )}
        </Wrapper>
    );
};
