import { Route, createPageRoute, AppId } from '@lumapps/router';

const communityAnalyticsRoute: Route = createPageRoute({
    slug: 'communities/:id/analytics',
    appId: AppId.backOffice,
});

const communityAnalytics = (communityId: string): Route => ({
    ...communityAnalyticsRoute,
    params: {
        id: communityId,
    },
});

export { communityAnalytics, communityAnalyticsRoute };
