import filter from 'lodash/filter';
import first from 'lodash/first';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import set from 'lodash/set';

import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';
import { mergeObjectOnly } from '@lumapps/utils/object/mergeObjectOnly';
import { createEmptyContent } from '@lumapps/wrex/utils/createEmptyContent';

import { EditedComment, Comment } from '../types';
import { CommentWithReplies, CommentList, CommentState } from './types';

export const domain = 'comment';

export const DEFAULT_EDITOR = {
    content: '',
    files: [],
    images: [],
    links: [],
    text: createEmptyContent(),
    parent: '',
    mentions: [],
    isEditorVisible: true,
};

export const initialState: CommentState = {
    commentEditors: {},
    currentEditor: {},
    commentListByContent: {},
};

const slice = createSlice({
    domain,
    initialState,
    reducers: {
        setCurrentEditor: (state: CommentState, action: PayloadAction<CommentState['currentEditor']>) => {
            set(state, 'currentEditor', action.payload);
        },
        setEditedComment: (state: CommentState, action: PayloadAction<Partial<EditedComment>>) => {
            const { text, ...newComment } = action.payload;
            const { content, parent } = action.payload;
            if (content) {
                let targetEditor = state.commentEditors[content]?.editor;
                if (parent) {
                    if (newComment.id) {
                        targetEditor =
                            state.commentEditors[content]?.repliesEditors[parent]?.editionEditors[newComment.id];
                    } else {
                        targetEditor = state.commentEditors[content]?.repliesEditors[parent]?.editor;
                    }
                } else if (newComment.id) {
                    targetEditor = state.commentEditors[content].commentsEditors[newComment.id];
                }
                mergeObjectOnly(targetEditor, newComment);
                if (text) {
                    let path = ['commentEditors', content, 'editor', 'text'];
                    if (parent) {
                        if (newComment.id) {
                            path = [
                                'commentEditors',
                                content,
                                'repliesEditors',
                                parent,
                                'editionEditors',
                                newComment.id,
                                'text',
                            ];
                        } else {
                            path = ['commentEditors', content, 'repliesEditors', parent, 'editor', 'text'];
                        }
                    } else if (newComment.id) {
                        path = ['commentEditors', content, 'commentsEditors', newComment.id, 'text'];
                    }

                    set(state, path, text);
                }
            }
        },
        setEditedCommentSaving: (
            state: CommentState,
            action: PayloadAction<{ isSaving?: boolean; content: string }>,
        ) => {
            const { content, isSaving } = action.payload;
            const editor = state.commentEditors[content];
            if (editor) {
                editor.isSaving = isSaving;
            }
        },
        initEditor: (state: CommentState, action: PayloadAction<Partial<EditedComment>>) => {
            const newComment = { ...DEFAULT_EDITOR, ...action.payload };
            const { content, parent, id } = action.payload;

            if (content) {
                if (!parent) {
                    if (id) {
                        const otherComments = get(state, ['commentEditors', content, 'commentsEditors']);
                        set(state, ['commentEditors', content, 'commentsEditors'], {
                            ...otherComments,
                            [id]: newComment,
                        });
                    } else {
                        set(state, ['commentEditors', content], { editor: newComment, repliesEditors: {} });
                    }
                } else if (id) {
                    const otherComments = get(state, [
                        'commentEditors',
                        content,
                        'repliesEditors',
                        parent,
                        'editionEditors',
                    ]);
                    set(state, ['commentEditors', content, 'repliesEditors', parent, 'editionEditors'], {
                        ...otherComments,
                        [id]: newComment,
                    });
                } else {
                    set(state, ['commentEditors', content, 'repliesEditors', parent], { editor: newComment });
                }
            }
        },
        setCommentList: (
            state: CommentState,
            action: PayloadAction<{ contentId: string; contentCommentState: Partial<CommentList> }>,
        ) => {
            const { contentCommentState, contentId } = action.payload;
            const targetCommentList = state.commentListByContent[contentId];
            mergeObjectOnly(targetCommentList, contentCommentState);
        },
        initComments: (
            state: CommentState,
            action: PayloadAction<{ contentId: string; contentCommentState: Partial<CommentList> }>,
        ) => {
            const { contentCommentState, contentId } = action.payload;
            const targetCommentList = state.commentListByContent[contentId];

            if (targetCommentList) {
                mergeObjectOnly(targetCommentList, contentCommentState);
            } else {
                set(state, ['commentListByContent', contentId], contentCommentState);
            }
        },
        updateComment: (
            state: CommentState,
            action: PayloadAction<{
                contentId: string;
                commentId: string;
                parentId?: string;
                comment: Partial<Comment>;
            }>,
        ) => {
            const { contentId, parentId, commentId, comment } = action.payload;
            const target = parentId
                ? state.commentListByContent[contentId].commentsEntities[parentId].replies?.find(
                      (reply) => reply.uid === commentId,
                  )
                : state.commentListByContent[contentId].commentsEntities[commentId].comment;
            if (target) {
                mergeObjectOnly(target, comment);
            }
        },
        updateMultipleComments: (
            state: CommentState,
            action: PayloadAction<{
                contentId: string;
                commentsIds: string[];
                parentId?: string;
                comment: Partial<Comment>;
            }>,
        ) => {
            const { contentId, parentId, commentsIds, comment } = action.payload;
            forEach(commentsIds, (commentId) => {
                const target = parentId
                    ? state.commentListByContent[contentId].commentsEntities[parentId].replies?.find(
                          (reply) => reply.uid === commentId,
                      )
                    : state.commentListByContent[contentId].commentsEntities[commentId].comment;
                if (target) {
                    mergeObjectOnly(target, comment);
                }
            });
        },
        updateCommentStatus: (
            state: CommentState,
            action: PayloadAction<{
                contentId: string;
                commentId: string;
                status: Omit<CommentWithReplies, 'comment' | 'replies'>;
            }>,
        ) => {
            const { contentId, commentId, status } = action.payload;
            const targetComment = state.commentListByContent[contentId].commentsEntities[commentId];
            if (targetComment) {
                mergeObjectOnly(targetComment, status);
            }
        },
        setCommentIsAlreadyReported: (
            state: CommentState,
            action: PayloadAction<{
                commentId: string;
                parentContentId: string;
                parentCommentId?: string;
            }>,
        ) => {
            const { parentContentId, commentId, parentCommentId } = action.payload;
            if (parentCommentId) {
                const commentReplies =
                    state.commentListByContent[parentContentId].commentsEntities[parentCommentId].replies;
                const targetReply = first(
                    filter(commentReplies, (reply: Comment | undefined) => reply?.uid === commentId),
                );
                mergeObjectOnly(targetReply as any, { isCommentAlreadyReported: true });
            } else {
                const targetComment = state.commentListByContent[parentContentId].commentsEntities[commentId].comment;
                mergeObjectOnly(targetComment, { isCommentAlreadyReported: true });
            }
        },
        updateCommentReplies: (
            state: CommentState,
            action: PayloadAction<{
                contentId: string;
                commentId: string;
                replies: Comment[];
                add?: boolean;
            }>,
        ) => {
            const { contentId, commentId, replies, add } = action.payload;
            const targetComment = state.commentListByContent[contentId].commentsEntities[commentId];
            set(targetComment, 'replies', add ? [...targetComment.replies, ...replies] : replies);
        },
    },
});

const { actions, reducer } = slice;

export { actions, reducer };
