import React from 'react';

import {
    Emphasis,
    Size,
    Button,
    SkeletonRectangle,
    SkeletonRectangleVariant,
    BaseButtonProps,
} from '@lumapps/lumx/react';
import { useTranslate, GLOBAL } from '@lumapps/translations';
import { useSocialProfileRoute } from '@lumapps/user-profile/hooks/useSocialProfileRoute';

import { MinimumViableUser } from '../../types';

export interface MoreInformationLinkProps {
    user: MinimumViableUser;
}

/**
 * The skeleton of the "more information" link.
 *
 * Used to show a loading state.
 */
export const MoreInformationSkeleton: React.FC<any> = () => (
    <SkeletonRectangle
        variant={SkeletonRectangleVariant.rounded}
        height={Size.m}
        width={Size.xxl}
        style={{ height: '16px', width: '90px', margin: '0' }}
    />
);

/**
 * The "more information" link of the user popover card.
 *
 * Links to the user profile.
 */
export const MoreInformationLink: React.FC<MoreInformationLinkProps> = ({ user }) => {
    const { translateKey } = useTranslate();
    const {
        linkProps: { target, ...linkProps },
    } = useSocialProfileRoute({ routeParams: { userId: user.id }, userData: user });

    return (
        <Button
            emphasis={Emphasis.low}
            color="primary"
            size={Size.s}
            target={target as BaseButtonProps['target']}
            {...linkProps}
        >
            {translateKey(GLOBAL.MORE_INFORMATION)}
        </Button>
    );
};
