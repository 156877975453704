import { createEntityManagementRoutes, createPageRoute, Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

import { CAMPAIGN_PROGRESSION, CAMPAIGN_STATUS } from './types';

export enum CAMPAIGN_VIEWS {
    EDIT = 'edit',
    VIEW = 'view',
    BROADCASTS = 'broadcasts',
}

const campaignRouteSlug = 'campaigns';

export interface CampaignBaseRouteParams {
    title?: string;
    statuses?: CAMPAIGN_STATUS[];
    progressions?: CAMPAIGN_PROGRESSION[];
}

/**
 * Create all basic routes and getters needed on journeys page
 * - /campaigns (list)
 * - /campaigns/settings
 * - /campaigns/create
 * - /campaigns/:id (campaign view)
 * - /campaigns/:id/edit
 * - /campaigns/:id/broadcasts
 * We only include broadcasts as additional modes since view and edit are already included natively
 */
export const campaignRoutes = {
    ...createEntityManagementRoutes({ appId: AppId.backOffice, slug: campaignRouteSlug }, [CAMPAIGN_VIEWS.BROADCASTS]),
    settingsRoute: createPageRoute({ appId: AppId.backOffice, slug: `${campaignRouteSlug}/settings` }),
    /**
     * We override native getBaseRoute to handle query params properly
     */
    getBaseRoute: (query?: CampaignBaseRouteParams): Route => {
        const queryParams: Record<string, string> = {};

        if (query?.title) {
            queryParams.title = query.title;
        }

        if (query?.statuses?.length) {
            queryParams.statuses = query.statuses.join(',');
        }

        if (query?.progressions?.length) {
            queryParams.progressions = query.progressions.join(',');
        }

        return {
            ...campaignRoutes.baseRoute,
            ...(Object.keys(queryParams).length ? { query: queryParams } : {}),
        };
    },
};
