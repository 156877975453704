import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { Menu } from '@lumapps/lumx-menu/components/Menu';
import { UserBlock } from '@lumapps/lumx/react';
import { Link as RouterLink } from '@lumapps/router';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { useSocialProfileRoute } from '@lumapps/user-profile/hooks/useSocialProfileRoute';

import { useUser } from '../../hooks/useUser';
import { MinimumViableUser, UserMenuLink } from '../../types';
import { UserMenuItem } from './UserMenuItem';

export type UserMenuProps = {
    /** Custom links like `logout` */
    links?: UserMenuLink[];
    /** scope where this component is used. This prop will be used for tracking purposes */
    scope: string;
    /** true if we do not wish to display the user block */
    withoutUserBlock?: boolean;
    /** user for which the menu will be displayed */
    user: MinimumViableUser;
};

const CLASSNAME = 'user-menu';

export const UserMenu: React.FC<UserMenuProps> = ({ links = [], scope, withoutUserBlock, user }) => {
    const { getUserFullName, avatarProps } = useUser(user);
    const { translateKey } = useTranslate();
    const { get } = useDataAttributes(scope as string);
    const { route } = useSocialProfileRoute({});
    const { element } = useClassnames(CLASSNAME);

    return (
        <>
            {!withoutUserBlock && (
                <>
                    <Menu.Item
                        className={element('profile')}
                        as={RouterLink}
                        to={route}
                        {...get({ element: 'user-profile' })}
                    >
                        <UserBlock
                            className={element('profile-block')}
                            name={getUserFullName()}
                            fields={[translateKey(GLOBAL.VIEW_USER_PROFILE)]}
                            avatarProps={{
                                ...avatarProps,
                                alt: '',
                            }}
                        />
                    </Menu.Item>
                    {links.length > 0 && <Menu.Divider />}
                </>
            )}

            {links.map(({ key, url, isLoading: isLoadingItem, target, ...other }) =>
                isLoadingItem ? (
                    <Menu.ItemSkeleton key={key} hasIcon loadingMessage={translateKey(GLOBAL.LOADING)} />
                ) : (
                    <UserMenuItem key={key} {...other} linkProps={{ href: url, target }} id={key} scope={scope} />
                ),
            )}
        </>
    );
};
