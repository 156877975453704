/* istanbul ignore file */
/** Ignoring the coverage for this file since we should not be testing that code is downloaded lazily */
import replace from 'lodash/replace';
import moment from 'moment';

import { DEFAULT_LANGUAGE } from '@lumapps/languages';
import { TranslationConfig } from '@lumapps/translations';

const useMomentLocale = ({ translations }: { translations: TranslationConfig | null }) => {
    // Set moment's user locale globally
    const lang = translations?.preferredLanguage;
    // Dynamically import the lang if it is not the default one
    if (lang && lang !== DEFAULT_LANGUAGE) {
        const normalizedLang = replace(lang, '_', '-');
        import(/* webpackChunkName: "[request]" */ `moment/locale/${normalizedLang}`)
            .then(() => {
                moment.locale(lang);
            })
            .catch(() => {
                // If the language is not supported by moment, use the default one
                moment.locale(DEFAULT_LANGUAGE);
            });
    }
};

export { useMomentLocale };
