import { USER_BOOKMARKS_RESOURCE_TYPE } from '@lumapps/user-bookmarks/constants';

export const USER_SAVED_ITEMS_SCOPE = 'saved-items';
export const USER_SAVED_ITEM_SCOPE = 'saved-item';

export const SAVED_ITEMS_RESOURCE_TYPES = {
    content: USER_BOOKMARKS_RESOURCE_TYPE.content,
    post: USER_BOOKMARKS_RESOURCE_TYPE.post,
    video: USER_BOOKMARKS_RESOURCE_TYPE.video,
} as const;

/** Array of all available saved items resource */
export const SAVED_ITEMS_RESOURCE_TYPES_ARRAY = Object.values(SAVED_ITEMS_RESOURCE_TYPES);
/**
 * The resource filters to display.
 * We might not want to display all available saved items as filters
 * until the save button is added to their corresponding resource.
 */
export const AVAILABLE_SAVED_ITEMS_FILTERS = [
    SAVED_ITEMS_RESOURCE_TYPES.content,
    SAVED_ITEMS_RESOURCE_TYPES.post,
    SAVED_ITEMS_RESOURCE_TYPES.video,
];
