import uniqueId from 'lodash/uniqueId';

import { SearchSuggestion } from '@lumapps/search/types';

/**
 * Generate a unique Id for quick search suggestions.
 * As the same result cannot
 */
export const generateSuggestionId = (suggestion: SearchSuggestion) => {
    const parts = [];
    const itemId = suggestion.item?.entityId;

    if (suggestion.type) {
        parts.push(suggestion.type);
    }

    if (suggestion.query) {
        const slugifiedQuery = encodeURIComponent(suggestion.query).toLowerCase();
        parts.push(slugifiedQuery);
    }

    if (itemId) {
        parts.push(itemId);
    }

    const suggestionId = parts.length > 0 ? parts.join('-') : uniqueId();

    return suggestionId;
};
