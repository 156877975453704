import React from 'react';

import { CommunitiesModuleSettingsMenuEntry } from '@lumapps/community-settings/components/CommunitiesModuleSettingsMenuEntry';
import { SpacesModuleSettingsMenuEntry } from '@lumapps/community-settings/components/SpacesModuleSettingsMenuEntry';
import { useDataAttributes } from '@lumapps/data-attributes';
import { DirectoriesSettingsMenu } from '@lumapps/directories-front-office/components/DirectoriesSettingsMenu';
import { ModulesSettingsMenu } from '@lumapps/directories-front-office/components/ModuleSettingsMenu';
import type { Directory } from '@lumapps/directories/types';
import { Menu } from '@lumapps/lumx-menu/components/Menu';
import { NewsletterSettingsMenu } from '@lumapps/newsletter/components/NewsletterSettingsMenu';
import { useTranslate } from '@lumapps/translations';

import { SETTINGS_MENU } from '../../keys';

export interface SettingsMenuModulesProps {
    canAccessCommunities: boolean;
    canEditDirectories: boolean;
    canAccessModules: boolean;
    directories: Directory[] | undefined;
    canEditNewsletter: boolean;
    scope: string;
}

export const SettingsMenuModules: React.FC<SettingsMenuModulesProps> = ({
    canAccessCommunities,
    canEditDirectories,
    directories,
    canEditNewsletter,
    canAccessModules,
    scope,
}) => {
    const { translateKey } = useTranslate();
    const { get } = useDataAttributes(scope);

    if (
        !canAccessCommunities &&
        !canAccessModules &&
        (!canEditDirectories || directories?.length === 0) &&
        !canEditNewsletter
    ) {
        return null;
    }

    return (
        <Menu.Section label={translateKey(SETTINGS_MENU.SUB_MODULES)} {...get({ element: 'sub-modules' })}>
            <ModulesSettingsMenu scope={scope} />
            <CommunitiesModuleSettingsMenuEntry scope={scope} />
            <SpacesModuleSettingsMenuEntry scope={scope} />
            <DirectoriesSettingsMenu scope={scope} />
            <NewsletterSettingsMenu scope={scope} />
        </Menu.Section>
    );
};

SettingsMenuModules.displayName = 'SettingsMenuModules';
