/* eslint-disable lumapps/no-classname-strings */
/* eslint-disable lumapps/no-manual-bems */
import React, { ReactNode } from 'react';

import { useClassnames } from '@lumapps/classnames';
import { mdiClose } from '@lumapps/lumx/icons';
import {
    PopoverDialog,
    Placement,
    FlexBox,
    Orientation,
    IconButton,
    Emphasis,
    Heading,
    Toolbar,
    FlexBoxProps,
    ToolbarProps,
    PopoverDialogProps,
    Divider,
} from '@lumapps/lumx/react';
import { useTranslate, GLOBAL } from '@lumapps/translations';
import { useIntersectionObserver } from '@lumapps/utils/hooks/useIntersectionObserver';
import { generateUUID } from '@lumapps/utils/string/generateUUID';

import './index.scss';

export interface InlineDialogProps {
    /** callback to be executed once the dialog is closed */
    onClose?: () => void;
    /** custom classname */
    className?: string;
    /** text to be displayed as the title of the inline dialog */
    title: string;
    /** whether the dialog is open or not */
    isOpen?: boolean;
    /** callback to control the opening/closing of the dialog */
    setIsOpen: (isOpen: boolean) => void;
    /** list of selected filters */
    anchorRef: React.RefObject<HTMLElement>;
    /** props to be passed down to the popover */
    popoverProps?: Partial<Omit<PopoverDialogProps, 'label'>>;
    /** props to be passed down to the dialog's footer */
    footerProps?: Partial<ToolbarProps>;
    /** props to be passed down to the dialog's header */
    headerProps?: Partial<ToolbarProps>;
    /** props to be passed down to the dialog's wrapper */
    wrapperProps?: Partial<FlexBoxProps>;
    /** display a divider between the header and the content */
    forceHeaderDivider?: boolean;
    /** display a divider between the footer and the content */
    forceFooterDivider?: boolean;
    children?: ReactNode;
}

const CLASSNAME = 'lumx-inline-dialog';

/**
 * Component that displays a given content inside an inline dialog. An Inline dialog is
 * a popover with a header and a footer.
 *
 * @family Dialogs
 * @param InlineDialogProps
 * @returns InlineDialog
 */
export const InlineDialog: React.FC<InlineDialogProps> = ({
    title,
    isOpen = false,
    setIsOpen,
    className,
    children,
    onClose,
    popoverProps,
    headerProps,
    footerProps,
    wrapperProps,
    anchorRef,
    forceFooterDivider,
    forceHeaderDivider,
}) => {
    const { element, block } = useClassnames(CLASSNAME);
    const { translateKey } = useTranslate();

    const [sentinelTop, setSentinelTop] = React.useState<Element | null>(null);
    const [sentinelBottom, setSentinelBottom] = React.useState<Element | null>(null);
    const intersections = useIntersectionObserver([sentinelTop, sentinelBottom], {
        threshold: [0, 1],
    });

    const hasTopIntersection = sentinelTop && !(intersections.get(sentinelTop)?.isIntersecting ?? true);
    const hasBottomIntersection = sentinelBottom && !(intersections.get(sentinelBottom)?.isIntersecting ?? true);

    const onInlineDialogClose = () => {
        setIsOpen(false);

        if (onClose) {
            onClose();
        }
    };

    const ariaLabelledById = `${CLASSNAME}-${generateUUID()}`;

    return (
        <PopoverDialog
            isOpen={isOpen}
            onClose={onInlineDialogClose}
            anchorRef={anchorRef}
            placement={Placement.BOTTOM_END}
            usePortal={false}
            className={block([className])}
            closeOnClickAway
            closeOnEscape
            aria-labelledby={ariaLabelledById}
            {...popoverProps}
        >
            <FlexBox orientation={Orientation.vertical} hAlign="space-between">
                <Toolbar
                    className={element('header')}
                    label={
                        <Heading as="h3" typography="title" id={ariaLabelledById}>
                            {title}
                        </Heading>
                    }
                    after={
                        <IconButton
                            label={translateKey(GLOBAL.CLOSE)}
                            icon={mdiClose}
                            onClick={onInlineDialogClose}
                            emphasis={Emphasis.low}
                        />
                    }
                    {...headerProps}
                />

                {forceHeaderDivider || hasTopIntersection ? <Divider /> : null}

                <FlexBox
                    orientation={Orientation.vertical}
                    gap="huge"
                    {...wrapperProps}
                    className={element('wrapper', [wrapperProps?.className])}
                >
                    <div className={`${CLASSNAME}__sentinel ${CLASSNAME}__sentinel--top`} ref={setSentinelTop} />
                    {children}
                    <div className={`${CLASSNAME}__sentinel ${CLASSNAME}__sentinel--bottom`} ref={setSentinelBottom} />
                </FlexBox>

                {forceFooterDivider || hasBottomIntersection ? <Divider /> : null}

                <Toolbar className={element('footer')} {...footerProps} />
            </FlexBox>
        </PopoverDialog>
    );
};
