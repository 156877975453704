/* eslint-disable lumapps/no-manual-bems */
import React from 'react';

import { classnames } from '@lumapps/classnames';
import { ButtonGroup, ButtonProps, Theme } from '@lumapps/lumx/react';
import { useResponsive } from '@lumapps/responsive';

import { SORT_FIELDS, SORT_ORDER } from '../../constants';
import { SortersType } from '../../types';
import { OrderToggleButton } from './OrderToggleButton';
import { SortersStatus } from './SortersStatus';
import { SortSelectButton } from './SortSelectButton';

export interface SortersProps {
    /** List of available sort items */
    choices?: SortersType[];
    /** Current selected sort item */
    sort: SortersType['id'];
    /** The order in which we are currently sorting */
    order?: SORT_ORDER;
    /** Whether we can change the order of the sorting */
    isChangingOrderEnabled?: boolean;
    /** is sorting loading */
    isLoading?: boolean;
    /** whether sorting is disabled */
    isDisabled?: boolean;
    /** whether the "Sorted by" prefix is displayed on the button or not */
    shouldDisplaySortedByLabel?: boolean;
    /** Action when the sort item changes */
    onChange(sort: SortersType['id'], order: SORT_ORDER): void;
    /** If we would like to have sorting name in mobile version */
    hasMobileText?: boolean;
    /** Class name of component */
    className?: string;
    /** Sort button props for select button  */
    sortButtonProps?: Partial<ButtonProps> & { label?: string };
    /** custom theme */
    theme?: Theme;
}

const CLASSNAME = 'lumx-sorters';

/**
 * Component that displays sorting options and allows the user to select between them. If you are displaying a select
 * with options Ascending/Descending for any search results, this is the component to use.
 * @family Filters
 * @param SortersProps
 * @returns Sorters
 */
export const Sorters: React.FC<SortersProps> = ({
    choices = SORT_FIELDS,
    onChange,
    sort,
    order = SORT_ORDER.INCREASING,
    isChangingOrderEnabled = true,
    isLoading = false,
    isDisabled = false,
    hasMobileText = false,
    className,
    sortButtonProps,
    shouldDisplaySortedByLabel = false,
    theme,
}) => {
    const buttonRef = React.useRef(null);
    const { isMobile } = useResponsive();

    const sortValue = React.useMemo(() => {
        return choices?.find((choice) => choice.id === sort);
    }, [choices, sort]);

    const onOrderChange = React.useCallback(
        (newOrder: SORT_ORDER) => {
            onChange(sort, newOrder);
        },
        [sort, onChange],
    );
    const onSortChange = React.useCallback(
        (newSort: SortersType) => {
            onChange(newSort?.id, order);
        },
        [order, onChange],
    );

    if (!choices || choices.length === 0) {
        return null;
    }

    const showSortLabel = hasMobileText || !isMobile;

    // Sort status (inert display)
    if (choices.length === 1) {
        return (
            <SortersStatus
                size={sortButtonProps?.size}
                shouldDisplaySortedByLabel={shouldDisplaySortedByLabel}
                showSortLabel={showSortLabel}
                sort={sortValue}
                order={isChangingOrderEnabled ? order : undefined}
            />
        );
    }

    const isSortingDisabled = isLoading || isDisabled;
    const sortDisplayVariant = showSortLabel ? 'default' : 'compact';

    return (
        <ButtonGroup className={classnames(CLASSNAME, className)}>
            <SortSelectButton
                variant={sortDisplayVariant}
                buttonRef={buttonRef}
                isDisabled={isSortingDisabled}
                shouldDisplaySortedByLabel={shouldDisplaySortedByLabel}
                options={choices}
                value={sortValue}
                onChange={onSortChange}
                theme={theme}
                {...sortButtonProps}
            />
            {isChangingOrderEnabled && (
                <OrderToggleButton
                    order={order}
                    theme={theme}
                    onChange={onOrderChange}
                    isDisabled={isSortingDisabled}
                    size={sortButtonProps?.size}
                />
            )}
        </ButtonGroup>
    );
};
