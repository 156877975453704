/* istanbul ignore file */

import { Route, createPageRoute } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

const saSocialNetworksRoutes: Route = createPageRoute({
    slug: 'social-advocacy/social-networks',
    legacyId: 'app.admin.social-advocacy.social-network',
    appId: AppId.backOffice,
});

const saSocialNetworks = (): Route => {
    return saSocialNetworksRoutes;
};

export { saSocialNetworks, saSocialNetworksRoutes };
