import React, { ReactNode } from 'react';

import { Suspense, SuspenseProps } from '../Suspense';

// Callback sent in `children` to mount/unmount the component.
type SetShouldMountFunction = (shouldMount: boolean) => void;

// Function children which is given the `setShouldMount` callback.
type ChildrenWithCallback = (setShouldMount: SetShouldMountFunction) => ReactNode;
/**
 * Children accepted by the DelayedSuspense Children.
 * Can either be a ReactNode or a callback that receives the `setShouldMount` callback
 */
type DelayedSuspenseChildren = ReactNode | ChildrenWithCallback;

// Check the `children` is a function that can accept the callback.
const isChildrenWithCallback = (children: DelayedSuspenseChildren): children is ChildrenWithCallback =>
    typeof children === 'function';

export interface DelayedSuspenseProps extends Omit<SuspenseProps, 'children'> {
    /**
     * Whether the component should be loaded.
     */
    shouldLoad?: boolean;
    /**
     * Either provide normal ReactNode children or a function wrapping the children that can inject a `setShouldMount`
     * function
     */
    children: DelayedSuspenseChildren;
}

/**
 * Component to use when you want to lazy load a component at a specific moment.
 * The component will be kept mounted after the `shouldLoad` prop is first set to `true` but you can also use a callback
 * in the `children` to unmount the component.
 *
 * @see stories for examples
 */
export const DelayedSuspense: React.FC<DelayedSuspenseProps> = ({ shouldLoad, children, ...rest }) => {
    const [shouldMount, setShouldMount] = React.useState(Boolean(shouldLoad));

    React.useEffect(() => {
        if (shouldLoad && !shouldMount) {
            setShouldMount(true);
        }
    }, [shouldMount, shouldLoad]);

    if (!shouldMount) {
        return null;
    }

    return <Suspense {...rest}>{isChildrenWithCallback(children) ? children(setShouldMount) : children}</Suspense>;
};
