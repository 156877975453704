import find from 'lodash/find';
import includes from 'lodash/includes';

import { isAnalyticsAllowed, isExtendedAnalyticsEnabled } from '@lumapps/analytics';
import { ARTICLES_IN_COMMUNITIES_FF } from '@lumapps/articles/constants';
import { isCommunity } from '@lumapps/content-types/utils';
import {
    contentSelector,
    getCurrentContentProperties,
    isEditorOfCurrentContent,
} from '@lumapps/contents/ducks/selectors';
import { isFeatureEnabled } from '@lumapps/features';
import { isInstanceSuperAdmin, isUserAllowed, MATCHES_METHODS } from '@lumapps/permissions';
import { createSelector } from '@lumapps/redux/reselect';
import { BaseStore } from '@lumapps/redux/types';
import { selectSpaceId } from '@lumapps/spaces/ducks/selectors';
import { connectedUserSelector, getSubscriptions } from '@lumapps/user/ducks/selectors';

import {
    COMMUNITY_FEATURE_TOKEN,
    HORIZONTAL_COMMUNITY_TEMPLATE,
    NEW_COMMUNITY_NAVIGATION,
    SECURITY_AT_COMMUNITY_LEVEL,
    SPACES_FEATURE_TOKEN,
    SPACES_HOMEPAGE_COPY_FEATURE_TOKEN,
} from '../constants';
import { COMMUNITIES_PERMISSIONS } from '../permissions';
import { RenderingType } from '../types';

/**
 * Determines whether the "Spaces" feature is enabled or not.
 */
const isSpacesFeatureEnabled = isFeatureEnabled(SPACES_FEATURE_TOKEN);

/**
 * Determines whether it's possible or not to copy a Space homepage when creating a new Space.
 */
const isSpacesHomepageCopyFeatureEnabled = isFeatureEnabled(SPACES_HOMEPAGE_COPY_FEATURE_TOKEN);

/**
 * Determines whether the user is allowed to access the community admin page.
 * Any user with a role with CREATE, EDIT or DELETE permission is allowed
 * to access the Communities admin page.
 */
const isCommunitiesAllowed = isUserAllowed(
    [
        COMMUNITIES_PERMISSIONS.COMMUNITY_EDIT,
        COMMUNITIES_PERMISSIONS.COMMUNITY_CREATE,
        COMMUNITIES_PERMISSIONS.COMMUNITY_DELETE,
    ],
    { matches: MATCHES_METHODS.SOME },
);

/**
 * Determines whether the user is allowed to create a new community.
 * Any user with a role with CREATE or EDIT permission is allowed
 * to create a new community.
 */
const isCommunitiesCreateAllowed = isUserAllowed(
    [COMMUNITIES_PERMISSIONS.COMMUNITY_CREATE, COMMUNITIES_PERMISSIONS.COMMUNITY_EDIT],
    { matches: MATCHES_METHODS.SOME },
);

/**
 * Determines whether the user is allowed to create a new community.
 * Any user with a role with EDIT permission is allowed
 * to edit a community.
 */
const isCommunitiesEditAllowed = isUserAllowed([COMMUNITIES_PERMISSIONS.COMMUNITY_EDIT]);

/**
 * Determines whether the user is allowed to delete a community.
 * Any user with a role with DELETE permission is allowed
 * to delete a community.
 */
const isCommunitiesDeleteAllowed = isUserAllowed(COMMUNITIES_PERMISSIONS.COMMUNITY_DELETE);

/**
 * Determines whether the user is allowed to access the community template admin page.
 * Only users with a role with EDIT permission on communities are allowed to access
 * the Community templates admin page.
 */
const isCommunityTemplatesAllowed = isUserAllowed(COMMUNITIES_PERMISSIONS.COMMUNITY_EDIT);
const isCommunitiesFeatureEnabled = isFeatureEnabled(COMMUNITY_FEATURE_TOKEN);
const isSecuredMediaEnabled = isFeatureEnabled(SECURITY_AT_COMMUNITY_LEVEL);

const isNewCommunityNavigationEnabled = isFeatureEnabled(NEW_COMMUNITY_NAVIGATION);
const isHorizontalCommunityTemplateEnabled = isFeatureEnabled(HORIZONTAL_COMMUNITY_TEMPLATE);

const communitiesFOSelector = (state: BaseStore) => state.communities.frontOffice;

export const communitiesEntitiesSelector = createSelector(communitiesFOSelector, (communities) => communities.entities);

const communitySearchResultsSelector = createSelector(communitiesFOSelector, (communityState) => {
    return communityState.search.ids.map((id: string) => communityState.entities[id]);
});

const isLoadingForPostDialogSelector = createSelector(
    communitiesFOSelector,
    (communityState) => communityState.isLoadingForPostDialog,
);

const communityNavigationEditSelector = createSelector(
    communitiesFOSelector,
    (communityState) => communityState.communityNavigationEdit,
);

const doesCurrentCommunityHaveDashboard = createSelector(
    getCurrentContentProperties,
    (properties) => properties.hasDashboard,
);

const communitySearchStateSelector = createSelector(communitiesFOSelector, (communityState) => communityState.search);

const followingOnlyFilterSelector = createSelector(communitySearchStateSelector, (communitySearchState) =>
    Boolean(communitySearchState?.followingOnly),
);

const selectedCommunityIdSelector = createSelector(
    communitiesFOSelector,
    (communityState) => communityState.selectedCommunityId,
);

const selectedCommunitySelector = createSelector(
    communitiesFOSelector,
    (communityState) => communityState.selectedCommunity,
);

export const getCommunityMetadata = createSelector(communitiesFOSelector, (communityState) => communityState.metadata);

const getCurrentContentAsCommunity = createSelector(contentSelector, (content) => {
    if (isCommunity(content)) {
        return content;
    }
    return null;
});

const getCommunityTitle = createSelector(getCurrentContentAsCommunity, (community) => community?.title);

const getCommunityFeedKeys = createSelector(getCurrentContentAsCommunity, (community) => community?.communityFeedKey);

const availablePostTypesSelector = createSelector(
    getCurrentContentAsCommunity,
    (community) => community?.postTypes || [],
);

/**
 * Determines whether the current logged in user is part of the managers in the currently displayed community.
 */
const isUserManagerForCurrentCommunity = createSelector(
    getCurrentContentAsCommunity,
    connectedUserSelector,
    isInstanceSuperAdmin,
    isEditorOfCurrentContent,
    (community, user, isAdmin, isEditor) => {
        if (community && user) {
            const { adminKeys } = community;
            const { id } = user;

            /**
             * If the user is instance or super admin, they are able to edit this community,
             * so we return true directly.
             */
            if (isAdmin) {
                return true;
            }

            /**
             * Then we need to check that the user is an admin of the current community, which is
             * something that is configured at a community level. in that case, they can edit it.
             */
            if (adminKeys && adminKeys.indexOf(id) >= 0) {
                return true;
            }

            /**
             * Finally, we check if the user can edit the current content, due to their group and the
             * editors selected on the community level.
             */
            return Boolean(isEditor);
        }

        return false;
    },
);

/**
 * Determines whether the current logged in user can edit the currently displayed community.
 */
const isCurrentCommunityEditableByUser = createSelector(
    getCurrentContentAsCommunity,
    connectedUserSelector,
    isCommunitiesEditAllowed,
    isUserManagerForCurrentCommunity,
    (community, user, canEditCommunities, isManager) => {
        if (community && user) {
            /**
             * If the user have a role create/edit for communitie
             * or if he is part of the community managers.
             */
            if (canEditCommunities || isManager) {
                return true;
            }
        }

        return false;
    },
);

/**
 * Determines whether the current user has access to the selected community as an editor
 */
export const isEditorOfSelectedCommunity = createSelector(
    selectedCommunitySelector,
    getSubscriptions,
    (community, subscriptions) => {
        if (community) {
            const { editors } = community;

            /**
             * The user can be an editor of the selected community, which is something that can be configured
             * at the community level, with groups.
             */
            if (editors && editors.length > 0) {
                const feedInEditor = find(subscriptions, (subscription) => includes(editors, subscription.feed));

                return Boolean(feedInEditor);
            }
        }

        return false;
    },
);

/**
 * Determines whether the current logged in user is part of the managers in the currently selected community.
 */
const isUserManagerForSelectedCommunity = createSelector(
    selectedCommunitySelector,
    getCurrentContentAsCommunity,
    connectedUserSelector,
    isInstanceSuperAdmin,
    isEditorOfSelectedCommunity,
    (community, currentCommunity, user, isAdmin, isEditor) => {
        if ((community && user) || (currentCommunity && user)) {
            let adminKeys;
            if (community.adminKeys) {
                adminKeys = community.adminKeys;
            } else {
                adminKeys = currentCommunity?.adminKeys;
            }
            const { id } = user;

            /**
             * If the user is instance or super admin, they are able to edit this community,
             * so we return true directly.
             */
            if (isAdmin) {
                return true;
            }

            /**
             * Then we need to check that the user is an admin of the current community, which is
             * something that is configured at a community level. in that case, they can edit it.
             */
            if (adminKeys && adminKeys.indexOf(id) >= 0) {
                return true;
            }

            /**
             * Finally, we check if the user can edit the current content, due to their group and the
             * editors selected on the community level.
             */
            return Boolean(isEditor);
        }

        return false;
    },
);

/**
 * Determines whether the current logged in user can edit the currently selected community.
 */
export const isSelectedCommunityEditableByUser = createSelector(
    selectedCommunitySelector,
    connectedUserSelector,
    isCommunitiesEditAllowed,
    isUserManagerForSelectedCommunity,
    (community, user, canEditCommunities, isManager) => {
        if (community && user) {
            /**
             * If the user have a role create/edit for communitie
             * or if he is part of the community managers.
             */
            if (canEditCommunities || isManager) {
                return true;
            }
        }

        return false;
    },
);

const getCommunityPrivacy = createSelector(getCurrentContentAsCommunity, (community) => community?.privacy);

const canCurrentUserViewCommunityAnalytics = createSelector(
    isExtendedAnalyticsEnabled,
    isCurrentCommunityEditableByUser,
    isAnalyticsAllowed,
    (isAnalyticsEnabled, isEditor, isAllowed) => {
        return isAnalyticsEnabled && (isAllowed || isEditor);
    },
);

const isConfigurationWizardOpenSelector = createSelector(
    communitiesFOSelector,
    (communityState) => communityState.isConfigurationWizardOpen,
);

/** Check if a user can write posts in a community. */
const canUserContribute = createSelector(getCurrentContentAsCommunity, (community) => community?.canContribute);

/** Check if the current community (content state) has article service enabled */
const areArticlesEnabledInCommunity = createSelector(
    getCurrentContentAsCommunity,
    (community) => community?.hasArticlesEnabled,
);

/** Check if the selected community (community state) has article service enabled */
const areArticlesEnabledInSelectedCommunity = createSelector(
    selectedCommunitySelector,
    (community) => community?.hasArticlesEnabled,
);

/**
 * Get Community|Space ID, depending on where we are and what data are stored in redux.
 * */
export const selectSpaceOrCommunityId = createSelector(
    selectSpaceId,
    getCurrentContentAsCommunity,
    (spaceId, community) => spaceId || community?.id,
);

const selectRenderingTypes = createSelector(isSpacesFeatureEnabled, (spacesFeatureEnabled) =>
    spacesFeatureEnabled ? Object.values(RenderingType) : [RenderingType.community],
);

// Redeclare locally this value to avoid circular dependency with articles/ducks/selectors.ts file
const isArticleInCommunitiesFeatureEnabled = isFeatureEnabled(ARTICLES_IN_COMMUNITIES_FF);

/**
 * Get if the Article picker option should be displayed in the navigation admin:
 *   - For spaces, always display the wizard option
 *   - For community, display if the FF 'articles' is enabled, even if the current community has not enabled the option
 * */
export const isArticleAvailableInNavigationAdmin = createSelector(
    getCurrentContentAsCommunity,
    isArticleInCommunitiesFeatureEnabled,
    isNewCommunityNavigationEnabled,
    (isACommunity, isArticleFlagEnabled, isNewNavEnabled) => !isACommunity || (isArticleFlagEnabled && isNewNavEnabled),
);

export const isCurrentCommunityASpace = createSelector(
    getCurrentContentAsCommunity,
    (community) => community?.renderingType === RenderingType.space,
);

export {
    canUserContribute,
    communitiesFOSelector,
    communitySearchResultsSelector,
    communitySearchStateSelector,
    followingOnlyFilterSelector,
    selectedCommunityIdSelector,
    selectedCommunitySelector,
    isCommunitiesAllowed,
    isCommunitiesCreateAllowed,
    isCommunitiesDeleteAllowed,
    isCommunityTemplatesAllowed,
    isCommunitiesFeatureEnabled,
    isSpacesFeatureEnabled,
    isSpacesHomepageCopyFeatureEnabled,
    isCurrentCommunityEditableByUser,
    isLoadingForPostDialogSelector,
    isUserManagerForCurrentCommunity,
    communityNavigationEditSelector,
    doesCurrentCommunityHaveDashboard,
    canCurrentUserViewCommunityAnalytics,
    getCurrentContentAsCommunity,
    availablePostTypesSelector,
    getCommunityPrivacy,
    getCommunityTitle,
    isConfigurationWizardOpenSelector,
    isSecuredMediaEnabled,
    isNewCommunityNavigationEnabled,
    isHorizontalCommunityTemplateEnabled,
    areArticlesEnabledInCommunity,
    areArticlesEnabledInSelectedCommunity,
    getCommunityFeedKeys,
    selectRenderingTypes,
};
