import { isDateInTheFuture } from '@lumapps/utils/date/isDateInTheFuture';
import { isDateInThePast } from '@lumapps/utils/date/isDateInThePast';

import { CONTENT_PUBLICATION_STATUS } from '../constants';
import { ContentStatus } from '../types';

/** Get the content publication status thanks to the legacy status and his dates
 * CF: [backend code](https://github.com/lumapps/core/blob/master/core/monolite/content/use_cases/property_computations.py#L31)
 */
export const getContentPublicationStatus = (
    legacyStatus?: ContentStatus,
    startDate?: string,
    endDate?: string,
    expiresSoonThreshold = 10,
) => {
    if (legacyStatus === ContentStatus.live) {
        // SCHEDULED
        if (startDate && isDateInTheFuture(startDate)) {
            return CONTENT_PUBLICATION_STATUS.SCHEDULED;
        }
        // EXPIRED
        if (endDate && isDateInThePast(endDate)) {
            return CONTENT_PUBLICATION_STATUS.EXPIRED;
        }
        // SOON EXPIRED
        if (endDate && new Date(endDate) < new Date(Date.now() + expiresSoonThreshold * 24 * 60 * 60 * 1000)) {
            return CONTENT_PUBLICATION_STATUS.EXPIRES_SOON;
        }
        // PUBLISHED
        return CONTENT_PUBLICATION_STATUS.PUBLISHED;
    }

    const statusList = Object.keys(CONTENT_PUBLICATION_STATUS);

    // OTHER (ARCHIVE, TO_VALIDATE...) OR DRAFT
    return legacyStatus && statusList.includes(legacyStatus)
        ? CONTENT_PUBLICATION_STATUS[legacyStatus]
        : CONTENT_PUBLICATION_STATUS.DRAFT;
};
