import React from 'react';

import { visuallyHidden } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { Emphasis, IconButton, IconButtonProps, Text } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { useId } from '@lumapps/utils/hooks/useId';

import { SORT_ORDER, SORT_ORDER_ICONS, SORT_ORDER_TRANSLATIONS, SORTERS_SCOPE } from '../../../constants';

const SORT_ORDER_TRANSITIONS = {
    [SORT_ORDER.INCREASING]: SORT_ORDER.DECREASING,
    [SORT_ORDER.DECREASING]: SORT_ORDER.INCREASING,
};

interface OrderToggleButtonProps extends Partial<IconButtonProps> {
    /** The order in which we are currently sorting */
    order?: SORT_ORDER;
    /** Callback on change */
    onChange?(newOrder: SORT_ORDER): void;
}

/**
 * Button toggling the sort order.
 */
export const OrderToggleButton: React.FC<OrderToggleButtonProps> = ({
    order = SORT_ORDER.INCREASING,
    onChange,
    ...forwardedProps
}) => {
    const { translateKey, translateAndReplace } = useTranslate();
    const { get } = useDataAttributes(SORTERS_SCOPE);

    const orderChangeMessageId = useId();
    const onClick = React.useCallback(() => {
        onChange?.(SORT_ORDER_TRANSITIONS[order]);
    }, [onChange, order]);

    return (
        <>
            <Text as="p" className={visuallyHidden()} id={orderChangeMessageId}>
                {translateKey(SORT_ORDER_TRANSLATIONS[order])}
            </Text>
            <IconButton
                emphasis={Emphasis.low}
                {...forwardedProps}
                onClick={onClick}
                icon={SORT_ORDER_ICONS[order]}
                label={translateAndReplace(GLOBAL.FORMAT_COLON, {
                    key: translateKey(GLOBAL.TOGGLE),
                    value: translateKey(GLOBAL.ORDER),
                })}
                aria-describedby={orderChangeMessageId}
                {...get({ element: 'sort', action: 'order' })}
            />
        </>
    );
};
