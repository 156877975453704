import { Route, createPageRoute, AppId } from '@lumapps/router';

export const communitiesAdminRoute: Route = createPageRoute({
    slug: 'communities',
    id: 'community',
    appId: AppId.backOffice,
});

export const spacesAdminRoute: Route = createPageRoute({
    slug: 'spaces',
    appId: AppId.backOffice,
});
