export enum WIDGET_BASE {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=6975642
     */
    ATTACHMENTS_DELETE_ALL = 'FRONT.ATTACHMENTS.DELETE_ALL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=62634061
     */
    NOT_MIGRATED = 'FRONT.WIDGET.WIDGET_NOT_MIGRATED',
    COULD_NOT_LOAD = 'FRONT.WIDGET.WIDGET_COULD_NOT_LOAD',
    DEPRECATION_WARNING_SELECTION_MESSAGE = 'FRONT.DEPRECATION_WARNING.SELECTION.MESSAGE',
    DEPRECATION_WARNING_WIDGET_LIST = 'FRONT.DEPRECATION_WARNING.WIDGET_LIST',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=438268876
     */
    REMOVE_FILE = 'WIDGET_BASE.REMOVE_FILE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=438671552
     */
    REMOVE_IMAGE_PLURAL = 'WIDGET_BASE.REMOVE_IMAGE_PLURAL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=8930094
     */
    REMOVE_VIDEO = 'FRONT.VIDEO.CONFIRM_DELETE_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=203147271
     */
    SETTINGS_INFORMATION_DISPLAYED_TITLE = 'WIDGET_BASE.SETTINGS_INFORMATION_DISPLAYED_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7556634
     */
    SETTINGS_MAX_RESULTS = 'FRONT.SETTINGS.MAX_RESULTS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=203147280
     */
    SETTINGS_MAX_RESULTS_HELPER = 'WIDGET_BASE.SETTINGS_MAX_RESULTS_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=312170852
     */
    SETTINGS_OPTION_NOT_SUPPORTED_NGI = 'WIDGET.SETTINGS_OPTION_NOT_SUPPORTED_NGI',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7556597
     */
    SETTINGS_SELECTION_TYPE_TITLE = 'FRONT.SETTINGS.SELECTION_TYPE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=203147311
     */
    SETTINGS_SHOW_DESCRIPTION = 'WIDGET_BASE.SETTINGS_SHOW_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=203147706
     */
    SETTINGS_SHOW_LOAD_MORE = 'WIDGET_BASE.SETTINGS_SHOW_LOAD_MORE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=203150269
     */
    SETTINGS_SHOW_SEARCH = 'WIDGET_BASE.SETTINGS_SHOW_SEARCH',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7253293
     */
    SETTINGS_SHOW_SEPARATOR = 'FRONT.SETTINGS.ITEMS_SEPARATOR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=7253294
     */
    SETTINGS_SHOW_SEPARATOR_HELPER = 'FRONT.SETTINGS.ITEMS_SEPARATOR_HELP',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=9585139
     */
    SETTINGS_VARIANTS = 'FRONT.SETTINGS.VIEW_MODE_VARIANTS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=19039040
     */
    SETTINGS_VIEW_MODE_GRID = 'FRONT.SETTINGS.VIEW_MODE.GRID',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=11195180
     */
    SETTINGS_VIEW_MODE_LIST = 'ADMIN.CUSTOMER.STREAMS.VIEW_MODE_LIST',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=19039044
     */
    SETTINGS_VIEW_MODE_SLIDESHOW = 'FRONT.SETTINGS.VIEW_MODE.SLIDESHOW',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=9585140
     */
    SETTINGS_VIEW_MODE_GROUPED = 'FRONT.SETTINGS.VIEW_MODE_GROUP',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=9585141
     */
    SETTINGS_VIEW_MODE_UNGROUPED = 'FRONT.SETTINGS.VIEW_MODE_UNGROUP',
}

export const getWidgetTypeTranslationKey = (widgetType: string) =>
    widgetType.toUpperCase() === 'TITLE' ? 'GLOBAL.TITLE' : `WIDGET_TYPE_${widgetType.toUpperCase()}`;
export const getWidgetDeprecationWarningMessage = (widgetType: string) =>
    `FRONT.DEPRECATION_WARNING.SELECTION.TOOLTIP_MESSAGE_${widgetType}`;
