import React from 'react';

import uniq from 'lodash/uniq';

import { useSelector } from '@lumapps/redux/react';

import { isHideEmailEnabled } from '../ducks/selectors';
import { MinimumViableUser } from '../types';

export type UseUserFieldsOptions = {
    /** list of fields to display */
    fields?: string[];
    /** additional fields to be displayed */
    additionalFields?: string[];
};

export const DEFAULT_FIELDS = ['email', 'title', 'location'];

/**
 * Hook that calculates the user's fields to be displayed, considering feature flags, additional fields passed in,
 * and organisation / user profile data.
 * @param user
 * @param options
 * @returns string[]
 */
export const useUserFields = (user: MinimumViableUser, options?: UseUserFieldsOptions) => {
    const isEmailHidden = useSelector(isHideEmailEnabled);
    const { fields = DEFAULT_FIELDS, additionalFields = [] } = options || {};

    return React.useMemo(() => {
        const uF: string[] = [];

        if (user?.email && !isEmailHidden && fields.includes('email')) {
            uF.push(user?.email);
        }

        const organization =
            user?.apiProfile?.organizations?.find((org) => org.primary) || user?.apiProfile?.organizations?.[0];

        if (organization) {
            if (organization?.title && fields.includes('title')) {
                uF.push(organization.title);
            }
            if (organization?.department && fields.includes('location')) {
                uF.push(organization.department);
            }
        }

        if (user?.jobTitle && fields.includes('title')) {
            uF.push(user?.jobTitle);
        }

        if (user?.location && fields.includes('location')) {
            uF.push(user?.location);
        }

        if (user?.locationName && fields.includes('location')) {
            uF.push(user?.locationName);
        }

        return uniq([...uF, ...additionalFields]);
    }, [
        additionalFields,
        isEmailHidden,
        user?.apiProfile?.organizations,
        user?.email,
        user?.jobTitle,
        user?.location,
        user?.locationName,
        fields,
    ]);
};
