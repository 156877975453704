import React from 'react';

import { type MicroAppDialogProps } from './MicroAppDialog';

const LazyMicroAppDialog = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'micro-app-dialog' */
            './MicroAppDialog'
        ),
);

export const MicroAppDialog = ({ isOpen, ...props }: MicroAppDialogProps) => {
    return (
        <React.Suspense fallback={null}>
            {isOpen ? <LazyMicroAppDialog isOpen={isOpen} {...props} /> : null}
        </React.Suspense>
    );
};
