import { TrackingEventType } from '@lumapps/analytics-tracking/types';

import { FacetInteraction, SEARCH_CAUSES } from '../types';

export const setSearchType = (action: SEARCH_CAUSES | FacetInteraction) => {
    if (
        action === SEARCH_CAUSES.breadcrumbResetAll ||
        action === FacetInteraction.DESELECT ||
        action === FacetInteraction.SELECT ||
        action === SEARCH_CAUSES.facetInteracted
    ) {
        return TrackingEventType.SEARCH_FILTER;
    }
    if (action === SEARCH_CAUSES.loadMore) {
        return TrackingEventType.SEARCH_LOAD_MORE;
    }
    return TrackingEventType.SEARCH;
};
