import React, { ReactNode } from 'react';

import findKey from 'lodash/findKey';

import { List, ListDivider, ListSubheader } from '@lumapps/lumx/react';
import { useId } from '@lumapps/utils/hooks/useId';

import { SectionContext } from '../../context/ComboboxContext';
import { useCombobox } from '../../hooks/useCombobox';
import { useComboboxSectionId } from '../../hooks/useComboboxSectionId';
import { isComboboxValue } from '../../utils';
import { ComboboxListSkeleton, ComboboxListSkeletonProps } from '../ComboboxListBox/ComboboxListSkeleton';

export interface ComboboxSectionProps {
    // The title of the section
    title: string;
    //  Whether the section should be displayed as loading
    isLoading?: boolean;
    /** Whether a divider should be displayed before the section */
    withDivider?: boolean;
    /** Custom skeletons to use for loading state */
    renderItemSkeleton?: ComboboxListSkeletonProps['children'];
    /** Options to display */
    children: ReactNode;
}

const CLASSNAME = 'ListBoxSection';

/**
 * Content of the ComboboxSection.
 */
const ComboboxSectionContent = ({
    title,
    children,
    withDivider,
    isLoading,
    renderItemSkeleton,
}: ComboboxSectionProps) => {
    const { options } = useCombobox();
    const groupId = useComboboxSectionId();

    const hasRegisteredChildren = Boolean(
        findKey(options, (option) => isComboboxValue(option) && option.sectionId === groupId),
    );
    const titleId = `${groupId}-title`;

    if (!children && !isLoading) {
        return null;
    }

    return (
        <List
            className={CLASSNAME}
            role="group"
            aria-labelledby={titleId}
            style={{ padding: 0, display: hasRegisteredChildren || isLoading ? undefined : 'none' }}
        >
            {withDivider && <ListDivider />}
            <ListSubheader role="presentation" id={titleId}>
                {title}
            </ListSubheader>
            <div role="presentation" style={{ display: isLoading ? 'none' : undefined }}>
                {children}
            </div>
            {isLoading && <ComboboxListSkeleton>{renderItemSkeleton}</ComboboxListSkeleton>}
        </List>
    );
};

/**
 * Section for options of a Combobox.
 *
 * @family Combobox
 * @param ComboboxSectionProps
 * @returns ComboboxSection
 */
export const ComboboxSection = ({ children, ...props }: ComboboxSectionProps) => {
    const groupId = useId();

    return (
        <SectionContext.Provider value={groupId}>
            <ComboboxSectionContent {...props}>{children}</ComboboxSectionContent>
        </SectionContext.Provider>
    );
};
