import React, { useMemo } from 'react';

import { useClassnames } from '@lumapps/classnames';
import { CustomizationComponent, PLACEMENT, Targets } from '@lumapps/customizations/api';
import { useDataAttributes } from '@lumapps/data-attributes';
import { ThumbnailWithIconProps } from '@lumapps/lumx-images/components/ThumbnailWithIcon';
import { ColorBubbleLetterProps } from '@lumapps/lumx-texts/components/ColorBubbleLetter';

import { getBaseClassName } from '../../helpers';
import { CustomMediaIconColor, useSearchResultIconColor } from '../../hooks/useSearchResultIconColor';
import { Thumbnail as ThumbnailUI } from './Thumbnail';

import './index.scss';

interface SearchThumbnailCustomizationProps {
    entityType: string;
    mimeType?: string;
}

export interface ThumbnailProps extends ThumbnailWithIconProps {
    /** colors to be used for rendering the color bubble component, by default it uses a list of base lumapps colors */
    colors?: ColorBubbleLetterProps['colors'];
    /** Allows to override the color of the icon */
    customColor?: CustomMediaIconColor;
    /** Total number of characters to display when using Color Bubble Letter */
    charactersToDisplay?: number;
    /** The result type of the component. Can be used for customization purposes. */
    customizationContext: SearchThumbnailCustomizationProps;
}

const NAMESPACE = 'thumbnail';
const CLASSNAME = getBaseClassName(NAMESPACE);

/**
 * Thumbnail element of search results.
 * Customizable via customization api
 */
export const Thumbnail: React.FC<ThumbnailProps> = ({
    icon,
    iconName,
    className,
    image,
    customColor,
    alt,
    size,
    isRounded,
    customizationContext,
    ...restOfProps
}) => {
    const { block, element } = useClassnames(CLASSNAME);
    const { get } = useDataAttributes('search-result-thumbnail');

    const blockClassName = block([className]);
    const imageClassName = element('img', [className]);

    const isIconValid = Boolean(iconName || icon);
    const isDynamicIcon = Boolean(iconName && !icon);

    const { iconClassName } = useSearchResultIconColor({ customColor });

    const thumbnailProps = useMemo(
        () => ({
            icon,
            iconName,
            iconClassName,
            imageClassName,
            size,
            alt,
            image,
            isRounded: !isIconValid || isRounded,
            isDynamicIcon,
            ...get({ element: iconName }),
            ...restOfProps,
        }),
        [
            alt,
            get,
            icon,
            iconClassName,
            iconName,
            image,
            imageClassName,
            isDynamicIcon,
            isIconValid,
            isRounded,
            restOfProps,
            size,
        ],
    );

    const customComponent = CustomizationComponent({
        target: Targets.SEARCH_RESULT_ICON,
        placement: PLACEMENT.REPLACE,
        context: {
            ...customizationContext,
            currentProps: {
                ...thumbnailProps,
                className: blockClassName,
                /**
                 * When using customization, we recommend users customers to use icon classnames
                 * using an `icon` prop.
                 * To match this recommendation, we swap the icon value with the iconName and
                 * pass an iconPath prop that corresponds to the svg path.
                 * A custom `CustomizationSearchThumbnail` component is be available to receive these props.
                 */
                iconName: undefined,
                iconPath: icon,
                icon: iconName,
            },
        },
    });

    return customComponent !== null ? customComponent : <ThumbnailUI className={blockClassName} {...thumbnailProps} />;
};
