import { Route, createPageRoute, AppId } from '@lumapps/router';

const feedRoute: Route = createPageRoute({
    slug: 'feed',
    appId: AppId.frontOffice,
    // When in webview mode for the mobile, this makes the mobile app redirect to the native personal feed
    mobilePageDescriptor: {
        type: 'personal-feed',
    },
});

const feed = (): Route => ({
    ...feedRoute,
    params: {},
});

export { feed, feedRoute };
