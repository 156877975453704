import isArray from 'lodash/isArray';

import BaseApi, { makeParamsSerializer, PRIORITY } from '@lumapps/base-api';
import { ServerListResponse } from '@lumapps/base-api/types';
import { CACHE_TYPE } from '@lumapps/cache';
import {
    get as getUserFromDirectory,
    cancelGet as cancelGetUserFromDirectory,
    userDirectoryQueryKeys,
} from '@lumapps/user-directory/api';

import { UserMicrosoftToken } from '../interface';
import { User, UserListRequestParams, UserListSuccessPayload } from '../types';

// List of params to pass to /settings/save endpoint.
// NOTE : some params are mandatory or else they reset the value to default one if not passed.
export interface SettingsSaveParams {
    lang?: string;
    emailNotifications?: any;
    settings: {
        cookiesBannerReadAt: string;
        hasAcceptedCookies: boolean;
        hasAcceptedTerms: boolean;
        notificationPreferences: any;
        notifications: any;
        social: any;
        termsReadAt: string;
    };
    alternateEmail?: string;
    showTutorial: boolean;
    rePassword?: string;
    currentPassword?: string;
    tutorials?: string;
    langs: string[];
    properties?: string;
}

export const userApi = new BaseApi({
    path: 'user',
});

export const userQueryKeys = {
    list: (params: UserListRequestParams) => ['user', 'list', params],
    get: userDirectoryQueryKeys.get,
};

export const paramsSerializer = makeParamsSerializer({ arrayFormat: 'repeat', encode: false });

export const getListParams = (params: UserListRequestParams) => ({
    lang: params.lang,
    maxResults: params.maxResults,
    query: params.searchTerm || params.query,
    queryFields: params.queryFields,
    more: params.more,
    cursor: params.cursor,
    instance: params.instance,
    showHidden: params.showHidden,
    status: params.status && isArray(params.status) ? params.status.map((s) => s.toLowerCase()) : params.status,
    reactedEntityKey: params.reactedEntityKey,
    reactedEntityKind: params.reactedEntityKind,
    ids: params.ids,
    email: params.email ? params.email : undefined,
    types: params.types,
    firstName: params.firstName ? params.firstName : undefined,
    lastName: params.lastName ? params.lastName : undefined,
    fields: params.retrieveAllFields
        ? undefined
        : params.fields ||
          'items(apiProfile(primaryEmail,profilePicture,thumbnail,thumbnailPhotoUrl),firstName,fullName,id,lastName, email, customer, uid),more,callId,cursor',
    feeds: params.feeds,
    sortOrder: params.sortOrder || 'firstName',
});

/**
 * List users.
 */
export const list = (params: UserListRequestParams, fetchKey?: string) => {
    return userApi.get<UserListSuccessPayload>(
        '/list',
        {
            params: getListParams(params),
            paramsSerializer,
        },
        undefined,
        fetchKey !== undefined,
        fetchKey,
    );
};

export const cancelList = (fetchKey: string) => {
    return userApi.cancel('/list', undefined, fetchKey);
};

export const save = (user: Partial<User>) => {
    return userApi.post('/save', user);
};

export const deleteMulti = (uid: string[]) => {
    return userApi.post('/deleteMulti', { uid });
};

/**
 * List users (with cache).
 */
export const listCacheFirst = (params: UserListRequestParams, fetchKey?: string) => {
    return userApi.getCacheFirst<ServerListResponse<User>>(
        '/list',
        CACHE_TYPE.MEMORY,
        PRIORITY.LOW,
        {
            params: getListParams(params),
            paramsSerializer,
        },
        undefined,
        fetchKey !== undefined,
        fetchKey,
    );
};

/**
 * Save the current user settings. This endpoint needs to contain some "mandatory" fields or else their values are reset
 * See the interface to check which is mandatory. We get those existing values from the redux store ConnectedUser,
 * and overwrite them with the news params.
 *
 * @param {*} params
 * @param {User} connectedUser
 * @return {*}
 */
export const saveCurrentUserSettings = (params: any, connectedUser: any) => {
    const newParams: SettingsSaveParams = {
        langs: connectedUser.langs,
        alternateEmail: connectedUser.alternateEmail,
        currentPassword: connectedUser.currentPassword,
        emailNotifications: connectedUser.emailNotifications,
        lang: connectedUser.lang,
        properties: connectedUser.properties,
        rePassword: connectedUser.rePassword,
        tutorials: connectedUser.tutorials,
        ...params,
        settings: {
            ...connectedUser.settings,
            ...params.settings,
        },
        showTutorial: params.showTutorial || false,
    };
    return userApi.post('/settings/save', newParams);
};

export const follow = (userId: string, params: { notify?: boolean } = {}) => {
    const defaultParams = {
        notify: false,
    };

    return userApi.post(`/${userId}/follow`, { ...defaultParams, ...params });
};

export const unfollow = (userId: string) => {
    return userApi.delete(`/${userId}/unfollow`);
};

/**
 * Retrieve a single user. This specifically uses the User Directory API because
 * that API manages the `apiProfile` and what to return depending on what User Directory
 * is set as main. We should NEVER do a get from the `/user` API, but always through
 * `/user/directory`
 */
export const get = getUserFromDirectory;

export const cancelGet = cancelGetUserFromDirectory;

/**
 * Change user setting 'hasacceptedTerms'.
 *
 * @param  {boolean} hasAccepted User has accepted or refused T&Cs.
 * @return {Promise} The request promise.
 */
export const acceptTermsAndConditions = (hasAccepted = false) => {
    return userApi.get('hasacceptedTerms', {
        params: {
            hasAccepted,
        },
    });
};

/**
 * Retrieves the user profile information and dispatches the different
 * actions depending on whether the api call was successful or not
 */
export const fetchUsers = async (
    options: UserListRequestParams,
    onSuccess: (options: any) => void,
    onError: (error: any) => void,
) => {
    try {
        const { add, ...requestParams } = options;
        const response = await list(requestParams);

        const { data = {} } = response;
        onSuccess({ ...data, add });
    } catch (exception) {
        onError(exception);
    }
};

/**
 * Retrieves each UserDirectory fields defined in the connected user's customer
 */
export const fetchUserMainDirectoryFields = () => {
    return userApi.get('main-directory/fields');
};

/**
 * Fetch the access token used by the MS provider.
 * This token can be used to access all microsoft graph open APIs
 * @warning: the `/service/getToken` API won't work with any other Provider than microsoft.
 * */
export const getUserMsToken = () => {
    return userApi.get<UserMicrosoftToken>('/service/getToken');
};
