import React from 'react';

import { ListDivider } from '@lumapps/lumx/react';

import './index.scss';

const CLASSNAME = 'combobox-divider';

/** Combobox option divider */
export function ComboboxDivider() {
    return <ListDivider className={CLASSNAME} role="presentation" />;
}
