/* istanbul ignore file */

import React from 'react';

import { ErrorPage } from '@lumapps/lumx-front-office/components/ErrorPage';
import { Suspense } from '@lumapps/utils/components/Suspense';

import { ContentLayoutProps } from '../../types';

const LazyContentLayout = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'content-layout' */
            './ContentLayout'
        ),
);

export const ContentLayout = (props: React.ComponentProps<typeof LazyContentLayout>) => (
    <Suspense errorFallback={<ErrorPage code={500} shouldShowIcon />}>
        <LazyContentLayout {...props} />
    </Suspense>
);

export type { ContentLayoutProps };
