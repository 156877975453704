import { Route } from '@lumapps/router';
import { AppId, URL_PREFIX } from '@lumapps/router/constants';

export const playLibraryRouteBase = `/${URL_PREFIX}/play-video-library`;

export const playLibraryRoute: Route = {
    path: `${playLibraryRouteBase}/:view?`,
    appId: AppId.frontOffice,
};

export enum PlayManagerViews {
    videos = 'videos',
    podcasts = 'podcasts',
    playlists = 'playlists',
    analytics = 'analytics',
    videoUsage = 'video-usage',
}

export interface ManagerViewParams {
    view?: PlayManagerViews;
}

const defaultView = (params: ManagerViewParams): Route => {
    return {
        ...playLibraryRoute,
        params: {
            view: params.view || PlayManagerViews.videos,
        },
    };
};

export const managerVideos = (params?: ManagerViewParams): Route => {
    return defaultView({ ...params, view: PlayManagerViews.videos });
};

export const managerPodcasts = (params?: ManagerViewParams): Route => {
    return defaultView({ ...params, view: PlayManagerViews.podcasts });
};

export const managerPlaylists = (params?: ManagerViewParams): Route => {
    return defaultView({ ...params, view: PlayManagerViews.playlists });
};

export const managerAnalytics = (params?: ManagerViewParams): Route => {
    return defaultView({ ...params, view: PlayManagerViews.analytics });
};

export const managerVideoUsage = (params?: ManagerViewParams): Route => {
    return defaultView({ ...params, view: PlayManagerViews.videoUsage });
};
