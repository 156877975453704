import { isExternalUrl } from '@lumapps/router/utils';
import { SEARCH_RESULT_TYPES } from '@lumapps/search/constants/types';
import { SEARCH_SOURCE_TYPES } from '@lumapps/search/keys/sourceTypes';
import { BaseSearchResult } from '@lumapps/search/types';
import { addBaseSlugToSearchUrl } from '@lumapps/search/utils';

import { ArticleResultProps } from '../../types';
import { props as breadcrumbProps } from '../Breadcrumb/props';
import { props as contentMetadataProps } from '../ContentMetadata/props';

const props = (result: BaseSearchResult, baseProps: any, options: any): ArticleResultProps => {
    const { translate, currentInstanceId, fromBackOffice } = options;
    const { url, name, snippet, onClick, id, flag, quickViewUrl, onQuickViewClick, isExternalLink } = baseProps;
    const defaultIcon = 'file-document-box';

    const bProps = {
        url,
        name,
        snippet,
        icon: defaultIcon,
        id: id || '',
        flag,
        quickViewUrl,
        onQuickViewClick,
        isExternalLink,
    };

    const { article } = result;

    if (!article) {
        return bProps;
    }

    const breadcrumbLevels = breadcrumbProps({
        instance: article.instanceDetails,
        metadata: article.metadataDetails,
        translate,
    });

    const metadata = contentMetadataProps(result, options);

    const isContentFromCurrentInstance = currentInstanceId === article.instanceDetails.id;

    /**
     * In order to generate the URL for a content result, we need to:
     * - Use the URL provided directly in the article object as the default one
     * - If the content is from the current instance, we retrieve the slug if there is, or as a fallback
     * we retrieve the slug from the complete URL
     * - If the content is from another instance, we will then use the complete URL.
     */
    let articleUrl = article.url || url;

    // Is the article using an external url such as https://my-url.com
    const isExternal = isExternalUrl(articleUrl);
    if (!isExternal) {
        if (fromBackOffice) {
            articleUrl = addBaseSlugToSearchUrl(article.url);
        } else if (article.slug) {
            articleUrl = translate(article.slug);
        } else {
            /**
             * If the slug is not there and the content is from the current instance,
             * we can still retrieve it from the absolute URL, we just need to parse it a little bit, and
             * remove the customer and instance slug
             */
            articleUrl = url.split('/').pop() as string;
        }
    }

    return {
        ...bProps,
        icon: article.customContentTypeDetails?.icon || defaultIcon,
        kind: translate(article.customContentTypeDetails?.name || SEARCH_SOURCE_TYPES.CONTENT),
        type: SEARCH_RESULT_TYPES.ARTICLE,
        iconName: article.customContentTypeDetails?.icon || defaultIcon,
        breadcrumbLevels,
        metadata,
        thumbnail: article.thumbnail,
        url: articleUrl,
        onClick,
        id: article.uid,
        isFromCurrentInstance: isContentFromCurrentInstance,
        originSiteId: article.instanceDetails.id,
        position: result.position,
        isPromoted: Boolean(result.isPromoted),
        isExternalLink: Boolean(article.isExternalLink),
        instanceDetails: article.instanceDetails,
    };
};

export { props };
