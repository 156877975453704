import React, { useRef } from 'react';

import { ComboboxOption } from '@lumapps/combobox/components/ComboboxOption';
import { ComboboxOptionProps } from '@lumapps/combobox/types';
import { Size } from '@lumapps/lumx/react';
import { Thumbnail } from '@lumapps/search-results/components/Thumbnail';
import { SEARCH_RESULT_TYPES } from '@lumapps/search/constants';
import { mergeRefs } from '@lumapps/utils/react/mergeRefs';
import { PolymorphicComponentProps } from '@lumapps/utils/types/PolymorphicComponent';

import { SearchBoxOptionWithItemProps } from '../../types';
import { SearchBoxOptionLabel } from '../SearchBoxOptionLabel';

/**
 * Base component for all "interaction" type options.
 * This displays the thumbnail / label
 */
export const BaseSearchBoxInteractionOption = <C extends React.ElementType>({
    suggestion,
    onSelect,
    itemRef,
    ...forwardedProps
}: PolymorphicComponentProps<C, SearchBoxOptionWithItemProps>) => {
    const { thumbnail, entityType, icon } = suggestion.item;
    const charactersToDisplay = entityType === SEARCH_RESULT_TYPES.THIRD_PARTY ? 2 : 1;
    const linkRef = useRef<any>(null);

    /**
     * When the combobox option is selected via the keyboard,
     * click on the interaction to trigger the link
     */
    const handleOnSelect: ComboboxOptionProps['onSelect'] = (option, source) => {
        if (source === 'keyboard' && linkRef.current) {
            linkRef.current.click();
        } else {
            onSelect();
        }
    };

    return (
        <ComboboxOption
            ref={mergeRefs([linkRef, itemRef])}
            onSelect={handleOnSelect}
            {...forwardedProps}
            textValue={suggestion.label}
            before={
                <Thumbnail
                    customizationContext={{
                        entityType,
                        mimeType: suggestion.mimeType,
                    }}
                    iconName={thumbnail ? undefined : icon}
                    image={thumbnail}
                    alt=""
                    size={Size.s}
                    hasShape
                    charactersToDisplay={charactersToDisplay}
                />
            }
            data={suggestion}
        >
            <SearchBoxOptionLabel label={suggestion.label} />
        </ComboboxOption>
    );
};
