import { useMemo } from 'react';

import { searchForPosting } from '@lumapps/communities/api';
import { CommunitiesSearchForPostingRequestParams } from '@lumapps/communities/types';
import { currentLanguageSelector } from '@lumapps/languages/ducks/selectors';
import { useSelector } from '@lumapps/redux/react';

import { CommunityPickerDialogHook } from '../types';
import { useCommunityPickerFilters } from './useCommunityPickerFilters';

export const useSearchForPostingPicker: CommunityPickerDialogHook<
    CommunitiesSearchForPostingRequestParams,
    typeof searchForPosting
> = (fetchParams, query = '') => {
    const lang = useSelector(currentLanguageSelector);
    const { component, sortOrder, filters, resetFilters } = useCommunityPickerFilters({
        enableMultipleInstancesSearch: !fetchParams.instanceId,
    });

    const fetchAPIParams: CommunitiesSearchForPostingRequestParams = useMemo(
        () => ({ ...filters, ...fetchParams, sortOrder, lang, query }),
        [fetchParams, filters, lang, query, sortOrder],
    );

    return {
        fetchAPIParams,
        fetchAPI: searchForPosting,
        additionalFilterFields: component,
        hasNoFilter: !fetchAPIParams.followingOnly && !fetchAPIParams.query && !fetchAPIParams.instanceId,
        resetFilters,
    };
};
