import { getCurrentInstanceParentId } from '@lumapps/instance/ducks/selectors';
import { useSelector } from '@lumapps/redux/react';
import { TranslateObject } from '@lumapps/translations';

import { useChildStyles, useStyles } from './useStyles';

export const useFooterStyle = (property = 'footer'): TranslateObject => {
    const parentId = useSelector(getCurrentInstanceParentId);
    const { styles: footer } = useStyles({ property });
    const { styles: childFooter } = useChildStyles({ property });

    return parentId ? childFooter : footer;
};
