import React from 'react';

import { Text } from '@lumapps/lumx/react';

import { useGlobalLiveMessage } from '../../hooks/useGlobalLiveMessage';
import { A11YLiveMessage } from '../A11YLiveMessage';

/**
 * Live region that will be updated with the current redux state.
 * There should only be one per layout as only one message can be read at a time.
 *
 * @family A11Y
 */
const GlobalLiveMessage = () => {
    const currentA11YMessage = useGlobalLiveMessage();

    return (
        <A11YLiveMessage hidden role="status" atomic="true">
            {currentA11YMessage ? <Text as="p">{currentA11YMessage}</Text> : ''}
        </A11YLiveMessage>
    );
};

export { GlobalLiveMessage };
