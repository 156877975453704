import { isFeatureEnabled } from '@lumapps/features';
import { createSelector } from '@lumapps/redux/reselect';

import { USER_DIRECTORY_REACT, USER_PROFILE_REACT } from '../constants';

export const isReactUserDirectory = createSelector(
    isFeatureEnabled(USER_DIRECTORY_REACT),
    isFeatureEnabled(USER_PROFILE_REACT),
    (userDirectoryReactEnabled, userProfileReactEnabled) => userDirectoryReactEnabled && userProfileReactEnabled,
);
