import { mdiArrowDown, mdiArrowUp } from '@lumapps/lumx/icons';
import { GLOBAL } from '@lumapps/translations';

import { SortersType } from './types';

export enum SORT_ORDER {
    DECREASING = 'decreasing',
    INCREASING = 'increasing',
}

/** Sort order translations */
export const SORT_ORDER_TRANSLATIONS = {
    [SORT_ORDER.INCREASING]: GLOBAL.ASCENDING,
    [SORT_ORDER.DECREASING]: GLOBAL.DESCENDING,
};

/** Sort order icons */
export const SORT_ORDER_ICONS = {
    [SORT_ORDER.DECREASING]: mdiArrowDown,
    [SORT_ORDER.INCREASING]: mdiArrowUp,
};

/** Sorters data attribute scope */
export const SORTERS_SCOPE = 'sorters';

export enum FILTERS_MODE {
    EXPOSED_FACETS = 'EXPOSED_FACETS',
    GROUPED = 'GROUPED',
    SEPARATED = 'SEPARATED',
}

/**
 * For compatibility issues, some legacy APIs use this naming convention instead.
 */
export type Order = 'asc' | 'desc';

/**
 * Default sorts
 */
export const SORT_FIELDS: SortersType[] = [
    { label: GLOBAL.MOST_RELEVANT },
    { label: GLOBAL.SORT_ALPHABETICALLY_ASC, id: 'title' },
    { label: GLOBAL.SORT_ALPHABETICALLY_DESC, id: '-title' },
    { label: GLOBAL.SORT_NEWEST, id: '-createdAt' },
    { label: GLOBAL.SORT_OLDEST, id: 'createdAt' },
];
