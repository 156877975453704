import React from 'react';

import { SearchBoxOptionTypeProps } from '../../types';
import { SearchBoxInteractionOption } from '../SearchBoxInteractionOption';
import { SearchBoxDefaultOption } from './SearchBoxDefaultOption';

/**
 * Base component for all "suggestion" type options.
 */
export const SearchBoxSuggestionOption = ({ suggestion, ...forwardedProps }: SearchBoxOptionTypeProps) => {
    const { item } = suggestion;

    const Component = item ? SearchBoxInteractionOption : SearchBoxDefaultOption;

    return <Component suggestion={suggestion} {...forwardedProps} />;
};
