import { isFeatureEnabled } from '@lumapps/features';
import { createSelector } from '@lumapps/redux/reselect';

import {
    LOGIN_V2_ALPHA_FEATURE_TOKEN,
    LOGIN_V2_BETA_FEATURE_TOKEN,
    PLAY_ALPHA_FEATURE_TOKEN,
    PLAY_AUTOGENERATED_SUBTITLES,
    PLAY_BETA_FEATURE_TOKEN,
    PLAY_CHINA_FEATURE_TOKEN,
    PLAY_HEADER_BUTTON_FEATURE_TOKEN,
    PLAY_HIDE_AUTHOR,
    PLAY_STANDARD,
    PLAY_VIDEO_DOWNLOAD_TOKEN,
    PLAY_VIDEO_FEATURE_TOKEN,
} from '../constants';

export const isPlayAlphaFeatureEnabled = isFeatureEnabled(PLAY_ALPHA_FEATURE_TOKEN);
export const isPlayBetaFeatureEnabled = isFeatureEnabled(PLAY_BETA_FEATURE_TOKEN);
export const isPlayChinaFeatureEnabled = isFeatureEnabled(PLAY_CHINA_FEATURE_TOKEN);
export const isPlayHeaderButtonFeatureTokenEnabled = isFeatureEnabled(PLAY_HEADER_BUTTON_FEATURE_TOKEN);
export const isPlayVideoFeatureTokenEnabled = isFeatureEnabled(PLAY_VIDEO_FEATURE_TOKEN);
export const isPlayVideoDownloadFeatureTokenEnabled = isFeatureEnabled(PLAY_VIDEO_DOWNLOAD_TOKEN);
export const isPlayHideAuthor = isFeatureEnabled(PLAY_HIDE_AUTHOR);
export const isPlayStandardEnabled = isFeatureEnabled(PLAY_STANDARD);
export const isPlayAutogeneratedSubtitlesEnabled = isFeatureEnabled(PLAY_AUTOGENERATED_SUBTITLES);

export const isLoginV2AlphaFeatureTokenEnabled = isFeatureEnabled(LOGIN_V2_ALPHA_FEATURE_TOKEN);
export const isLoginV2BetaFeatureTokenEnabled = isFeatureEnabled(LOGIN_V2_BETA_FEATURE_TOKEN);

export const isPlayVideoEnabled = createSelector(isPlayVideoFeatureTokenEnabled, (isVideoEnabled) => isVideoEnabled);
export const isPlayAlphaEnabled = createSelector(isPlayAlphaFeatureEnabled, (isPlayAlphaEnabled) => isPlayAlphaEnabled);

export const canDisplayPlayHeaderButton = createSelector(
    isPlayVideoFeatureTokenEnabled,
    isPlayHeaderButtonFeatureTokenEnabled,
    (isPlayEnabled, isPlayheaderButtonEnabled) => isPlayEnabled && isPlayheaderButtonEnabled,
);
