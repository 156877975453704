import { useSelector } from '@lumapps/redux/react';

import { currentA11yLiveMessageSelector } from '../ducks/selectors';

/**
 * Hook that returns the current page's global live message
 */
export const useGlobalLiveMessage = () => {
    /** The currently set message. */
    const currentA11YMessage = useSelector(currentA11yLiveMessageSelector);

    return currentA11YMessage;
};
