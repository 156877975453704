import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { get } from '@lumapps/constants';
import { ContentLayoutProps, ContentLayout } from '@lumapps/content-layout/components/ContentLayout';
import { isUserDirectory } from '@lumapps/content-types/utils';
import { Page, PAGE_WIDTH } from '@lumapps/lumx-front-office/components/Page';
import { Redirect, useParams } from '@lumapps/router';
import { createRelativeUrl } from '@lumapps/router/utils';
import { Tutorial } from '@lumapps/tutorials/components/Tutorial';
import { userDirectoryView } from '@lumapps/user-directory-front-office/routes';
import { useHandleNotAllowed } from '@lumapps/utils/hooks/useHandleNotAllowed';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import { useContent } from '../../hooks/useContent';
import { useContentStatusNotification } from '../../hooks/useContentStatusNotification';
import { getContentPageId } from '../../utils/getContentPageId';
import { ContentContextualActions } from '../ContentContextualActions';

import './index.scss';

const constants = get();
const CLASSNAME = 'content-page';

interface ContentPageProps {
    /** List of authorized widgets. */
    enabledWidgetTypes?: ContentLayoutProps['enabledWidgetTypes'];
    /** Page content ref. */
    contentRef?: ContentLayoutProps['contentRef'];
}

export const ContentPage: React.FC<ContentPageProps> = ({ enabledWidgetTypes, contentRef }) => {
    const { id }: any = useParams();
    const {
        currentContentTitle,
        currentContentType,
        pageId,
        isHomePage,
        currentContentSlug: slug,
        canCurrentUserEditContent,
    } = useContent();
    const [status, setStatus] = React.useState<BaseLoadingStatus>(BaseLoadingStatus.loading);
    const { block } = useClassnames(CLASSNAME);
    /**
     * Get the Content ID from 3 different sources, depending on the use case
     * Default - from the param URL
     * Homepage - from the store (set by the front-init)
     * Content with legacy URL - from the initialContent
     * */
    const contentId = id || (isHomePage && pageId) || constants.initialContentId;

    // Handle #not-allowed URL hash on home page
    useHandleNotAllowed(isHomePage);

    useContentStatusNotification({
        contentId,
        isNotificationDisplayable: canCurrentUserEditContent && status === BaseLoadingStatus.idle,
    });

    // Redirect to user directory v2
    if (contentId && slug && isUserDirectory(currentContentType)) {
        const redirectURL = createRelativeUrl(userDirectoryView({ contentId, slug }));
        return <Redirect to={redirectURL} push={false} />;
    }

    if (!contentId) {
        return null;
    }

    return (
        <Page
            id={getContentPageId(contentId, slug)}
            className={block({ 'is-homepage': isHomePage, [`type-${currentContentType}`]: true })}
            hasBackground={false}
            title={currentContentTitle || ''}
            isLoading={status === BaseLoadingStatus.loading}
            width={PAGE_WIDTH.FULL}
            type={currentContentType}
        >
            {status === BaseLoadingStatus.idle && <ContentContextualActions />}
            <ContentLayout
                contentRef={contentRef}
                contentId={contentId}
                enabledWidgetTypes={enabledWidgetTypes}
                onStatusChange={setStatus}
            />
            {/* Tutorials: */}
            <Tutorial />
        </Page>
    );
};
