import omit from 'lodash/omit';
import set from 'lodash/set';
import moment from 'moment';

import { HALF_AN_HOUR, FULL_DATE_SIX_FRACTIONAL_SECONDS } from '@lumapps/constants';
import { combineReducers } from '@lumapps/redux';
import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';
import { mergeObjectOnly } from '@lumapps/utils/object/mergeObjectOnly';
import type { LinkAttachment } from '@lumapps/widget-base/types';

import { EditedPost, PostsState, Post, PostType, AttachmentTypes } from '../types';

export const domain = 'post';

const initialState: PostsState = {
    editedPost: {},
    entities: {},
    saving: false,
    isPostDialogOpen: false,
};

const { actions, reducer: rootReducer } = createSlice({
    domain,
    initialState,
    reducers: {
        openDialog: (state: PostsState) => {
            set(state, 'isPostDialogOpen', true);
        },
        resetEditedPost: (state: PostsState) => {
            set(state, 'editedPost', {});
        },
        updatePost: (state: PostsState, action: PayloadAction<{ postId: string; post: Partial<Post> }>) => {
            const newPost = mergeObjectOnly(state.entities[action.payload.postId], action.payload.post);
            set(state, ['entities', action.payload.postId], newPost);
        },
        resetUpdatedPost: (state: PostsState, action: PayloadAction<{ postId: string }>) => {
            const updatedPosts = omit(state.entities, action.payload.postId);
            set(state, 'entities', updatedPosts);
        },
        setEditedPost: (state: PostsState, action: PayloadAction<EditedPost>) => {
            const newPost = action.payload;
            if (newPost.postType === PostType.EVENT) {
                const createdAt = moment()
                    .minutes(moment().minutes() >= HALF_AN_HOUR ? HALF_AN_HOUR : 0)
                    .utc()
                    .local()
                    .format(FULL_DATE_SIX_FRACTIONAL_SECONDS);

                newPost.eventStartDate = newPost.eventStartDate || createdAt;

                newPost.eventEndDate =
                    newPost.eventEndDate ||
                    moment(newPost.eventStartDate).add(1, 'hour').format(FULL_DATE_SIX_FRACTIONAL_SECONDS);
            }
            mergeObjectOnly(state.editedPost, newPost);
            if (newPost.content) {
                set(state, ['editedPost', 'content'], newPost.content);
            }
            if (newPost.title) {
                set(state, ['editedPost', 'title'], newPost.title);
            }
        },
        savePostRequest: (state: PostsState) => {
            set(state, 'saving', true);
        },
        savePostSuccess: (state: PostsState) => {
            set(state, 'editedPost', {});
            set(state, 'isPostDialogOpen', false);
            set(state, 'saving', false);
        },
        savePostError: (state: PostsState) => {
            set(state, 'saving', false);
        },
        removeAttachment: (
            state: PostsState,
            action: PayloadAction<{ attachmentType: AttachmentTypes; attachmentIndex: number }>,
        ) => {
            const { attachmentType, attachmentIndex } = action.payload;

            const newAttachments = state.editedPost[attachmentType]?.filter(
                (attachment: any, index: number) => index !== attachmentIndex,
            );

            if (attachmentType === 'images') {
                set(state, ['editedPost', 'images'], []);
            } else {
                set(state, ['editedPost', action.payload.attachmentType], newAttachments);
            }
        },
        editAttachment: (
            state: PostsState,
            action: PayloadAction<{ attachmentType: AttachmentTypes; data?: LinkAttachment }>,
        ) => {
            const { attachmentType, data } = action.payload;

            if (attachmentType === 'links') {
                set(state, ['editedPost', attachmentType], [data]);
            }
        },
    },
});

const reducer = combineReducers({
    root: rootReducer,
});

export { actions, reducer };
