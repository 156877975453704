/* istanbul ignore file */
import { isModuleAllowed } from '@lumapps/directories/ducks/selectors';
import { isInstanceSuperAdmin } from '@lumapps/permissions';
import { connect } from '@lumapps/redux/react';
import { BackOfficeStore } from '@lumapps/redux/types';

import { ModulesSettingsMenu } from './ModulesSettingsMenu';

const mapStateToProps = (state: BackOfficeStore) => {
    const isAllowed = isModuleAllowed(state);
    const isSuperAdmin = isInstanceSuperAdmin(state);

    return {
        isAllowed: isAllowed || isSuperAdmin,
        isSuperAdmin,
    };
};

const mapDispatchToProps = {};

const Connected = connect(mapStateToProps, mapDispatchToProps)(ModulesSettingsMenu);

export { Connected as ModulesSettingsMenu };
