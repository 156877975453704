import isEmpty from 'lodash/isEmpty';
import partition from 'lodash/partition';
import pick from 'lodash/pick';

import { selectSpaceOrCommunityId } from '@lumapps/communities/ducks/selectors';
import { get as getConfig } from '@lumapps/constants';
import { contentSelector } from '@lumapps/contents/ducks/selectors';
import { isFeatureEnabled } from '@lumapps/features';
import { createSelector } from '@lumapps/redux/reselect';
import { FrontOfficeStore } from '@lumapps/redux/types';
import { WIDGET_TITLE_TYPE } from '@lumapps/widget-title/constants';

import { CLIENT_COMPUTED_WIDGET_TYPE, NGI_WIDGETS_IN_DESIGNER_FF_TOKEN, TRANSLATABLE_WIDGET_TYPES } from '../constants';
import { OwnerResourceInfo } from '../types';
import { domain } from './domain';
import { WidgetState } from './type';

const constants = getConfig();

/** Get widget v2 redux state in store. */
export const getWidgetState = (store: FrontOfficeStore) => store[domain] ?? {};

export const getWidgetEntities = createSelector(getWidgetState, (state) => state.entities ?? {});

/** Get widget state by `widgetId` in store. */
export const getWidget = (state: FrontOfficeStore, { widgetId }: { widgetId?: string }) => {
    if (!widgetId) {
        return undefined;
    }
    return getWidgetState(state).entities[widgetId];
};

export const getWidgetsOfType = (widgetType?: string) => (state: FrontOfficeStore) => {
    if (!widgetType) {
        return undefined;
    }
    const widgets = Object.values(getWidgetState(state)?.entities || {});
    return widgets.filter((widget) => widget.widgetType === widgetType);
};

export const getClientComputedWidgetsOfType = (widgetType?: string) => (state: FrontOfficeStore) => {
    if (!widgetType) {
        return undefined;
    }

    return getWidgetsOfType(CLIENT_COMPUTED_WIDGET_TYPE)(state)?.filter(
        (widget) => widget.body?.widgetType === widgetType,
    );
};

export const getWidgetLoadingState = (state: FrontOfficeStore, { widgetId }: { widgetId?: string }) => {
    return getWidget(state, { widgetId })?.state;
};

export const getWidgetPaginationType = (state: FrontOfficeStore, { widgetId }: { widgetId?: string }) => {
    return getWidget(state, { widgetId })?.paginationType;
};

export const getTranslatableWidgets = createSelector(getWidgetEntities, (entities) => {
    const widgets = Object.values(entities);
    const translatableWidgets = widgets.filter((widget) => TRANSLATABLE_WIDGET_TYPES.includes(widget.widgetType));

    if (!translatableWidgets.length) {
        return null;
    }

    // Split title widgets from other translatable widgets
    const [titleWidgets, otherWidgets] = partition(
        translatableWidgets,
        (widget) => widget.widgetType === WIDGET_TITLE_TYPE,
    );

    return { titleWidgets, otherWidgets };
});

export const getTranslationEntity = (
    state: FrontOfficeStore,
    { widgetId }: { widgetId: string },
): Partial<WidgetState> => {
    return getWidgetState(state).translationEntities[widgetId];
};

export const isNGIContentFiltersFFEnabled = isFeatureEnabled('ngi-content-filter');

export const isNGIContentFiltersGlobalSearchFFEnabled = isFeatureEnabled('ngi-content-filter-global-search-link');

export const getMainWidget = createSelector(getWidgetEntities, (entities) =>
    Object.values(entities).find((entity) => entity.isMainWidget),
);

/**
 * This FF will display NGI Widgets in Designer.
 * Only works for supported widgets, see associated legacy widget code to see if the widget is supported.
 * */
export const isNGIWidgetsInDesignerFFEnabled = isFeatureEnabled(NGI_WIDGETS_IN_DESIGNER_FF_TOKEN);

/**
 * Get the owner resource info (community, space or content) needed for OTF APIs
 */
export const getOwnerResourceInfo = createSelector(
    [selectSpaceOrCommunityId, contentSelector],
    (communityId, currentContent): OwnerResourceInfo | undefined => {
        const instance = constants.instanceId;

        if (communityId) {
            return { ownerResourceId: communityId, ownerResourceType: 'community', properties: { instance } };
        }

        const content = pick(currentContent, ['id', 'title', 'thumbnail', 'mediaThumbnail', 'type', 'externalKey']);
        if (!isEmpty(currentContent)) {
            return { ownerResourceId: content.id, ownerResourceType: 'content', properties: { instance, ...content } };
        }

        return undefined;
    },
);
