/* istanbul ignore file */
import { reducer as a11yMessage } from '@lumapps/aria-live/ducks/slice';
import { reducer as bookmarks } from '@lumapps/bookmarks/ducks/slice';
import { reducer as chatReducer } from '@lumapps/chat/ducks/slice';
import { reducer as commentReducer } from '@lumapps/comment/ducks/slice';
import { reducers as communitiesReducers } from '@lumapps/communities/ducks/slice';
import { reducer as contentTypes } from '@lumapps/content-types/ducks/slice';
import { reducer as content } from '@lumapps/contents/ducks/slice';
import { reducer as directories } from '@lumapps/directories/ducks/slice';
import { reducer as events } from '@lumapps/events/ducks/slice';
import { reducer as folksonomy } from '@lumapps/folksonomy/ducks/slice';
import { reducer as instance } from '@lumapps/instance/reducer';
import { reducer as nanny } from '@lumapps/nanny-details/ducks/slice';
import { reducer as notificationCenter } from '@lumapps/notification-center/ducks/slice';
import { reducer as notifications } from '@lumapps/notifications';
import { reducer as permissions } from '@lumapps/permissions/ducks/slice';
import { reducer as post } from '@lumapps/posts/ducks/slice';
import { combineReducers } from '@lumapps/redux';
import { reducer as router } from '@lumapps/router/ducks/slice';
import { reducer as saConnectedUser } from '@lumapps/sa-connected-user/ducks/slice';
import { reducer as saPrograms } from '@lumapps/sa-programs/ducks/slice';
import { reducer as search } from '@lumapps/search/ducks/slice';
import { reducer as user } from '@lumapps/user/ducks/slice';
import { reducer as widget } from '@lumapps/widget-base/ducks/slice';

const defaultReducer = (state: any = {}) => state;

export default {
    bookmarks,
    contentTypes,
    content,
    customer: defaultReducer,
    locale: defaultReducer,
    styles: defaultReducer,
    header: defaultReducer,
    user,
    permissions,
    instance,
    notifications,
    search,
    directories,
    saConnectedUser,
    saPrograms,
    notificationCenter,
    communities: combineReducers(communitiesReducers),
    post,
    events,
    comment: commentReducer,
    feed: defaultReducer,
    folksonomy,
    nanny,
    widget,
    chat: chatReducer,
    router,
    a11yMessage,
    analyticsInfo: defaultReducer,
};
