import { ComboboxDivider } from '../ComboboxDivider';
import { ComboboxOption } from '../ComboboxOption';
import { SelectTextField as BaseSelectTextField } from './SelectTextField';

export {
    type SelectTextFieldProps,
    type SingleSelectTextFieldProps,
    type MultipleSelectTextFieldProps,
} from './SelectTextField';

export const SelectTextField = Object.assign(BaseSelectTextField, { Option: ComboboxOption, Divider: ComboboxDivider });
