/* istanbul ignore file */
import { Route, AppId, createPageRoute } from '@lumapps/router';

const moduleRoute: Route = createPageRoute({
    slug: 'admin/module',
    legacyId: 'app.admin.module',
    appId: AppId.legacy,
});

const directoriesRoute: Route = createPageRoute({
    slug: 'directories',
    appId: AppId.backOffice,
});

const BASE_DIRECTORY_URL = 'directory';

/**
 * Retrieves the route for module's admin.
 */
const admin = (): Route => {
    return moduleRoute;
};

const baseAdminDirectory = (id: string, route: Route): Route => {
    const params = {
        id,
    };

    return {
        ...route,
        params,
    };
};

export { admin, moduleRoute, baseAdminDirectory, BASE_DIRECTORY_URL, directoriesRoute };
