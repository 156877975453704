import React from 'react';

import { mdiMenuDown } from '@lumapps/lumx/icons';
import { ButtonProps, Button } from '@lumapps/lumx/react';
import { getWithSelector } from '@lumapps/utils/function/selector/getWithSelector';
import { InfiniteScroll } from '@lumapps/utils/hooks/useInfiniteScroll';

import { BaseSelectProps, type ComboboxProps, SingleSelection, OnComboboxSelect } from '../../types';
import { renderSelectOptions } from '../../utils/renderSelectOptions';
import { Combobox } from '../Combobox';
import { ComboboxButtonProps } from '../ComboboxButton';
import { ComboboxListBoxProps } from '../ComboboxListBox';

type InheritComboboxProps = Pick<ComboboxProps, 'onOpen'>;

type InheritedButtonProps = ComboboxButtonProps & ButtonProps;

type SelectionProps<O = any> = Omit<SingleSelection<O>, 'selectionType'>;

/**
 * SelectButton props
 */
export type SelectButtonProps<O = any> = InheritComboboxProps &
    InheritedButtonProps &
    BaseSelectProps<O> &
    SelectionProps<O> & {
        buttonRef?: React.RefObject<HTMLButtonElement>;
        popoverProps?: ComboboxListBoxProps['popoverProps'];
    };

/**
 * A Button with a select dropdown to choose between a list of options.
 *
 * @family Combobox
 */
export const SelectButton = <O,>(props: SelectButtonProps<O>) => {
    const {
        as = Button,
        options,
        getOptionId,
        getOptionName = getOptionId,
        getOptionDescription,
        getSectionId,
        renderOption,
        value,
        onChange,
        onOpen,
        onLoadMore,
        hasSectionDividers,
        label,
        buttonRef,
        popoverProps,
        ...forwardButtonProps
    } = props;
    const [listElement, setListElement] = React.useState<HTMLElement | null>(null);

    const selectedId = value ? getWithSelector(getOptionId, value) : undefined;
    const selectedName = value ? getWithSelector(getOptionName, value) : '';
    const selectedIds = selectedId ? [selectedId] : undefined;

    // Map option id selection to option object selection
    const onSelect = React.useCallback<OnComboboxSelect>(
        (selectedOption) => {
            const newValue =
                selectedOption &&
                options?.find((option) => {
                    const optionId = getWithSelector(getOptionId, option);
                    return optionId === selectedOption?.id;
                });

            onChange?.(newValue);
        },
        [getOptionId, onChange, options],
    );

    const buttonProps = as === Button ? { rightIcon: mdiMenuDown, ...forwardButtonProps } : forwardButtonProps;

    return (
        <Combobox
            selectedIds={selectedIds}
            onSelect={onSelect}
            onOpen={onOpen}
            inputValue={selectedName}
            autoFilter={false}
            showEmptyState={false}
        >
            <Combobox.Button as={as} ref={buttonRef} label={label} {...buttonProps} />

            <Combobox.List listRef={setListElement} popoverProps={{ placement: 'bottom-start', ...popoverProps }}>
                {renderSelectOptions({
                    options,
                    getSectionId,
                    renderOption,
                    getOptionId,
                    getOptionName,
                    getOptionDescription,
                    hasSectionDividers,
                })}

                {onLoadMore ? (
                    <InfiniteScroll callback={onLoadMore} options={{ root: listElement, rootMargin: '100px' }} />
                ) : null}
            </Combobox.List>
        </Combobox>
    );
};
