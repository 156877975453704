/* istanbul ignore file */
import uniq from 'lodash/uniq';

import BaseApi, { fetchAll } from '@lumapps/base-api';
import { ServerListRequest, ServerListResponse, PRIORITY, BaseApiPromise } from '@lumapps/base-api/types';
import { CACHE_TYPE } from '@lumapps/cache';
import { AdminMetadata, AdminMetadataPositionUpdate, NewMetadataPayload } from '@lumapps/metadata-admin/type';
import { splitArray } from '@lumapps/utils/array/splitArray';

import { FETCH_ALL_METADATA_MAX_RESULTS } from '../constants';
import { Metadata } from '../types';

/**
 * Metadata API.
 * https://api.lumapps.com/docs/metadata
 */
export const metadataApi = new BaseApi({ path: 'metadata', bypassMonolith: true });

interface ListMetadataRequest extends Omit<ServerListRequest, 'maxResults'> {
    emptyParent?: boolean;
    name?: string;
    parent?: string;
    instance?: string;
    familyId?: string;
    fields?: string;
    maxResults?: number;
    more?: boolean;
    displayInFilter?: boolean;
    customContentTypeIds?: string[];
    withoutCustomContentTypes?: boolean;
}

export const listMetadata = (params: ListMetadataRequest) =>
    metadataApi.get<ServerListResponse<Metadata>>('list', { params });

export const getMetadata = (uid: string, fields?: string) =>
    metadataApi.getCacheFirst<Metadata>('get', CACHE_TYPE.MEMORY, PRIORITY.HIGH, { params: { uid, fields } });

const RESTRICT_TO_METADATA_LIMIT = 150;
export interface GetMultipleMetadata {
    ids: string[];
    fields?: string;
    listKey?: string;
}

export const getMultipleMetadata = (params: GetMultipleMetadata) => {
    /**
     * If the total queried `ids` is more than RESTRICT_TO_METADATA_LIMIT, the backend will return a 400
     * since it has a fixed limit on the backend. We fetch these items in batchs in order to avoid that error,
     * and return a single promise that will resolve when all those promises are finished.
     */
    if (params.ids && params.ids.length > 0) {
        if (params.ids.length > RESTRICT_TO_METADATA_LIMIT) {
            const metadatas = splitArray(uniq(params.ids), RESTRICT_TO_METADATA_LIMIT);
            const promises: BaseApiPromise<ServerListResponse<Metadata>>[] = metadatas.map((list: string[]) => {
                return getMultipleMetadata({
                    ...params,
                    ids: list,
                });
            });

            return Promise.all(promises).then((responses) => {
                const items: Metadata[] = [];

                responses.forEach((response) => {
                    const { data } = response;

                    items.push(...data.items);
                });

                /**
                 * we use all metadata and headers of the first request, and return
                 * the total items from all responses. Not the best, we are trying to find a fix
                 * on the backend side for this.
                 */
                return {
                    ...responses[0],
                    data: {
                        items,
                        cursor: '',
                        more: false,
                    },
                };
            });
        }

        /**
         * If `ids` is less than the limit, we pass in `maxResults` as the same as
         * the total `ids` to have them all.
         */
        Object.assign(params, { maxResults: params.ids.length.toString() });
    }

    return metadataApi.getCacheFirst<ServerListResponse<Metadata>>('getMulti', CACHE_TYPE.MEMORY, PRIORITY.HIGH, {
        params,
    });
};
/**
 * Recursively call all metadata from a family.
 *
 * Returns an object with the data to get closer to Axios response.
 */
export const getAllFamilyMetadata = async ({
    maxResults = FETCH_ALL_METADATA_MAX_RESULTS,
    ...params
}: ListMetadataRequest) => {
    const metadataFamilies = await fetchAll(listMetadata, { ...params }, maxResults);

    /**
     * Returns an object with the data to get closer to Axios response.
     */
    return {
        data: metadataFamilies,
    };
};

/**
 * Create metadata category. Can be used for both instance and customer
 * @param params metadata
 */
export const createMetadata = (metadata: AdminMetadata | NewMetadataPayload) => {
    return metadataApi.post('save', { ...metadata });
};

/**
 * Update metadata category. Can be used for both instance and customer
 * @param params metadata
 */
export const updateMetadata = (metadata: AdminMetadata | AdminMetadataPositionUpdate) => {
    return metadataApi.post('save', { ...metadata });
};

/**
 * get list of all metadata for the admin.
 * @param params ListMetadataRequest
 * @param getCacheFirst Boolean
 * @returns AdminMetadata array
 */
export const listAdminMetadata = (params: ListMetadataRequest, getCacheFirst = false) =>
    getCacheFirst
        ? metadataApi.getCacheFirst<ServerListResponse<AdminMetadata>>('list', CACHE_TYPE.MEMORY, PRIORITY.HIGH, {
              params,
          })
        : metadataApi.get<ServerListResponse<AdminMetadata>>('list', { params });

/**
 * delete metadata category. Can be used for both instance and customer
 * @param params metadata
 */
export const deleteMetadata = (metadataId: string) => {
    return metadataApi.delete('delete', { params: { uid: metadataId } });
};
