//! PLEASE keep this list in alphabetical order for a better readability
export enum CAMPAIGNS {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=202583688
     */
    ABOUT_TO_CHANGE_BASELINE_DATE = 'CAMPAIGNS.ABOUT_TO_CHANGE_BASELINE_DATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=203513664
     */
    ARCHIVE_CAMPAIGN = 'CAMPAIGNS.ARCHIVE_CAMPAIGN',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=369245656
     */
    BANNER_ROLES_ADMINS = 'CAMPAIGNS.BANNER_ROLES_ADMINS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=194265517
     */
    BASELINE_DESCRIPTION = 'CAMPAIGNS.BASELINE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=202554671
     */
    BROADCASTS_BASELINE_DATE_WILL_UPDATE = 'CAMPAIGNS.BROADCASTS_BASELINE_DATE_WILL_UPDATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=202554702
     */
    BROADCASTS_WILL_GO_TO_DRAFT = 'CAMPAIGNS.BROADCASTS_WILL_GO_TO_DRAFT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=375431243
     */
    CAMPAIGN = 'CAMPAIGNS.CAMPAIGN',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=203779036
     */
    CAMPAIGN_ARCHIVED = 'CAMPAIGNS.CAMPAIGN_ARCHIVED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=369267567
     */
    CAMPAIGNS_BROADCASTS = 'CAMPAIGNS.CAMPAIGNS_BROADCASTS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=189978554
     */
    CAMPAIGN_NAME = 'CAMPAIGNS.CAMPAIGN_NAME',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=190206967
     */
    CAMPAIGN_SAVED = 'CAMPAIGNS.CAMPAIGN_SAVED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=208723999
     */
    CAMPAIGN_UNARCHIVED = 'CAMPAIGNS.CAMPAIGN_UNARCHIVED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=203521949
     */
    CONFIRM_ARCHIVE_DESCRIPTION = 'CAMPAIGNS.CONFIRM_ARCHIVE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=457551143
     */
    CAMPAIGN_UNAVAILABLE = 'CAMPAIGNS.CAMPAIGN_UNAVAILABLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=209256085
     */
    DESCRIBE_YOUR_CAMPAIGN = 'CAMPAIGNS.DESCRIBE_YOUR_CAMPAIGN',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=209256074
     */
    DESCRIPTION = 'CAMPAIGNS.DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=193643534
     */
    EMPTY_STATE_TITLE = 'CAMPAIGNS.EMPTY_STATE_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=190939488
     */
    NAME_YOUR_CAMPAIGN = 'CAMPAIGNS.NAME_YOUR_CAMPAIGN',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=194265492
     */
    NAME_YOUR_CAMPAIGN_BASELINE_DATE = 'CAMPAIGNS.NAME_YOUR_CAMPAIGN_BASELINE_DATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=189802305
     */
    NEW_CAMPAIGN = 'CAMPAIGNS.NEW_CAMPAIGN',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=194265506
     */
    NO_BASELINE_SET = 'CAMPAIGNS.NO_BASELINE_SET',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=193191326
     */
    NUMBER_BROADCAST = 'CAMPAIGNS.NUMBER_BROADCAST',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=210392222
     */
    PROGRESSION = 'CAMPAIGNS.PROGRESSION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=202584514
     */
    RESCHEDULE_BROADCASTS_WARNING = 'CAMPAIGNS.RESCHEDULE_BROADCASTS_WARNING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=367110822
     */
    SETTINGS = 'CAMPAIGNS.SETTINGS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=599825141
     */
    SIDE_NAV_TITLE = 'CAMPAIGNS.SIDE_NAV_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=208726290
     */
    SHOW_ARCHIVED_CAMPAIGNS = 'CAMPAIGNS.SHOW_ARCHIVED_CAMPAIGNS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=188190765
     */
    TITLE = 'CAMPAIGNS.TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=202554619
     */
    UPDATE_RELATED = 'CAMPAIGNS.UPDATE_RELATED',
}
