import difference from 'lodash/difference';

import { HTML_ALLOWED_ATTRIBUTES, HTML_ALLOWED_TAGS } from '@lumapps/widget-html/constants';

/* istanbul ignore file */
export const WIDGET_CONTENT_LIST_TYPE = 'content-list';

export const CONTENT_LIST_HTML_ALLOWED_TAGS = difference(
    HTML_ALLOWED_TAGS,
    // Exclude tags that are not allowed in content list
    ['script', 'style', 'embed'],
);

export const CONTENT_LIST_HTML_ALLOWED_ATTRIBUTES = [...HTML_ALLOWED_ATTRIBUTES];
