export enum CONTENTS {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=68593154
     */
    CONTEXTUAL_ACTIONS_TRANSLATION_ON_ACTION = 'FRONT.CONTEXTUAL_ACTIONS.TRANSLATION_ON_ACTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=68593157
     */
    CONTEXTUAL_ACTIONS_TRANSLATION_OFF_ACTION = 'FRONT.CONTEXTUAL_ACTIONS.TRANSLATION_OFF_ACTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=165415697
     */
    CONTENT_UNPUBLISHED = 'REPORTS.ACCEPT_REPORT_CONTENT_SUCCESS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=92200905
     */
    CONTENT_SETTINGS_NOTIFICATION_CONFIRM_DIALOG_PUBLISH = 'FRONT.CONTENT_SETTINGS.NOTIFICATION.CONFIRM_DIALOG.PUBLISH',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=92200991
     */
    CONTENT_SETTINGS_NOTIFICATION_CONFIRM_DIALOG_CHANGE_SETTINGS = 'FRONT.CONTENT_SETTINGS.NOTIFICATION.CONFIRM_DIALOG.CHANGE_SETTINGS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=92201143
     */
    CONTENT_SETTINGS_NOTIFICATION_CONFIRM_DIALOG_SINGLE_FEED = 'FRONT.CONTENT_SETTINGS.NOTIFICATION.CONFIRM_DIALOG.SINGLE_FEED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=92201150
     */
    CONTENT_SETTINGS_NOTIFICATION_CONFIRM_DIALOG_FEED_LIST_MESSAGE = 'FRONT.CONTENT_SETTINGS.NOTIFICATION.CONFIRM_DIALOG.FEED_LIST_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=92201156
     */
    CONTENT_SETTINGS_NOTIFICATION_CONFIRM_DIALOG_TITLE = 'FRONT.CONTENT_SETTINGS.NOTIFICATION.CONFIRM_DIALOG.TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=93565729
     */
    CONTENT_SETTINGS_NOTIFICATION_CONFIRM_DIALOG_ALL_FEED = 'FRONT.CONTENT_SETTINGS.NOTIFICATION.CONFIRM_DIALOG.ALL_FEED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=493733631
     */
    CONTENT_PUBLISHED = 'CONTENTS.CONTENT_PUBLISHED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=493733904
     */
    CONTENT_PUBLISHED_NOTIFICATION_DESCRIPTION = 'CONTENTS.CONTENT_PUBLISHED_NOTIFICATION_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=8453628
     */
    EDIT_CONTENT = 'FRONT.FROALA.EDITCONTENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=158106895
     */
    NO_CONTENT = 'CONTENTS.NO_CONTENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=340580608
     */
    DISPLAY_PUBLICATION = 'CONTENTS.DISPLAY_PUBLICATION',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452766
     */
    SETTINGS_VISIBLE_BY = 'CONTENT_SETTINGS_VISIBLE_BY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=27106794
     */
    SETTINGS_VISIBLE_BY_HELP = 'ADMIN.TEMPLATE.CONTENT_SETTINGS_VISIBLE_BY_HELPER',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452723
     */
    SETTINGS_HIGHLIGHTED_FEEDS = 'CONTENT_SETTINGS_HIGHLIGHTED_FEEDS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=27106798
     */
    SETTINGS_HIGHLIGHTED_FEEDS_HELP = 'ADMIN.TEMPLATE.CONTENT_SETTINGS_HIGHLIGHTED_FOR_HELPER',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452794
     */
    TYPES = 'CONTENT_TYPES',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452947
     */
    FEED_REQUIRED = 'FEED_REQUIRED',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452767
     */
    WRITE_ON_BEHALF_OF = 'CONTENT_SETTINGS_WRITE_INSTEAD_OF',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452719
     */
    EDITORS = 'CONTENT_SETTINGS_EDITORS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=28364221
     */
    DRAFT_IN_PROGRESS = 'CONTENT.LAST_REVISION.DRAFT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=28364227
     */
    VERSION_REFUSED = 'CONTENT.LAST_REVISION.REFUSED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=28366240
     */
    VERSION_TO_VALIDATE = 'CONTENT.LAST_REVISION.TO_VALIDATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=28364177
     * */
    WORKFLOW_TO_VALIDATE = 'CONTENT.STATUS_TO_VALIDATE',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452780
     * */
    WORKFLOW_REFUSED = 'CONTENT_STATUS_REFUSED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=554924250
     */
    WORKFLOW_VALIDATION = 'CONTENT.WORKFLOW_VALIDATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=580104813
     */
    SELECT_CONTENT_TYPE = 'CONTENT.SELECT_CONTENT_TYPE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=593447266
     */
    EXPIRED_STATUS_NOTIFICATION = 'CONTENTS.EXPIRED_STATUS_NOTIFICATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=593458174
     */
    SCHEDULED_STATUS_NOTIFICATION = 'CONTENTS.SCHEDULED_STATUS_NOTIFICATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=593463466
     */
    DRAFT_STATUS_NOTIFICATION = 'CONTENTS.DRAFT_STATUS_NOTIFICATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=593463514
     */
    TO_VALIDATE_STATUS_NOTIFICATION = 'CONTENTS.TO_VALIDATE_STATUS_NOTIFICATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=593463557
     */
    EXPIRES_SOON_STATUS_NOTIFICATION = 'CONTENTS.EXPIRES_SOON_STATUS_NOTIFICATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=593463624
     */
    CONTENT_NOT_PUBLISHED_YET = 'CONTENTS.CONTENT_NOT_PUBLISHED_YET',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=593463656
     */
    CONTENT_EXPIRING_SOON = 'CONTENTS.CONTENT_EXPIRING_SOON',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=593477114
     */
    STATUS_NOTIFICATION_DESCRIPTION = 'CONTENTS.STATUS_NOTIFICATION_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=593990729
     */
    REFUSED_STATUS_NOTIFICATION = 'CONTENTS.REFUSED_STATUS_NOTIFICATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=594692466
     */
    EDIT_THIS_CONTENT = 'CONTENTS.EDIT_THIS_CONTENT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=603869131
     */
    ARCHIVED_STATUS_NOTIFICATION = 'CONTENTS.ARCHIVED_STATUS_NOTIFICATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=617491450
     */
    WRITING_ON_BEHALF_OF = 'CONTENTS.WRITING_ON_BEHALF_OF',
}
