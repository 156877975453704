import { getMetadataByContentId } from '@lumapps/metadata/api';

type ContentMetadataLayerAttributes = {
    lumapps_content_metadata?: string[];
    lumapps_content_metadata_ids?: string[];
};
export const DEFAULT_ATTRIBUTES: ContentMetadataLayerAttributes = {
    lumapps_content_metadata: [],
    lumapps_content_metadata_ids: [],
};

export const getMetadataAttributes = async (
    shouldFetch: boolean,
    contentId: string,
): Promise<ContentMetadataLayerAttributes> => {
    if (!shouldFetch) {
        return {};
    }

    try {
        const metadata = await getMetadataByContentId(contentId);
        const attributes: ContentMetadataLayerAttributes = {
            lumapps_content_metadata: metadata.map((item) => item.name.value || ''),
            lumapps_content_metadata_ids: metadata.map((item) => item.id),
        };

        return attributes;
    } catch (ex) {
        return DEFAULT_ATTRIBUTES;
    }
};
