import React, { ReactNode } from 'react';

import type { GenericProps, Theme } from '@lumapps/lumx/react';

import type { CalendarEventUIProps } from './components/Event/Event';

/**
 * All the different views in which the events can be displayed inside the calendar.
 * */
export enum CalendarViews {
    month = 'month',
    week = 'week',
    schedule = 'schedule',
}

/**
 * List of all week days and their associated numeric value.
 * Based on the `getDay()` method from the javascript Date object.
 * */
export enum WeekDay {
    sunday = 0,
    monday = 1,
    tuesday = 2,
    wednesday = 3,
    thursday = 4,
    friday = 5,
    saturday = 6,
}

/**
 * List of all options for verticalFormat prop
 */
export enum VerticalFormat {
    default = 'default',
    adaptative = 'adaptative',
}

/**
 * List of hours to be displayed in the timeline
 * Formatted based on the current local
 * */
export interface DayHours {
    hour: string;
}

/**
 * Default Day object with formatted values
 * */
export interface Day {
    /**
     * Day Date object
     * */
    date: Date;
    /**
     * Numeric value for the Date day
     * */
    numeric: number;
    /**
     * Formatted and translated name of the Date using the Locale provided in the configuration
     * */
    name: {
        long: string;
        short: string;
    };
}

/**
 * List of all available status for the event.
 * 'accepted': user as accepted to participate in the event
 * 'declined': user as declined to participate in the event
 * 'needsActions': user as not yet answered
 * 'tentative': user might participate in the event
 * */
export const EVENT_STATUS = ['accepted', 'declined', 'needsAction', 'tentative'] as const;
export type EventStatus = (typeof EVENT_STATUS)[number];

/**
 * Event object that can be displayed in the calendar
 * */
export interface CalendarEvent {
    /**
     * Starting date of the event
     * */
    start: Date;
    /**
     * Ending date of the event
     * */
    end?: Date;
    /**
     * Event uniq ID
     * */
    id: string;
    /**
     * Event summary
     * */
    summary: string;
    /**
     * External link to the event in the provider
     * */
    link?: string;
    /**
     * Uniq ID of the event calendar (parent)
     * Events can come from multiple Provider and or calendars inside the same providers. The calendar ID is used to group event.
     * */
    calendarId?: string;
    /**
     * User response status for the event.
     * */
    status?: EventStatus;
    /**
     * External link to a meeting room in the provider (if selected)
     * */
    meetingLink?: string;
    /**
     * Event description
     * */
    description?: string;
    /**
     * Color to be used when displaying the event in the calendar grid.
     * Usually, colors are defined by group (all events with the same calendarId should have the same color)
     * */
    color?: string;
    /**
     * When event is custom, it can have any other prop
     */
    [key: string]: any;
}

/**
 * Configuration for the hours display in the week view
 * */
export interface CalendarHoursConfig {
    /**
     * Starting hour of the day (should be somewhere between 0 and 24)
     * */
    start: number;
    /**
     * Ending hour of the day (should be somewhere between 0 and 24, and bigger than the starting hour to avoid incoherent display)
     * */
    end: number;
    /**
     * Granularity used in the hours timeline
     * - 'full': show only hours
     * - 'half': show half hours
     * - 'quarter': show quarter of hours
     *
     * Events are rounded and shown to the closest line of the timeline, based on the display mode
     * */
    display: 'full' | 'half' | 'quarter';
}

/**
 * Configuration for the week display both in week and month view
 * */
export interface CalendarWeekConfig {
    /**
     * Display or hide the weekends (Saturdays and sundays)
     * */
    showWeekend: boolean;
    /**
     * Define the starting day of the week (usually Sunday or monday, but not restricted)
     * */
    firstDay: WeekDay;
    /**
     * The list of days define has being part of the weekend
     * */
    weekend: Set<WeekDay>;
}
/**
 * Calendar configuration object
 * Should contain all the static configuration, to format and display the events, days, weeks, ...
 * */
export interface CalendarConfig {
    /**
     * Configuration to display hours
     * */
    hours: CalendarHoursConfig;
    /**
     * Configuration to display weeks
     * */
    week: CalendarWeekConfig;
    /**
     * Whether we want to show the toolbar component
     * Allow for calendar updates on view and displayed days.
     * */
    hasToolbar: boolean;
    /**
     * All available views for the calendar, can be used to disabled certain views.
     * */
    availableViews: CalendarViews[];
    /**
     * Whether we want to show the toolbar views selector buttons
     * */
    displayViewSelector?: boolean;
    /**
     * Locale to be used in the Calendar
     * */
    locale: string;
}

export interface CustomCalendarEventProps extends CalendarEventUIProps {
    [key: string]: any;
}
export interface CalendarProps extends GenericProps {
    /**
     * List of events to display in the calendar views
     * */
    events: Record<string, CalendarEvent>;
    /**
     * Default config object to configure calendar rules of display
     * */
    config: CalendarConfig;
    /**
     * Weather or not the calendar is currently loading data
     * */
    isLoading: boolean;
    /**
     * Global Theme for the component
     * @default 'light'
     * */
    theme?: Theme;
    /**
     * The view in which the calendar should be displayed
     * */
    view: CalendarViews;
    /**
     * Callback triggered on all changes in the view, both display format and date
     * */
    handleViewChange(view: CalendarViews): void;
    /**
     * The Date used to generate the calendar view
     * */
    date: Date;
    /**
     * Callback triggered on all changes in the view, both display format and date
     * */
    handleDateChange(newDate: Date): void;
    /**
     * React or HTML element that can be appended in the before part of the toolbar for customisation
     * */
    toolbarBefore?: ReactNode;
    /**
     * React or HTML element that can be appended after the date in the toolbar for customisation
     * */
    toolbarAfterLabelButton?: ReactNode;
    /**
     * Override the default background color otherwise based on the current theme
     * */
    backgroundColor?: string;
    /**
     * Whether the calendar takes the place it needs or it's forced to adapt to its container
     */
    verticalFormat?: VerticalFormat;
    /**
     * Component to render events
     */
    eventComponent?: React.FC<CustomCalendarEventProps>;
    /**
     * True to avoid scroller in each cell in month view when there are too many event.
     * Instead, it will only display the first events and add a "show all" button on top of cell
     */
    withShowAll?: boolean;
    /**
     * Component to render events in "show all" dialog
     * If none is given, use eventComponent is provided or regular event if not
     */
    dialogEventRenderer?: (event: CalendarEvent & { [key: string]: any }) => React.ReactElement;
}

/**
 * Values stored in the Context provider
 * Should be accessible in qll Calendar child component
 * */
export interface CalendarContextValues extends CalendarProps {
    /**
     * List of all days available in the week in the correct order
     * generated based on the calendar config
     * */
    weekFormat: Day[];
    /**
     * List of days currently visible in the calendar
     * generated based on the view and date
     * */
    days: Day[];
    /**
     * List of all visible hours in the timeline
     * Generated from the calendar configuration object
     * */
    timeline: DayHours[];
}
