import debounce from 'lodash/debounce';
import pick from 'lodash/pick';

import { DEBOUNCE_DELAY } from '@lumapps/constants';
import { notifySubscribers } from '@lumapps/customizations/api';
import { Events } from '@lumapps/customizations/types';
import { instanceIdSelector } from '@lumapps/instance/ducks/selectors';
import { currentLanguageSelector } from '@lumapps/languages/ducks/selectors';
import { isSuperAdmin } from '@lumapps/permissions';
import { Dispatch } from '@lumapps/redux';
import { FrontOfficeStore } from '@lumapps/redux/types';

import { search, suggest } from '../api';
import { formatSorts, search as lumappsSearch } from '../api/lumapps';
import {
    SEARCH_FIRST_PAGE,
    SEARCH_FORMAT,
    ENGINES,
    MAX_SEARCH_RESULTS,
    CUTOMIZATION_EVENTS_SEARCH_CAUSE,
} from '../constants';
import {
    BackendSearchSort,
    BaseSearchResult,
    FacetFilter,
    FetchResultsParams,
    SEARCH_CAUSES,
    SearchFilter,
    SearchParams,
} from '../types';
import { getResultType } from '../utils/getResultType';
import { shouldUseGroupsRestrictions } from '../utils/shouldUseGroupsRestrictions';
import { getCurrentFeatures, getCursor, getFiltersMetadata, getSelectedFilterId } from './selectors';
import { actions } from './slice';

export const getSearchParams = (
    currentState: FrontOfficeStore,
    {
        query,
        filter,
        currentFacets = {},
        moreResults = false,
        sort,
        page = SEARCH_FIRST_PAGE,
        format = SEARCH_FORMAT.JSON,
        context,
    }: FetchResultsParams,
): SearchParams => {
    const instanceId = instanceIdSelector(currentState);
    const currentCursor = getCursor(currentState);
    const currentLanguage = currentLanguageSelector(currentState);
    const features = getCurrentFeatures(currentState);
    // for some filter, more configuration like sharepoint / drive
    // const filterConfig = getInstanceFilter(filter)(currentState);
    const filtersMeta = getFiltersMetadata(currentState);
    const filterId = filter || getSelectedFilterId(currentState);
    const isGlobalAdmin = isSuperAdmin(currentState);

    // Should we use groups restrictions
    const useGlobalAdminPower = isGlobalAdmin && !shouldUseGroupsRestrictions();

    return {
        query,
        instanceId,
        lang: currentLanguage,
        filter: filterId,
        facets: currentFacets,
        cursor: moreResults ? currentCursor ?? undefined : undefined,
        maxResults: MAX_SEARCH_RESULTS,
        features,
        sort,
        config: filtersMeta && filterId ? filtersMeta[filterId] : undefined,
        useGlobalAdminPower,
        page,
        format,
        context: features.isCoveoEnabled ? context : undefined,
    };
};

export const runSearch = ({
    currentState,
    resultsParams,
    traceId,
    engine,
}: {
    currentState: FrontOfficeStore;
    resultsParams: FetchResultsParams;
    traceId: string;
    engine?: ENGINES;
}) => {
    const searchParams = getSearchParams(currentState, resultsParams);

    return search(searchParams, traceId, engine);
};

export const runDownloadPage = (currentState: FrontOfficeStore, resultsParams: FetchResultsParams) => {
    const searchParams = getSearchParams(currentState, resultsParams);

    return lumappsSearch(searchParams, true, true, false);
};

/** Retrieves the suggestions for the specific query */
export const fetchSuggestions = async (
    params: { query: string; siteId?: string; acceptLanguage?: string },
    engine: ENGINES,
    dispatch: Dispatch,
) => {
    const { query, siteId, acceptLanguage } = params;
    try {
        dispatch(actions.transitionSuggestionsToLoadingState());
        const response = await suggest({ query, siteId, acceptLanguage }, engine);
        dispatch(actions.setSuggestionResults(response));
    } catch (error) {
        dispatch(actions.resetSuggestions());
    }
};

export const debouncedFetchSuggestions = debounce(fetchSuggestions, DEBOUNCE_DELAY);

/**
 * used to emit search event for customization API
 * @param props.cause search cause that trigger the event
 * @param props.facets facet currently applied on search results
 * @param props.items search results
 * @param props.query query used for the current search results
 * @param props.resultCountExact number of results currently availble basd on the applied filters
 * @param props.sort current sort applied on search results
 * @param props.sortOrders all available sort orders
 * @param props.currentFilter filters currently available
 */
export const emitEventsForCustoApi = (props: {
    cause: SEARCH_CAUSES;
    facets: FacetFilter[];
    items: BaseSearchResult[];
    query: string;
    resultCountExact: number;
    sort?: string;
    sortOrders: BackendSearchSort[];
    currentFilter?: SearchFilter;
}) => {
    const { cause, facets, items, query, resultCountExact, sort, sortOrders, currentFilter } = props;

    const filteredResultCount =
        (items.length === 0 && resultCountExact !== 0) || (items.length !== 0 && resultCountExact === 0)
            ? -1
            : resultCountExact;
    // event for filters
    if (
        [SEARCH_CAUSES.facetClearAll, SEARCH_CAUSES.facetInteracted, SEARCH_CAUSES.breadcrumbResetAll].includes(cause)
    ) {
        notifySubscribers(
            {
                filters: facets.map((f) => pick(f, ['id', 'label', 'value', 'choices'])),
                query,
                filteredResultCount,
                cause: CUTOMIZATION_EVENTS_SEARCH_CAUSE.FILTER_INTERACTION,
            },
            Events.SEARCH,
        );
    }

    // events for results
    if ([SEARCH_CAUSES.queryChange, SEARCH_CAUSES.tabChange, SEARCH_CAUSES.init].includes(cause)) {
        // format results for customization API
        const filters = facets
            ?.filter((f) => f.value !== undefined)
            .map((f) => ({ id: f.id, choices: f.choices, label: f.label, value: f.value }));
        // send results event for customization API
        const cleanedResults = items.map((i) => {
            return {
                resultType: getResultType(i),
                title: i.title,
                isPromoted: i.isPromoted,
                url: i.url,
                id: i.id,
                snippet: i.snippet,
            };
        });

        const selectedTabInfo = {
            // as backend does not send result count for Drive tab for instance, we display -1 instead of 0 if results are displayed
            totalResultCount:
                currentFilter?.count === undefined && cleanedResults.length !== 0 ? -1 : currentFilter?.count,
            label: currentFilter?.label,
        };
        notifySubscribers(
            {
                results: cleanedResults,
                query,
                filteredResultCount,
                selectedTabInfo,
                filters,
                cause: CUTOMIZATION_EVENTS_SEARCH_CAUSE.FETCH_RESULTS,
            },
            Events.SEARCH,
        );
    }

    // sort order
    if (cause === SEARCH_CAUSES.sortChange) {
        notifySubscribers(
            {
                selectedSort: sort,
                query,
                filteredResultCount,
                sortOrders: formatSorts(sortOrders),
                cause: CUTOMIZATION_EVENTS_SEARCH_CAUSE.SORT_INTERACTION,
            },
            Events.SEARCH,
        );
    }
};
