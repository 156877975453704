import { createPageRoute, Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

export enum SUB_PAGES_VIEWS {
    ACTIVITIES = 'activities',
    TOOLBOX = 'toolbox',
    RANKING = 'ranking',
    ACHIEVEMENTS = 'achievements',
}

export const trainingCoursePageRoute: Route = createPageRoute({
    slug: 'training-courses/:id/:view',
    appId: AppId.frontOffice,
});

export const webviewTrainingPageRoute: Route = createPageRoute({
    slug: 'training-courses/:id/:view',
    appId: AppId.webview,
});

/** Get the training course route. */
export const getTrainingCourseRoute = (id: string, view?: SUB_PAGES_VIEWS): Route => {
    return {
        ...trainingCoursePageRoute,
        params: {
            id,
            view,
        },
        mobilePageDescriptor: {
            type: 'training-course',
            id,
        },
    };
};

/**
 * Parameters for redirecting to training course page
 */
export interface TrainingCourseRouteParams {
    /**
     * The view to redirect to.
     */
    view?: SUB_PAGES_VIEWS;
}
