import BaseApi, { PRIORITY } from '@lumapps/base-api';
import { ServerListRequest, ServerListResponse } from '@lumapps/base-api/types';
import { CACHE_TYPE } from '@lumapps/cache';
import { OneOrMany } from '@lumapps/utils/types/OneOrMany';

import { Content } from '../types';

// Base content view fields
const CONTENT_GET_DEFAULT_FIELDS =
    'id,slug,type,link,instance,title,isHomepage,canEdit,properties,hasCommentWidget,externalKey,startDate,endDate,lastRevision';
// Fields required by the content analytics link the the contextual actions:
const CONTENT_ANALYTICS_FIELDS = 'customContentType,status,editors,authorId';
// User fields required on the author and writer.
const USER_FIELDS = 'id,firstName,lastName,email,apiProfile';
// Fields used in the metadata widget:
const WIDGET_META_FIELDS = `comments,liked,likes,type,writerDetails(${USER_FIELDS}),authorDetails(${USER_FIELDS}),publicationDate,customContentTypeTags,customContentTypeDetails(tags(id,name),name),canonicalUrl`;
// Content header fields
const CONTENT_HEADER_FIELDS = 'headerDetails';
// Fields used in view only
const CONTENT_VIEW_FIELDS = 'template(isV2Compatible)';
// Fields used in preview only
const CONTENT_PREVIEW_FIELDS = 'template(isV2Compatible,components),thumbnail,mediaThumbnail';

export const contentApi = new BaseApi({ path: 'content' });

/** Get content params. */
export interface GetContentAPIParams {
    /** Content identifier. */
    uid?: string;
    /** Content slug. */
    slug?: string;
    /** Instance identifier. */
    instance?: string;
    /** Fields. */
    fields?: string;
    /** The preview token used to bypass EDIT access authorization */
    previewToken?: string;
    /** Action for which to get the content (defaults to PAGE_READ) */
    action?: 'PAGE_EDIT';
    /** Abort signal */
    signal?: AbortSignal;
}

/** Get content. */
export const get = ({ signal, ...params }: GetContentAPIParams, useCache = true, headers = {}) => {
    return useCache
        ? contentApi.getCacheFirst('get', CACHE_TYPE.MEMORY, PRIORITY.HIGH, { params, headers, signal })
        : contentApi.get('get', { params, headers, signal });
};

export interface GetContentForViewOptions {
    /** Skip fetch of the content header */
    skipHeader?: boolean;
    /** Fetch for preview (slight change to the fetched fields and params) */
    forPreview?: boolean;
    /** The preview token used to bypass EDIT access authorization */
    token?: string;
    /** Abort signal */
    signal?: AbortSignal;
}

/** Get content for content view (with defaults fields). */
export const getContentForView = async (
    contentId: string,
    { signal, forPreview, token, skipHeader }: GetContentForViewOptions = {},
): Promise<Content> => {
    // Core content fields:
    let fields = `${CONTENT_GET_DEFAULT_FIELDS},${WIDGET_META_FIELDS},${CONTENT_ANALYTICS_FIELDS}`;

    let previewToken: GetContentAPIParams['previewToken'];

    let action: GetContentAPIParams['action'];
    if (!forPreview) {
        // View use case
        fields += `,${CONTENT_VIEW_FIELDS}`;
    } else {
        // Preview use case
        fields += `,${CONTENT_PREVIEW_FIELDS}`;
        action = 'PAGE_EDIT';
        previewToken = token;
    }

    if (!skipHeader) {
        // Content header
        fields += `,${CONTENT_HEADER_FIELDS}`;
    }

    const { data } = await get({ uid: contentId, previewToken, fields, action, signal });
    return data;
};

/** Delete content params. */
export interface DeleteContentAPIParams {
    uid: string[];
}

/** Delete content. */
export const remove = (params: DeleteContentAPIParams = { uid: [] }) => {
    return contentApi.delete('deleteMulti', { params });
};

/** Follow content. */
export const follow = (contentId: string, params: { notify?: boolean } = {}) => {
    const defaultParams = {
        notify: false,
    };

    return contentApi.post(`/${contentId}/follow`, { ...defaultParams, ...params });
};

/** Unfollow content. */
export const unfollow = (contentId: string) => {
    return contentApi.delete(`/${contentId}/unfollow`);
};

/** Like content. */
export const like = (contentId: string) => {
    return contentApi.post('/like', { uid: contentId });
};

/** Unlike content. */
export const unlike = (contentId: string) => {
    return contentApi.post('/unlike', { uid: contentId });
};

/**
 * List content params.
 * {@see https://api.lumapps.com/docs/output/_schemas/servercontentcontentmessagescontentlistrequest}
 */
export interface ListContentAPIParams extends ServerListRequest {
    action?: string;
    /** The lang on which the query should apply. */
    lang: OneOrMany<string>;
    /** The content types. */
    type?: OneOrMany<Content['type']>;
    /** types to exclude from search */
    excludeType?: OneOrMany<Content['type']>;
    /** The list of content IDs. */
    ids?: OneOrMany<string>;
    /** list content cursor */
    cursor?: string;
    /** list instance id */
    instanceId?: string | string[];
    /** list of statuses to filter by */
    status?: string[];
    /** list of custom content type ids to filter by */
    customContentType?: string[];
    /** search query */
    query?: string;
    /** customer id */
    customerId?: string;
}

/** List content. */
export const list = <T extends Content = Content>(params: ListContentAPIParams) => {
    // Monolite/monolithe compatibility (weird stuff with the legacy api)
    // As monolite is stricter we make sure we send correctly serialized data to it
    // to avoid a fallback on monolith.
    // Note: Monolith knows how to handle both formats.
    if (params?.sortOrder && typeof params.sortOrder === 'string') {
        // eslint-disable-next-line no-param-reassign
        params.sortOrder = [params.sortOrder];
    }
    if (params?.instanceId && typeof params.instanceId === 'string') {
        // eslint-disable-next-line no-param-reassign
        params.instanceId = [params.instanceId];
    }
    return contentApi.post<ServerListResponse<T>>('list', params);
};

export const contentQueryKeys = {
    all: () => ['content'] as const,
    getForPreview: (contentId: string, token?: string) =>
        [...contentQueryKeys.all(), 'preview', contentId, { token }] as const,
    listContents: (params?: ListContentAPIParams) => [...contentQueryKeys.all(), 'list', params] as const,
};
