import React from 'react';

import { useContentLink } from '@lumapps/contents/hooks/useContentLink';
import { instanceIdSelector } from '@lumapps/instance/ducks/selectors';
import { useSelector } from '@lumapps/redux/react';
import { addBaseSlugToUrl, isExternalUrl } from '@lumapps/router/utils';
import { SEARCH_RESULT_TYPES } from '@lumapps/search/constants';

import { SearchBoxOptionWithItemProps } from '../../types';
import { BaseSearchBoxInteractionOption } from './BaseSearchBoxInteraction';

/**
 * Default component for all "interaction" type options.
 * This generates the link based on the suggestion data.
 */
export const SearchBoxInteractionDefault = ({ suggestion, ...forwardedProps }: SearchBoxOptionWithItemProps) => {
    const instanceId = useSelector(instanceIdSelector);
    const { entityId, url, entityType, originSiteId } = suggestion.item;

    const formattedUrl = [SEARCH_RESULT_TYPES.MEDIA, SEARCH_RESULT_TYPES.DIRECTORY_ENTRY].includes(entityType)
        ? url
        : addBaseSlugToUrl(url);

    const contentLink = useContentLink({
        id: entityId,
        slug: url,
    });

    /**
     * Origin site id may not be set in older version of quick-search
     * Site id is an int and the instance id too. Only the originSiteId is a string.
     * TODO: remove this when the backend is sending string for siteId
     */
    const isFromCurrentInstance = `${originSiteId || suggestion.siteId}` === `${instanceId}`;
    const shouldOpenInNewTab =
        isExternalUrl(url) || !isFromCurrentInstance || Boolean(contentLink.contentLinkRef.instance);

    if (!suggestion.item) {
        return null;
    }

    let linkProps: { rel?: string; target: React.ComponentProps<'a'>['target'] } = {
        rel: undefined,
        target: '_self',
    };
    if (shouldOpenInNewTab) {
        linkProps = {
            rel: 'noopener noreferrer',
            target: '_blank',
        };
    }

    return (
        <BaseSearchBoxInteractionOption
            suggestion={suggestion}
            as="a"
            href={formattedUrl}
            {...forwardedProps}
            {...linkProps}
        />
    );
};
