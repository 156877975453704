import set from 'lodash/set';

import createSlice, { PayloadAction } from '@lumapps/redux/createSlice';

import {
    DEFAULT_FILTER,
    STATUS,
    ENGINES,
    RESET_FILTER,
    SEARCH_FIRST_PAGE,
    FILTER_TYPES,
    isExternalEngine,
} from '../constants';
import {
    SearchFilter,
    FacetFilter,
    SearchAPIResponse,
    SearchExternalAPIResponse,
    SearchSuggestion,
    SearchSuggestionResponse,
    SEARCH_CAUSES,
    SearchSort,
    BackendSearchSort,
} from '../types';
import { convertTabToSearchFilter, createSearchEngineFilter } from '../utils';
import { resetLumappsTabsCount } from '../utils/resetLumappsTabsCount';
import { initialState, LastInteractedFacet, SearchState } from './state';

const slice = createSlice({
    domain: 'search',
    initialState,
    reducers: {
        transitionToLoadingState: (state: SearchState): void => {
            set(state, 'status', STATUS.LOADING);
        },
        transitionToBrowsingState: (state: SearchState): void => {
            set(state, 'status', STATUS.BROWSING);
        },
        transitionToFilteringState: (state: SearchState): void => {
            set(state, 'status', STATUS.FILTERING);
        },
        transitionToErrorState: (
            state: SearchState,
            action: PayloadAction<{ filter?: string; error?: unknown }>,
        ): void => {
            const { filter } = action?.payload || {};
            set(state, 'status', STATUS.ERROR);
            set(state, 'facets', initialState.facets);

            /**
             * If the current filter is not the default one, and there are no filters to use, if the user
             * arrives to a page that has an error, they won't be able to remove the current filter, since
             * no chips will be displayed. In that case, we add a wildcard filter that allows simply to reset
             * the current filter, so that the user can start again their search.
             */
            if (filter !== DEFAULT_FILTER && state.filters && state.filters.length === 0) {
                const resetFilter = createSearchEngineFilter(RESET_FILTER as ENGINES, 'FILTER_RESET');
                set(state, 'filters', [resetFilter]);
                set(state, 'selectedFilter', resetFilter);
                set(state, 'filter', RESET_FILTER);
                set(state, 'sorts', initialState.sorts);
            }
        },
        setResults: (state: SearchState, action: PayloadAction<SearchAPIResponse>): void => {
            const {
                items,
                filters,
                metadata,
                query,
                cursor,
                filter,
                facets,
                sort,
                more,
                sorts,
                promoted,
                appendResults = false,
                spellResults,
                resultCountExact,
                searchQueryUid,
                shouldUseFacetChoicesHistory,
                page = SEARCH_FIRST_PAGE,
                splitTestRunName,
                splitTestRunVersion,
                templates,
            } = action.payload;

            const hasQueryChanged = state.cause === SEARCH_CAUSES.queryChange || state.query !== query;
            const hasResults = items.length > 0;

            /**
             * There are 2 specific scenarios for this function:
             * 1. When we click on a filter, facet or reset the query parameter, this means that we will
             *  reload all the results on the page, so we do now what to append them, rather reset them
             * 2. When we are looking for more results, we need to append the results that we got and keep the
             * same filters that we had, since the backend does not send them.
             */
            set(state, 'results', appendResults ? [...state.results, ...items] : items);
            set(state, 'totalResultsCount', resultCountExact);
            set(state, 'templates', templates);
            /**
             * Should set filters if
             * 1. No filter is selected or the default one. We know we will have right info in payload
             * 1b. If we where in an error state with the a Reset Filter
             * 1c. The query has changed
             * 1d. I have no filter on my current state
             * 2. If we change the query. We want to only display filters that have results
             */
            if (
                (filter === DEFAULT_FILTER ||
                    filter === undefined ||
                    state.filter === RESET_FILTER ||
                    hasQueryChanged ||
                    state.filters.length === 0) &&
                !appendResults
            ) {
                set(state, 'filters', filters);
            }

            set(state, 'filter', filter);
            set(
                state,
                'metadata',
                appendResults && state.metadata ? state.metadata : metadata || initialState.metadata,
            );

            if (!appendResults) {
                set(state, 'sorts', sorts || initialState.sorts);
            }
            set(state, 'query', query);
            set(state, 'cursor', cursor);
            set(state, 'hasMoreResults', more);
            set(state, 'status', STATUS.BROWSING);
            set(state, 'currentPage', page);
            set(state, 'queryUid', searchQueryUid);
            set(state, 'splitTestRunName', splitTestRunName);
            set(state, 'splitTestRunVersion', splitTestRunVersion);

            if (shouldUseFacetChoicesHistory) {
                const newFacets = appendResults && state.facets ? state.facets : facets || initialState.facets;
                /**
                 * Keep previous facet choices
                 * Set prev facet in order to avoid to the user to clear the facet and then filter
                 */
                if (state.prevFacet) {
                    const index = newFacets.findIndex((facet) => facet.id === state.prevFacet?.id);
                    if (index >= 0 && newFacets[index] && newFacets[index].value) {
                        newFacets[index].choices = state.prevFacet.choices;
                    }
                }
                set(state, 'facets', newFacets);
            } else if (hasResults || hasQueryChanged) {
                set(state, 'facets', facets);
            }

            if (!appendResults) {
                set(state, 'promotedResults', promoted ? promoted.items : []);
                set(state, 'selectedSort', initialState.selectedSort);
            }

            set(state, 'sort', sort || initialState.sort);

            // Is there any query suggestion?
            const suggestedQuery = spellResults && spellResults[0] ? spellResults[0].suggestedQuery : null;
            set(state, 'suggestedQuery', suggestedQuery);
        },
        setExternalResults: (state: SearchState, action: PayloadAction<SearchExternalAPIResponse>) => {
            const { items, more, cursor, facets, appendResults, filters } = action.payload;
            set(state, 'results', appendResults ? [...state.results, ...items] : items);
            set(state, 'cursor', cursor);
            set(state, 'hasMoreResults', more);
            set(state, 'status', STATUS.BROWSING);
            set(state, 'facets', facets);
            set(state, 'promotedResults', initialState.promotedResults);
            set(state, 'selectedSort', initialState.selectedSort);
            set(state, 'sort', initialState.sort);
            set(state, 'totalResultsCount', initialState.totalResultsCount);

            // Update tab count if possible
            if (filters) {
                const tabIndex = state.filters.findIndex((filter) => filter.value === state.filter);
                const tab = filters.find((filter) => filter.value === state.filter);
                if (tabIndex >= 0 && tab) {
                    const newFilters = [...state.filters];
                    newFilters[tabIndex].totalCount = tab.totalCount;
                    set(state, 'filters', newFilters);
                }
            }
        },
        setQuery: (state: SearchState, action: PayloadAction<string>): void => {
            set(state, 'query', action.payload);
        },
        setTraceId: (state: SearchState, action: PayloadAction<string>): void => {
            set(state, 'traceId', action.payload);
        },
        setCursor: (state: SearchState, action: PayloadAction<string>): void => {
            set(state, 'cursor', action.payload);
        },
        resetQuery: (state: SearchState): void => {
            set(state, 'query', initialState.query);
        },
        resetPage: (state: SearchState): void => {
            set(state, 'query', initialState.query);
            set(state, 'filters', initialState.filters);
            set(state, 'sort', initialState.sort);
            set(state, 'results', initialState.results);
            set(state, 'filter', initialState.filter);
            set(state, 'selectedFilter', initialState.selectedFilter);
            set(state, 'selectedFacet', initialState.selectedFacet);
            set(state, 'selectedSort', initialState.selectedSort);
        },
        setSelectedFilter: (state: SearchState, action: PayloadAction<SearchFilter>): void => {
            set(state, 'selectedFilter', action.payload);
            set(state, 'filter', action.payload ? action.payload.value : initialState.filter);
            set(state, 'results', initialState.results);
            set(state, 'selectedSort', initialState.selectedSort);
            set(state, 'selectedFacet', initialState.selectedFacet);
            set(state, 'sort', initialState.sort);
            set(state, 'cause', SEARCH_CAUSES.tabChange);

            set(state, 'facets', initialState.facets);
            set(state, 'sorts', initialState.sorts);
            set(state, 'totalResultsCount', '');

            if (!action.payload) {
                set(state, 'filters', initialState.filters);
            }
        },
        setSelectedSort: (state: SearchState, action: PayloadAction<{ sort?: SearchSort }>): void => {
            set(state, 'selectedSort', action.payload.sort);
            set(state, 'cause', SEARCH_CAUSES.sortChange);
        },
        setSelectedFilterId: (state: SearchState, action: PayloadAction<string>): void => {
            set(state, 'filter', action.payload);
        },
        setSelectedFacet: (
            state: SearchState,
            action: PayloadAction<{ facet: FacetFilter; value?: SearchFilter | SearchFilter[] }>,
        ): void => {
            const { value, facet } = action.payload;
            set(state, 'selectedFacet', value);
            set(state, 'prevFacet', value ? facet : null);
            set(state, 'cause', SEARCH_CAUSES.facetInteracted);

            /**
             * Here, we want to update the selected facet on the facets store so we can rapidly show the user that
             * something has changed and that we are performing a search. So, we go through the different facets that
             * we currently have and check the one that matches to the selected facet. if it matches, we update the value
             * with the one passed in as a parameter
             */
            set(
                state,
                'facets',
                state.facets.map((currentFacet: FacetFilter) => {
                    if (currentFacet.id === facet.id) {
                        return {
                            ...currentFacet,
                            value,
                        };
                    }

                    return currentFacet;
                }),
            );
        },
        resetResults: (
            state: SearchState,
            action?: PayloadAction<{ keepFilters: boolean; cause?: SEARCH_CAUSES }>,
        ): void => {
            const { keepFilters, cause = SEARCH_CAUSES.queryChange } = action?.payload || {};

            set(state, 'results', initialState.results);
            set(state, 'promotedResults', initialState.promotedResults);
            set(state, 'hasMoreResults', initialState.hasMoreResults);
            set(state, 'cursor', initialState.cursor);
            set(state, 'cause', cause);

            // Reset count since it is no longer true
            const meta = state.metadata[state.filter];
            const currentFilter = state.filter !== DEFAULT_FILTER && meta ? convertTabToSearchFilter(meta) : meta;

            if (cause === SEARCH_CAUSES.queryChange && isExternalEngine(currentFilter?.type as FILTER_TYPES)) {
                set(state, 'filters', resetLumappsTabsCount(state.filters));
            }

            if (!keepFilters) {
                set(state, 'filter', initialState.filter);
                set(state, 'facets', initialState.facets);
                set(state, 'filters', initialState.filters);
                set(state, 'sorts', initialState.sorts);
                set(state, 'selectedFacet', initialState.selectedFacet);
                set(state, 'selectedFilter', initialState.selectedFilter);
                set(state, 'selectedSort', initialState.selectedSort);
                set(state, 'sort', initialState.sort);
                set(state, 'cause', SEARCH_CAUSES.init);
            }
        },
        setSuggestionResults: (state: SearchState, action: PayloadAction<SearchSuggestionResponse>): void => {
            set(state, 'suggestions', action.payload.queries);
            set(state, 'resultSuggestions', action.payload.results);
            set(state, 'suggestionsStatus', STATUS.BROWSING);
        },
        transitionSuggestionsToLoadingState: (state: SearchState): void => {
            set(state, 'suggestions', initialState.suggestions);
            set(state, 'suggestionsStatus', STATUS.LOADING);
        },
        resetSuggestions: (state: SearchState): void => {
            set(state, 'suggestions', initialState.suggestions);
            set(state, 'resultSuggestions', initialState.resultSuggestions);
            set(state, 'suggestionsStatus', initialState.suggestionsStatus);
        },
        setHistorySuggestions: (state: SearchState, action: PayloadAction<SearchSuggestion[]>): void => {
            set(state, 'historySuggestions', action.payload);
        },
        setIsDownloading: (state: SearchState, action: PayloadAction<boolean>) => {
            set(state, 'status', action.payload ? STATUS.DOWNLOADING : STATUS.BROWSING);
        },
        setCause: (state: SearchState, action: PayloadAction<SEARCH_CAUSES>) => {
            set(state, 'cause', action.payload);
        },
        setLastInteractedFacet: (state: SearchState, action: PayloadAction<LastInteractedFacet>) => {
            const key: keyof typeof state = 'lastInteractedFacet';

            set(state, key, action.payload);
        },
        setSearchEngine: (state: SearchState, action: PayloadAction<ENGINES | string>) => {
            set(state, 'engine', action.payload);
        },
        setSelectedFilters: (state: SearchState, action: PayloadAction<Record<string, string>>) => {
            set(state, 'selectedFilters', action.payload);
        },
        setSortOrders: (state: SearchState, action: PayloadAction<BackendSearchSort[]>) => {
            set(state, 'sortOrders', action.payload);
        },
        setTotalCount: (state: SearchState, action: PayloadAction<number>) => {
            set(state, 'totalResultsCount', action.payload);
        },
        setExtensionID: (state: SearchState, action: PayloadAction<string>) => {
            set(state, 'extensionId', action.payload);
        },
    },
});

const { actions, reducer } = slice;

export { actions, reducer };
