import React from 'react';

import { SelectButton, SelectButtonProps } from '@lumapps/combobox/components/SelectButton';
import { useDataAttributes } from '@lumapps/data-attributes';
import { mdiSort } from '@lumapps/lumx/icons';
import { Button, IconButton, PopoverProps } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { PartialBy } from '@lumapps/utils/types/PartialBy';

import { SORT_FIELDS, SORTERS_SCOPE } from '../../../constants';
import { SortersType } from '../../../types';

type InheritedSelectButtonProps = PartialBy<SelectButtonProps<SortersType>, 'label'>;

export interface SortSelectButtonProps extends InheritedSelectButtonProps {
    variant?: 'default' | 'compact';
    /** whether the "Sorted by" prefix is displayed on the button or not */
    shouldDisplaySortedByLabel?: boolean;
    /** additional props for the popover */
    popoverProps?: Partial<PopoverProps> | undefined;
}

/**
 * Button selecting the sort field.
 */
export const SortSelectButton: React.FC<SortSelectButtonProps> = ({
    className,
    variant,
    buttonRef,
    label: propLabel,
    shouldDisplaySortedByLabel,
    options = SORT_FIELDS,
    value = options[0],
    ...forwardedProps
}) => {
    const { translateKey, translateAndReplace, translate } = useTranslate();
    const { get } = useDataAttributes(SORTERS_SCOPE);

    // Use given prop label
    let label = propLabel;
    // Else, if we have a value
    if (!label && value) {
        const valueLabel = translate(value?.label) as string;
        if (shouldDisplaySortedByLabel) {
            label = translateAndReplace(GLOBAL.SORTED_BY, { sort: valueLabel });
        } else {
            label = valueLabel;
        }
    }
    // Fallback on a default label
    if (!label) {
        label = translateKey(GLOBAL.SORTING) as string;
    }

    return (
        <SelectButton<SortersType>
            {...(variant === 'compact'
                ? { as: IconButton, icon: mdiSort, labelDisplayMode: 'show-tooltip' }
                : { as: Button, leftIcon: mdiSort, labelDisplayMode: 'show-label' })}
            label={label}
            value={value}
            options={options}
            buttonRef={buttonRef}
            emphasis="low"
            className={className}
            {...forwardedProps}
            {...get({ element: 'sort-button', action: value?.id })}
            popoverProps={{
                placement: 'bottom-end',
                ...get({ action: 'list', element: 'sort' }),
                ...forwardedProps.popoverProps,
            }}
            getOptionId={(sorter) => sorter.id || 'default'}
            getOptionName={(sorter) => translateKey(sorter.label) as string}
            renderOption={(sorter, index) => (
                <SelectButton.Option {...get({ action: sorter.id, element: 'sort', position: index + 1 })} />
            )}
        />
    );
};
