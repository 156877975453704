import React from 'react';

import { useDataAttributes } from '@lumapps/data-attributes';
import { ENTRY_TYPES } from '@lumapps/directories-entries/constants';
import { useEntryLink } from '@lumapps/directories-entries/hooks/useEntryLink';
import { DirectoryEntry } from '@lumapps/directories-entries/types';
import { getDirectoryEntryIcon } from '@lumapps/directories-entries/utils';
import { sendTrackingDirectoryEntryClickActionEvent } from '@lumapps/directories/api/analytics';
import { currentLanguageSelector } from '@lumapps/languages';
import { Menu } from '@lumapps/lumx-menu/components/Menu';
import { mdiBookmark } from '@lumapps/lumx/icons';
import { Thumbnail, Icon } from '@lumapps/lumx/react';
import { useSelector } from '@lumapps/redux/react';
import { useTranslate } from '@lumapps/translations';
import { isUrl } from '@lumapps/utils/string/isUrl';

export type FavoriteDropdownItemProps = {
    /** The instance slug to redirect */
    instanceSlug?: string;
    /** The directory entry to display in the menu item */
    item: DirectoryEntry;
    /** Custom classname */
    className: string;
    /** Callback function to open the micro-app dialog */
    onOpenMicroAppDialog?: (resourceId: string) => void;
};

const SCOPE = 'favorite-item';

/**
 * A favorite item
 * @param FavoriteDropdownItemProps
 */
export const FavoriteDropdownItem: React.FC<FavoriteDropdownItemProps> = ({
    instanceSlug,
    item,
    className,
    onOpenMicroAppDialog,
    ...rest
}) => {
    const currentLanguage = useSelector(currentLanguageSelector);
    const { translateObject } = useTranslate();
    const { get } = useDataAttributes(SCOPE);
    const thumbnail = React.useMemo(() => {
        if (item.type === ENTRY_TYPES.MICROAPP) {
            return getDirectoryEntryIcon(item)?.[currentLanguage];
        }
        if (!item.thumbnail) {
            return null;
        }

        return isUrl(item.thumbnail) ? item.thumbnail : `/serve/${item.thumbnail}`;
    }, [currentLanguage, item]);

    const url = useEntryLink(item.link, instanceSlug);
    const name = translateObject(item.name);

    if (!url) {
        return null;
    }

    // used to send tracking event on user click
    const onClick = (event: Event) => {
        if (item.type === ENTRY_TYPES.MICROAPP && item.resourceId) {
            event.preventDefault();
            onOpenMicroAppDialog?.(item.resourceId);
        }
        sendTrackingDirectoryEntryClickActionEvent({
            targetId: item.id,
            url,
            title: name ?? undefined,
            thumbnail: thumbnail ?? undefined,
        });
    };

    return (
        <Menu.Item
            {...rest}
            before={
                thumbnail ? (
                    <Thumbnail
                        alt=""
                        image={thumbnail}
                        size="s"
                        aspectRatio="square"
                        // eslint-disable-next-line lumapps/no-classname-strings
                        className={`${className}-item__image`}
                    />
                ) : (
                    <Icon icon={mdiBookmark} size="s" />
                )
            }
            as="a"
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            onClick={onClick}
            {...get({ element: 'entry', action: item.id })}
        >
            {name}
        </Menu.Item>
    );
};
