import { get as getConstants } from '@lumapps/constants';
import { AppId } from '@lumapps/constants/app';
import { getCurrentInstance, getInstanceById } from '@lumapps/instance/ducks/selectors';
import { Dispatch, GetBaseState } from '@lumapps/redux/types';
import { Route } from '@lumapps/router';
import { RouterApi } from '@lumapps/router/api';
import { TranslationAPI } from '@lumapps/translations';
import { watchLinkActivation } from '@lumapps/utils/element/watchLinkActivation';

import { CommunityLinkRef } from '../../types';
import { prepareFetchUpdateCommunityLinkRef } from './fetchUpdatedCommunityLinkRef';
import { getCommunityRoute } from './getCommunityRoute';
import { updateCommunityLinkFromState } from './updateCommunityLinkFromState';

const updateFromStateThunk =
    (communityLinkRef: CommunityLinkRef, translateObject: TranslationAPI['translateObject']) =>
    (_: any, getState: GetBaseState) => {
        const state = getState();
        const instanceById = getInstanceById(state) || {};
        const { id: currentInstanceId } = getCurrentInstance(state);

        return updateCommunityLinkFromState(communityLinkRef, translateObject, {
            currentInstanceId,
            instanceById,
        });
    };

export function watchUpdateLink(
    anchor: HTMLElement,
    to: CommunityLinkRef,
    options: {
        languageHeader: string;
        currentInstanceId: string;
        isSpaceFeatureEnabled: boolean;
        homeRoute: Route;
        dispatch: Dispatch;
        translateObject: TranslationAPI['translateObject'];
        redirect: RouterApi['redirect'];
    },
) {
    const { applicationId } = getConstants();
    const { currentInstanceId, isSpaceFeatureEnabled, homeRoute, dispatch, translateObject, redirect } = options;
    const { renderingType, hasStaleData, instance } = to;

    const isCrossInstanceSlugMissing = instance?.id && instance?.id !== currentInstanceId && !instance?.slug;
    const isWebviewApp = applicationId === AppId.webview;

    // We don't need to update if:
    // - On webview app (we only need the id)
    // - No renderingType but Space FF is disabled
    const needUpdate =
        !isWebviewApp && ((!renderingType && isSpaceFeatureEnabled) || isCrossInstanceSlugMissing || hasStaleData);

    const fetchUpdateCommunityLinkRef = prepareFetchUpdateCommunityLinkRef({ to, ...options });

    const onActivate = async (openInNewTab: boolean) => {
        // only updating if missing information
        const updatedTo = needUpdate ? await fetchUpdateCommunityLinkRef?.fetch() : to;
        // Get updated content link ref from store
        const updatedCommunityLinkRef: CommunityLinkRef = dispatch(updateFromStateThunk(updatedTo, translateObject));
        // Getting a route: either a space, a community, homepage or notFound depending of the updated params
        const { route, routerData } = getCommunityRoute(updatedCommunityLinkRef, { isSpaceFeatureEnabled, homeRoute });

        redirect(route, {
            routerData,
            openInNewTab,
        });
    };

    return watchLinkActivation({
        element: anchor,
        onActivate,
    });
}
