import React from 'react';

import { classnames } from '@lumapps/classnames';
import { Heading, HeadingProps } from '@lumapps/lumx/react';
import { ALIGNMENTS, ALIGNMENT_CLASSES } from '@lumapps/wrex/constants';
import type { ElementRender } from '@lumapps/wrex/types';

import { HEADLINE } from '../../../constants';
import type { HeadlineElement } from '../../../types';

import './index.scss';

export const Headline: ElementRender<HeadlineElement, HTMLHeadingElement> = (props) => {
    const { children, className, elementRef, element, ...forwardedProps } = props;
    const alignment = element?.alignment ? element.alignment : ALIGNMENTS.start;
    return (
        <Heading
            {...(forwardedProps as HeadingProps)}
            className={classnames(className, 'wrex-content-headline', ALIGNMENT_CLASSES[alignment])}
            ref={elementRef}
        >
            {children}
        </Heading>
    );
};
Headline.displayName = HEADLINE;
