import { STYLE_TYPES } from '../types';

const groupStylesByType = (initState: any = {}) => {
    const { styles = [], instance, parentStyle } = initState;
    const stylesByType: Record<string, any> = {};

    if (styles && styles.length > 0) {
        styles.forEach((style: any) => {
            const { type, id } = style;

            /**
             * Since the backend returns all the possible styles that we have, we could have
             * the case where there are multiple global styles created. In that scenario, we need to
             * select the global style that is currently used by the instance, and we can check that
             * by using the style id available in the instance. If the style is not global, we just add
             * it to the list
             */
            if (type === STYLE_TYPES.INSTANCE) {
                if (parentStyle && parentStyle === id) {
                    stylesByType[STYLE_TYPES.INSTANCE] = style;
                }

                if (id === instance.style) {
                    stylesByType[parentStyle ? STYLE_TYPES.CHILD : STYLE_TYPES.INSTANCE] = style;
                }
            } else if (type === STYLE_TYPES.WIDGET) {
                stylesByType[type] = { ...stylesByType[type], [style.id]: style };
            } else if (type !== STYLE_TYPES.INSTANCE) {
                stylesByType[type] = style;
            }
        });
    }

    Object.assign(initState, { styles: stylesByType });

    return initState;
};

export { groupStylesByType };
