import { useInitialState } from './useInitialState';
import { useMomentLocale } from './useMomentLocale';
import { useRetrieveTranslations } from './useRetrieveTranslations';

/**
 * Hook that will be executed to initialize the application. As for the moment, it executes:
 * - Listens to the init request
 * - Listens to the translations request.
 *
 * This hook only returns something when we have receive enough information to display on the page,
 * since both translations and the initial request are blocking for the page rendering. Only when both
 * values are populated, we return something to the page. If this logic changes (we want to execute something else but still render),
 * we will need to add a little bit more logic here.
 */
const useInitializeApplication = () => {
    const initialState = useInitialState();
    const translationsState = useRetrieveTranslations();

    useMomentLocale({ translations: translationsState });

    return initialState && translationsState ? { initialState, translationsState } : {};
};

export { useInitializeApplication };
