import find from 'lodash/find';
import first from 'lodash/first';

import { isFeatureEnabled } from '@lumapps/features';
import { isTagzEnabled } from '@lumapps/folksonomy/ducks/selectors';
import { getInstanceProperties } from '@lumapps/instance/ducks/selectors';
import { createSelector } from '@lumapps/redux/reselect';
import { FrontOfficeStore } from '@lumapps/redux/types';
import { GLOBAL } from '@lumapps/translations';
import { connectedUserSelector, getEmail, getFullName, isHideEmailEnabled } from '@lumapps/user/ducks/selectors';
import { retrieveKeyFromApiProfile } from '@lumapps/user/utils/apiProfile';

import { DEFAULT_FILTER, ENGINES, isDefaultSort, STATUS, FILTER_TYPES, GCS_INDEXING_FEATURE_TOKEN } from '../constants';
import { SearchFilter, FeaturesStatus, SEARCH_CAUSES } from '../types';
import { convertTabToSearchFilter } from '../utils';
import { SearchState, initialState } from './state';

const searchSelector = (state: FrontOfficeStore): SearchState => state.search || {};

/** Retrieves the current list of results */
export const getResults = createSelector(searchSelector, (search) => search.results);

/** Retrieves templates data for third party source */
export const getTemplates = createSelector(searchSelector, (search) => search.templates);

/** Retrives the current list of promoted results */
export const getPromotedResults = createSelector(searchSelector, (search) => search.promotedResults);

/** Retrieves the current cursor */
export const getCursor = createSelector(searchSelector, (search) => search.cursor);

/** Retrieves the current search engine */
export const getSearchEngine = createSelector(searchSelector, (search) => search.engine);

/** Retrieves the current filters metadata */
export const getFiltersMetadata = createSelector(searchSelector, (search) => search.metadata);

/** Retrieves whether there is a search in progress or not */
export const isSearchInProgress = createSelector(searchSelector, (search) => search.status === STATUS.LOADING);

/** Retrieves whether there is a filter in progress or not */
export const isFilteringInProgress = createSelector(searchSelector, (search) => search.status === STATUS.FILTERING);

/** Retrieves whether there is a search in progress or not */
export const isSearchInErrorStatus = createSelector(searchSelector, (search) => search.status === STATUS.ERROR);

/** Retrieves whether there is a pending download */
export const isSearchInDownloadingStatus = createSelector(
    searchSelector,
    (search) => search.status === STATUS.DOWNLOADING,
);

/** Retrieves whether there are more results on the backend or not */
export const hasMoreResults = createSelector(searchSelector, (search: SearchState) => search.hasMoreResults);

/** Returns true if the cloud search functionality is enabled */
export const hasGCSIndexingEnabled = isFeatureEnabled(GCS_INDEXING_FEATURE_TOKEN);

export const isSearchAlphaEnabled = isFeatureEnabled('search-alpha');

export const autocompleteSearchEnabled = isFeatureEnabled('autocomplete-search');

/** Returns true if the feature flag for the zendesk feature flag is enabled */
export const isZendeskIntegrationEnabled = isFeatureEnabled('zendesk');

/** Returns true if the feature flag for the sharepoint beta feature flag is enabled */
export const isSharePointBetaEnabled = isFeatureEnabled('sharepoint-beta-search');

/** Returns true if the FF Coveo is enabled */
export const isCoveoEnabled = isFeatureEnabled('coveo-search');
export const isCoveoIndexingEnabled = isFeatureEnabled('coveo-indexing');
export const isRecommendationEnabled = isFeatureEnabled('search-recommendation');
export const isSearchPageImprovementsEnabled = isFeatureEnabled('search-page-q1-2023');

export const selectEngineLabel = createSelector(isCoveoEnabled, (isCoveo) => {
    if (isCoveo) {
        return GLOBAL.COVEO;
    }

    return GLOBAL.NATIVE_SEARCH;
});

export const getSuggestionEngine = createSelector(isCoveoEnabled, (coveoEnabled) => {
    if (coveoEnabled) {
        return ENGINES.COVEO;
    }

    // Use LumApps' native search by default
    return ENGINES.LUMAPPS;
});

/** Returns the search related feature flags and whether they are enabled or not */
export const getCurrentFeatures = createSelector(
    isCoveoEnabled,
    isSharePointBetaEnabled,
    isRecommendationEnabled,
    isTagzEnabled,
    isHideEmailEnabled,
    isSearchPageImprovementsEnabled,
    (isCoveo, isSPEnabled, isRecoEnabled, isTagz, hideEmailIsEnabled, areImprovementsEnabled) => {
        const features: FeaturesStatus = {
            isNSEnabled: !isCoveo,
            isCoveoEnabled: isCoveo,
            isSharePointBetaEnabled: isSPEnabled,
            areAnalyticsEnabled: isCoveo,
            // Recommendation is only available for coveo for the moment.
            isRecommendationEnabled: isRecoEnabled && isCoveo,
            isTagzEnabled: isTagz,
            isHideEmailEnabled: hideEmailIsEnabled,
            areImprovementsEnabled,
        };
        return features;
    },
);

export const getInstanceFilter = (filterId: string) =>
    createSelector(getInstanceProperties, (properties) => {
        const { searchTabs = [] } = properties;

        return find(searchTabs, (tab: { id: string; name: string }) => tab.id === filterId || tab.name === filterId);
    });

/** Retrieves the list of current available facets in a formatted fashion  */
export const getFormattedFacets = createSelector(searchSelector, (search: SearchState) => search.facets);

/** get selected facets */
export const getSelectedFilters = createSelector(searchSelector, (search: SearchState) => search.selectedFilters);

/** Retrieves the list of current available filters in a formatted fashion  */
export const getFormattedFilters = createSelector(searchSelector, (search: SearchState) => search?.filters || []);

/** Retrieves the current filter selected id */
export const getSelectedFilterId = createSelector(searchSelector, (search) => search.filter);

/** Retrieves the current selected filter ready to be displayed */
export const getSelectedFilter = createSelector(
    searchSelector,
    getSelectedFilterId,
    getFormattedFilters,
    (search: SearchState, selectedFilterId: string, displayableFilters: SearchFilter[]) => {
        const { metadata, selectedFilter } = search;

        if (selectedFilter && selectedFilter.value === selectedFilterId) {
            return selectedFilter;
        }

        if (selectedFilterId && selectedFilterId !== DEFAULT_FILTER && metadata[selectedFilterId]) {
            return convertTabToSearchFilter(metadata[selectedFilterId]);
        }

        return displayableFilters.find((f) => f.value === selectedFilterId);
    },
);

/** Returns the list of suggestions to display for the autocomplete */
export const getSuggestions = createSelector(searchSelector, (search: SearchState) => search.suggestions || []);
export const getResultSuggestions = createSelector(
    searchSelector,
    (search: SearchState) => search.resultSuggestions || [],
);

/** Returns true if the suggestions are loading */
export const areSuggestionsLoading = createSelector(
    searchSelector,
    (search: SearchState) => search.suggestionsStatus === STATUS.LOADING,
);

/** Returns the list of formatted sorts */
export const getFormattedSorts = createSelector(searchSelector, (search: SearchState) => search.sorts || []);

/**
 * Returns the current sort selected taking into account the current state
 */
export const getCurrentSort = createSelector(searchSelector, (search: SearchState) => {
    const { selectedSort, sort, sorts } = search;

    /**
     * If we already have a selectedSort variable created, it means that we have already navigated
     * on the Search application and that we have already called the backend. In that scenario, we retrieve
     * directly return the sort.
     */
    if (selectedSort) {
        const isDefault = isDefaultSort(selectedSort.value);

        return { sort: selectedSort, isDefault };
    }

    /**
     * If we only have the sort variable, it means that this is the first time that we are doing an API call
     * to the search backend, and we did not yet set the default sort. In that scenario, we retrieve the sort
     * from the list of available sorts and use that UI representation to show it.
     */
    if (sort) {
        const isDefault = isDefaultSort(sort);

        return {
            sort: find(sorts, (s) => s.value === sort),
            isDefault,
        };
    }

    /**
     * Worst case scenario, there is no sort applied, so we look through the list of available sorts and use
     * the default one or the first one if there is no default one.
     */
    const defaultSort = find(sorts, (s) => isDefaultSort(s.value));

    if (defaultSort) {
        return { sort: defaultSort, isDefault: true };
    }

    return { sort: first(sorts), isDefault: false };
});

/** Retrieves sort orders as received from backend */
export const getSortOrders = createSelector(searchSelector, (search: SearchState) => search.sortOrders);

/** Retrieves the current query */
export const getSearchQuery = createSelector(searchSelector, (search: SearchState) => search.query || '');

/** Retrieves the current query Uid from the search response */
export const getSearchQueryUid = createSelector(searchSelector, (search: SearchState) => search.queryUid);

export const getCoveoSearchInfo = createSelector(searchSelector, (search: SearchState): Record<string, string> => {
    if (search.splitTestRunName && search.splitTestRunVersion) {
        return {
            splitTestRunName: search.splitTestRunName,
            splitTestRunVersion: search.splitTestRunVersion,
        };
    }

    return {};
});

/** Returns true if there are currently no results */
export const hasNoResults = createSelector(
    isSearchInProgress,
    getResults,
    (isLoading: boolean, results: SearchState['results']) => {
        return !isLoading && results?.length === 0;
    },
);

/**
 * To check if we are in the initial state we are looking at the cursor.
 * If it is set we are not in the initial state anymore
 * For Iframe tabs we haven't a cursor, so if an iframe tab is selected, we can consider that we are no more in the initial state.
 */
export const isInitialState = createSelector(searchSelector, (search: SearchState) => {
    return (
        initialState.cursor === search.cursor &&
        (!search.selectedFilter || search.selectedFilter.type !== FILTER_TYPES.IFRAME) &&
        initialState.filters === search.filters
    );
});

export const getSuggestedQuery = createSelector(searchSelector, (search: SearchState) => search.suggestedQuery);
export const getSearchCause = createSelector(
    searchSelector,
    (search: SearchState) => search.cause || SEARCH_CAUSES.init,
);

export const getTotalResultsCount = createSelector(searchSelector, (search: SearchState) => search.totalResultsCount);

export const searchContextSelector = createSelector(
    getEmail,
    getFullName,
    connectedUserSelector,
    (email, fullName, user) => {
        const profile = user?.apiProfile?.profile || {};

        return {
            email,
            fullName,
            jobTitle: user ? retrieveKeyFromApiProfile('title', user) : undefined,
            location: user ? retrieveKeyFromApiProfile('location', user) : undefined,
            department: user ? retrieveKeyFromApiProfile('department', user) : undefined,
            ...profile,
        };
    },
);

/** Returns if we should display sort or not
 * If we have results OR if the sort is not the default one (allow user to change the sort if it's responsible of the no results)
 *      Users are filtered if we sort by date
 * AND if we have sorting option
 */
export const shouldDisplaySorts = createSelector(
    getFormattedSorts,
    getTotalResultsCount,
    getCurrentSort,
    (sorts: SearchFilter[] | null, count, currentSort) => {
        return (count > 0 || !currentSort.isDefault) && Boolean(sorts?.length);
    },
);

export const selectlastInteractedFacet = createSelector(
    searchSelector,
    (state: SearchState) => state.lastInteractedFacet,
);

export const hasContextualSearchBoxEnabled = isFeatureEnabled('search-contextual-searchbox');

export const getExtensionId = createSelector(searchSelector, (search: SearchState) => search.extensionId);

export const getSearchTraceId = createSelector(searchSelector, (search: SearchState) => search.traceId);
