import React from 'react';

import { SEARCH_RESULT_TYPES } from '@lumapps/search/constants';

import { SearchBoxOptionTypeProps, SearchBoxOptionWithItemProps, isSuggestionWithItem } from '../../types';
import { SearchBoxInteractionContent } from './SearchBoxInteractionContent';
import { SearchBoxInteractionDefault } from './SearchBoxInteractionDefault';
import { SearchBoxInteractionUser } from './SearchBoxInteractionUser';

/**
 * Dictionary of all suggestion component to use depending on the search result type.
 */
const ComponentsByEntityType: { [key in SEARCH_RESULT_TYPES]?: React.FC<SearchBoxOptionWithItemProps> } = {
    [SEARCH_RESULT_TYPES.USER]: SearchBoxInteractionUser,
    [SEARCH_RESULT_TYPES.ARTICLE]: SearchBoxInteractionContent,
};

/**
 * Component to display "interaction" type options.
 * Interactions are options that redirects directly to the resource
 */
export const SearchBoxInteractionOption = ({ suggestion, ...forwardedProps }: SearchBoxOptionTypeProps) => {
    if (!isSuggestionWithItem(suggestion)) {
        return null;
    }

    // Check that a specific component is set fot this entity type, if not use the default interaction item.
    const InteractionComponent = ComponentsByEntityType[suggestion.item.entityType] || SearchBoxInteractionDefault;

    return <InteractionComponent suggestion={suggestion} {...forwardedProps} />;
};
