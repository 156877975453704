import { createEntityManagementRoutes, AppId } from '@lumapps/router';

const { baseRoute, createRoute, editRoute, getEditRoute } = createEntityManagementRoutes({
    appId: AppId.backOffice,
    slug: 'segments',
    id: 'segment',
});

export interface SegmentParams {
    id: string;
}

export {
    baseRoute as segmentBaseRoute,
    createRoute as segmentCreateRoute,
    editRoute as segmentEditRoute,
    getEditRoute as getEditSegmentRoute,
};
