/* istanbul ignore file */
import React, { useMemo } from 'react';

import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';

import { useDispatch, useSelector } from '@lumapps/redux/react';
import { useRouter, useQueryParams, useDecodedParameters, useRouteMatch } from '@lumapps/router';
import { SEARCH_PAGE } from '@lumapps/search-page-v2/keys';
import { useTranslate } from '@lumapps/translations';
import { sanitizeHTML } from '@lumapps/utils/string/sanitizeHtml';
import { decodeURIComponentSafe } from '@lumapps/utils/string/uriComponent';

import {
    facetParamsFilter,
    isDefaultSort,
    RESET_FILTER,
    SEARCH_FIRST_PAGE,
    SEARCH_PAGE_PARAM,
    FILTER_TYPES,
} from '../constants';
import {
    getSearchCause,
    getSearchQuery,
    isSearchInProgress,
    getCurrentSort,
    getFiltersMetadata,
    getSelectedFilter,
    hasContextualSearchBoxEnabled,
    getSearchTraceId,
} from '../ducks/selectors';
import { actions } from '../ducks/slice';
import { LastInteractedFacet } from '../ducks/state';
import { downloadResultsPage } from '../ducks/thunks';
import { search, searchRoute, mobileSearchRoute } from '../routes';
import {
    SearchFilter,
    FacetFilter,
    SearchSuggestion,
    FacetOptions,
    SEARCH_CAUSES,
    SearchTab,
    SearchSort,
} from '../types';
import { getCurrentFacets as getCurrentFacetsUtil } from '../utils/getCurrentFacets';
import { useIsSearchPage } from './useIsSearchPage';
import { useSearchQuery } from './useSearchQuery';

export interface UseSearch {
    /** callback to execute if a filter has change */
    onFilterChange: (selectedFilter?: SearchFilter) => void;
    /** callback to execute if a facet has change */
    onFacetChange: (
        facet: FacetFilter,
        lastInteractedFacet: LastInteractedFacet,
        selectedValue?: SearchFilter | SearchFilter[],
    ) => void;
    /** callback to execute if a sort has change */
    onSortChange: (sort?: SearchSort) => void;
    /** callback to execute if the search query wants to be changed */
    onSearch: (searchIntention: SearchSuggestion) => void;
    /** current search query */
    query?: string;
    /** key/value object where key is the id of the search URL and the value is a boolean that determines if the user is currently in that URL or not  */
    routes: Record<string, boolean>;
    /** function that focuses on the search input upon call */
    focusOnSearchInput: () => void;
    /** search page title with no markup */
    rawSearchPageTitle: string;
    /** the current filter applied */
    filter: SearchFilter;
    /** whether we are currently on the search page or not */
    isSearchPage: boolean;
    /** is the page loading? */
    isLoading: boolean;
    /** callback to reset search query */
    resetQuery: () => void;
    /** callback to reset all page state (tab) */
    resetPage: () => void;
    /** set query */
    setQuery: (query: string) => void;
    /** callback to reset selected search facets */
    resetFacets: () => void;
    /** get current selected facets */
    getCurrentFacets: (facetToIgnore?: FacetFilter) => FacetOptions[];
    /** reason of triggering search */
    cause: SEARCH_CAUSES;
    /** current page */
    currentPage: number;
    /** callback when the page change */
    onPageChange: (page: number) => void;
    /** callback used to downlaod the current page */
    onDownloadPage: (page: number) => void;
    /** callback when the search cause change. ex: The user click on something that will trigger the search. */
    onCauseChange: (cause: SEARCH_CAUSES) => void;
    /** is the contextual search box FF activated ? */
    hasContextualSearchBox: boolean;
}

export interface UseSearchOptions {
    /** Whether the filters should be kept upon executing a search */
    keepFiltersOnSearch?: boolean;
}

/**
 * Hook useful to reuse logic related to the search engine while adding components to the search page. Returns
 * a series of functionalities and callbacks that allow to change the current filters, sorts, facets and query
 * in the search page.
 */
export const useSearch = ({ keepFiltersOnSearch = false }: UseSearchOptions = {}): UseSearch => {
    let query = '';
    const params = useDecodedParameters() as any;
    const searchQuery = useSelector(getSearchQuery);
    const isLoading = useSelector(isSearchInProgress);
    const cause = useSelector(getSearchCause);
    const currentSort = useSelector(getCurrentSort);
    const filtersMetadata = useSelector(getFiltersMetadata);
    const match = useRouteMatch({ path: searchRoute.path }) as any;
    const matchMobileRoute = useRouteMatch({ path: mobileSearchRoute.path }) as any;
    const isSearchPage = useIsSearchPage();
    const traceId = useSelector(getSearchTraceId);

    /**
     * If the FF 'search-contextual-searchbox' is activated :
     * - needs to execute a query with a tab which is not one of : [FILTER_TYPES.EXTENSION, FILTER_TYPES.EXTERNAL, FILTER_TYPES.IFRAME]
     * - when no search query is done yet, the searchbox is still in the main page header
     * - when a search query is done : the search box is moved to the ResultsPage component in the search page
     * - if the query change, we keep the previous facet filters for the new search query
     * - the position of the searchbox doesnt change in responsive mode
     */
    const disabledFilterTypes = [FILTER_TYPES.EXTENSION, FILTER_TYPES.EXTERNAL, FILTER_TYPES.IFRAME];
    const selectedFilterType = useSelector(getSelectedFilter)?.type;
    const hasContextualSearchBox =
        useSelector(hasContextualSearchBoxEnabled) &&
        isSearchPage &&
        Boolean(selectedFilterType) &&
        !disabledFilterTypes.includes(selectedFilterType as FILTER_TYPES);

    /**
     * In order to determine which query to use, we need to take into consideration that this hook
     * could be called from outside the search page. So to get the query, we first evaluate the
     * query params that we have. If they are there, it means that the hook was called from inside
     * the search page, so we use that as query. If there is a match but no params, it means that this
     * hook was called from outside the search page, but on the search URL, possibly from the search box
     * on the top navigation. If that is the case, we use the params from the URL match.
     *
     * Finally, we use the query from the redux store.
     */
    if (params && params.query) {
        query = params.query;
    } else if (match?.params?.query) {
        query = match.params.query;
    } else {
        query = searchQuery;
    }
    const filter = match?.params?.filter;
    const { redirect } = useRouter();
    const dispatch = useDispatch();
    const facetParams = useQueryParams({
        filter: facetParamsFilter,
    });
    const pageParams = useQueryParams({
        filter: (key: string) => key === SEARCH_PAGE_PARAM,
    });

    const getCurrentFacets = React.useCallback(
        (facetToIgnore?: FacetFilter) => {
            return getCurrentFacetsUtil(facetParams, facetToIgnore);
        },
        [facetParams],
    );

    /**
     * When a filter is triggered, we want to redirect to the search page with the filter applied.
     * We also want to set the current selected filter in order to update the UI
     */
    const onFilterChange = React.useCallback(
        (selectedFilter?: SearchFilter) => {
            dispatch(actions.setSelectedFilter(selectedFilter));
            dispatch(actions.transitionToFilteringState());
            redirect(search(query || '', selectedFilter?.value !== RESET_FILTER ? selectedFilter?.value : undefined));
        },
        [query, redirect, dispatch],
    );

    /**
     * When a facet changes, we need to retrieve the currently applied facets and apply them
     * when we redirect to the search page. We also need to add or remove the facet that changed.
     * Is selected is used to handle multiselect facets
     */
    const onFacetChange = React.useCallback(
        (
            facet: FacetFilter,
            lastInteractedFacet: LastInteractedFacet,
            selectedValue?: SearchFilter | SearchFilter[],
        ) => {
            const facets: FacetOptions[] = getCurrentFacets(facet);

            if (selectedValue) {
                const value = isArray(selectedValue) ? selectedValue.map((v) => v.value) : selectedValue.value;
                /**
                 * If there was a value selected, we add that facet to the facets to applied. If there
                 * is no value selected, it means that the facet was removed, so in that case, we avoid adding it.
                 */
                if (value && !isEmpty(value)) {
                    facets.push({
                        id: facet.id,
                        value,
                    });
                }
            }

            dispatch(actions.setSelectedFacet({ facet, value: selectedValue }));
            dispatch(actions.setLastInteractedFacet(lastInteractedFacet));
            dispatch(actions.resetResults({ keepFilters: true, cause: SEARCH_CAUSES.facetInteracted }));
            dispatch(actions.transitionToFilteringState());
            redirect(search(query || '', filter, facets));
        },
        [getCurrentFacets, dispatch, redirect, query, filter],
    );

    /**
     * When a sort changes, we need to retrieve the currently applied facets and apply them
     * when we redirect to the search page.
     */
    const onSortChange = React.useCallback(
        (sort?: SearchSort) => {
            const facets: FacetOptions[] = getCurrentFacets();

            dispatch(actions.setSelectedSort({ sort }));
            dispatch(actions.resetResults({ keepFilters: true, cause: SEARCH_CAUSES.sortChange }));
            dispatch(actions.transitionToFilteringState());
            redirect(search(query || '', filter, facets, isDefaultSort(sort?.value) ? undefined : sort?.value));
        },
        [getCurrentFacets, filter, query, redirect, dispatch],
    );

    const onSearch = React.useCallback(
        (searchIntention: SearchSuggestion) => {
            /**
             * If the user wants to search the same query that they are currently looking at,
             * we prevent it from executing the search since it is the same query.
             * For tags, we should check the decoded string to compare the same thing ex: tag%3A"toto" with tag:"toto"
             */
            if (!isSearchPage || decodeURIComponentSafe(query) !== decodeURIComponentSafe(searchIntention.query)) {
                dispatch(actions.resetResults({ keepFilters: keepFiltersOnSearch }));

                const facets: FacetOptions[] = getCurrentFacets();

                /**
                 * If we do not want to keep the current filters, we just redirect to the search page
                 * using the search query provided. If we do want to keep the filters, we retrieve them
                 * from the current URL and redirect to that page with those filters applied.
                 */
                if (!keepFiltersOnSearch) {
                    redirect(search(searchIntention.query), {
                        keepFocus: true,
                    });
                } else if (hasContextualSearchBox) {
                    /**
                     * If the 'search-page-q1-2023' FF is activated and
                     * the user uses the searchbox in the results page context,
                     * we want to keep the facet filters
                     */
                    redirect(search(searchIntention.query, filter, facets), {
                        keepFocus: true,
                    });
                } else {
                    redirect(search(searchIntention.query, filter), {
                        keepFocus: true,
                    });
                }
            }
        },
        [
            isSearchPage,
            query,
            dispatch,
            keepFiltersOnSearch,
            getCurrentFacets,
            hasContextualSearchBox,
            redirect,
            filter,
        ],
    );

    const focusOnSearchInput = () => {
        if (window.SEARCH_INPUT && window.SEARCH_INPUT.current) {
            window.SEARCH_INPUT.current.focus();
        }
    };

    const resetQuery = React.useCallback(() => {
        dispatch(actions.resetQuery());
    }, [dispatch]);

    const setQuery = React.useCallback(
        (q: string) => {
            dispatch(actions.setQuery(q));
        },
        [dispatch],
    );

    const resetFacets = React.useCallback(() => {
        const { sort } = currentSort;

        dispatch(actions.resetResults({ keepFilters: true, cause: SEARCH_CAUSES.breadcrumbResetAll }));
        redirect(search(query, filter, [], sort?.value));
    }, [currentSort, dispatch, filter, query, redirect]);

    const { translateAndReplace } = useTranslate();
    const sanitizedQuery = useMemo(() => sanitizeHTML(query), [query]);

    const rawSearchPageTitle = useMemo(() => {
        return translateAndReplace(SEARCH_PAGE.SEARCH_RESULTS_FOR, {
            query: sanitizedQuery,
        });
    }, [translateAndReplace, sanitizedQuery]);

    /**
     * in order to return the currently active query to this hooks caller, we need to consider the
     * fact that when we render the page, the redux store does not have the query since it still needs
     * to be initialized, but we can find the search query on the URL. in that case, if we are on the search page
     * and the redux store was not initialized, we return the query from the URL match. Else, we return the search
     * query from the redux store. This will allow the first render of the page to show a Searchbox with a query,
     * and then allow updating that field as the user types.
     */
    const currentQuery = useSearchQuery(isSearchPage);
    const currentFilter: SearchTab | null = filter ? filtersMetadata[filter] : null;
    const currentPage = parseInt(pageParams.page, 10) || SEARCH_FIRST_PAGE;

    const onPageChange = React.useCallback(
        (page: number) => {
            const facets: FacetOptions[] = getCurrentFacets();

            dispatch(actions.resetResults({ keepFilters: true }));
            // should skip the next search event for Coveo
            dispatch(actions.setCause(SEARCH_CAUSES.loadMore));

            redirect(search(query, filter, facets, currentSort.sort?.value, page));
        },
        [currentSort.sort?.value, dispatch, filter, getCurrentFacets, query, redirect],
    );

    const onDownloadPage = React.useCallback(
        (page: number) => {
            dispatch(
                downloadResultsPage({
                    query,
                    filter,
                    currentFacets: facetParams,
                    sort: currentSort?.sort?.value || '',
                    page,
                    traceId,
                }),
            );
        },
        [dispatch, query, filter, facetParams, currentSort, traceId],
    );

    const onCauseChange = React.useCallback(
        (newCause: SEARCH_CAUSES) => {
            dispatch(actions.setCause(newCause));
        },
        [dispatch],
    );

    const resetPage = React.useCallback(() => {
        dispatch(actions.resetPage());
    }, [dispatch]);

    return React.useMemo(() => {
        const res: UseSearch = {
            onSearch,
            onSortChange,
            onFacetChange,
            onFilterChange,
            focusOnSearchInput,
            getCurrentFacets,
            query: currentQuery,
            routes: {
                mobileRoute: Boolean(matchMobileRoute),
                searchRoute: Boolean(match),
            },
            filter: {
                value: currentFilter?.uid ?? filter,
                type: currentFilter?.type as FILTER_TYPES,
                label: '',
            },
            isSearchPage,
            resetQuery,
            resetPage,
            setQuery,
            isLoading,
            cause,
            resetFacets,
            rawSearchPageTitle,
            currentPage,
            onPageChange,
            onDownloadPage,
            onCauseChange,
            hasContextualSearchBox,
        };

        return res;
    }, [
        onSearch,
        onSortChange,
        onFacetChange,
        onFilterChange,
        getCurrentFacets,
        currentQuery,
        matchMobileRoute,
        match,
        currentFilter?.uid,
        currentFilter?.type,
        filter,
        isSearchPage,
        resetQuery,
        resetPage,
        setQuery,
        isLoading,
        cause,
        resetFacets,
        rawSearchPageTitle,
        currentPage,
        onPageChange,
        onDownloadPage,
        onCauseChange,
        hasContextualSearchBox,
    ]);
};
