/* istanbul ignore file */
import { injectAvailableLanguages } from '@lumapps/customer/transforms';
import { initDirectoryEntries } from '@lumapps/directories/transforms';
import { injectInstanceFromWindow } from '@lumapps/instance/transforms';
import { injectLocale } from '@lumapps/languages/transforms';
import { computePermissions } from '@lumapps/permissions/transforms/front';
import { groupStylesByType } from '@lumapps/style/transforms';
import { injectTokenIntoUser } from '@lumapps/user/transforms';

const transforms = [
    injectInstanceFromWindow,
    injectTokenIntoUser,
    computePermissions,
    injectAvailableLanguages,
    injectLocale,
    initDirectoryEntries,
    groupStylesByType,
];

export { transforms };
