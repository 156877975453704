import omit from 'lodash/omit';

import BaseApi, { PRIORITY } from '@lumapps/base-api';
import { CACHE_TYPE } from '@lumapps/cache';
import { User } from '@lumapps/user/types';

export interface ListParameters {
    maxResults?: number;
    more?: boolean;
    cursor?: string;
    query?: string;
    queryFields?: string[];
    lang?: string;
}
export interface GetParameters {
    /** user's email */
    email?: string;
    /** user's id */
    uid?: string;
    /** user directory id */
    contentId?: string;
    /** whether to do a cache first call */
    cacheFirst?: boolean;
    /** fields to retrieve. */
    fields?: string;
}

const userDirectoryApi = new BaseApi({
    path: 'user/directory',
});

const contentApi = new BaseApi({
    path: 'content',
});

const list = (parameters: ListParameters) => {
    return userDirectoryApi.post('/list', parameters);
};

const directoriesList = (parameters: ListParameters) => {
    return contentApi.post('/list', { ...parameters, type: ['user_directory'] });
};

const get = ({ cacheFirst, ...params }: GetParameters) => {
    if (cacheFirst) {
        return userDirectoryApi.getCacheFirst('/get', CACHE_TYPE.MEMORY, PRIORITY.HIGH, { params });
    }

    return userDirectoryApi.get('/get', { params });
};

const cancelGet = (params: GetParameters) => {
    return userDirectoryApi.cancel('/get', { params: omit(params, ['cacheFirst']) });
};

export interface SaveParameters extends User {
    /** The id of the user directory to save to */
    contentId: string;
}

const save = (user: SaveParameters) => {
    return userDirectoryApi.post<User>('/save', user);
};

export const userDirectoryQueryKeys = {
    all: () => ['user-directory'] as const,
    get: (params: GetParameters) => [...userDirectoryQueryKeys.all(), 'get', params] as const,
};

export { list, userDirectoryApi, directoriesList, contentApi, get, save, cancelGet };
