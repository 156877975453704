import { WIDGET_ARTICLE_LIST_TYPE } from '@lumapps/widget-article-list/constants';
import { WIDGET_BREADCRUMB_TYPE } from '@lumapps/widget-breadcrumb/constants';
import { WIDGET_CALENDAR_TYPE } from '@lumapps/widget-calendar/constants';
import { WIDGET_COMMENTS_TYPE } from '@lumapps/widget-comments/constants';
import { WIDGET_COMMUNITY_LIST_TYPE } from '@lumapps/widget-community-list/constants';
import { WIDGET_CONTACT_FORM_TYPE } from '@lumapps/widget-contact-form/constants';
import { WIDGET_CONTENT_FILTER_TYPE } from '@lumapps/widget-content-filter/constants';
import { WIDGET_CONTENT_LIST_TYPE } from '@lumapps/widget-content-list/constants';
import { WIDGET_CONTRIBUTION_TYPE } from '@lumapps/widget-contribution/constants';
import { WIDGET_DIRECTORY_ENTRIES_TYPE } from '@lumapps/widget-directory-entries/constants';
import { WIDGET_EVENT_LIST_TYPE } from '@lumapps/widget-event-list/constants';
import { WIDGET_FEATURED_IMAGE_TYPE } from '@lumapps/widget-featured-image/constants';
import { WIDGET_FILE_LIST_TYPE } from '@lumapps/widget-file-list/constants';
import { WIDGET_HELP_TYPE } from '@lumapps/widget-help/constants';
import { WIDGET_HTML_TYPE } from '@lumapps/widget-html/constants';
import { WIDGET_IFRAME_TYPE } from '@lumapps/widget-iframe/constants';
import { WIDGET_IMAGE_GALLERY_TYPE } from '@lumapps/widget-image-gallery/constants';
import { WIDGET_INTRO_TYPE } from '@lumapps/widget-intro/constants';
import { WIDGET_LEARNING_CATALOG_TYPE } from '@lumapps/widget-learning-catalog/constants';
import { WIDGET_LEARNING_CERTIFICATE_TYPE } from '@lumapps/widget-learning-certificate/constants';
import { WIDGET_LEARNING_LEADERBOARD_TYPE } from '@lumapps/widget-learning-leaderboard/constants';
import { WIDGET_LINKS_TYPE } from '@lumapps/widget-links/constants';
import { WIDGET_MANDATORY_READ } from '@lumapps/widget-mandatory-read/constants';
import { WIDGET_METADATA_TYPE } from '@lumapps/widget-metadata-selection/constants';
import { WIDGET_META_TYPE } from '@lumapps/widget-metadata/constants';
import { WIDGET_MICRO_APP_TYPE } from '@lumapps/widget-micro-app/constants';
import { WIDGET_PERSONAL_FEED_PREVIEW_TYPE } from '@lumapps/widget-personal-feed-preview/constants';
import { WIDGET_PLAY_TYPE } from '@lumapps/widget-play/constants';
import { WIDGET_PLAYLIST_TYPE } from '@lumapps/widget-playlist/constants';
import { WIDGET_POST_DETAILS_TYPE } from '@lumapps/widget-post-details/constants';
import { WIDGET_COMMUNITY_POST_LIST_TYPE } from '@lumapps/widget-post-list/constants';
import { WIDGET_RECOMMENDED_TRAINING_COURSES_TYPE } from '@lumapps/widget-recommended-training-courses/constants';
import { WIDGET_REMOTE } from '@lumapps/widget-remote/constants';
import { WIDGET_SHARE_TYPE } from '@lumapps/widget-share/constants';
import { WIDGET_SITE_LIST_TYPE } from '@lumapps/widget-site-list/constants';
import { WIDGET_SOCIAL_META_TYPE } from '@lumapps/widget-social-metadata/constants';
import { WIDGET_SUB_NAVIGATION_TYPE } from '@lumapps/widget-sub-navigation/constants';
import { WIDGET_SUMMARY_TYPE } from '@lumapps/widget-summary/constants';
import { WIDGET_SURVEY_TYPE } from '@lumapps/widget-survey/constants';
import { WIDGET_TEAM_KPI_TYPE } from '@lumapps/widget-team-kpi/constants';
import { WIDGET_TITLE_TYPE } from '@lumapps/widget-title/constants';
import { WIDGET_TRAINING_COURSES_IN_PROGRESS_TYPE } from '@lumapps/widget-training-courses-in-progress/constants';
import { WIDGET_USER_KPI_TYPE } from '@lumapps/widget-user-kpi/constants';
import { WIDGET_USER_LIST_TYPE } from '@lumapps/widget-user-list/constants';
import { WIDGET_VIDEO_TYPE } from '@lumapps/widget-video/constants';

import { WidgetSizes } from './types';

/**
 * Defines all widget size breakpoints.
 */
export const WIDGET_SIZE_BREAKPOINTS: { [Size in WidgetSizes]: number } = {
    xs: 0,
    s: 0,
    m: 480,
    l: 700,
};

export const WIDGET_IN_VIEW_CONFIGURATION = ({ wasWidgetLoaded }: { wasWidgetLoaded: boolean }) => ({
    /** once the widget is in the view, we want to kill the observer and never rerender again. triggerOnce does exactly this */
    triggerOnce: true,
    /** if the widget was loaded, we avoid creating an observer for it */
    skip: wasWidgetLoaded,
    /** if the widget was loaded, we want to default the inView value to true */
    initialInView: wasWidgetLoaded,
    /** this changes the viewport, adding some px to it so it can start rendering widgets outside the original viewport */
    rootMargin: '0px 0px 200px 0px',
});

/**
 * Local storage key used for storing skeleton heights in order to correctly display them the following times the user enters the page
 */
export const WIDGET_SKELETON_CACHE_KEY = 'widget-skeletons';

/**
 * This is the amount of pages that we save on local storage for correctly displaying skeletons
 */
export const WIDGET_SKELETON_MAX_PAGES_SAVED = 5;

/**
 * List of all widgets who are translatable
 */
export const TRANSLATABLE_WIDGET_TYPES = [
    WIDGET_INTRO_TYPE,
    WIDGET_HTML_TYPE,
    WIDGET_TITLE_TYPE,
    WIDGET_CONTRIBUTION_TYPE,
];

/**
 * Defines the values for each view mode.
 */
export enum VIEW_MODES_VALUES {
    GRID = 'grid',
    LIST = 'list',
    SLIDESHOW = 'slideshow',
}

/**
 * Defines the values for each view mode variant.
 */
export enum VIEW_MODE_VARIANTS_VALUES {
    GROUPED = 'grouped',
    UNGROUPED = 'ungrouped',
}

/**
 * List of widget types supported on webview app.
 *
 * <b>Warning</b>: Make sure you validate that your widget is working in the webview app.
 * Some of the things to test:
 * - UI/UX
 *   - The widget MUST be responsive (obviously)
 *   - The UX should be adapted to touch screens (no pointer hover)
 *   - It MUST avoid Dialogs/Modals as it could be weird integrated in the hybrid mobile app
 *   - Make sure you communicate in-app redirections to the host mobile app or else the hybrid app will open a new
 *     mobile browser
 * - Redux & Init
 *   - The webview app could start to render you widget before the init is finished
 *   - The webview app has a smaller redux store, make sure your widget has what it needs
 */
export const WEBVIEW_ENABLED_WIDGET_TYPES: string[] = [
    WIDGET_ARTICLE_LIST_TYPE,
    WIDGET_CALENDAR_TYPE,
    WIDGET_COMMUNITY_LIST_TYPE,
    WIDGET_COMMUNITY_POST_LIST_TYPE,
    WIDGET_CONTENT_FILTER_TYPE,
    WIDGET_CONTENT_LIST_TYPE,
    WIDGET_CONTRIBUTION_TYPE,
    WIDGET_DIRECTORY_ENTRIES_TYPE,
    WIDGET_EVENT_LIST_TYPE,
    WIDGET_FEATURED_IMAGE_TYPE,
    WIDGET_FILE_LIST_TYPE,
    WIDGET_HTML_TYPE,
    WIDGET_IFRAME_TYPE,
    WIDGET_IMAGE_GALLERY_TYPE,
    WIDGET_INTRO_TYPE,
    WIDGET_LINKS_TYPE,
    WIDGET_PLAY_TYPE,
    WIDGET_PLAYLIST_TYPE,
    WIDGET_REMOTE,
    WIDGET_USER_LIST_TYPE,
    WIDGET_VIDEO_TYPE,
    WIDGET_CONTACT_FORM_TYPE,
    WIDGET_MANDATORY_READ,
    WIDGET_META_TYPE,
    WIDGET_MICRO_APP_TYPE,
    WIDGET_RECOMMENDED_TRAINING_COURSES_TYPE,
    WIDGET_PERSONAL_FEED_PREVIEW_TYPE,
    WIDGET_TRAINING_COURSES_IN_PROGRESS_TYPE,
    WIDGET_USER_KPI_TYPE,
    WIDGET_LEARNING_CATALOG_TYPE,
    WIDGET_LEARNING_CERTIFICATE_TYPE,
    WIDGET_LEARNING_LEADERBOARD_TYPE,
    WIDGET_TEAM_KPI_TYPE,
    WIDGET_HELP_TYPE,
    WIDGET_SUMMARY_TYPE,
    WIDGET_SURVEY_TYPE,
];

export const CLIENT_COMPUTED_WIDGET_TYPE = 'client-computed';

/**
 * Block types part of this table handle the 'loadingcontent' state in their own way,
 * hence 'loadingcontent' won't be handled in WidgetContent.
 */
export const BLOCK_TYPES_WITH_SPECIFIC_LOADING_CONTENT = [
    'BlockTabFilteredContainer',
    'BlockAugmentedContainer',
    'BlockCascade',
];

/**
 *
 *  List of text editable widgets
 */
export const EDITABLE_WIDGET_LIST = [
    WIDGET_CONTRIBUTION_TYPE,
    WIDGET_HTML_TYPE,
    WIDGET_SUMMARY_TYPE,
    WIDGET_INTRO_TYPE,
    WIDGET_TITLE_TYPE,
];

export const NGI_WIDGETS_IN_DESIGNER_FF_TOKEN = 'ngi-widgets-in-legacy-designer';

/**
 * List of client computed widgets
 */
export const CLIENT_COMPUTED_WIDGET_TYPES = [
    WIDGET_BREADCRUMB_TYPE,
    WIDGET_COMMENTS_TYPE,
    WIDGET_CALENDAR_TYPE,
    WIDGET_IFRAME_TYPE,
    WIDGET_SOCIAL_META_TYPE,
    WIDGET_META_TYPE,
    WIDGET_LINKS_TYPE,
    WIDGET_VIDEO_TYPE,
    WIDGET_PLAY_TYPE,
    WIDGET_PLAYLIST_TYPE,
    WIDGET_FILE_LIST_TYPE,
    WIDGET_CONTACT_FORM_TYPE,
    WIDGET_METADATA_TYPE,
    WIDGET_SITE_LIST_TYPE,
    WIDGET_SHARE_TYPE,
    WIDGET_POST_DETAILS_TYPE,
    WIDGET_SUB_NAVIGATION_TYPE,
    WIDGET_MANDATORY_READ,
    WIDGET_CONTENT_FILTER_TYPE,
    WIDGET_RECOMMENDED_TRAINING_COURSES_TYPE,
    WIDGET_SURVEY_TYPE,
    WIDGET_TRAINING_COURSES_IN_PROGRESS_TYPE,
    WIDGET_LEARNING_CATALOG_TYPE,
    WIDGET_LEARNING_CERTIFICATE_TYPE,
    WIDGET_TEAM_KPI_TYPE,
    WIDGET_LEARNING_LEADERBOARD_TYPE,
    WIDGET_USER_KPI_TYPE,
    WIDGET_HELP_TYPE,
    WIDGET_MICRO_APP_TYPE,
] as const;
