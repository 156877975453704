import BaseApi from '@lumapps/base-api';

import { LumappsTrackingEvent } from '../types';

const trackingApi = new BaseApi({ path: 'analytics/events', version: BaseApi.versions.v2 });

export const sendTracking = async (event: LumappsTrackingEvent) => {
    try {
        return await trackingApi.post('', event, {
            validateStatus: (status: number) => status !== 400,
        });
    } catch (err) {
        // eslint-disable-next-line no-console
        return console.error('An error occurred while sending tracking event', { event, err });
    }
};
