import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { FlexBox, Orientation, Heading } from '@lumapps/lumx/react';

import { FilterProps } from '../Filter/types';

import './index.scss';

const CLASSNAME = 'grouped-filter';

/**
 * Component that displays a filter in the mode `GROUPED`.
 * @family Filters
 * @param FilterProps
 * @returns GroupedFilter
 */
export const GroupedFilter: React.FC<FilterProps> = ({
    title,
    children,
    className,
    hideTitle = false,
    isFieldset = false,
    titleId,
}) => {
    const { block, element } = useClassnames(CLASSNAME);

    return (
        <FlexBox
            as={isFieldset ? 'fieldset' : 'div'}
            orientation={Orientation.vertical}
            className={block([className])}
            aria-labelledby={isFieldset ? titleId : undefined}
        >
            {!hideTitle ? (
                <Heading as="h4" className={element('title')} typography="subtitle1" id={titleId}>
                    {title}
                </Heading>
            ) : null}

            <div className={element('content')}>{children}</div>
        </FlexBox>
    );
};
