import React from 'react';

import { getLumappsPublicUniqueId, setLumappsPublicUniqueId } from '../utils';

export const usePublicUniqueId = (initialState: any) => {
    /**
     * Set a LUMAPPS-PUBLIC-UNIQUE-ID in the localStorage
     * if the user is not connected && the id doesn't exist yet
     * It will be used for analytics purpose
     */
    const isUserGuest = initialState && !initialState?.user?.id;

    React.useEffect(() => {
        const publicUniqueId = getLumappsPublicUniqueId();
        if (!publicUniqueId && isUserGuest) {
            setLumappsPublicUniqueId();
        }
    }, [isUserGuest]);
};
