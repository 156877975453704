import React, { ReactElement } from 'react';

import { margin, useClassnames } from '@lumapps/classnames';
import { CustomizationComponent } from '@lumapps/customizations/api';
import { Targets, PLACEMENT } from '@lumapps/customizations/types';
import { useDataAttributes } from '@lumapps/data-attributes';
import { mdiPencil, mdiTrashCanOutline } from '@lumapps/lumx/icons';
import {
    Emphasis,
    IconButton,
    Orientation,
    Size,
    SkeletonTypography,
    Typography,
    UserBlock,
    UserBlockProps,
} from '@lumapps/lumx/react';
import { useSubscription } from '@lumapps/subscriptions/hooks/useSubscription';
import { SubscriptionsTypes, UseSubscriptionStatuses } from '@lumapps/subscriptions/types';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { useUser, UseUserOptions, DEFAULT_FIELDS } from '../../hooks/useUser';
import { MinimumViableUser } from '../../types';
import { UserCardActions } from './UserCardActions';

import './index.scss';

/**
 * Defines the props of the component.
 */
export interface UserCardProps extends Omit<UseUserOptions, 'enabled'> {
    /** callback to be executed when the user card is being edited */
    onEdit?: () => void;
    /** callback to be executed when the user card is being removed */
    onRemove?: () => void;
    /** Icon to display for the onRemove button to override the default */
    onRemoveIcon?: string;
    /** Icon to display for the onEdit button to override the default */
    onEditIcon?: string;
    /** Label to display for the onEdit button to override the default */
    onEditLabel?: string;
    /** custom classname for the component */
    className?: string;
    /** user to display in the card. */
    user: MinimumViableUser;
    /** whether the user card is loading or not */
    isLoading?: boolean;
    /** whether the follow button is displayed or not */
    showFollow?: boolean;
    /** Actions to be displayed on the user card */
    customUserCardActions?: ReactElement;
    /** Whether follow info is already available and does not need to be fetched */
    defaultIsSubscribed?: boolean;
    /** Whether the follow button should be disabled */
    isFollowButtonDisabled?: boolean;
    /** To be used for data-id */
    scope?: string;
    /** additional props for the wrapper */
    wrapperProps?: any;
    /** whether user actions should be hidden or not. */
    hideActions?: boolean;
    /** whether the avatar should be hidden or not */
    hideAvatar?: boolean;
    /** whether the fields should be hidden or not */
    hideFields?: boolean;
    /** additional user block props */
    userBlockProps?: Partial<UserBlockProps>;
    /** whether the user should be fetched when showing the card */
    shouldFetchUser?: boolean;
}

const CLASSNAME = 'user-card';

/**
 * Component used to display a card with user basic info such as job title and location.
 * Also display by default a more info link and a Follow button can also be added
 * Optionnally edit and remove button can also be used to let a user edit the card
 * @returns a user card with basic user info, a link to user profile and some actions
 */
const UserCard: React.FC<UserCardProps> = ({
    onEdit,
    onRemove,
    onEditIcon,
    onRemoveIcon,
    onEditLabel,
    className,
    customUserCardActions,
    isLoading = false,
    user,
    showFollow = false,
    defaultIsSubscribed,
    isFollowButtonDisabled = false,
    scope,
    wrapperProps,
    hideActions = false,
    hideAvatar = false,
    hideFields = false,
    shouldFetchUser = false,
    userBlockProps,
    ...options
}) => {
    const { translateKey } = useTranslate();
    const { block, element } = useClassnames(CLASSNAME);
    const { get } = useDataAttributes(scope || CLASSNAME);
    const { getUserFullName, userFields, avatarProps } = useUser(user, {
        ...options,
        enabled: shouldFetchUser,
    });

    const fullName = getUserFullName();

    const {
        status: useSubscriptionStatus,
        isSubscribed,
        toggleSubscription,
        fetchResourceSubscription,
    } = useSubscription({
        resourceType: SubscriptionsTypes.user,
        resourceId: user.id || '',
        autoFetch: false,
        isSubscribed: defaultIsSubscribed,
    });

    const userCardAction = customUserCardActions || (
        <UserCardActions
            userLoading={isLoading}
            showFollow={showFollow}
            isSubscribed={isSubscribed}
            toggleSubscription={toggleSubscription}
            useSubscriptionStatus={useSubscriptionStatus}
            user={user}
            isFollowButtonDisabled={isFollowButtonDisabled}
        />
    );

    React.useEffect(() => {
        if (
            defaultIsSubscribed === undefined &&
            showFollow &&
            useSubscriptionStatus === UseSubscriptionStatuses.initial
        ) {
            fetchResourceSubscription();
        }
    }, [showFollow, useSubscriptionStatus, fetchResourceSubscription, defaultIsSubscribed]);

    return (
        <div className={block([className])} {...wrapperProps}>
            <UserBlock
                name={fullName}
                /** Don't show fields until they are all loaded. */
                fields={isLoading || hideFields ? undefined : userFields}
                simpleAction={
                    !hideActions ? (
                        <>
                            {/* userFields loading skeletons. */}
                            {isLoading && (
                                <div className={margin('bottom', 'big')}>
                                    <SkeletonTypography
                                        typography={Typography.overline}
                                        style={{ width: '90px', margin: '-10px auto 4px auto' }}
                                    />
                                    <SkeletonTypography
                                        typography={Typography.overline}
                                        style={{ width: '110px', margin: '0 auto' }}
                                    />
                                </div>
                            )}
                            {userCardAction}
                        </>
                    ) : null
                }
                additionalFields={
                    <CustomizationComponent
                        target={Targets.USER_CARD_FIELDS}
                        placement={PLACEMENT.UNDER}
                        context={{
                            id: user.id,
                            fullName,
                            email: user.email,
                        }}
                    />
                }
                size={Size.l}
                orientation={Orientation.vertical}
                avatarProps={!hideAvatar ? avatarProps : undefined}
                {...userBlockProps}
            />

            <div className={element('preview-edit-wrapper')}>
                {onEdit ? (
                    <IconButton
                        size={Size.s}
                        className={element('preview-thumbnail-edit-btn')}
                        onClick={onEdit}
                        label={onEditLabel || translateKey(GLOBAL.EDIT)}
                        emphasis={Emphasis.medium}
                        hasBackground
                        icon={onEditIcon || mdiPencil}
                        {...get({ element: 'button', action: 'edit-preview-thumbnail' })}
                    />
                ) : null}

                {onRemove ? (
                    <IconButton
                        size={Size.s}
                        className={element('preview-thumbnail-remove-btn')}
                        onClick={onRemove}
                        label={translateKey(GLOBAL.REMOVE)}
                        emphasis={Emphasis.medium}
                        hasBackground
                        icon={onRemoveIcon || mdiTrashCanOutline}
                        {...get({ element: 'button', action: 'remove-preview-thumbnail' })}
                    />
                ) : null}
            </div>
        </div>
    );
};

export { UserCard, DEFAULT_FIELDS };
