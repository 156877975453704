import React, { forwardRef } from 'react';

import { useClassnames } from '@lumapps/classnames';
import { mdiClose } from '@lumapps/lumx/icons';
import {
    GenericBlock,
    GenericBlockProps,
    Heading,
    HeadingProps,
    IconButton,
    IconButtonProps,
} from '@lumapps/lumx/react';
import { useOnNavigation } from '@lumapps/router';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { useId } from '@lumapps/utils/hooks/useId';

import './index.scss';

export interface ExtendedNotificationProps extends Omit<React.ComponentPropsWithoutRef<'div'>, 'content'> {
    /** Required notification title */
    title: string;
    /** Title props forwarding */
    titleProps?: Partial<HeadingProps>;
    /** Figure (icon or thumbnail) */
    figure?: React.ReactNode;
    /** Content */
    content?: React.ReactNode;
    /** Content props forwarding */
    contentProps?: Partial<GenericBlockProps>;
    /** Actions (buttons, links, etc.) */
    actions?: React.ReactNode;
    /** Close button props forwarding */
    closeProps?: Partial<IconButtonProps>;
    /** Callback on close button activated */
    onClose?(): void;
    /** Callback on navigation change */
    onNavigationChange?(): void;
    /** Whether the notification should be removed when changing page  */
    shouldBeRemovedOnNavigation?: boolean;
    /** Whether the notification should be persistent (not dismissable) */
    isClosable?: boolean;
}

const CLASSNAME = 'extended-notification';

/**
 * Extended notification component.
 *
 * Similar to the DS `Notification` component can provide more text and possibly
 * links or other button related to the event.
 *
 * Also, this notification should not dismiss automatically, the user has to
 * manually close it.
 *
 * Make sure the focus of the user is at the top of the page before displaying
 * an extended notification so that he/she can easily move the focus to it.
 */
export const ExtendedNotification = forwardRef<HTMLDivElement, ExtendedNotificationProps>((props, ref) => {
    const {
        title,
        titleProps,
        className,
        figure,
        content,
        actions,
        onClose,
        onNavigationChange,
        contentProps,
        closeProps,
        isClosable = true,
        ...forwardedProps
    } = props;
    const { translateKey } = useTranslate();
    const { block, element } = useClassnames(CLASSNAME);
    const titleId = useId(CLASSNAME);

    useOnNavigation(() => {
        if (onNavigationChange) {
            onNavigationChange();
        }
    }, [onNavigationChange]);

    return (
        <article ref={ref} className={block(undefined, className)} {...forwardedProps}>
            <div className={element('header')}>
                {isClosable && (
                    <IconButton
                        label={translateKey(GLOBAL.CLOSE)}
                        aria-describedby={titleId}
                        icon={mdiClose}
                        emphasis="low"
                        onClick={onClose}
                        {...closeProps}
                    />
                )}
            </div>

            <GenericBlock
                role="alert"
                aria-live="polite"
                className={element('content')}
                orientation="vertical"
                vAlign="center"
                gap="huge"
                {...contentProps}
            >
                <GenericBlock.Figure orientation="vertical" gap="regular">
                    {figure}
                    <Heading id={titleId} as="h1" typography="title" className={element('title')} {...titleProps}>
                        {title}
                    </Heading>
                </GenericBlock.Figure>

                <GenericBlock.Content gap="big">{content}</GenericBlock.Content>

                <GenericBlock.Actions wrap gap="regular">
                    {actions}
                </GenericBlock.Actions>
            </GenericBlock>
        </article>
    );
});
