/* istanbul ignore file */
import React from 'react';

import { DEFAULT_DIRECTORY_ICON } from '@lumapps/directories/constants';
import { Directory } from '@lumapps/directories/types';
import { MicroAppDialog } from '@lumapps/micro-apps/components/MicroAppDialog';
import { useBooleanState } from '@lumapps/utils/hooks/useBooleanState';

import { FavoriteDropdown, FavoriteDropdownProps } from '../FavoriteDropdown';

export type FavoritesProps = Partial<FavoriteDropdownProps> & {
    /** All directories to display */
    directories: Directory[];
    /** The user lang */
    language: string;
    /** styles passed down to each favorite icon */
    styles?: Record<string, any>;
};

/**
 * Display all given directories using the FavoriteDropdown component
 * @param FavoritesProps
 */
const Favorites: React.FC<FavoritesProps> = ({ directories = [], language, ...rest }) => {
    const [isMicroAppDialogOpen, , closeMicroAppDialog, openMicroAppDialog] = useBooleanState(false);
    const [resourceId, setResourceId] = React.useState('');

    const handleOpenMicroAppDialog = React.useCallback(
        (resourceId: string) => {
            setResourceId(resourceId);
            openMicroAppDialog();
        },
        [openMicroAppDialog],
    );

    if (!directories || directories.length === 0) {
        return null;
    }

    return (
        <>
            {directories.map(({ id, contentId, name, slug, instanceSlug, instance, properties }) => (
                <FavoriteDropdown
                    key={id}
                    id={id}
                    name={name}
                    slug={slug}
                    contentId={contentId}
                    icon={properties.icon || DEFAULT_DIRECTORY_ICON}
                    language={language}
                    instance={{ id: instance, slug: instanceSlug }}
                    onOpenMicroAppDialog={handleOpenMicroAppDialog}
                    {...rest}
                />
            ))}
            <MicroAppDialog isOpen={isMicroAppDialogOpen} onClose={closeMicroAppDialog} id={resourceId} />
        </>
    );
};

export { Favorites };
