import { DRIVE_DEFAULT_CONFIG } from '@lumapps/google-drive-pickers/constants';
import { GDCustomDocViewType, GDPickerConfiguration } from '@lumapps/google-drive-pickers/types';

import { PublicationStatus } from './api/types';

export const SCOPE = 'play';

export const FULL_DATE_THRESHOLD = 364;

export const PLAY_ALPHA_FEATURE_TOKEN = 'play-alpha';
export const PLAY_BETA_FEATURE_TOKEN = 'play-beta';
export const PLAY_CHINA_FEATURE_TOKEN = 'play-china';
export const PLAY_VIDEO_DOWNLOAD_TOKEN = 'play-download';
export const PLAY_HEADER_BUTTON_FEATURE_TOKEN = 'play-header-button';
export const PLAY_VIDEO_FEATURE_TOKEN = 'play-video';
export const PLAY_HIDE_AUTHOR = 'play-hide-author';
export const PLAY_STANDARD = 'play-standard';
export const PLAY_AUTOGENERATED_SUBTITLES = 'play-autogenerated-subtitles';

export const LOGIN_V2_ALPHA_FEATURE_TOKEN = 'login-v2-alpha';
export const LOGIN_V2_BETA_FEATURE_TOKEN = 'login-v2-beta';

export const UPLOADER_ACCEPT_AUDIO_EXTENSIONS = ['MP3', 'OGG'];
export const UPLOADER_ACCEPT_AUDIO_TYPES = '.mp3, .ogg';
export const UPLOADER_ACCEPT_VIDEO_TYPES = '.mkv, .mp4, .avi, .mov, .wmf, .mpeg, .wmv';
export const UPLOADER_ACCEPT_IMAGE_TYPES = 'image/*';
export const UPLOADER_ACCEPT_MIME_TYPES = 'video/*, .mkv';
export const UPLOADER_ACCEPT_SUBTITLES_TYPES = '.vtt, .srt';
export const UPLOADER_ACCEPT_VIDEO_EXTENSIONS = ['MP4', 'MPG', 'AVI', 'MOV', 'WMF', 'MKV'];
export const UPLOADER_CAN_HANDLE_MULTIPLE_FILE = false;
export const UPLOADER_MP3_FILE_TYPE = '.mp3';
export const UPLOADER_MKV_FILE_TYPE = '.mkv';
export const UPLOADER_MOV_FILE_TYPE = '.mov';
export const UPLOADER_OGG_FILE_TYPE = '.ogg';
export const UPLOADER_SRT_FILE_TYPE = '.srt';
export const UPLOADER_VTT_FILE_TYPE = '.vtt';

export const BASE_THUMBNAIL_URL = '/_ah/api/lumsites/v2/organizations';
export const THUMBNAIL_ENDPOINT = 'thumbnail.jpg';

export const THUMBNAIL_SMALL = {
    thumbnailHeight: 72,
    thumbnailWidth: 128,
} as const;

export const THUMBNAIL_REGULAR = {
    thumbnailHeight: 216,
    thumbnailWidth: 384,
} as const;

export const THUMBNAIL_LARGE = {
    thumbnailHeight: 540,
    thumbnailWidth: 960,
} as const;

export const THUMBNAIL_XLARGE = {
    thumbnailHeight: 720,
    thumbnailWidth: 1280,
} as const;

export const THUMBNAIL_HD = {
    thumbnailHeight: 1080,
    thumbnailWidth: 1920,
} as const;

export const THUMBNAIL = {
    SMALL: THUMBNAIL_SMALL,
    REGULAR: THUMBNAIL_REGULAR,
    LARGE: THUMBNAIL_LARGE,
    XLARGE: THUMBNAIL_XLARGE,
    HD: THUMBNAIL_HD,
} as const;

// Player
export const VIDEO_DETAILS_LIST = ['publicationDate', 'views'];

// Video group details
export const MAX_VISIBLE_GROUPS = 2;

// Video + Playlists pagination settings
export const MAX_PAGE_CONTENT = 10;

// Google Drive Picker
export const DRIVE_VIDEO_MIME_TYPES = [
    'application/vnd.google-apps.video',
    'video/mpeg',
    'video/mp4',
    'video/x-msvideo',
    'video/x-quicktime',
    'video/x-ms-wmv',
    'video/quicktime',
    'video/avi',
    'video/x-matroska',
];

export const DRIVE_VIDEO_DEFAULT_CONFIG: Partial<GDPickerConfiguration> = {
    ...DRIVE_DEFAULT_CONFIG,
    multiselect: true,
    selectableMimeTypes: DRIVE_VIDEO_MIME_TYPES,
    customDocViews: [
        GDCustomDocViewType.DEFAULT_VIEW,
        GDCustomDocViewType.SHARED_DRIVE_VIEW,
        GDCustomDocViewType.STARRED_VIEW,
    ],
};

export const PUBLICATION_STATUS: PublicationStatus[] = ['draft', 'scheduled', 'expired', 'published', 'archived'];

export const ERROR_MAX_LENGTH_EXCEEDED = 'PLAY_MAX_LENGTH_EXCEEDED';
