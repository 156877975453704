import React from 'react';

import { ContentLink } from '@lumapps/contents/components/ContentLink';

import { SearchBoxOptionWithItemProps } from '../../types';
import { BaseSearchBoxInteractionOption } from './BaseSearchBoxInteraction';

/**
 * Search box options for contents.
 * Generates the link to the content and pass it to the Base component
 */
export const SearchBoxInteractionContent = ({ suggestion, ...forwardedProps }: SearchBoxOptionWithItemProps) => {
    return (
        <BaseSearchBoxInteractionOption
            suggestion={suggestion}
            as={ContentLink}
            to={{
                id: suggestion.item.entityId,
                slug: suggestion.item.url,
                instance: {
                    id: suggestion.item.originSiteId,
                },
            }}
            {...forwardedProps}
        />
    );
};
