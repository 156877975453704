import React from 'react';

import { margin, padding, useClassnames } from '@lumapps/classnames';
import { mdiSort } from '@lumapps/lumx/icons';
import { FlexBox, Icon, Size, Text, Theme } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { SORT_ORDER, SORT_ORDER_ICONS, SORT_ORDER_TRANSLATIONS } from '../../../constants';
import { SortersType } from '../../../types';

export interface SortersStatusProps {
    /** Display sort label */
    showSortLabel?: boolean;
    /** Display size variant */
    size?: Extract<Size, 's' | 'm'>;
    /** Sort value */
    sort?: SortersType;
    /** Order value */
    order?: SORT_ORDER;
    /** whether the "Sorted by" prefix is displayed on the button or not */
    shouldDisplaySortedByLabel?: boolean;
    theme?: Theme;
}

const CLASSNAME = 'lumx-sorters-status';
/**
 * Display an inert sort & order status.
 */
export const SortersStatus: React.FC<SortersStatusProps> = ({
    size,
    showSortLabel,
    sort,
    order,
    shouldDisplaySortedByLabel,
    theme,
}) => {
    const { translateAndReplace, translateKey, translate } = useTranslate();
    const { block } = useClassnames(CLASSNAME);

    let label: string | null = null;
    const sortLabel = translate(sort?.label);
    if (showSortLabel && sortLabel) {
        label = shouldDisplaySortedByLabel
            ? translateAndReplace(GLOBAL.SORTED_BY, { sort: sortLabel as string })
            : sortLabel;
    }

    return (
        <FlexBox
            orientation="horizontal"
            gap="regular"
            hAlign="center"
            className={block([size === Size.m ? padding('all', 'regular') : undefined])}
        >
            <Icon
                icon={mdiSort}
                size={size || Size.xs}
                alt={translateKey(GLOBAL.SORTING)}
                color="dark"
                colorVariant="L1"
                theme={theme}
            />

            {label ? (
                <Text as="span" typography={size === Size.m ? 'body1' : 'caption'} color="dark" colorVariant="L1">
                    <b>{label}</b>
                </Text>
            ) : null}

            {order ? (
                <Icon
                    icon={SORT_ORDER_ICONS[order]}
                    size={size || Size.xs}
                    alt={translateKey(SORT_ORDER_TRANSLATIONS[order])}
                    color="dark"
                    colorVariant="L1"
                    className={margin('left', 'regular')}
                    theme={theme}
                />
            ) : null}
        </FlexBox>
    );
};
