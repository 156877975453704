import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { Menu } from '@lumapps/lumx-menu/components/Menu';
import { REAL_SIZE_FOR_LUMX_SIZE } from '@lumapps/lumx/constants';
import { Placement, Offset } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { useTopBarUserMenuLinks } from '../../hooks/useTopBarUserMenuLinks';
import { USER } from '../../keys';
import { loginRoute } from '../../routes';
import { User } from '../../types';
import { UserAvatar } from '../UserAvatar';
import { UserMenu } from '../UserMenu';

import './index.scss';

export interface UserProfileDropdownProps {
    /** The dropdown placement */
    placement?: Placement;
    /** The dropdown offset */
    offset?: Offset;
    /** whether this component is visible or not */
    isVisible?: boolean;
    /** is user connected or is it a guest */
    isUserConnected?: boolean;
    /** connected user */
    user?: User;
}

const CLASSNAME = 'user-profile-dropdown';
const SCOPE = 'user-profile';

export const UserProfileDropdown: React.FC<UserProfileDropdownProps> = ({
    placement,
    offset = {},
    isVisible = true,
    isUserConnected,
    user,
}) => {
    const { element } = useClassnames(CLASSNAME);
    const { translateKey } = useTranslate();

    /** Should fetch user permissions only if dropdown is open */
    const [isUserMenuOpen, setIsUserMenuOpen] = React.useState(false);
    const { topBarUserMenuLinks } = useTopBarUserMenuLinks(isUserMenuOpen);

    if (!isVisible) {
        return null;
    }

    if (!isUserConnected) {
        return (
            <UserAvatar
                className={CLASSNAME}
                user={user}
                scope={SCOPE}
                avatarAs="link"
                linkUrl={loginRoute.path}
                tooltipProps={{
                    label: translateKey(USER.LOGIN_CLASSIC_SIGN_IN),
                }}
                alt={translateKey(USER.LOGIN_CLASSIC_SIGN_IN)}
            />
        );
    }

    if (user) {
        // Hide the tooltip if the menu is open
        const tooltipLabel = isUserMenuOpen ? null : translateKey(GLOBAL.VIEW_USER_PROFILE);

        return (
            <Menu.Trigger
                onToggle={setIsUserMenuOpen}
                popoverProps={{ placement, offset }}
                popoverWidth={{ minWidth: REAL_SIZE_FOR_LUMX_SIZE.xxl }}
            >
                <Menu.TriggerButton
                    as={UserAvatar}
                    tooltipProps={{
                        label: tooltipLabel,
                    }}
                    className={CLASSNAME}
                    user={user}
                    scope={SCOPE}
                    avatarAs="button"
                    alt={translateKey(GLOBAL.VIEW_USER_PROFILE)}
                />

                <Menu className={element('menu-content')}>
                    <UserMenu links={topBarUserMenuLinks} scope={SCOPE} user={user} />
                </Menu>
            </Menu.Trigger>
        );
    }

    return null;
};
