import { BASE_DIRECTORY_URL } from '@lumapps/directories-back-office/routes';
import { Route, AppId, createPageRoute } from '@lumapps/router';

export const directoryEntriesRoutes: Route = createPageRoute({
    slug: `${BASE_DIRECTORY_URL}/:id/entries`,
    legacyId: 'app.admin.new.directory',
    appId: AppId.backOffice,
});

export const directoryEntriesRoute = (id: string): Route => {
    const params = {
        id,
    };

    return {
        ...directoryEntriesRoutes,
        params,
    };
};
