import { Image } from '@lumapps/lumx-images/types';
import { getImageForThumbnail } from '@lumapps/lumx-images/utils';

import { ENTRY_TYPES } from './constants';
import { DirectoryEntry } from './types';

export const getDirectoryEntryImage = (entry: DirectoryEntry): Image | undefined => {
    const { thumbnail } = entry;

    return getImageForThumbnail(thumbnail);
};

export const getDirectoryEntryIcon = (entry?: Partial<DirectoryEntry>) => {
    if (entry?.type === ENTRY_TYPES.LINK) {
        return entry?.properties?.icon;
    }
    return entry?.resourceProperties?.componentSettings?.find((setting) =>
        Object.keys(setting).find((key) => key === 'icon'),
    )?.icon;
};
