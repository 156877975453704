import React from 'react';

import { useDataAttributes } from '@lumapps/data-attributes';
import { mdiAlertCircle, mdiInformation, mdiPencil } from '@lumapps/lumx/icons';
import { Button, Emphasis, Icon, Text } from '@lumapps/lumx/react';
import { useNotification } from '@lumapps/notifications/hooks/useNotifications';
import { useSelector } from '@lumapps/redux/react';
import { Link, useOnNavigation } from '@lumapps/router';
import { useTranslate } from '@lumapps/translations';
import type { ObjectValues } from '@lumapps/utils/types/ObjectValues';

import { CONTENT_PUBLICATION_STATUS_WITH_NOTIFICATION, ContentStatusWithNotification } from '../constants';
import { getCurrentContentPublicationStatus, isInDesignerMode } from '../ducks/selectors';
import { CONTENTS } from '../keys';
import { contentEdit } from '../routes';

const CLASSNAME = 'content-status-notification';

interface UseContentStatusNotificationParams {
    contentId?: string;
    /** Whether the notification can be displayed */
    isNotificationDisplayable: boolean;
}

export const useContentStatusNotification = ({
    contentId,
    isNotificationDisplayable = false,
}: UseContentStatusNotificationParams) => {
    const { translateKey } = useTranslate();
    const { get } = useDataAttributes(CLASSNAME);
    const { extended, remove } = useNotification();

    const isInDesigner = useSelector(isInDesignerMode);
    const contentPublicationStatus = useSelector(getCurrentContentPublicationStatus);

    const [currentNotificationId, setCurrentNotificationId] = React.useState<undefined | string>();
    const notificationId = React.useMemo(() => `${CLASSNAME}-${contentId}`, [contentId]);

    const isPublicationStatusRequireNotification =
        contentPublicationStatus &&
        Object.keys(CONTENT_PUBLICATION_STATUS_WITH_NOTIFICATION).includes(contentPublicationStatus);
    const contentStatusNotificationInfo = isPublicationStatusRequireNotification
        ? CONTENT_PUBLICATION_STATUS_WITH_NOTIFICATION[
              contentPublicationStatus as ObjectValues<ContentStatusWithNotification>
          ]
        : undefined;
    const shouldDisplayNotification =
        !!contentId && isNotificationDisplayable && !!contentStatusNotificationInfo && !isInDesigner;

    const removeContentStatusNotification = React.useCallback(() => {
        remove(notificationId);
        setCurrentNotificationId(undefined);
    }, [remove, notificationId]);

    const showContentStatusNotification = React.useCallback(() => {
        if (shouldDisplayNotification && !currentNotificationId) {
            setCurrentNotificationId(notificationId);
            extended({
                id: notificationId,
                title: translateKey(contentStatusNotificationInfo.title),
                onNavigationChange: removeContentStatusNotification,
                figure: (
                    <Icon
                        icon={
                            contentStatusNotificationInfo.notificationType === 'warning'
                                ? mdiAlertCircle
                                : mdiInformation
                        }
                        color={contentStatusNotificationInfo.notificationType === 'warning' ? 'yellow' : 'blue'}
                        size="m"
                    />
                ),
                content: (
                    <div style={{ textAlign: 'center' }}>
                        <Text as="p" typography="body1" color="dark">
                            {translateKey(contentStatusNotificationInfo.description)}
                        </Text>
                        <Text as="p" typography="body1" color="dark">
                            {translateKey(CONTENTS.STATUS_NOTIFICATION_DESCRIPTION)}
                        </Text>
                    </div>
                ),

                actions: (
                    <Button
                        leftIcon={mdiPencil}
                        emphasis={Emphasis.medium}
                        linkAs={Link}
                        to={contentEdit(contentId)}
                        {...get({ element: 'button', action: `edit-content-${contentPublicationStatus}` })}
                    >
                        {translateKey(CONTENTS.EDIT_THIS_CONTENT)}
                    </Button>
                ),
            });
        }
    }, [
        contentId,
        contentPublicationStatus,
        contentStatusNotificationInfo?.description,
        contentStatusNotificationInfo?.notificationType,
        contentStatusNotificationInfo?.title,
        currentNotificationId,
        extended,
        get,
        notificationId,
        shouldDisplayNotification,
        translateKey,
        removeContentStatusNotification,
    ]);

    // Display the notification on init
    React.useEffect(() => {
        showContentStatusNotification();
    }, [showContentStatusNotification]);

    /** Force the notification to be removed on isNotificationDisplayable going to false
     * Used for legacy route change
     */
    React.useEffect(() => {
        if (!isNotificationDisplayable) {
            removeContentStatusNotification();
        }
    }, [isNotificationDisplayable, removeContentStatusNotification]);

    // Display the notification on content link click (react context -> react context)
    useOnNavigation(() => {
        if (!currentNotificationId) {
            showContentStatusNotification();
        }
    }, [currentNotificationId]);
};
