import React from 'react';

import { classnames } from '@lumapps/classnames';
import { Theme, useTheme } from '@lumapps/lumx/react';
import type { ElementRender } from '@lumapps/wrex/types';

import { CODE_BLOCK } from '../../../constants';
import type { CodeBlockElement } from '../../../types';

import './index.scss';

export const CodeBlock: ElementRender<CodeBlockElement, HTMLPreElement> = (props) => {
    // eslint-disable-next-line unused-imports/no-unused-vars, @typescript-eslint/no-unused-vars
    const { children, className, elementRef, element, ...forwardedProps } = props;
    const theme = useTheme() || Theme.light;

    return (
        <pre
            {...forwardedProps}
            className={classnames(className, 'wrex-content-code-block', `wrex-content-code-block--theme-${theme}`)}
            ref={elementRef}
        >
            <code>{children}</code>
        </pre>
    );
};
CodeBlock.displayName = CODE_BLOCK;
