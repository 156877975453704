import React from 'react';

import {
    SelectTextField,
    type MultipleSelectTextFieldProps,
    type SelectTextFieldProps,
} from '@lumapps/combobox/components/SelectTextField/SelectTextField';
import { useDataAttributes } from '@lumapps/data-attributes';

import { FILTERS_MODE } from '../../constants';
import { UseListFilter } from '../../hooks/useListFilter';
import { Filter } from '../Filter';
import { FilterProps } from '../Filter/types';

import './index.scss';

export interface MultipleSelectFilterProps<T> extends FilterProps {
    /** list of options to display as list items */
    options: SelectTextFieldProps<T>['options'];
    /** onSelected callback, generated from the `useListFilter` hook */
    onSelectedMultiple: UseListFilter<T>['onSelectedMultiple'];
    /** isInSelection callback, generated from the `useListFilter` hook */
    selection: UseListFilter<T>['selection'];
    /** custom props for the Select field multiple component */
    selectTextFieldProps: Partial<MultipleSelectTextFieldProps<T>> & Pick<MultipleSelectTextFieldProps, 'autoFilter'>;
    /** callback when the select is open */
    onOpen?: () => void;
    /**
     * callback to be executed once there is a change in the filter,
     * useful for doing custom logic when a filter changes
     */
    onChange?: UseListFilter<T>['onSelectedMultiple'];
}

const CLASSNAME = 'multiple-select-filter';

/**
 * Filter that displays a list of options in a multi select fashion.
 *
 * @family Filters
 * @param MultipleSelectFilterProps
 * @returns MultipleSelectFilter
 */
export const MultipleSelectFilter = <T,>({
    options,
    onSelectedMultiple,
    selection,
    selectTextFieldProps,
    mode,
    onChange,
    ...props
}: MultipleSelectFilterProps<T>) => {
    const { get } = useDataAttributes('select-field-multiple');
    const isExposedFacet = mode === FILTERS_MODE.EXPOSED_FACETS;

    return (
        <Filter {...props} hideTitle mode={mode}>
            <SelectTextField<T>
                {...get({ element: 'input', action: 'search' })}
                selectionType="multiple"
                label={isExposedFacet || props.hideTitle ? undefined : props.title}
                aria-label={isExposedFacet || props.hideTitle ? `${props.title}` : undefined}
                options={options}
                onChange={(sel: T[]) => {
                    onSelectedMultiple(sel);

                    if (onChange) {
                        onChange(sel);
                    }
                }}
                value={selection}
                className={CLASSNAME}
                onOpen={!isExposedFacet ? props.onOpen : undefined}
                {...selectTextFieldProps}
            />
        </Filter>
    );
};
