import { classnames } from '@lumapps/classnames';

import { ICON } from '../helpers';

import './useSearchResultIconColor.scss';

const defaultBaseClassName = 'custom-icon';

export enum CustomMediaIconColor {
    GREEN = 'green',
    RED = 'red',
    BLUE = 'blue',
    ORANGE = 'orange',
    VIOLET = 'violet',
    YELLOW = 'yellow',
}

const CUSTOM_COLORS = (isContent?: boolean) => ({
    [ICON.GOOGLE_DOCS]: isContent ? undefined : CustomMediaIconColor.BLUE,
    [ICON.PDF]: CustomMediaIconColor.RED,
    [ICON.GOOGLE_SLIDES]: CustomMediaIconColor.ORANGE,
    [ICON.GOOGLE_SHEETS]: CustomMediaIconColor.GREEN,
    [ICON.GOOGLE_KEEP]: CustomMediaIconColor.YELLOW,
    [ICON.VIDEO]: CustomMediaIconColor.RED,
    [ICON.IMAGE]: CustomMediaIconColor.VIOLET,
    [ICON.MAIL]: CustomMediaIconColor.RED,
    [ICON.MICROSOFT_POWERPOINT]: CustomMediaIconColor.ORANGE,
    [ICON.MICROSOFT_EXCEL]: CustomMediaIconColor.GREEN,
    [ICON.MICROSOFT_WORD]: CustomMediaIconColor.BLUE,
});

interface UseSearchResultIconColorProps {
    /** icon for which color needs to be applied */
    icon?: string;
    /** color that should be forced on the icon. Will override the color that should be used by default if icon is also provided */
    customColor?: CustomMediaIconColor;
    /** Whether the document is a content or not */
    isContent?: boolean;
}

/**
 * Give a color and a classname for an icon either based on the icon or on a custom color.
 * If a custom color and an icon are provided together, classname will always be based on the custom color
 * To be used along with useSearchIconColor.scss file
 * @param icon name of the icon on which color needs to be applied
 * @param customColor color that need to be used to generate a className
 * @returns a color and a classname
 */
export const useSearchResultIconColor = ({ icon, customColor, isContent }: UseSearchResultIconColorProps) => {
    let iconColor;
    if (icon !== undefined) {
        iconColor = CUSTOM_COLORS(isContent)[icon];
    }

    const iconClassName = classnames(defaultBaseClassName, {
        [`${defaultBaseClassName}-${customColor || iconColor}`]: Boolean(customColor || iconColor),
    });

    return { iconColor, iconClassName };
};
