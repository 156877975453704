import React from 'react';

import { ComboboxOption } from '@lumapps/combobox/components/ComboboxOption';
import { mdiMagnify } from '@lumapps/lumx/icons';
import { Icon, Size } from '@lumapps/lumx/react';

import { SearchBoxOptionTypeProps } from '../../types';
import { SearchBoxOptionLabel } from '../SearchBoxOptionLabel';

/**
 * Default option for the search box
 * This is the component that will be displayed if no other condition is met.
 */
export const SearchBoxDefaultOption = ({ suggestion, ...forwardedProps }: SearchBoxOptionTypeProps) => {
    const label = suggestion.label || suggestion.query;
    return (
        <ComboboxOption
            before={<Icon icon={mdiMagnify} size={Size.s} />}
            textValue={label}
            data={suggestion}
            {...forwardedProps}
        >
            <SearchBoxOptionLabel label={label} />
        </ComboboxOption>
    );
};
