import React from 'react';

import { margin } from '@lumapps/classnames';
import { FlexBox, Orientation, Alignment } from '@lumapps/lumx/react';
import { UseSubscriptionStatuses } from '@lumapps/subscriptions/types';

import { MinimumViableUser } from '../../types';
import { FollowUserButton } from '../FollowUserButton/FollowUserButton';
import { MoreInformationLink, MoreInformationSkeleton } from './MoreInformationLink';

export interface UserCardActionsProps {
    /** Whether the user information are loading */
    userLoading: boolean;
    /** Whether to show the follow button */
    showFollow?: boolean;
    /** The current status of the follow button */
    useSubscriptionStatus?: UseSubscriptionStatuses;
    /** Whether the current user is subscribed to this user */
    isSubscribed?: boolean;
    /** Action to toggle the current user's subcription to this user.  */
    toggleSubscription?(): void;
    /** user to be displayed */
    user?: MinimumViableUser;
    /** Whether the follow button should be disabled */
    isFollowButtonDisabled?: boolean;
}

/**
 * The actions of the usercard.
 *
 * It should:
 * * show a link to the user's profile if the userId is defined.
 * * show a follow button if showFollow is true.
 */
export const UserCardActions: React.FC<UserCardActionsProps> = ({
    userLoading,
    showFollow,
    useSubscriptionStatus,
    isSubscribed,
    user,
    toggleSubscription,
    isFollowButtonDisabled,
}) => (
    <FlexBox vAlign={Alignment.center} hAlign={Alignment.center} orientation={Orientation.vertical}>
        {/* If we already have the user ID, show the link. If not and a loading is still in progress, show skeleton */}
        {user ? <MoreInformationLink user={user} /> : userLoading && <MoreInformationSkeleton />}

        {showFollow && (
            <FollowUserButton
                className={margin('top')}
                status={useSubscriptionStatus as UseSubscriptionStatuses}
                isSubscribed={isSubscribed as boolean}
                onClick={toggleSubscription}
                isDisabled={isFollowButtonDisabled}
            />
        )}
    </FlexBox>
);
