import React from 'react';

import { RadioButton, RadioButtonProps, RadioGroup } from '@lumapps/lumx/react';

import { UseSingleSelectFilter } from '../../hooks/useSingleSelectFilter';
import { Filter } from '../Filter';
import { FilterProps } from '../Filter/types';

export interface RadioFilterProps<T> extends FilterProps {
    /** list of options to display as radio */
    options: T[];
    /** determines how to render the react node for the radios */
    renderRadio: (entity: T) => React.ReactNode;
    /** onSelected callback, generated from the `useSingleSelectFilter` hook */
    onSelected: UseSingleSelectFilter<T>['onSelected'];
    /** isInSelection callback, generated from the `useSingleSelectFilter` hook */
    isInSelection: UseSingleSelectFilter<T>['isInSelection'];
    /** custom props for the rendered radio */
    radioProps?: (entity: T) => Partial<RadioButtonProps>;
    /** Callback to get the id for each filter */
    getId?: (entity: T) => string;
}

/**
 * Component that renders a radio filter, used in the `@lumapps/lumx-filter-and-sort` pattern.
 * Commonly used for single choice among several options.
 *
 * @family Filters
 * @param RadioFilterProps
 * @returns RadioFilter
 */
export const RadioFilter = <T,>({
    options,
    getId,
    renderRadio,
    isInSelection,
    onSelected,
    radioProps = () => ({}),
    ...props
}: RadioFilterProps<T>) => {
    return (
        <Filter {...props} isFieldset>
            <RadioGroup>
                {options.map((option, index) => (
                    <RadioButton
                        key={getId ? getId(option) : index}
                        isChecked={isInSelection?.(option)}
                        label={renderRadio(option)}
                        onChange={() => {
                            onSelected(option);
                        }}
                        value={getId ? getId(option) : (option as string)}
                        {...radioProps(option)}
                    />
                ))}
            </RadioGroup>
        </Filter>
    );
};
