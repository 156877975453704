import BaseApi, { PRIORITY } from '@lumapps/base-api';
import { CACHE_TYPE } from '@lumapps/cache';
import { getActionsHistory } from '@lumapps/quick-search/utils/cacheUtils';
import { queueTrackSearch } from '@lumapps/search/api/analytics';

import { DEFAULT_MAX_RECOMMENDATIONS, LUMAPPS_RECO_ORIGIN } from '../constants';
import { FetchRecommendationsParams, FetchRecommendationsResponse } from '../types';

export const recommendationsApi = new BaseApi({ version: BaseApi.versions.v2, path: 'search' });

export const fetchRecommendations = async ({
    maxResults = DEFAULT_MAX_RECOMMENDATIONS,
    lang,
    cause,
    context,
    instanceId,
    skipAnalyticsOnFetch,
    query,
}: FetchRecommendationsParams) => {
    const response = await recommendationsApi.getCacheFirst<FetchRecommendationsResponse>(
        'recommendations',
        CACHE_TYPE.MEMORY,
        PRIORITY.HIGH,
        {
            params: {
                maxResults,
                lang,
            },
        },
    );
    const { splitTestRunName, splitTestRunVersion, resultCountExact, page, responseTime, searchQueryUid } =
        response.data;
    if (!skipAnalyticsOnFetch) {
        // Send Search analytics
        queueTrackSearch({
            splitTestRunName,
            splitTestRunVersion,
            language: lang,
            actionCause: cause,
            numberOfResults: resultCountExact,
            queryText: query || '',
            pageNumber: page,
            responseTime,
            searchQueryUid,
            context,
            actionsHistory: getActionsHistory(instanceId),
            originLevel2: LUMAPPS_RECO_ORIGIN,
        });
    }
    return response;
};
