import { getActionsHistory, storeActionsHistory } from '@lumapps/quick-search/utils/cacheUtils';

import { MAX_ACTION_HISTORY_STORED } from '../constants';
import { SearchActions } from '../types';

/**
 * Add an entry to the search history
 * @param query the user query
 */
export const addActionHistory = (item: SearchActions, siteId: string) => {
    if (!item.value) {
        return;
    }

    const actionsHistory = getActionsHistory(siteId);

    actionsHistory.unshift({
        time: item.time,
        name: item.name,
        value: item.value,
    });

    // Handle max results by deleting the excess
    if (actionsHistory.length > MAX_ACTION_HISTORY_STORED) {
        actionsHistory.splice(MAX_ACTION_HISTORY_STORED);
    }

    storeActionsHistory(actionsHistory, siteId);
};
