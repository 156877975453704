import type { Layout, WidgetReference, Row } from '@lumapps/widget-layout/types';

/**
 * This should be removed once Mandatory widget migrated to LAYOUT v2.
 * The layout endpoint should have info about widgets in the page, avoiding this infernal loop.
 * @param mainRow Template row
 * @returns true if a widget is found
 */
export const hasMandatoryReadWidget = (mainRow: Layout['components']): boolean => {
    for (const row of mainRow) {
        if (!row.cells) {
            return false;
        }

        for (const cell of row.cells) {
            for (const component of cell.components) {
                if (component.type === 'row') {
                    if (hasMandatoryReadWidget([component as Row])) {
                        return true;
                    }
                } else if ((component as WidgetReference).widgetType === 'mandatory-read') {
                    return true;
                }
            }
        }
    }

    return false;
};
