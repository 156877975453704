import React from 'react';

import { initStore, useInitializeApplication } from '@apps/core';
import { createPortal } from 'react-dom';

import { RouteAnnouncer } from '@lumapps/a11y/components/RouteAnnouncer';
import { usePublicUniqueId } from '@lumapps/analytics-tracking/hooks/usePublicUniqueId';
import { GlobalLiveMessage } from '@lumapps/aria-live/components/GlobalLiveMessage';
import { createQueryClient, QueryClientProvider, ReactQueryDevtools } from '@lumapps/base-api/react-query';
import { get } from '@lumapps/constants';
import { Customizations } from '@lumapps/customizations';
import { CustomizationComponent, Targets, PLACEMENT } from '@lumapps/customizations/api';
import { AdvancedDebugInfo } from '@lumapps/debug';
import { DigitalAssistant } from '@lumapps/digital-assistant/components/DigitalAssistant';
import { Footer } from '@lumapps/footer';
import { GdprBanner } from '@lumapps/gdpr-banner';
import { Header } from '@lumapps/header';
import { Metrics } from '@lumapps/metric';
import { GoogleAnalyticsAndTagManager } from '@lumapps/metric/components/GoogleAnalyticsAndTagManager';
import { MobileAppModal } from '@lumapps/mobile-app-modal';
import { NannyDetails } from '@lumapps/nanny-details/components/DetailModal';
import { NotificationStack } from '@lumapps/notifications';
import { Provider } from '@lumapps/redux/react';
import { BrowserRouter as Router, getBaseUrl } from '@lumapps/router';
import { ConnectedPageTransition } from '@lumapps/router/components/PageTransition';
import { TermsAndConditions } from '@lumapps/terms-and-conditions/components/Modal';
import { TranslationsProvider } from '@lumapps/translations';
import { ErrorBoundary } from '@lumapps/utils/components/ErrorBoundary';

import { rootReducer, transforms } from '../../ducks';
import Loading from '../Loading';
import Pages from '../Pages';
import { NO_SLIDESHOW_PAGES } from '../Pages/configuration';

/** Add html attribute on element focus by keyboard (required for LumX focus style). */
import 'focus-visible';

const { isDev } = get();

declare const window: any;

// Create a React Query client
const queryClient = createQueryClient();

/**
 * Main entrypoint of the front office application. It takes care of setting up:
 * - initial state of the application and setting up redux
 */
const App = () => {
    const { initialState, translationsState } = useInitializeApplication();

    /** Set a LUMAPPS-PUBLIC-UNIQUE-ID in the localStorage */
    usePublicUniqueId(initialState);

    /**
     * Since the initial state and the translations are blocking for rendering, while they are not here,
     * we want to show a Loading component in order to provide some feedback to the user
     */
    if (!initialState && !translationsState) {
        return <Loading />;
    }

    window.lumappsPerformances.loaderHidden = Date.now();

    /**
     * We retrieve the base URL that includes the customer and the instance slug
     * and use it as the base path for the react-router implementation
     */
    const baseUrl = getBaseUrl(window.location.pathname);
    const store = initStore(rootReducer, initialState, transforms);

    return (
        <ErrorBoundary fallback={<Loading />}>
            <QueryClientProvider client={queryClient}>
                <Provider store={store}>
                    <ConnectedPageTransition />
                    <Metrics />
                    <GlobalLiveMessage />
                    <TranslationsProvider value={translationsState}>
                        {/*
                         * The GDPR banner and notification must be the first element of the DOM for a11y purposes
                         * as users might want to interact with them before navigating the application.
                         */}
                        <GdprBanner />
                        <Router basename={baseUrl}>
                            <NotificationStack />
                            <GoogleAnalyticsAndTagManager />

                            <Customizations>
                                <CustomizationComponent target={Targets.APP} placement={PLACEMENT.LEFT} />

                                <Header hideContentHeaderRoutes={NO_SLIDESHOW_PAGES} />

                                <Pages basePath={baseUrl} />
                                <Footer />

                                <CustomizationComponent target={Targets.APP} placement={PLACEMENT.UNDER} />
                                <CustomizationComponent target={Targets.APP} placement={PLACEMENT.RIGHT} />

                                <AdvancedDebugInfo showModes showFeatureFlags />

                                <DigitalAssistant />
                            </Customizations>

                            <TermsAndConditions />
                            <MobileAppModal />
                            <NannyDetails />
                            <RouteAnnouncer />
                        </Router>
                    </TranslationsProvider>
                </Provider>

                {/**
                 * ℹ️ Even though Devtools are excluded in production builds (@see https://tanstack.com/query/latest/docs/react/devtools#devtools-in-production)
                 * avoid creating an unnecessary portal in production builds
                 */}
                {isDev && createPortal(<ReactQueryDevtools />, document.body)}
            </QueryClientProvider>
        </ErrorBoundary>
    );
};

export { App };
