import { FocusPoint } from '@lumapps/lumx/react';
import type { WithComments, WithReactions } from '@lumapps/reactions-core/api/v2/types';
import { EditedFeaturedImage, StructuredContent } from '@lumapps/structured-content/types';
import { TranslateObject } from '@lumapps/translations';
import { UserReference } from '@lumapps/widget-base/types';
import { SlateStructuredContent } from '@lumapps/wrex/serialization/dita/types';
import type { Wrex } from '@lumapps/wrex/types';

import { Article as APIArticle, ArticleLayout } from './api/types';

/** Sorting options that can be given to the API to retrieve articles */
export enum ARTICLES_SORTING {
    CREATED_ASC = 'createdAt',
    CREATED_DESC = '-createdAt',
}

export type Article = Partial<APIArticle>;

export interface EditedArticleState {
    articleId: string;
    structuredContentId: string;
    lang: string;
    translations: TranslateObject<SlateStructuredContent>;
    shares?: Article['shares'];
}

export interface ArticlesState {
    /** Currently edited article in article dialog. */
    editedArticle?: EditedArticleState;
    /** Is article dialog open. */
    isArticleDialogOpen?: boolean;
    /** Is article dialog publishing. */
    isArticleDialogPublishing?: boolean;
    /** Is article dialog on a read only state. */
    isArticleDialogReadOnly?: boolean;
    /** Data passed when a user wants to convert a post to an article */
    articleFromConversion?: {
        /** The converted article data (used on convert post to article) */
        convertedArticle: { title?: SlateStructuredContent['title']; children?: SlateStructuredContent['children'] };
        /** Callback when the conversion to an article is confirmed */
        onConversionConfirm: () => void;
    };
    /** Currently displayed article on the article view page. */
    current?: ArticleLayout;
    /* list of article ids to fetch details */
    articleBatchIds?: string[];
    /** List of articles records by ID */
    entities?: Record<string, Article | null>;
}
export interface ArticlePreviewState {
    /* list of article ids to fetch details */
    articleBatchIds?: string[];
    /** List of articles records by ID */
    entities?: Record<string, Article | null>;
}

export interface PublishArticleParams {
    children: Wrex.Nodes;
    title: string;
    featuredImage?: EditedFeaturedImage;
}

export interface CommunityNavArticle {
    title: TranslateObject;
    id: string;
    structuredContent?: StructuredContent;
    url?: string;
}

export interface GetArticlesParams {
    communityIds?: string[];
    ids?: string[];
    title?: string;
    cursor?: string;
    maxResults?: number;
    sort?: ARTICLES_SORTING;
    siteIds?: string[];
}

export interface ArticlePreview extends WithReactions, WithComments {
    id: string;
    title: string;
    image?: string;
    description?: string;
    status: 'draft' | 'published' | 'unpublished' | 'deleted';
    focalPoint?: FocusPoint;
    author?: UserReference;
}
