import { AppId } from '@lumapps/constants/app';
import { createPageRoute, Route } from '@lumapps/router';

const learningRoute: Route = createPageRoute({
    slug: 'learning',
    appId: AppId.frontOffice,
});

const learningManagerRoute: Route = createPageRoute({
    slug: 'learning-manager',
    appId: AppId.frontOffice,
});

export const webviewLearningRoute: Route = createPageRoute({
    slug: 'learning',
    appId: AppId.webview,
});

export const webviewLearningManagerRoute: Route = createPageRoute({
    slug: 'learning-manager',
    appId: AppId.webview,
});

const learningView = (contentType: string): Route => ({
    ...learningRoute,
    params: {
        type: contentType,
    },

    mobilePageDescriptor: {
        type: 'learning',
        id: contentType,
    },
});

const learningManagerView = (contentType: string): Route => ({
    ...learningManagerRoute,
    params: {
        type: contentType,
    },

    mobilePageDescriptor: {
        type: 'learning-manager',
        id: contentType,
    },
});

const webviewLearningManagerView = (contentType: string): Route => ({
    ...webviewLearningManagerRoute,
    params: {
        type: contentType,
    },

    mobilePageDescriptor: {
        type: 'learning-manager',
        id: contentType,
    },
});

export { learningView, learningRoute, learningManagerView, learningManagerRoute, webviewLearningManagerView };
