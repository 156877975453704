/* istanbul ignore file */
import React, { Suspense } from 'react';

/*
 * Exceptionally, the content page is not loaded lazily since we managed to make it tiny
 * (3kb + lazy loading widgets) and because this page is one of the most visited page of the app.
 */
import { ContentPage } from '@lumapps/contents/components/ContentPage';
import { contentViewRoute, legacyContentViewRoute } from '@lumapps/contents/routes';
import { ErrorPage } from '@lumapps/lumx-front-office/components/ErrorPage';
import { Page } from '@lumapps/lumx-front-office/components/Page';
import { mdiHome } from '@lumapps/lumx/icons';
import { BrowserRoute, Switch, notFoundRoute, errorRoute, useRouteMatching } from '@lumapps/router';
import { AppId, LOADING_PAGE_ID } from '@lumapps/router/constants';
import { ErrorBoundary } from '@lumapps/utils/components/ErrorBoundary';

import { ALTERNATIVE_BACKGROUND_COLOR_PAGES, ALTERNATIVE_BACKGROUND_CLASS, REDIRECTS } from './configuration';
import { PagesConfig } from './pages-config';

const PagesConfigKeys = Object.keys(PagesConfig);

const Loading = () => <Page title="" isLoading id={LOADING_PAGE_ID} />;

/**
 * This is the main entry point for the different pages of the application
 * Here we must map a Route with a specific react component, so that when
 * that route is accessed, we get redirected to that page.
 *
 */
const Pages = ({ basePath = '' }) => {
    const doesOverrideApplicationBackground = useRouteMatching({
        path: ALTERNATIVE_BACKGROUND_COLOR_PAGES,
        appId: AppId.frontOffice,
    });

    React.useEffect(() => {
        const html = document.querySelector('html');

        /**
         * Unfortunately since the background color of the entire page is defined on the html tag of the page,
         * and that tag is not rendered by React, we need to update that background color by hand using vanilla JS.
         *
         * We are doing this by applying a CSS class on the HTML of the page. If the current page wants to override
         * the background color of the app and the HTML does not have the class, we add it. If the current page does not want to override
         * the background color of the app and the HTML has the class, we remove it
         */
        if (html && html.classList) {
            const hasAlternativeBackground = html.classList.contains(ALTERNATIVE_BACKGROUND_CLASS);

            if (doesOverrideApplicationBackground && !hasAlternativeBackground) {
                html.classList.add(ALTERNATIVE_BACKGROUND_CLASS);
            } else if (!doesOverrideApplicationBackground && hasAlternativeBackground) {
                html.classList.remove(ALTERNATIVE_BACKGROUND_CLASS);
            }
        }
    }, [doesOverrideApplicationBackground]);

    return (
        <ErrorBoundary fallback={<ErrorPage code={500} shouldShowIcon shouldShowAction />}>
            <Suspense fallback={<Loading />}>
                <Switch>
                    <BrowserRoute path={errorRoute.path}>
                        <ErrorPage code={500} shouldShowIcon shouldShowAction />
                    </BrowserRoute>

                    <BrowserRoute path={notFoundRoute.path}>
                        <ErrorPage
                            code={404}
                            shouldShowIcon
                            buttonProps={{ href: basePath, leftIcon: mdiHome }}
                            shouldShowAction
                        />
                    </BrowserRoute>

                    {REDIRECTS.map((redirect) => (
                        <BrowserRoute {...redirect} key={redirect.key} />
                    ))}

                    {PagesConfigKeys.map((key) => {
                        const PageConfig = PagesConfig[key];

                        return (
                            <BrowserRoute key={key} path={key}>
                                <PageConfig />
                            </BrowserRoute>
                        );
                    })}

                    <BrowserRoute path={contentViewRoute.path}>
                        <ContentPage />
                    </BrowserRoute>
                    <BrowserRoute path={legacyContentViewRoute.path}>
                        <ContentPage />
                    </BrowserRoute>
                </Switch>
            </Suspense>
        </ErrorBoundary>
    );
};

export default Pages;
