import { lumappsJourney } from '@lumapps/lumx/custom-icons';
import { mdiCellphone, mdiEmailOutline } from '@lumapps/lumx/icons';
import { GLOBAL } from '@lumapps/translations';

import { CHANNEL_TYPES, SCHEDULE_OFFSET_DIRECTION, SCHEDULE_OFFSET_UNIT } from './types';

export const JOURNEYS_ICON = lumappsJourney;

export const JOURNEYS_FF = 'journey-journey';
/** Feature flag used for under development features */
export const JOURNEYS_ALPHA_FF = 'journey-alpha';
/** Feature flag used for features ready but non available for customers */
export const JOURNEYS_BETA_FF = 'journey-beta';

/**
 * Configuration to be used for displaying channel with their associated
 * icons and labels.
 */
export const CHANNEL_CONFIG = {
    icons: {
        [CHANNEL_TYPES.EMAIL]: mdiEmailOutline,
        [CHANNEL_TYPES.MOBILE_PUSH]: mdiCellphone,
    },
    labels: {
        [CHANNEL_TYPES.EMAIL]: GLOBAL.EMAIL,
        [CHANNEL_TYPES.MOBILE_PUSH]: GLOBAL.MOBILE_PUSH,
    },
};

/* Object operators for Unit and Operator */
export const offsetOperatorsMapper = {
    [SCHEDULE_OFFSET_UNIT.DAYS]: GLOBAL.DAYS,
    [SCHEDULE_OFFSET_UNIT.WEEKS]: GLOBAL.WEEKS,
    [SCHEDULE_OFFSET_DIRECTION.BEFORE]: GLOBAL.BEFORE,
    [SCHEDULE_OFFSET_DIRECTION.AFTER]: GLOBAL.AFTER,
};

/**
 * The basic dateOffset of a scheduleConfiguration object
 * when related to a baseline date but not on the same day
 * */
export const BASE_DATE_OFFSET = {
    unit: SCHEDULE_OFFSET_UNIT.DAYS,
    value: 0,
    direction: SCHEDULE_OFFSET_DIRECTION.AFTER,
} as const;

/**
 * Form's translatable fields to be used to update the source language
 */
export const FORM_TRANSLATABLE_FIELDS: Record<string, { id: string; path: string }> = {
    resourceTitle: {
        id: 'resourceTitle',
        path: 'attachment.title',
    },
    resourceDescription: {
        id: 'resourceDescription',
        path: 'attachment.description',
    },
    senderName: {
        id: 'senderName',
        path: 'senderName',
    },
    emailSubject: {
        id: 'emailSubject',
        path: 'subject',
    },
    emailMessage: {
        id: 'emailMessage',
        path: 'message',
    },
    richText: {
        id: 'richText',
        path: 'richText',
    },
};

export const SEGMENT_MAX_LIMIT = 20;

export const SENDER_NAME_MAX_LENGTH = 50;
export const SUBJECT_MAX_LENGTH_MOBILE_PUSH = 35;
export const SUBJECT_MAX_LENGTH_CHANNELS = 100;
export const MESSAGE_MAX_LENGTH_MOBILE_PUSH = 240;

/** Rich text wrapper and message background color */
export const DEFAULT_WRAPPER_BACKGROUND_COLOR = '#F3F3F3';
export const DEFAULT_MESSAGE_BACKGROUND_COLOR = '#FFFFFF';
