import React from 'react';

// eslint-disable-next-line lumapps/do-not-import-react-query
import {
    type QueryKey as BaseQueryKey,
    useInfiniteQuery,
    useQueryClient,
    type UseInfiniteQueryOptions as BaseUseInfiniteQueryOptions,
    type UseInfiniteQueryResult as BaseUseInfiniteQueryResult,
    InfiniteData,
} from '@tanstack/react-query';

import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import { BaseApiError, ServerListResponse } from '../types';
import { getStatusFromInfiniteQuery } from './utils';

export type UseInfiniteQueryOptions<
    TQueryFnData = unknown,
    TError = BaseApiError,
    TData = TQueryFnData,
    TQueryData = TQueryFnData,
    TQueryKey extends BaseQueryKey = BaseQueryKey,
> = Pick<
    BaseUseInfiniteQueryOptions<TQueryFnData, TError, TData, TQueryData, TQueryKey>,
    'enabled' | 'cacheTime' | 'staleTime'
>;

export type UseInfiniteQueryResult<TData = unknown, TError = BaseApiError> = BaseUseInfiniteQueryResult<
    TData,
    TError
> & {
    /** retrieve the base loading status for the current query */
    baseLoadingStatus: BaseLoadingStatus;
    /** list of items returned by the API call */
    items: TData extends ServerListResponse<infer TItem> ? TItem[] : never;
};

/**
 * React Query Use Infinite Query, used when doing API calls to services that retrieve a paginated list of items.
 * Please take a look at the examples on how to implement it.
 *
 * @example
 * import { useInfiniteQuery } from '@lumapps/base-api/react-query';
 *
 * import { getAskAiConcepts, askAiConceptsQueryKeys } from '../api';
 * import type { GetAskAiConceptsParams } from '../types';
 *
 * export const useAskAiConcepts = (params: GetAskAiConceptsParams) => {
 *     return useInfiniteQuery({
 *       queryKey: askAiConceptsQueryKeys.getConcepts(params),
 *       queryFn: ({ pageParam, signal }) => getAskAiConcepts({ ...params, cursor: pageParam }, signal),
 *       getNextPageParam: (lastPage) => lastPage?.more && lastPage?.cursor,
 *     });
 * };
 *
 * @param options
 * @returns UseInfinityQueryResult
 */
export const useCustomUseInfiniteQuery = <
    TQueryFnData = unknown,
    TError = BaseApiError,
    TData = TQueryFnData,
    TQueryKey extends BaseQueryKey = BaseQueryKey,
>(
    options: BaseUseInfiniteQueryOptions<TQueryFnData, TError, TData, TQueryFnData, TQueryKey>,
): UseInfiniteQueryResult<TData, TError> => {
    const queryClient = useQueryClient();

    // Get the defaulted options, which also allows us to have a stable query hash
    const defaultedOptions = queryClient.defaultQueryOptions(options);
    // Get the query hash to have a stable key and the stale time
    const { queryHash, staleTime = 0 } = defaultedOptions;

    /**
     * By default, react-query fetches all pages sequentially when the query becomes stale
     * This is not a behavior that we want because it can be resource intensive for our infrastructure.
     *
     * Here, when the hook is unmounted, we modify the cache to keep only the first page in it. This way,
     * when we refetch the data, we are fetching only the first page.
     *
     * @see https://tanstack.com/query/v4/docs/react/guides/infinite-queries#what-happens-when-an-infinite-query-needs-to-be-refetched
     * @see https://github.com/TanStack/query/discussions/1670
     * @see https://github.com/TanStack/query/discussions/3576
     */
    React.useEffect(() => {
        // Parse the query hash to convert to array
        let key: TQueryKey | undefined;
        try {
            key = queryHash ? JSON.parse(queryHash) : undefined;
        } catch (error) {
            key = undefined;
        }

        /**
         * Find the query with the same query hash to find how many observers it currently have
         */
        const queryCache = queryClient.getQueryCache();
        const query = queryCache.getAll().find((query) => query.queryHash === queryHash);
        const observerCount = query?.getObserversCount() || 0;

        /**
         * Remove all pages except the first from cache.
         */
        const invalidateAllNextPages = () => {
            /** Don't invalidate if at least one observer is remaining */
            if (observerCount > 0 || !key || !query) {
                return;
            }
            /**
             * Check if the query should be considered as stale.
             */
            const { dataUpdatedAt = 0 } = query.state || {};
            // The date at which the query should be considered stale
            const staleDate = dataUpdatedAt + staleTime;
            /**
             * A query should be considered stale if
             * * there has been at least one fetch
             * * No stale time has been set (always stale)
             * * The current time is bigger than the stale date.
             */
            const isStale = dataUpdatedAt > 0 ? !staleTime || Date.now() >= staleDate : false;

            if (isStale) {
                setTimeout(() => {
                    queryClient.setQueryData<InfiniteData<TData>>(key, (prev) => ({
                        pages: prev?.pages.slice(0, 1) || [],
                        pageParams: prev?.pageParams.slice(0, 1) || [],
                    }));
                });
            }
        };

        invalidateAllNextPages();

        return () => {
            invalidateAllNextPages();
        };
    }, [queryClient, queryHash, staleTime]);

    const infiniteQuery = useInfiniteQuery(options);

    return React.useMemo(() => {
        // eslint-disable-next-line deprecation/deprecation
        const baseLoadingStatus = getStatusFromInfiniteQuery(infiniteQuery);
        // casting as any so that the type can be inferred afterwards
        const items: any = infiniteQuery.data?.pages.flatMap((page: any) => page.items) || [];

        return {
            ...infiniteQuery,
            baseLoadingStatus,
            items,
        };
    }, [infiniteQuery]);
};

export { useCustomUseInfiniteQuery as useInfiniteQuery };
