/* istanbul ignore file */
import get from 'lodash/get';
import includes from 'lodash/includes';
import memoize from 'lodash/memoize';

import { isFeatureEnabled } from '@lumapps/features';
import { isSuperAdmin } from '@lumapps/permissions';
import { createSelector } from '@lumapps/redux/reselect';
import { BaseStore } from '@lumapps/redux/types';
import { SocialNetworks } from '@lumapps/sa-social-networks/types';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import { ROLES, PERMISSIONS, SA_FEATURE_NAME } from '../constants';

const isSAEnabled = isFeatureEnabled(SA_FEATURE_NAME);

const internalSaConnectedUserSelector = (state: BaseStore) => state.saConnectedUser || {};

const saConnectedUserSelector = createSelector(internalSaConnectedUserSelector, (saConnectedUser) => saConnectedUser);

const saUserLoadingStatusSelector = createSelector(saConnectedUserSelector, (state) => state.saUserLoadingStatus);

const hasSocialNetworkAccessesLoadedSelector = createSelector(saConnectedUserSelector, (state) => {
    return state.hasSocialNetworkAccessesLoaded;
});

const hasLoadedSelector = createSelector(
    saUserLoadingStatusSelector,
    (saUserLoadingStatus) => saUserLoadingStatus === BaseLoadingStatus.idle,
);

const selectCurrentUser = createSelector(saConnectedUserSelector, (state) => state.user);

/** PROGRAMS SELECTORS */

const canAccessSASelector = createSelector([selectCurrentUser], (currentUser) => currentUser?.canAccessSA || false);

const canManagePlaftormPrograms = createSelector(
    [isSAEnabled, isSuperAdmin],
    (SAEnabled, superAdmin) => SAEnabled && superAdmin,
);

const canManagePlatformSocialNetworks = canManagePlaftormPrograms;

/** LEGACY SELETORS */

const selectCurrentUserRoles = createSelector(selectCurrentUser, (currentUser) => get(currentUser, 'roles', []));

const selectCurrentUserPermissions = createSelector(selectCurrentUser, (currentUser) =>
    get(currentUser, 'permissions', []),
);

const isAdmin = createSelector(selectCurrentUserRoles, (currentUserRoles) => includes(currentUserRoles, ROLES.ADMIN));

const isAmbassador = createSelector(selectCurrentUserRoles, (currentUserRoles) =>
    includes(currentUserRoles, ROLES.AMBASSADOR),
);

const isProgramManager = createSelector(selectCurrentUserRoles, (currentUserRoles) =>
    includes(currentUserRoles, ROLES.PROGRAM_MANAGER),
);

const canAccessAllSharesData = createSelector(
    [isSAEnabled, isSuperAdmin, selectCurrentUserPermissions],
    (SAEnabled, SuperAdmin, currentUsersPermissions) =>
        SAEnabled && (SuperAdmin || includes(currentUsersPermissions, PERMISSIONS.CAN_ACCESS_ALL_SHARES_DATA)),
);

const canAccessSocialAdvocacyBasicFeatures = createSelector(
    [isSAEnabled, isSuperAdmin, selectCurrentUserPermissions],
    (SAEnabled, SuperAdmin, currentUsersPermissions) =>
        SAEnabled &&
        (SuperAdmin || includes(currentUsersPermissions, PERMISSIONS.CAN_ACCESS_SOCIAL_ADVOCACY_BASIC_FEATURES)),
);

const canManageShareableContents = createSelector(
    [isSAEnabled, isSuperAdmin, selectCurrentUserPermissions],
    (SAEnabled, SuperAdmin, currentUsersPermissions) =>
        SAEnabled && (SuperAdmin || includes(currentUsersPermissions, PERMISSIONS.CAN_MANAGE_SHAREABLE_CONTENTS)),
);

const canManageSocialAdvocacyAmbassadors = createSelector(
    [isSAEnabled, isSuperAdmin, selectCurrentUserPermissions],
    (SAEnabled, SuperAdmin, currentUsersPermissions) =>
        SAEnabled &&
        (SuperAdmin || includes(currentUsersPermissions, PERMISSIONS.CAN_MANAGE_SOCIAL_ADVOCACY_AMBASSADORS)),
);

const canManageSocialAdvocacyProgramManagers = createSelector(
    [isSAEnabled, isSuperAdmin, selectCurrentUserPermissions],
    (SAEnabled, SuperAdmin, currentUsersPermissions) =>
        SAEnabled &&
        (SuperAdmin || includes(currentUsersPermissions, PERMISSIONS.CAN_MANAGE_SOCIAL_ADVOCACY_PROGRAM_MANAGERS)),
);

const canManageSocialAdvocacyTopics = createSelector(
    [isSAEnabled, isSuperAdmin, selectCurrentUserPermissions],
    (SAEnabled, SuperAdmin, currentUsersPermissions) =>
        SAEnabled && (SuperAdmin || includes(currentUsersPermissions, PERMISSIONS.CAN_MANAGE_SOCIAL_ADVOCACY_TOPICS)),
);

const canShareShareableContents = createSelector(
    [isSAEnabled, isSuperAdmin, selectCurrentUserPermissions],
    (SAEnabled, SuperAdmin, currentUsersPermissions) =>
        SAEnabled && (SuperAdmin || includes(currentUsersPermissions, PERMISSIONS.CAN_SHARE_SHAREABLE_CONTENTS)),
);

const shouldGetUser = createSelector(
    [saUserLoadingStatusSelector, hasLoadedSelector],
    (saUserLoadingStatus, hasLoaded) => saUserLoadingStatus === BaseLoadingStatus.initial && !hasLoaded,
);

/** SOCIAL NETWORK ACCESSES */

const getAllSocialNetworksData = createSelector(saConnectedUserSelector, (state) => state.socialNetworkAccesses || []);

const getSingleSocialNetworkData = createSelector(getAllSocialNetworksData, (allSocialNetworksData) =>
    memoize(
        (socialNetwork: SocialNetworks) =>
            allSocialNetworksData.find(
                ({ socialNetworkId }: { socialNetworkId: SocialNetworks }) => socialNetworkId === socialNetwork,
            ) || { userName: '', profilePictureUrl: '' },
    ),
);

export {
    isSAEnabled,
    saConnectedUserSelector,
    saUserLoadingStatusSelector,
    hasSocialNetworkAccessesLoadedSelector,
    shouldGetUser,
    selectCurrentUser,
    isAdmin,
    isAmbassador,
    isProgramManager,
    canManagePlaftormPrograms,
    canAccessAllSharesData,
    canAccessSocialAdvocacyBasicFeatures,
    canManageShareableContents,
    canManageSocialAdvocacyAmbassadors,
    canManageSocialAdvocacyProgramManagers,
    canManageSocialAdvocacyTopics,
    canManagePlatformSocialNetworks,
    canShareShareableContents,
    getAllSocialNetworksData,
    getSingleSocialNetworkData,
    canAccessSASelector,
    hasLoadedSelector,
};
