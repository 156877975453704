import { Route, createPageRoute } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

export const contentStreamsAdminRoutes: Route = createPageRoute({
    slug: 'content-streams',
    legacyId: 'app.admin.mobile-content-streams',
    appId: AppId.backOffice,
});

export const contentStreamsAdmin = (): Route => {
    return contentStreamsAdminRoutes;
};
