import React from 'react';

import { classnames } from '@lumapps/classnames';
import type { ElementRender } from '@lumapps/wrex/types';

import { LIST_ITEM } from '../../../constants';
import { ListItemElement } from '../../../types';

import './index.scss';

export const ListItem: ElementRender<ListItemElement, HTMLLIElement> = (props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
    const { children, className, elementRef, element, ...forwardedProps } = props;
    const style = element?.markerColor ? { ['--_marker-color' as string]: element.markerColor } : undefined;

    return (
        <li
            {...forwardedProps}
            className={classnames(className, 'wrex-content-list-item')}
            ref={elementRef}
            style={style}
        >
            {children}
        </li>
    );
};
ListItem.displayName = LIST_ITEM;
