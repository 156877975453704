import get from 'lodash/fp/get';

import { SPACES_FEATURE_TOKEN } from '@lumapps/communities/constants';
import { selectSpaceOrCommunityId } from '@lumapps/communities/ducks/selectors';
import { isFeatureEnabled } from '@lumapps/features';
import { createSelector } from '@lumapps/redux/reselect';
import { SlateStructuredContent } from '@lumapps/wrex/serialization/dita/types';

import { ARTICLES_IN_COMMUNITIES_FF } from '../constants';
import { ArticlePreviewState, ArticlesState } from '../types';

const articleSelector = ({ articles }: { articles: ArticlesState }) => articles;
export const articlePreviewSelector = ({ articlePreview }: { articlePreview: ArticlePreviewState }) => articlePreview;

/** Check if the article dialog is open. */
export const isArticleDialogOpenSelector = createSelector(articleSelector, get('isArticleDialogOpen'));

/** Check if the article dialog is in the publishing state. */
export const isArticleDialogPublishingSelector = createSelector(articleSelector, get('isArticleDialogPublishing'));

/** Check if the article dialog is in the read only state. */
export const isArticleDialogReadOnlySelector = createSelector(articleSelector, get('isArticleDialogReadOnly'));

/** Get the currently edited article. */
export const editedArticleSelector = createSelector(articleSelector, get('editedArticle'));

/** Get the currently converted article */
export const articleFromConversionSelector = createSelector(articleSelector, get('articleFromConversion'));

/** Get the currently edited article in the currently selected lang. */
export const translatedEditedArticleSelector = createSelector(
    [editedArticleSelector],
    (editedArticle): SlateStructuredContent | undefined => editedArticle?.translations[editedArticle?.lang],
);

/**
 * Check if the article feature is enabled in communities.
 *
 * @deprecated This FF is deprecated, please do not use it for new features.
 * It should only be used if you are checking for articles available in communities.
 *
 */
export const isArticleInCommunitiesFeatureEnabled = isFeatureEnabled(ARTICLES_IN_COMMUNITIES_FF);

/**
 * Will return true if the article feature is enabled in communities OR if spaces are enabled.
 */
export const isArticleFeatureAvailable = createSelector(
    isFeatureEnabled(ARTICLES_IN_COMMUNITIES_FF),
    isFeatureEnabled(SPACES_FEATURE_TOKEN),
    (articlesInCommunities, spaces) => articlesInCommunities || spaces,
);

/** Get the current article. */
export const currentArticleSelector = createSelector(articleSelector, get('current'));

/** Get the current article title. */
export const currentArticleTitleSelector = createSelector(articleSelector, get('current.title'));

/** Get the container id of the current article (new or edited). */
export const articleContainerIdSelector = createSelector(
    [editedArticleSelector, currentArticleSelector, selectSpaceOrCommunityId],
    (editedArticle, currentArticle, parentContainerId) => {
        if (editedArticle || currentArticle) {
            return currentArticle
                ? currentArticle.shares?.[0].container.containerId
                : editedArticle?.shares?.[0].container.containerId;
        }
        return parentContainerId;
    },
);

/** Get entities for article. */
export const articleEntitiesSelector = createSelector(articlePreviewSelector, get('entities'));

export const articleEntitySelector = (id: string) => {
    return createSelector([articleEntitiesSelector], (entities) => (entities ? entities[id] : undefined));
};

/** Get batch of article id's. */
export const articleBatchIdsSelector = createSelector(articlePreviewSelector, get('articleBatchIds'));
