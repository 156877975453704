import { cache, CACHE_TYPE } from '@lumapps/cache';
import { createEntityManagementRoutes, createPageRoute, type Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

import { BROADCAST_LIST_VIEW, BROADCAST_LIST_VIEW_STORAGE_KEY } from './constants';

export enum BROADCAST_VIEWS {
    SUMMARY = 'summary',
    INSIGHTS = 'insights',
}

export const broadcastRouteSlug = 'broadcasts';

/**
 * @deprecated This route should ONLY be used in broadcast timeline/calendar header where we want to navigate to timeline specific view.
 * broadcastRoutes.getDefaultRoute() MUST be used everywhere else or it will break "last viewed" feature.
 */
export const broadcastTimelineRoute: Route = createPageRoute({
    appId: AppId.backOffice,
    slug: `${broadcastRouteSlug}/timeline`,
});

export interface BroadcastTimelineParams {
    title?: string;
    statuses?: string[];
    focusDate?: string;
}

/**
 * @deprecated This function should ONLY be used in broadcast timeline/calendar header where we want to navigate to timeline specific view.
 * broadcastRoutes.getDefaultRoute() MUST be used everywhere else or it will break "last viewed" feature.
 */
export const broadcastTimeline = (query?: BroadcastTimelineParams): Route => {
    const queryParams: Record<string, string> = {};

    if (query?.title) {
        queryParams.title = query.title;
    }

    if (query?.statuses) {
        queryParams.statuses = query.statuses.join(',');
    }

    if (query?.focusDate) {
        queryParams.focusDate = query.focusDate;
    }

    return {
        ...broadcastTimelineRoute,
        query: queryParams,
    };
};

/**
 * @deprecated This route should ONLY be used in broadcast timeline/calendar header where we want to navigate to calendar specific view.
 * broadcastRoutes.getDefaultRoute() MUST be used everywhere else or it will break "last viewed" feature.
 */
export const broadcastCalendarRoute: Route = createPageRoute({
    appId: AppId.backOffice,
    slug: `${broadcastRouteSlug}/calendar`,
});

export interface BroadcastCalendarQueryParams {
    title?: string;
    statuses?: string[];
    focusDate?: string;
}

/**
 * @deprecated This function should ONLY be used in broadcast timeline/calendar header where we want to navigate to calendar specific view.
 * broadcastRoutes.getDefaultRoute() MUST be used everywhere else or it will break "last viewed" feature.
 */
export const broadcastCalendar = (query?: BroadcastCalendarQueryParams): Route => {
    const queryParams: Record<string, string> = {};

    if (query?.title) {
        queryParams.title = query.title;
    }

    if (query?.focusDate) {
        queryParams.focusDate = query.focusDate;
    }

    if (query?.statuses) {
        queryParams.statuses = query.statuses.join(',');
    }

    return {
        ...broadcastCalendarRoute,
        query: queryParams,
    };
};

/**
 * Create all basic routes and getters needed on broadcastspage
 * - /broadcasts (base, not an actual route since list is displayed either on /timeline or /calendar)
 * - /broadcasts/create
 * - /broadcasts/:id (braodcast summary)
 * - /broadcasts/:id/edit
 * - /broadcasts/:id/insights
 * We also manually add getDefaultRoute to return either /timeline or /calendar
 */
export const broadcastRoutes = {
    ...createEntityManagementRoutes({ appId: AppId.backOffice, slug: broadcastRouteSlug }, [BROADCAST_VIEWS.INSIGHTS]),
    settingsRoute: createPageRoute({ appId: AppId.backOffice, slug: `${broadcastRouteSlug}/settings` }),
    /**
     * This function return either timeline route or calendar route depending on last one viewed (according to cache storage)
     */
    getDefaultRoute: (query?: BroadcastTimelineParams | BroadcastCalendarQueryParams) => {
        const { view } = cache.retrieve(BROADCAST_LIST_VIEW_STORAGE_KEY, CACHE_TYPE.STORAGE) || {};

        return view === BROADCAST_LIST_VIEW.CALENDAR ? broadcastCalendar(query) : broadcastTimeline(query);
    },
};
