import { FrontOfficeStore } from '@lumapps/redux/types';

/** The a11y message state. */
export const a11YMessageSelector = (state: FrontOfficeStore) => state.a11yMessage || {};

/** The current a11y message displayed. */
export const currentA11yLiveMessageSelector = (state: FrontOfficeStore) => {
    const a11yMessageState = a11YMessageSelector(state);
    return a11yMessageState.message;
};
