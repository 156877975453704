import { Route, createPageRoute } from '@lumapps/router';
import { AppId, URL_PREFIX } from '@lumapps/router/constants';

/* istanbul ignore file */
export enum ProgramDashboardViews {
    feed = 'feed',
    analytics = 'analytics',
}

export const programDashboardRoute: Route = {
    path: `/${URL_PREFIX}/programs/:slug/:view`,
    legacyId: 'app.front.programs-dashboard',
    appId: AppId.legacy,
};

export const programsAdminRoute: Route = createPageRoute({
    slug: 'social-advocacy/programs',
    legacyId: 'app.admin.social-advocacy.programs',
    appId: AppId.backOffice,
});

export const programsAdmin = (): Route => {
    return programsAdminRoute;
};

export const programDashboard = (
    programSlug = 'default',
    view: ProgramDashboardViews = ProgramDashboardViews.feed,
): Route => {
    return {
        path: programDashboardRoute.path,
        legacyId: programDashboardRoute.legacyId,
        params: { slug: programSlug, view },
        appId: AppId.legacy,
    };
};
