import { ENTRY_TYPES } from '@lumapps/directories-entries/constants';
import { DEFAULT_DIRECTORY_MDI_ICON } from '@lumapps/directories/constants';
import { lumappsMicroApp } from '@lumapps/lumx/custom-icons';
import { SEARCH_RESULT_TYPES } from '@lumapps/search/constants';
import { BaseSearchResult } from '@lumapps/search/types';
import { GLOBAL } from '@lumapps/translations';

import { props as breadcrumbProps } from '../Breadcrumb/props';
import { props as contentMetadataProps } from '../ContentMetadata/props';

const props = (result: BaseSearchResult, baseProps: any, options: any) => {
    const { url, name, snippet, onClick, flag, quickViewUrl, onQuickViewClick } = baseProps;
    const bProps = {
        url,
        name,
        snippet,
        flag,
        quickViewUrl,
        onQuickViewClick,
    };

    const { directoryEntry } = result;

    if (!directoryEntry) {
        return bProps;
    }

    const { translate } = options;
    const breadcrumbLevels = breadcrumbProps({
        metadata: directoryEntry?.metadataDetails,
        translate,
    });

    const metadata = contentMetadataProps(result, options);

    /**
     * Display the proper icon, depending on the directory entry type
     */
    const isDirectoryEntryMicroApp = directoryEntry.directoryEntryType === ENTRY_TYPES.MICROAPP;
    const iconName = isDirectoryEntryMicroApp ? lumappsMicroApp : DEFAULT_DIRECTORY_MDI_ICON;

    return {
        ...bProps,
        id: directoryEntry.id,
        name: directoryEntry.name || name,
        breadcrumbLevels,
        instanceDetails: directoryEntry?.instanceDetails,
        metadata,
        thumbnail: directoryEntry.thumbnail,
        url: directoryEntry.link,
        onClick,
        position: result.position,
        iconName,
        kind: translate(GLOBAL.DIRECTORY_ENTRY),
        type: SEARCH_RESULT_TYPES.DIRECTORY_ENTRY,
    };
};

export { props };
