import keys from 'lodash/keys';
import omit from 'lodash/omit';

import { formatSearchResultsForTrackingAnalytics } from '@lumapps/analytics-tracking/utils';
import { instanceIdSelector } from '@lumapps/instance/ducks/selectors';
import { currentLanguageSelector, getLanguageHeader } from '@lumapps/languages/ducks/selectors';
import { error as showError } from '@lumapps/notifications/ducks/thunks';
import { getActionsHistory } from '@lumapps/quick-search/utils/cacheUtils';
import { addSearchHistorySuggestion } from '@lumapps/quick-search/utils/suggestionsUtils';
import { isArrayFacet, convertArrayParameterToValues } from '@lumapps/router/utils';
import { isPromotedResultsEnabled } from '@lumapps/search-promoted/ducks/selectors';
import { GLOBAL } from '@lumapps/translations';
import { isConnected } from '@lumapps/user/ducks/selectors';
import { getDateNowISO } from '@lumapps/utils/date/getDateNowISO';
import { base64toBlob } from '@lumapps/utils/file/base64toBlob';
import { downloadFile } from '@lumapps/utils/file/downloadFile';
import { logException } from '@lumapps/utils/log/logException';

import { searchSendEvents } from '../api/analytics';
import {
    ENGINES,
    FILTER_TYPES,
    SEARCH_EXPORT_PAGE_FILENAME,
    SEARCH_FIRST_PAGE,
    SEARCH_FORMAT,
    isExternalEngine,
    ACTION_HISTORY_NAME,
    MAX_SEARCH_RESULTS,
} from '../constants';
import {
    FetchResultsParams,
    SearchFilter,
    SEARCH_CAUSES,
    Thunk,
    BaseSearchResult,
    SearchEvent,
    SelectedFacets,
} from '../types';
import { isSearchQueryValid } from '../utils';
import { addActionHistory } from '../utils/actionHistoryUtils';
import { addDefaultFilter } from '../utils/addDefaultFilter';
import {
    getCurrentFeatures,
    getSearchCause,
    getSuggestionEngine,
    searchContextSelector,
    getFormattedFilters,
    selectlastInteractedFacet,
    getSearchEngine,
    getResults,
    isInitialState,
    isSearchInProgress,
} from './selectors';
import { actions } from './slice';
import { initialState } from './state';
import { debouncedFetchSuggestions, emitEventsForCustoApi, runDownloadPage, runSearch } from './thunksUtils';

/**
 * Retrieves search results from the corresponding search engine.
 * @param params - FetchResultParams
 */
const fetchResults =
    (
        {
            query,
            filter,
            currentFacets = {},
            moreResults = false,
            sort,
            page = SEARCH_FIRST_PAGE,
            traceId,
            filterType,
        }: FetchResultsParams,
        engine?: ENGINES,
        useStatus?: boolean,
    ): Thunk =>
    async (dispatch, getState) => {
        const currentState = getState();
        const instanceId = instanceIdSelector(currentState);
        const features = getCurrentFeatures(currentState);
        const { isCoveoEnabled } = features;
        const isPromotedEnabled = isPromotedResultsEnabled(currentState);
        const isUserConnected = isConnected(currentState);
        const cause = getSearchCause(currentState);
        const currentLanguage = currentLanguageSelector(currentState);
        const context = searchContextSelector(currentState);
        const customFilters = getFormattedFilters(currentState);
        const lastInteractedFacet = selectlastInteractedFacet(currentState);
        const lastUsedSearchEngine = getSearchEngine(currentState);
        const searchInitialState = isInitialState(currentState);
        const isLoading = isSearchInProgress(currentState);
        dispatch(actions.setQuery(query));
        dispatch(actions.setSelectedFilters(currentFacets));
        if (!moreResults) {
            dispatch(actions.resetResults({ keepFilters: true, cause }));
        }
        dispatch(actions.transitionToLoadingState());

        /**
         * We want to trigger search sometimes only depending on status.
         * Wa always call search to get results and tabs.
         * If first tab is Lumapps, we already have results available
         * If it is Drive or something else, another call will be made to the proper service
         */
        if (useStatus && searchInitialState !== undefined && isLoading !== undefined) {
            const currentFilter = currentState.search.selectedFilter;
            const currentFilterType = currentFilter?.type;
            const canSearch =
                (searchInitialState || currentFilterType === FILTER_TYPES.LUMAPPS || !currentFilter) && !isLoading;
            if (!canSearch) {
                return;
            }
        }

        try {
            const fetchParams: FetchResultsParams = {
                query,
                filter,
                currentFacets,
                moreResults,
                sort,
                page,
                context,
                traceId,
            };
            const {
                items = [],
                filters = initialState.filters,
                metadata,
                facets,
                cursor,
                more,
                sorts,
                promoted,
                spellResults,
                resultCountExact,
                page: currentPage,
                responseTime,
                searchQueryUid,
                splitTestRunName,
                splitTestRunVersion,
                templates,
                engine: searchEngine,
                responseStatus,
                sortOrders,
            } = await runSearch({
                currentState,
                resultsParams: fetchParams,
                traceId,
                engine,
            });

            dispatch(actions.setSortOrders(sortOrders));

            // We update the store only if backend has been called. If cache is used instead,
            // we retrieve the previously used search engine by preventing the store from being updated
            if (responseStatus !== 'CACHED_OK') {
                dispatch(actions.setSearchEngine(searchEngine));
            }

            let finalFilters: SearchFilter[] = filters;
            /**
             * NS returns the default tab directly on the search response
             * It is not true for Coveo
             */
            if (features.isCoveoEnabled) {
                finalFilters = addDefaultFilter({
                    filters: finalFilters,
                    customFilters,
                });
            }

            // If no filter is provided, we should select the first one
            const finalFilter = filter ?? (finalFilters.length > 0 ? finalFilters[0].value : '');
            const isExternal = isExternalEngine(filterType ?? (finalFilter as FILTER_TYPES));
            const currentFilter = finalFilters.find((f) => f.value === finalFilter);

            const currentFilterType = currentFilter?.type ?? finalFilters[0]?.type;
            // Must check that we send an event only for the search query and not for the call that retrieves tabs.
            // For that we send analytics only when the selected tab & default tab are a lumapps tab or when the user is changing tab/loading more results.
            const sendAnalytics =
                (engine === ENGINES.LUMAPPS && currentFilterType && currentFilterType === FILTER_TYPES.LUMAPPS) ||
                cause === SEARCH_CAUSES.tabChange ||
                cause === SEARCH_CAUSES.loadMore ||
                features.isCoveoEnabled;

            if (!isExternal || engine === ENGINES.LUMAPPS) {
                dispatch(
                    actions.setResults({
                        items,
                        filters: finalFilters,
                        query,
                        filter: finalFilter,
                        metadata,
                        // Avoid blink effect by settings some facets from lumapps and then from the other engine like Zendesk
                        facets: isExternal ? [] : facets,
                        cursor,
                        more,
                        appendResults: moreResults,
                        sorts,
                        sort,
                        promoted: isPromotedEnabled ? promoted : null,
                        spellResults,
                        resultCountExact: resultCountExact || 0,
                        page: currentPage,
                        searchQueryUid,
                        splitTestRunName,
                        splitTestRunVersion,
                        shouldUseFacetChoicesHistory: features.isCoveoEnabled || features.areImprovementsEnabled,
                        templates,
                        engine: searchEngine,
                        responseStatus,
                    }),
                );
            } else {
                dispatch(
                    actions.setExternalResults({
                        items,
                        filters,
                        facets,
                        cursor,
                        more,
                        appendResults: moreResults,
                    }),
                );
            }
            const actionCause =
                lastInteractedFacet && cause === SEARCH_CAUSES.facetInteracted
                    ? lastInteractedFacet.facetInteraction
                    : cause;
            // send analytics for the query
            if (cause !== SEARCH_CAUSES.none && sendAnalytics) {
                const promotedIds: string[] = [];
                items.forEach((item: BaseSearchResult) => {
                    if (item.isPromoted && item.id) {
                        promotedIds.push(item.id);
                    }
                });
                let isCoveoAnalytics = features.areAnalyticsEnabled;

                /** For analytics purpose, we want the results position
                 * We need the updated state in case of 'loadMore' action
                 * By default, the number of displayed results = default size page (10 results)
                 * If the number of items < MAX_SEARCH_RESULTS, use it instead of MAX_SEARCH_RESULTS
                 */
                let numberOfDisplayedResults = items?.length < MAX_SEARCH_RESULTS ? items?.length : MAX_SEARCH_RESULTS;

                if (cause === SEARCH_CAUSES.loadMore) {
                    const updatedState = getState();
                    const storedResults = getResults(updatedState);

                    numberOfDisplayedResults = storedResults.length;

                    /** Do no send loadMore event for Coveo
                     * LoadMore events are already sent in the Pagination component
                     */
                    isCoveoAnalytics = false;
                }

                /**
                 * We go through the current facets and we add them to the list of
                 * facets to apply. We avoid the facet that changed so we can later on determine
                 * whether we need to add it or not.
                 */
                const selectedFacets: SelectedFacets = {};
                keys(currentFacets).forEach((key) => {
                    const rawFacetValue = currentFacets[key];
                    const facetValue = isArrayFacet(rawFacetValue)
                        ? convertArrayParameterToValues(rawFacetValue)
                        : rawFacetValue;

                    selectedFacets[key] = facetValue;
                });

                let startPosition = numberOfDisplayedResults - items.length;
                /** Handle position when using Pagination instead of LoadMore */
                if (currentPage > 0) {
                    startPosition = MAX_SEARCH_RESULTS * currentPage;
                }

                const eventPayload: SearchEvent = {
                    traceId,
                    splitTestRunName,
                    splitTestRunVersion,
                    currentLanguage,
                    actionCause,
                    resultCountExact: resultCountExact ?? -1,
                    query,
                    currentPage,
                    responseTime,
                    searchQueryUid,
                    context,
                    sort,
                    currentFacets: selectedFacets,
                    engine: searchEngine || lastUsedSearchEngine,
                    promotedIds,
                    displayedResults: formatSearchResultsForTrackingAnalytics(items, startPosition),
                    /**
                     * When displaying an external engine, don't check if
                     * the filter exists as we never have this information
                     * */
                    filter: isExternal ? filter : currentFilter?.value,
                    actionsHistory: getActionsHistory(instanceId),
                    customData: omit(lastInteractedFacet, 'facetInteraction'),
                };
                searchSendEvents(eventPayload, isCoveoAnalytics, isCoveoEnabled, sortOrders);
            }
            emitEventsForCustoApi({
                cause,
                currentFilter,
                facets,
                items,
                query,
                resultCountExact,
                sort,
                sortOrders,
            });

            // Add the query to the history
            addSearchHistorySuggestion(
                { query, siteId: instanceId, lastUsed: getDateNowISO(), counterClick: 1 },
                isUserConnected,
            );

            if (
                cause === SEARCH_CAUSES.init ||
                cause === SEARCH_CAUSES.queryChange ||
                cause === SEARCH_CAUSES.didYouMeanClick
            ) {
                // Add the action history
                addActionHistory(
                    {
                        name: ACTION_HISTORY_NAME,
                        time: getDateNowISO(),
                        value: query,
                    },
                    instanceId,
                );
            }
        } catch (error) {
            logException(error);
            dispatch(actions.transitionToErrorState({ filter, error }));
        }
    };

/**
 * Updates the state with the new query and retrieves suggestions if the autocomplete is enabled
 * @param query - search query
 */
const changeSearchQuery =
    (query: string): Thunk =>
    (dispatch, getState) => {
        dispatch(actions.setQuery(query));
        const state = getState();

        const isSearchValid = isSearchQueryValid(query);
        const engine = getSuggestionEngine(state);
        const siteId = instanceIdSelector(state);
        const acceptLanguage = getLanguageHeader(state);

        if (isSearchValid && engine) {
            dispatch(actions.transitionSuggestionsToLoadingState());

            debouncedFetchSuggestions({ query, siteId, acceptLanguage }, engine, dispatch);
        }
    };

export const downloadResultsPage =
    (params: FetchResultsParams): Thunk =>
    async (dispatch, getState) => {
        const currentState = getState();

        dispatch(actions.setIsDownloading(true));

        try {
            const response = await runDownloadPage(currentState, {
                ...params,
                format: SEARCH_FORMAT.CSV,
            });

            // decode the result from Base64
            const file = response.data.formatResult;
            if (file) {
                const blob = base64toBlob(file, {
                    type: 'text/csv',
                });
                downloadFile(blob, SEARCH_EXPORT_PAGE_FILENAME);
            }
        } catch (error) {
            logException(error);
            dispatch(showError({ translate: GLOBAL.GENERIC_ERROR }));
        }

        dispatch(actions.setIsDownloading(false));
    };

export { fetchResults, changeSearchQuery };
