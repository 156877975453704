import React from 'react';

import {
    SelectTextField,
    type SingleSelectTextFieldProps,
} from '@lumapps/combobox/components/SelectTextField/SelectTextField';

import { FILTERS_MODE } from '../../constants';
import { UseSingleSelectFilter } from '../../hooks/useSingleSelectFilter';
import { Filter } from '../Filter';
import { FilterProps } from '../Filter/types';

import './index.scss';

export interface SingleSelectFilterProps<T> extends FilterProps {
    /** list of options to display as list items */
    options: T[];
    /** onSelected callback, generated from the `useListFilter` hook */
    onSelected: UseSingleSelectFilter<T>['onSelected'];
    /** onFilter callback, generated from the `useListFilter` hook */
    onFilter: UseSingleSelectFilter<T>['onFilter'];
    /** onClearSelected callback, generated from the `useListFilter` hook */
    onClearSelected: UseSingleSelectFilter<T>['onClearSelected'];
    /** isInSelection callback, generated from the `useListFilter` hook */
    selection: UseSingleSelectFilter<T>['selection'];
    /** custom props for the Select field simple component */
    selectTextFieldProps: Partial<SingleSelectTextFieldProps<T>> & Pick<SingleSelectTextFieldProps, 'autoFilter'>;
    /** callback when the select is open */
    onOpen?: () => void;
}

const CLASSNAME = 'single-select-filter';

/**
 * Filter that renders the provided options as a list with a single option.
 * @family Filters
 * @param SingleSelectFilterProps
 * @returns SingleSelectFilter
 */
export const SingleSelectFilter = <T,>({
    options,
    onSelected,
    selection,
    selectTextFieldProps,
    mode,
    shouldDisplayOptionsDirectly,
    onOpen,
    ...props
}: SingleSelectFilterProps<T>) => {
    return (
        <Filter {...props} hideTitle mode={mode} shouldDisplayOptionsDirectly={shouldDisplayOptionsDirectly}>
            <SelectTextField<T>
                selectionType="single"
                label={mode === FILTERS_MODE.EXPOSED_FACETS ? undefined : props.title}
                options={options}
                onChange={onSelected}
                value={selection}
                className={CLASSNAME}
                onOpen={onOpen}
                {...selectTextFieldProps}
            />
        </Filter>
    );
};
