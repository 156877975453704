import { Route, createPageRoute, AppId } from '@lumapps/router';
import { USER_SUBSCRIPTIONS_FILTERS } from '@lumapps/subscriptions/types';

const subscriptionsRoute: Route = createPageRoute({
    slug: 'subscriptions',
    appId: AppId.frontOffice,
});

interface SubscriptionRouteParams {
    filter?: USER_SUBSCRIPTIONS_FILTERS;
}

const subscriptions = (params?: SubscriptionRouteParams): Route => {
    const queryParams: Route['query'] = {};

    if (params?.filter) {
        queryParams.filter = params.filter;
    }

    return {
        ...subscriptionsRoute,
        params: {},
        query: queryParams,
    };
};

export { subscriptions, subscriptionsRoute };
