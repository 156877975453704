import { get as getConstants } from '@lumapps/constants';
import { getDirectories } from '@lumapps/directories/ducks/selectors';
import { getCurrentInstance, getInstanceById } from '@lumapps/instance/ducks/selectors';
import { Dispatch, GetBaseState } from '@lumapps/redux/types';
import { RouterApi } from '@lumapps/router/api';
import { AppId } from '@lumapps/router/constants';
import { TranslationAPI } from '@lumapps/translations';
import { watchLinkActivation } from '@lumapps/utils/element/watchLinkActivation';

import { getContentEntities } from '../../ducks/selectors';
import { contentView } from '../../routes';
import { ContentLinkRef } from '../../types';
import { prepareFetchUpdateContentLinkRef } from './fetchUpdateContentLinkRef';
import { updateContentLinkFromState } from './updateContentLinkFromState';

const updateFromStateThunk =
    (contentLinkRef: ContentLinkRef, translateObject: TranslationAPI['translateObject']) =>
    (_: any, getState: GetBaseState) => {
        const state = getState();
        const directories = getDirectories(state);
        const contentById = getContentEntities(state) || {};
        const instanceById = getInstanceById(state) || {};
        const { id: currentInstanceId } = getCurrentInstance(state);
        return updateContentLinkFromState(contentLinkRef, translateObject, {
            currentInstanceId,
            contentById,
            instanceById,
            directories,
        });
    };

/**
 * Watch & update content link on activation if needed.
 * Updates the content slug, instance slug and even
 */
export function watchUpdateLink(
    anchor: HTMLElement,
    to: ContentLinkRef,
    options: {
        isNewInterfaceRoutingEnabled?: boolean;
        isReactUserDirectoryEnabled?: boolean;
        isLayoutOn: boolean;
        languageHeader: string;
        currentInstanceId: string;
        dispatch: Dispatch;
        translateObject: TranslationAPI['translateObject'];
        redirect: RouterApi['redirect'];
        onClick?: (evt: Event) => void;
    },
): (() => void) | undefined {
    const { isLegacyContext, applicationId } = getConstants();

    const needUpdate =
        // Skips update on webview app (we only need the id of the content).
        applicationId !== AppId.webview &&
        // Link ref has stale data (outdated content slug or instance slug).
        (to.hasStaleData ||
            (isLegacyContext && (to.isHomePage || options.isNewInterfaceRoutingEnabled)) ||
            // Not in legacy context and content migration status is unknown.
            (!isLegacyContext && to.isV2Compatible === undefined) ||
            // Cross instance link is missing the instance slug
            (to.instance?.id && to.instance?.id !== options.currentInstanceId && !to.instance?.slug));

    // (if update needed) Prepare the update of the link ref.
    const fetchUpdateContentLinkRef = needUpdate ? prepareFetchUpdateContentLinkRef({ to, ...options }) : undefined;

    const onActivate = async (openInNewTab: boolean, evt: Event) => {
        if (to.hasStaleData || !openInNewTab) {
            // When opening in the current tab => fetch an updated link ref (while displaying the page loading transition)
            await fetchUpdateContentLinkRef?.fetch();
        }
        // Get updated content link ref from store.
        const updatedContentLinkRef = options.dispatch(updateFromStateThunk(to, options.translateObject));
        const route = contentView({
            isLayoutEnabled: options.isLayoutOn,
            isReactUserDirectoryEnabled: options.isReactUserDirectoryEnabled,
            to: updatedContentLinkRef,
        });

        if (!openInNewTab && route.appId === AppId.legacy && applicationId !== AppId.legacy) {
            // Flag marking the fallback from app v2 to content v1 page.
            sessionStorage.setItem('page-load-content-analytics-disable', 'true');
        }

        // Some link may have an onClick to call before redirect
        if (options.onClick) {
            options.onClick(evt);
        }

        // Redirect to route.
        options.redirect(route, { openInNewTab });
    };

    return watchLinkActivation({
        element: anchor,
        onActivate,
    });
}
