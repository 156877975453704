import React from 'react';

import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import { searchInstances } from '../../api';
import { SearchInstanceDefaultParams, SearchInstancesParams } from '../../api/types';
import { Instance } from '../../types';

/**
 * Fetch sites available for the current instance
 */
export const useFetchSites = (defaultParams?: Partial<SearchInstancesParams>) => {
    const [sites, setSites] = React.useState<Instance[]>([]);
    const [hasMore, setHasMore] = React.useState(false);
    const [status, setStatus] = React.useState(BaseLoadingStatus.initial);

    const currentCursor = React.useRef<string | undefined>(undefined);

    /**
     * Method to fetch sites, used both in the initial fetch and for handling
     * pagination ('fetchMore')
     */
    const fetchData = React.useCallback(
        async (overrides?: Partial<SearchInstancesParams>) => {
            const {
                items,
                cursor: nextCursor,
                more,
            } = await searchInstances({
                cursor: currentCursor.current,
                ...SearchInstanceDefaultParams,
                ...defaultParams,
                ...overrides,
            });
            setHasMore(more);
            currentCursor.current = nextCursor;
            return items;
        },
        [defaultParams],
    );

    /**
     * Initial sites fetching, handles loading, error, and result
     */
    const fetch = React.useCallback(
        async ({
            onFetch,
            ...propsOverrides
        }: Partial<SearchInstancesParams> & { onFetch?: (items: Instance[]) => void }) => {
            try {
                setStatus(BaseLoadingStatus.loading);

                const items = await fetchData(propsOverrides);

                setSites(items);

                if (onFetch) {
                    onFetch(items);
                }

                setStatus(BaseLoadingStatus.idle);
            } catch {
                setStatus(BaseLoadingStatus.error);
            }
        },
        [fetchData],
    );

    /**
     * Handles sites pagination using result's cursor
     */
    const fetchMore = React.useCallback(
        async (overrides?: Partial<SearchInstancesParams>) => {
            try {
                setStatus(BaseLoadingStatus.loadingMore);

                const items = await fetchData(overrides);

                setSites((prev) => [...prev, ...items]);

                setStatus(BaseLoadingStatus.idle);
            } catch (e) {
                setStatus(BaseLoadingStatus.error);
            }
        },
        [fetchData],
    );

    return {
        /**
         * Error fetching sites
         */
        error: status === BaseLoadingStatus.error,
        /**
         * Use this method to fetch the sites available for this instance.
         */
        fetch,
        /**
         * Pagination : fetch more sites based on result cursor
         */
        fetchMore,
        /**
         * Helps knowing if more sites are available, based on API result ('more')
         */
        hasMore,
        /**
         * Separate loading state for 'fetchMore' calls
         */
        isLoadingMore: status === BaseLoadingStatus.loadingMore,
        /**
         * Loading state for the initial call
         */
        loading: status === BaseLoadingStatus.loading,
        initial: status === BaseLoadingStatus.initial,
        /**
         * The site list return after fetching sites
         * The list grows every time we fetch more results
         */
        sites,
        /** current loading status */
        status,
        /** manually add a site to the list of given sites */
        addSite: (instance: Instance) => {
            setSites([...sites, instance]);
        },
        updateSite: (instance: Instance) => {
            setSites(sites.map((i) => (i.id === instance.id ? instance : i)));
        },
        deleteSites: (ids: string[]) => {
            setSites(sites.filter((s) => ids.indexOf(s.id) < 0));
        },
        reset: () => {
            setSites([]);
        },
    };
};
