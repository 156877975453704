import { createPageRoute, Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

const trainingPageRoute: Route = createPageRoute({
    slug: 'learning/training/:identifier',
    appId: AppId.frontOffice,
});

export const webviewTrainingPageRoute: Route = createPageRoute({
    slug: 'learning/training/:identifier',
    appId: AppId.webview,
});

const trainingView = (identifier: string): Route => ({
    ...trainingPageRoute,
    params: {
        identifier,
    },
    /**
     * The mobilePageDescriptor key is used in the Mobile Webview React App to trigger
     * an event to the host mobile app indicating which page should be opened next.
     *
     * @see https://we.lumapps.com/battlefront/ls/content/5831209251635200/react-webview-app
     */
    mobilePageDescriptor: {
        type: 'training-course',
        id: identifier,
    },
});

const trainingViewDuelNotification = (identifier: string, activityId?: string, duelId?: string): Route => ({
    ...trainingPageRoute,
    params: {
        identifier,
    },
    query: {
        activityId,
        duelId,
    },
    mobilePageDescriptor: {
        type: 'training-course',
        id: identifier,
    },
});

export { trainingView, trainingViewDuelNotification, trainingPageRoute };
