import { createEntityManagementRoutes, createPageRoute } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

export enum JOURNEY_VIEWS {
    EDIT = 'edit',
    VIEW = 'view',
    ITEM_CONFIGURATIONS = 'item-configurations',
    ENROLLMENTS = 'enrollments',
}

export const journeyRouteSlug = 'journeys';

/**
 * Create all basic routes and getters needed on journeys page
 * - /journeys (list)
 * - /journeys/settings
 * - /journeys/create
 * - /journeys/:id (journey view)
 * - /journeys/:id/edit
 * - /journeys/:id/enrollments
 * - /journeys/:id/item-configurations
 * We only include enrollments and item-conf as additional modes since view and edit are already included natively
 */
export const journeyRoutes = {
    ...createEntityManagementRoutes({ appId: AppId.backOffice, slug: journeyRouteSlug }, [
        JOURNEY_VIEWS.ITEM_CONFIGURATIONS,
        JOURNEY_VIEWS.ENROLLMENTS,
    ]),
    settingsRoute: createPageRoute({ appId: AppId.backOffice, slug: `${journeyRouteSlug}/settings` }),
};
