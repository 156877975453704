import React, { ReactNode } from 'react';

import { classnames, useClassnames, visuallyHidden } from '@lumapps/classnames';
import { GenericBlock, Heading, Placement, Popover, PopoverProps, Text, type GenericProps } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { sanitizeHTML } from '@lumapps/utils/string/sanitizeHtml';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import { useComboboxRefs } from '../../context/ComboboxRefsContext';
import { useCombobox } from '../../hooks/useCombobox';
import { ComboboxListSkeleton, ComboboxListSkeletonProps } from './ComboboxListSkeleton';

import './index.scss';

export interface ComboboxListBoxProps extends GenericProps, React.ComponentProps<'ul'> {
    /** Options display in the combobox */
    children?: ReactNode;
    /**
     * Component to use as skeleton for each option instead of the default one.
     * Can either be a react node or a component that receives the index as prop
     */
    renderItemSkeleton?: ComboboxListSkeletonProps['children'];
    /** Label for the list */
    label?: string;
    /** Props of the popover element. */
    popoverProps?: Partial<PopoverProps>;
    /**
     * An element to display at the bottom of the listbox.
     * No interactive element must be set here as they will not be accessible.
     */
    footer?: ReactNode;
    /** List ref */
    listRef?: React.Ref<HTMLElement>;
}

/** The states in which the full loading must be displayed */
const FULL_LOADING_STATES = [BaseLoadingStatus.loading, BaseLoadingStatus.debouncing, BaseLoadingStatus.filtering];
const CLASSNAME = 'combobox-listbox';
const allowedTags = ['strong', 'b', 'br'];

/**
 * The listbox containing the combobox's options.
 *
 * @family Combobox
 * @param ComboboxListBoxProps
 * @returns ComboboxListBox
 */
export const ComboboxListBox = ({
    children,
    renderItemSkeleton,
    label,
    popoverProps,
    footer,
    listRef,
    ...forwardedProps
}: ComboboxListBoxProps) => {
    const { translateAndReplace, translateKey, pluralize } = useTranslate();
    const {
        status,
        listboxId,
        isOpen,
        optionsLength,
        handleClose: contextHandleClose,
        type,
        inputValue,
        showEmptyState,
        showErrorState,
    } = useCombobox();
    const { block, element } = useClassnames(CLASSNAME);
    const { anchorRef } = useComboboxRefs();
    const { onClose, ...otherPopoverProps } = popoverProps || {};
    const { style, className, ...listProps } = forwardedProps;
    // The states to show a full skeleton instead of the options
    const showFullLoading = FULL_LOADING_STATES.includes(status);

    const isErrorStateDisplayed = showErrorState && isOpen && status === BaseLoadingStatus.error;
    const isEmptyStateDisplayed = showEmptyState && isOpen && status === BaseLoadingStatus.idle && optionsLength === 0;

    /**
     *  The conditions in which we want to show the content of the popover
     *  * The parent asked for the popover to be opened
     *  * There is at least one option to displayed OR a skeleton
     */
    const showPopover =
        (isOpen && (optionsLength > 0 || showFullLoading)) || isEmptyStateDisplayed || isErrorStateDisplayed;

    const handleClose = () => {
        if (isOpen) {
            contextHandleClose?.();
            onClose?.();
        }
    };

    const emptyMessage = inputValue
        ? sanitizeHTML(
              translateAndReplace(GLOBAL.NO_RESULTS_FOR, {
                  text: inputValue,
              }),
              { allowedTags: [] },
          )
        : translateKey(GLOBAL.NO_RESULTS);

    return (
        <>
            {/* Read changes in available options by a screen reader */}
            <Text as="p" className={visuallyHidden()} role="log" aria-live="assertive">
                {isOpen &&
                    !showFullLoading &&
                    !isEmptyStateDisplayed &&
                    !isErrorStateDisplayed &&
                    translateAndReplace(pluralize(GLOBAL.NB_OPTIONS_AVAILABLE, optionsLength), { NB: optionsLength })}
                {isErrorStateDisplayed && (
                    <>
                        {translateKey(GLOBAL.SERVICE_UNAVAILABLE)} {translateKey(GLOBAL.TRY_RELOAD)}
                    </>
                )}
                {showFullLoading && translateKey(GLOBAL.LOADING)}
                {isEmptyStateDisplayed && emptyMessage}
            </Text>
            <Popover
                role="none"
                onClose={handleClose}
                anchorRef={anchorRef}
                fitToAnchorWidth
                fitWithinViewportHeight
                closeOnEscape
                closeOnClickAway
                placement={Placement.BOTTOM}
                isOpen={isOpen}
                {...otherPopoverProps}
            >
                <div
                    style={{
                        ...style,
                        overflowY: 'auto',
                        display: showPopover ? 'flex' : 'none',
                        flexDirection: 'column',
                    }}
                >
                    {/**
                     * The DS `List` component has a lot of behavior / default props we do not want here.
                     * So we use a native ul tag.
                     */}
                    <ul
                        ref={listRef as React.Ref<HTMLUListElement>}
                        id={listboxId}
                        role={type}
                        aria-label={label}
                        className={classnames(block(), className, 'lumx-list lumx-list--item-padding-big')}
                        style={{
                            flex: 1,
                            overflowY: 'auto',
                        }}
                        {...listProps}
                    >
                        {/* Only show children if a full skeleton isn't already displayed */}
                        {!showFullLoading && children}
                        {[...FULL_LOADING_STATES, BaseLoadingStatus.loadingMore].includes(status) && (
                            <ComboboxListSkeleton isLoadingMore={status === BaseLoadingStatus.loadingMore}>
                                {renderItemSkeleton}
                            </ComboboxListSkeleton>
                        )}
                    </ul>
                    {footer}
                    {(isEmptyStateDisplayed || isErrorStateDisplayed) && (
                        <GenericBlock className={element('state')} orientation="vertical" vAlign="center">
                            <GenericBlock.Content>
                                {inputValue && isEmptyStateDisplayed && (
                                    <Text
                                        as="p"
                                        typography="body1"
                                        color="dark"
                                        colorVariant="L2"
                                        dangerouslySetInnerHTML={{
                                            __html: sanitizeHTML(
                                                translateAndReplace(GLOBAL.NO_RESULTS_FOR, {
                                                    text: inputValue,
                                                }),
                                                { allowedTags },
                                            ),
                                        }}
                                    />
                                )}
                                {!inputValue && isEmptyStateDisplayed && (
                                    <Text as="p" typography="body1" color="dark" colorVariant="L2">
                                        {translateKey(GLOBAL.NO_RESULTS)}
                                    </Text>
                                )}
                                {isErrorStateDisplayed && (
                                    <>
                                        <Heading as="h2" typography="subtitle2">
                                            {translateKey(GLOBAL.SERVICE_UNAVAILABLE)}
                                        </Heading>
                                        <Text as="p" typography="body1" color="dark" colorVariant="L2">
                                            {translateKey(GLOBAL.TRY_RELOAD)}
                                        </Text>
                                    </>
                                )}
                            </GenericBlock.Content>
                        </GenericBlock>
                    )}
                </div>
            </Popover>
        </>
    );
};
