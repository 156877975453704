export enum TRAININGS {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=565851965
     */
    CONTINUE_TRAINING = 'TRAININGS.CONTINUE_TRAINING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=434864670
     */
    CURRENT_TRAININGS = 'LEARNING.CURRENT_TRAININGS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=434919430
     */
    RECOMMENDED_TRAININGS = 'LEARNING.RECOMMENDED_TRAININGS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=436888345
     */
    TRAINING_COURSE = 'LEARNING.TRAINING_COURSE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=436917839
     */
    LEARNING_PATH = 'LEARNING.LEARNING_PATH',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=580172686
     */
    RECOMMENDATION_QUIZ = 'LEARNING.RECOMMENDATION_QUIZ',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=436920068
     */
    NO_CATEGORY = 'LEARNING.NO_CATEGORY',
    /**
     *  https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=468564230,
     */
    LAUNCH_TRAINING = 'LEARNING.LAUNCH_TRAINING',
    /**
     *  https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=547005794
     */
    TRAININGS_TO_DO = 'LEARNING.TRAININGS_TO_DO',
}
