import React from 'react';

import { useCustomizations, MODES } from '@lumapps/customizations';
import { useFooterStyle } from '@lumapps/style/hooks/useFooterStyles';
import { standardizeTranslateObject, useTranslate } from '@lumapps/translations';
import { useInjectHTMLWithJS } from '@lumapps/utils/hooks/useInjectHTMLWithJS';

import './index.scss';

const CLASSNAME = 'footer';
const Footer: React.FC = () => {
    const { translateObject } = useTranslate();

    const footer = useFooterStyle();

    const translatedFooter = translateObject(standardizeTranslateObject(footer));
    const { modes } = useCustomizations();
    const { ref: footerRef } = useInjectHTMLWithJS({ html: translatedFooter });

    if (modes[MODES.HEADLESS]) {
        return null;
    }

    if (!modes[MODES.WITH_CUSTOM_CODE]) {
        return null;
    }

    return translatedFooter ? (
        // eslint-disable-next-line react/no-danger
        <footer
            /**
             * Even though it might seem redundant to add a contentinfo role to the footer,
             * it's actually a good practice as it has a larger assistive technology compatibility.
             * This must be the only "contentinfo" on the page though, other "footers" must not have this role.
             */
            role="contentinfo"
            ref={footerRef}
            className={CLASSNAME}
        />
    ) : null;
};

export { Footer };
