import { ENGINES } from '@lumapps/search/constants';
import { BaseSearchResult, DriveSearchResult, SearchTab } from '@lumapps/search/types';
import { TranslationAPI } from '@lumapps/translations';

import { getIconFromType } from '../../../helpers';
import { props as contentMetadataProps, getMetadataTimestampLabel } from '../../ContentMetadata/props';

export interface DefaultGoogleResultPropsOptions {
    translate: TranslationAPI['translate'];
    filtersMetadata: Record<string, SearchTab>;
    engine?: ENGINES;
}

const lumappsProps = (result: BaseSearchResult, baseProps: any, options: DefaultGoogleResultPropsOptions) => {
    const { url, name, snippet, mimeType, sourceUid, onClick, shouldOpenInNewTab, flag, quickViewUrl } = baseProps;
    const { filtersMetadata } = options;
    const resultMetadata = filtersMetadata ? filtersMetadata[sourceUid] : { template: null };
    const { template } = resultMetadata;
    const iconName = getIconFromType(mimeType);

    const isEmail = template === 'mail';

    /**
     * GCS is changing the url at each query for the same item. So we cannot use the url as an id.
     * We are using sourceUid and the name of the item to handle this special case.
     */
    return {
        id: `${sourceUid}-${encodeURIComponent(name)}`,
        url,
        snippet,
        title: name,
        metadata: contentMetadataProps(result, {
            ...options,
            useUpdateTime: !isEmail,
            useReceivedOnTime: isEmail,
            isAuthorVisible: false,
        }),
        mimeType: mimeType || template,
        position: result.position,
        shouldOpenInNewTab,
        onClick,
        flag,
        iconName,
        quickViewUrl,
    };
};

const driveProps = (result: DriveSearchResult, baseProps: any, options: DefaultGoogleResultPropsOptions) => {
    const { onClick, flag, quickViewUrl } = baseProps;
    const iconName = getIconFromType(result.mimeType);

    const metadataLabel = getMetadataTimestampLabel(
        {
            updateTime: result.lastUpdate,
            createTime: result.lastUpdate,
        },
        { useUpdateTime: true, translate: options.translate },
    );

    // TODO: Should only map known props
    return {
        ...result,
        url: result.link,
        metadata: {
            metadata: [metadataLabel],
        },
        onClick,
        flag,
        iconName,
        quickViewUrl,
    };
};

const engineProps: Record<ENGINES, any> = {
    [ENGINES.DRIVE]: driveProps,
    [ENGINES.LUMAPPS]: lumappsProps,
    [ENGINES.COVEO]: lumappsProps,
    [ENGINES.MICROSOFT]: lumappsProps,
    [ENGINES.LUMWORK]: null,
    [ENGINES.ZENDESK]: null,
    [ENGINES.SHAREPOINT]: null,
};

const props = (
    result: BaseSearchResult | DriveSearchResult,
    baseProps: any,
    options: DefaultGoogleResultPropsOptions,
) => {
    const { engine = ENGINES.LUMAPPS } = options;
    const propsGeneratorForEngine = engineProps[engine];

    return propsGeneratorForEngine ? propsGeneratorForEngine(result, baseProps, options) : {};
};

export { props };
