import { isFeatureEnabled } from '@lumapps/features';
import { isInstanceSuperAdmin, isSuperAdmin } from '@lumapps/permissions';
import { createSelector } from '@lumapps/redux/reselect';

export const isContentManagementBetaFeatureEnabled = isFeatureEnabled('content-management-beta');

/** Check whether the current user has access to the all content page in the back-office
 * To has access those conditions should be met:
 * - User is global or site admin
 * - The 'content-management-beta' FF should be enabled
 */
const isContentBulkAllowed = createSelector(
    isSuperAdmin,
    isInstanceSuperAdmin,
    isContentManagementBetaFeatureEnabled,
    (isGlobalAdmin, isSiteAdmin, hasContentManagementBetaFF) => {
        /** Allow if the user is a global admin or a site admin and the feature is enabled */
        return (isGlobalAdmin || isSiteAdmin) && hasContentManagementBetaFF;
    },
);

export { isContentBulkAllowed };
