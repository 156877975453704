import React from 'react';

import { useDataAttributes } from '@lumapps/data-attributes';
import { AlertDialog, Kind, AlertDialogProps, Typography, Text } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { NOTIFICATIONS_CENTER } from '../../keys';

import './index.scss';

export type DeleteAllNotificationConfirmModalProps = Omit<AlertDialogProps, 'confirmProps' | 'cancelProps'> & {
    // Action when the user confirm
    onConfirm(): void;
    // Action when the user cancel
    onCancel(): void;
};

const SCOPE = 'notifications-clear-all';
const CLASSNAME = 'notification-center-confirm-modal';
const DeleteAllNotificationConfirmModal: React.FC<DeleteAllNotificationConfirmModalProps> = ({
    zIndex = 10000,
    onConfirm,
    onCancel,
    ...props
}) => {
    const { translateKey } = useTranslate();
    const { get } = useDataAttributes(SCOPE);

    return (
        <AlertDialog
            zIndex={zIndex}
            {...props}
            {...get({ element: 'modal' })}
            kind={Kind.error}
            title={translateKey(NOTIFICATIONS_CENTER.DELETE_ALL_DIALOG_TITLE)}
            confirmProps={{
                label: translateKey(GLOBAL.DELETE),
                onClick: onConfirm,
                ...get({ element: 'modal', action: 'confirm' }),
            }}
            cancelProps={{
                label: translateKey(GLOBAL.CANCEL),
                onClick: onCancel,
                ...get({ element: 'modal', action: 'cancel' }),
            }}
        >
            <Text as="p" typography={Typography.body1}>
                {translateKey(NOTIFICATIONS_CENTER.CLEAR_ALL_CONFIRM_DESCRIPTION)}
            </Text>
        </AlertDialog>
    );
};

export { DeleteAllNotificationConfirmModal, CLASSNAME };
