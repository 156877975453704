import React from 'react';

import { useDataAttributes } from '@lumapps/data-attributes';
import { JOURNEYS_ICON } from '@lumapps/journeys/constants';
import { journeyRoutes } from '@lumapps/journeys/routes';
import { Menu } from '@lumapps/lumx-menu/components/Menu';
import { useSelector } from '@lumapps/redux/react';
import { Link as RouterLink } from '@lumapps/router';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { canAccessJourneysAdmin } from '../../ducks/selectors';

export interface JourneysSettingMenuProps {
    scope: string;
}

export const JourneysSettingMenu: React.FC<JourneysSettingMenuProps> = ({ scope }) => {
    const { get } = useDataAttributes(scope);
    const isAllowed = useSelector(canAccessJourneysAdmin);
    const { translateKey } = useTranslate();

    if (!isAllowed) {
        return null;
    }

    return (
        <Menu.Item
            icon={JOURNEYS_ICON}
            as={RouterLink}
            to={journeyRoutes.baseRoute}
            {...get({ element: 'access-journey' })}
        >
            {translateKey(GLOBAL.JOURNEYS)}
        </Menu.Item>
    );
};

JourneysSettingMenu.displayName = 'SettingMenuItem';
