import React from 'react';

import { Link } from '@lumapps/router';
import { useSocialProfileRoute } from '@lumapps/user-profile/hooks/useSocialProfileRoute';

import { SearchBoxOptionWithItemProps } from '../../types';
import { BaseSearchBoxInteractionOption } from './BaseSearchBoxInteraction';

/**
 * Search box options for users.
 * Generates the link to the user and pass it to the Base component
 */
export const SearchBoxInteractionUser = ({ suggestion, ...forwardedProps }: SearchBoxOptionWithItemProps) => {
    const { item } = suggestion;
    const { route } = useSocialProfileRoute({
        routeParams: { userId: item?.entityId },
    });

    return <BaseSearchBoxInteractionOption suggestion={suggestion} as={Link} to={route} {...forwardedProps} />;
};
