import BaseApi, { PRIORITY } from '@lumapps/base-api';
import { ServerListResponse } from '@lumapps/base-api/types';
import { CACHE_TYPE } from '@lumapps/cache';

import { Instance } from '../types';
import {
    GetInstanceParams,
    ListInstanceParams,
    ListInstanceSiblingsParams,
    SearchInstanceDefaultParams,
} from './types';

export const PATH = 'instance';

export const instanceApi = new BaseApi({ path: PATH });

export const listInstances = (params: ListInstanceParams) =>
    instanceApi.get<ServerListResponse<Instance>>('list', { params });

/**
 * Retrieves an instance from the server
 * @param params GetInstanceParams
 * @param ignoreCache whether the instance should be retrieved from cache or not.
 * @returns Promise<Instance>
 */
export const getInstance = (params: GetInstanceParams, retrieveFromCache = true) => {
    if (!retrieveFromCache) {
        return instanceApi.get<Instance>('get', { params });
    }

    return instanceApi.getCacheFirst<Instance>('get', CACHE_TYPE.MEMORY, PRIORITY.HIGH, { params });
};

export const listInstanceSiblings = (params: ListInstanceSiblingsParams) =>
    instanceApi.get<ServerListResponse<Instance>>('siblings/list', { params });

export const searchInstances = (params = SearchInstanceDefaultParams) =>
    instanceApi
        .postCacheFirst<ServerListResponse<Instance>>('search', { ...params }, CACHE_TYPE.MEMORY, PRIORITY.HIGH)
        .then((response) => {
            const { items = [], cursor, more } = response.data;

            return { items, cursor, more, status: response.status };
        });

export const saveInstance = (instance: Instance) => instanceApi.post<Instance>('/save', instance);

export const deleteInstances = (uid: string[]) => instanceApi.delete<Instance>('/deleteMulti', { params: { uid } });

export const instanceQueryKeys = {
    all: [PATH] as const,
    get: (params: GetInstanceParams) => [...instanceQueryKeys.all, 'get', params] as const,
};
