/* istanbul ignore file */
import { Route, createPageRoute, AppId } from '@lumapps/router';

/**
 * @deprecated this route is still here to work with previous mobile app versions
 * and is supposed to be replaced by 'webviewCommunityHomepageViewRoute'
 */
export const webviewCommunityHomepageViewRouteForBackwardCompatibility: Route = createPageRoute({
    slug: 'community/home/id/:id',
    appId: AppId.webview,
});

export const webviewCommunityHomepageViewRoute: Route = createPageRoute({
    slug: 'community/id/:id/home',
    appId: AppId.webview,
});

/**
 * @deprecated this route is still here to work with previous mobile app versions
 * and is supposed to be replaced by 'webviewSpaceHomepageViewRoute'
 */
export const webviewSpaceHomepageViewRouteForBackwardCompatibility: Route = createPageRoute({
    slug: 'space/home/id/:id',
    appId: AppId.webview,
});

export const webviewSpaceHomepageViewRoute: Route = createPageRoute({
    slug: 'space/id/:id/home',
    appId: AppId.webview,
});

export const webviewSpaceCalendarViewRoute: Route = createPageRoute({
    slug: 'space/id/:id/calendar',
    appId: AppId.webview,
});
