import { Route, createPageRoute, AppId } from '@lumapps/router';

const savedItemsRoute: Route = createPageRoute({
    slug: 'saved-items',
    appId: AppId.frontOffice,
});

const savedItems = (): Route => ({
    ...savedItemsRoute,
    params: {},
});

export { savedItems, savedItemsRoute };
