import { get } from '@lumapps/constants';
import { TRAINING_LEGACY_DATA_TYPE, TRAINING_TYPE } from '@lumapps/learning-trainings/types';

const Config = get();
const orgId = Config.customerId;

export const learningApi = {
    URL: `${Config.haussmannCell}/v2/organizations/${orgId}/learning/centaurus/api`,
};
export const mcLearningApi = {
    URL: `${Config.haussmannCell}/v2/organizations/${orgId}/learning/mc/jwtlogin?redirect_page=`,
};
/** Feature flag used for under development features */
export const LEARNING_ALPHA_FF = 'learning-alpha';

/** Feature flag used for beta features */
export const LEARNING_BETA_FF = 'learning-beta';

/**  Feature flag used for learning specific features */
export const LEARNING_FF = 'learning';

/**  Feature flag used for display training page react */
export const LEARNING_TRAINING_PAGE_REACT = 'learning-training-page-react';

export enum STATUSES_LEARNING_ENTITIES {
    TODO = 'todo',
    STARTED = 'started',
    COMPLETED = 'completed',
    FAILED = 'failed',
    SUCCEEDED = 'succeeded',
}

export const FILTER_TRAINING_TYPE: Record<TRAINING_TYPE | TRAINING_LEGACY_DATA_TYPE, TRAINING_TYPE> = {
    [TRAINING_TYPE.training]: TRAINING_TYPE.training,
    [TRAINING_LEGACY_DATA_TYPE.training]: TRAINING_TYPE.training,
    [TRAINING_TYPE.learningPath]: TRAINING_TYPE.learningPath,
    [TRAINING_LEGACY_DATA_TYPE.learningPath]: TRAINING_TYPE.learningPath,
    [TRAINING_TYPE.recommendationQuiz]: TRAINING_TYPE.recommendationQuiz,
    [TRAINING_LEGACY_DATA_TYPE.recommendationQuiz]: TRAINING_TYPE.recommendationQuiz,
    [TRAINING_TYPE.unsupported]: TRAINING_TYPE.unsupported,
};
