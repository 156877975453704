import React from 'react';

export function useFocusLastChipOnBackspace(
    lastChipRef: React.RefObject<HTMLElement>,
    inputRef?: React.RefObject<HTMLInputElement>,
) {
    React.useEffect(() => {
        const input = inputRef?.current;
        if (!input) {
            return undefined;
        }

        const onKeyDown = (evt: KeyboardEvent) => {
            const { current: lastChip } = lastChipRef;
            const backspacePressed = evt.key === 'Backspace';
            const cursorAtStart = input.selectionStart === 0 && input.selectionEnd === 0;
            if (!backspacePressed || !cursorAtStart || !lastChip) {
                return;
            }

            lastChip.focus();
        };

        input.addEventListener('keydown', onKeyDown);
        return () => {
            input.removeEventListener('keydown', onKeyDown);
        };
    }, [inputRef, lastChipRef]);
}
