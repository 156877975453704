import { CommunityLink } from '@lumapps/communities/components/CommunityLink';
import { CommunityLinkRef } from '@lumapps/communities/types';
import { get } from '@lumapps/constants';
import { ContentLink } from '@lumapps/contents/components/ContentLink';
import { contentEdit, contentView } from '@lumapps/contents/routes';
import { ContentLinkRef } from '@lumapps/contents/types';
import { trainingView, trainingViewDuelNotification } from '@lumapps/learning-trainings/routes';
import { Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';
import { getBaseUrl } from '@lumapps/router/utils';

import {
    Notification,
    NotificationContentType,
    NotificationLinkUrlParams,
    NotificationMetaLinkAs,
    NotificationTypes,
} from '../types';

const handleInstanceRoute = (notification: Notification, route: Route, currentInstanceId?: string) => {
    const newRoute = { ...route };

    // If the notification is in another instance, we recraft the route as external with the correct instance slug
    if (notification.instanceId && currentInstanceId && notification.instanceId !== currentInstanceId) {
        const baseUrl = getBaseUrl(window.location.pathname, false);
        const newInstanceSlug = notification.instanceDetails?.slug || 'home';
        const relativePath = route.path.startsWith('/') ? route.path : `/${route.path}`;
        newRoute.path = `${baseUrl}/${newInstanceSlug}${relativePath}`;
        newRoute.appId = AppId.external;
    }

    return newRoute;
};

const getEditContentRoute = ({ notification, currentInstanceId }: NotificationLinkUrlParams): Route | null => {
    if (!notification) {
        return null;
    }

    const route = contentEdit(notification?.externalKey || '');

    return handleInstanceRoute(notification, route, currentInstanceId);
};

const getContentRoute = ({
    notification,
    currentInstanceId,
    translation,
    anchor,
}: NotificationLinkUrlParams): Route | null => {
    if (!notification) {
        return null;
    }

    // handle external with link prop
    if (notification.link && Object.values(notification.link).some((value) => !!value)) {
        return {
            path: translation.translateObject(notification.link) || '',
            appId: AppId.external,
        };
    }

    const slug = translation.translateObject(notification.url);

    // TODO: handle layout v2 links
    const route = contentView({ isLayoutEnabled: false, to: { slug: slug || '', anchor } });

    return handleInstanceRoute(notification, route, currentInstanceId);
};

const getContentLink = ({
    notification,
    translation,
    anchor,
}: NotificationLinkUrlParams): NotificationMetaLinkAs | null => {
    if (notification) {
        const to: ContentLinkRef = {
            id: notification.externalKey,
            slug: translation.translateObject(notification.contentDetails.slug) as string,
            instance: {
                id: notification.instanceId || notification.instanceDetails?.id,
                slug: notification.instanceDetails?.slug,
            },
            anchor,
        };

        return {
            linkAs: ContentLink,
            to,
        };
    }

    return null;
};

const getCommunityViewLink = (
    { notification, translation, anchor }: NotificationLinkUrlParams,
    view?: string,
    identifier?: string,
): NotificationMetaLinkAs | null => {
    if (!notification) {
        return null;
    }
    const to: Partial<CommunityLinkRef> = {
        view,
        identifier,
        instance: {
            id: notification.instanceId || notification.instanceDetails?.id,
            slug: notification.instanceDetails?.slug,
        },
        anchor,
    };
    if (view && notification.parentContentDetails?.slug) {
        to.slug = translation.translateObject(notification.parentContentDetails.slug) as string;
    } else if (notification.contentDetails?.slug) {
        to.slug = translation.translateObject(notification.contentDetails.slug) as string;
    }
    return { linkAs: CommunityLink, to, crossInstanceInNewTab: true };
};

/** Generate link to a community */
const getCommunityLink = (params: NotificationLinkUrlParams): NotificationMetaLinkAs | null => {
    return getCommunityViewLink(params);
};

/** Generate link to a post */
const getPostLink = (params: NotificationLinkUrlParams): NotificationMetaLinkAs | null => {
    const { notification } = params;
    if (!notification) {
        return null;
    }
    return getCommunityViewLink(params, 'post', notification.externalKey);
};

/** Generate link to an event */
const getEventLink = (params: NotificationLinkUrlParams): NotificationMetaLinkAs | null => {
    const { notification } = params;
    if (!notification) {
        return null;
    }
    return getCommunityViewLink(params, 'event', notification?.properties.event_id);
};

/** Generate link to an article */
const getArticleLink = (params: NotificationLinkUrlParams): NotificationMetaLinkAs | null => {
    const { notification } = params;
    if (!notification) {
        return null;
    }
    return getCommunityViewLink(params, 'article', notification?.properties.article_id);
};

/** Generate link to comment on community, post or a content  */
const getCommentLink = (params: NotificationLinkUrlParams): NotificationMetaLinkAs | null => {
    const { notification } = params;
    if (!notification) {
        return null;
    }
    if (notification.contentDetails?.type === NotificationContentType.community) {
        return getCommunityViewLink({ ...params, anchor: 'comments' });
    }
    if (notification.contentDetails?.type === NotificationContentType.post) {
        return getCommunityViewLink({ ...params, anchor: 'comments' }, 'post', notification.externalKey);
    }
    if (notification.contentDetails?.type === NotificationContentType.article) {
        return getCommunityViewLink({ ...params, anchor: 'comments' }, 'article', notification.externalKey);
    }
    if (notification.contentDetails?.type === NotificationContentType.event) {
        return getCommunityViewLink({ ...params, anchor: 'comments' }, 'event', notification.externalKey);
    }
    return getContentLink({ ...params, anchor: 'comments' });
};

/* Generate route to a learning (aka training) */
const getLearningRoute = ({ notification, currentInstanceId }: NotificationLinkUrlParams): Route | null => {
    if (!notification) {
        return null;
    }

    const route =
        notification.type === NotificationTypes.LearningDuel
            ? trainingViewDuelNotification(
                  notification?.externalKey || '',
                  notification.additionalContext?.activityId,
                  notification.additionalContext?.duelId,
              )
            : trainingView(notification?.externalKey || '');

    return handleInstanceRoute(notification, route, currentInstanceId);
};

const appId = get().applicationId as any;

/** An empty route when the generated link as is sufficient */
const getEmptyRoute = (): Route => ({ appId, path: '' });

export {
    getArticleLink,
    getEditContentRoute,
    getContentRoute,
    getContentLink,
    getEmptyRoute,
    getCommentLink,
    getPostLink,
    getEventLink,
    getCommunityLink,
    getLearningRoute,
};
