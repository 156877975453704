export const LIST_ITEM = 'list-item';
export const ORDERED_LIST = 'ordered-list';
export const UNORDERED_LIST = 'unordered-list';

/** Available list toggleable features */
export enum LIST_FEATURES {
    /** Allow ordered list element
     * 1. Like this item
     */
    orderedList = ORDERED_LIST,
    /** Allow unordered list element
     * - Like this item
     */
    unorderedList = UNORDERED_LIST,
    /**
     * Allow marker to have a color
     */
    markerColor = 'marker-color',
}
