/* istanbul ignore file */
import React from 'react';

import { renderApplication } from '@apps/core/render';
import ReactDOM from 'react-dom';

import '@apps/core/utils/publicPath';

// Must import base style (LumX) before react component CSS
import './index.scss';

import { App } from './components/App';

const app = document.getElementById('front-office-app');

/**
 * The main div has a loading class that allows us to show specific custom css while
 * the JS bundles are been loaded. With this we remove that class and allow the React
 * application to take over.
 */
app.removeAttribute('class');

renderApplication({
    render: () => {
        ReactDOM.render(<App />, app);
    },
});
