import React from 'react';

import { useClassnames, classnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { REAL_SIZE_FOR_LUMX_SIZE } from '@lumapps/lumx/constants';
import {
    Avatar,
    Size,
    Button,
    Emphasis,
    AvatarSize,
    LinkProps,
    ButtonProps,
    AvatarProps,
    TooltipProps,
    Tooltip,
} from '@lumapps/lumx/react';

import { useUserAvatar } from '../../hooks/useUserAvatar';
import { MinimumViableUser } from '../../types';

import './index.scss';

export interface UserAvatarProps extends Partial<AvatarProps> {
    /** user for which the avatar will be displayed */
    user?: MinimumViableUser;
    /** Custom? className */
    className?: string;
    /** Action when the link is clicked */
    onClick?(): void;
    /** scope where this component is used. This prop will be used for tracking purposes */
    scope: string;
    /** Avatar size. */
    size?: AvatarSize;
    /** whether the avatar should be displayed as an image, as a button or as a link. */
    avatarAs?: 'image' | 'button' | 'link';
    /** converts the avatar into a link */
    linkUrl?: string;
    /** additional props */
    buttonProps?: ButtonProps | LinkProps;
    /** props for a tooltip that will surround the button or link. */
    tooltipProps?: Partial<TooltipProps>;
}

export const CLASSNAME = 'user-avatar';

/**
 * Displays a User's avatar, while managing different types of displays, either as an image (default),
 * a link or a button. Use this component if you need to display a user's avatar in our product.
 */
export const UserAvatar: React.FC<UserAvatarProps> = React.forwardRef<HTMLButtonElement, UserAvatarProps>(
    (props: UserAvatarProps, ref) => {
        const {
            user,
            onClick,
            className,
            scope,
            size = Size.m,
            avatarAs = 'image',
            buttonProps,
            linkUrl,
            tooltipProps,
            ...forwardedProps
        } = props;
        const { get } = useDataAttributes(scope);
        const { block } = useClassnames(CLASSNAME);

        const isImage = avatarAs === 'image';
        const isLink = avatarAs === 'link';

        const imgProps = {
            width: REAL_SIZE_FOR_LUMX_SIZE[size],
            height: REAL_SIZE_FOR_LUMX_SIZE[size],
        };

        const { alt, getUserProfilePicture } = useUserAvatar(user, { customUserAvatar: forwardedProps.image });

        const AvatarComponent = (
            <Avatar
                className={isImage ? classnames(className, block()) : undefined}
                image={getUserProfilePicture()}
                size={size}
                alt={alt}
                {...get({ element: 'avatar', action: !isImage ? 'image' : undefined })}
                {...forwardedProps}
                thumbnailProps={{ ...forwardedProps.imgProps, imgProps }}
            />
        );

        if (isImage) {
            return AvatarComponent;
        }

        const AvatarButtonComponent = (
            <Button
                ref={ref}
                className={classnames(className, block({ [`${avatarAs}`]: Boolean(avatarAs) }))}
                onClick={onClick}
                role={linkUrl && isLink ? 'link' : 'button'}
                emphasis={Emphasis.low}
                // Transforms the button into a proper <a> link with the LumX button style.
                href={isLink ? linkUrl : undefined}
                {...buttonProps}
                target="_self"
                {...get({ element: 'avatar' })}
            >
                {AvatarComponent}
            </Button>
        );

        if (!tooltipProps) {
            return AvatarButtonComponent;
        }

        return <Tooltip {...tooltipProps}>{AvatarButtonComponent}</Tooltip>;
    },
);
