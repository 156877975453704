import { insertAt } from '@lumapps/utils/array/insertAt';

import { DEFAULT_FILTER, ENUMARABLE_TABS, FILTER_TYPES } from '../constants';
import { SEARCH } from '../keys';
import { SearchFilter } from '../types';

/**
 * TODO: Temporary fix to get the ALL tab for Coveo.
 * Should be removed when search settings V2 are available.
 * The backend will send the Tab directly in the search response
 */
export const addDefaultFilter = ({
    filters,
    customFilters,
}: {
    filters: SearchFilter[];
    customFilters: SearchFilter[];
}) => {
    /**
     * If there are no tabs or
     * if the Tab all is already set
     * skip
     */
    if (
        (filters.length === 0 && customFilters.length === 0) ||
        filters.findIndex((filter) => filter.value === DEFAULT_FILTER) >= 0
    ) {
        return filters;
    }

    const allTotalCount = filters.reduce((acc, item) => {
        // only count enumerable tabs
        if (!item || !ENUMARABLE_TABS.includes(item.value)) {
            return acc;
        }

        if (item.totalCount) {
            return acc + item.totalCount;
        }

        if (item.count) {
            return acc + item.count;
        }

        return acc;
    }, 0);

    // find out the index where the tab should be added
    const filterAll: SearchFilter = {
        value: DEFAULT_FILTER,
        type: FILTER_TYPES.LUMAPPS,
        label: SEARCH.ALL_TAB,
        count: allTotalCount,
        totalCount: allTotalCount,
    };

    return insertAt(filters, filterAll, 0);
};
