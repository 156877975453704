import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { mdiClose } from '@lumapps/lumx/icons';
import { Chip, ChipGroup, ChipProps, Icon, Tooltip, type Theme } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { getWithSelector } from '@lumapps/utils/function/selector/getWithSelector';
import { Selector } from '@lumapps/utils/function/selector/types';
import { mergeRefs } from '@lumapps/utils/react/mergeRefs';
import { isComponentType } from '@lumapps/utils/types/isComponentType';

import { useFocusLastChipOnBackspace } from '../../hooks/useFocusLastChipOnBackspace';
import { LUMX_INPUTS } from '../../keys';

import './index.scss';

export interface SelectionChipGroupProps<O> {
    /**
     * Option object id selector (either the property name or a function to get the id)
     */
    getOptionId?: Selector<O>;
    /**
     * Option object name selector (either the property name or a function to get the name)
     * By default it reuses the `getOptionId`
     */
    getOptionName?: Selector<O>;
    /**
     * Selected options array
     */
    value?: O[];
    /**
     * Callback on option array selected
     */
    onChange?(newValue?: O[]): void;
    /**
     * Input ref to restore focus
     */
    inputRef?: React.RefObject<HTMLInputElement>;
    /**
     * Input label, used to generate the chip group aria description
     */
    inputLabel?: string;
    /**
     * Customize how chips should render
     */
    renderChip?: (option: O) => React.ReactNode;
    /**
     * Scope for tracking purposes
     */
    scope?: string;
    /**
     * LumX theme
     */
    theme?: Theme;
}

const CLASSNAME = 'lumx-selection-chip-group';

/**
 * Selection chip group listing currently selected options in a chip list.
 *
 * Remove value on:
 * - Activated (click or Enter pressed)
 * - Backspace pressed (also moves focus on previous chip or the input)
 */
export const SelectionChipGroup = <O,>({
    onChange,
    value,
    getOptionId,
    getOptionName,
    inputRef,
    inputLabel,
    renderChip,
    scope = CLASSNAME,
    theme,
}: SelectionChipGroupProps<O>) => {
    const { translateKey, translateAndReplace } = useTranslate();
    const { block, element } = useClassnames(CLASSNAME);
    const { get } = useDataAttributes(scope);
    const chipRefs = React.useRef<React.RefObject<HTMLElement>[]>([]);
    const lastChipRef = React.useRef(null);
    useFocusLastChipOnBackspace(lastChipRef, inputRef);

    const label = translateAndReplace(LUMX_INPUTS.SELECTED_VALUES_LIST_LABEL, {
        SELECT_LABEL: inputLabel || translateKey(GLOBAL.VALUES),
    });
    return (
        <ChipGroup role="group" aria-label={label} className={block()}>
            {value?.map((v, index) => {
                const name = getWithSelector(getOptionName, v);
                const id = getWithSelector(getOptionId, v);
                const onClick = () => {
                    const newValue = [...value];
                    const existingIndex = value.findIndex((v) => getWithSelector(getOptionId, v) === id);
                    if (existingIndex === -1) {
                        return;
                    }
                    // Remove value
                    newValue.splice(existingIndex, 1);

                    onChange?.(newValue);
                };
                const onKeyDown = (evt: React.KeyboardEvent) => {
                    if (evt.key !== 'Backspace') {
                        return;
                    }
                    // Activate (remove value) on Backspace pressed
                    onClick();

                    const previousChip = chipRefs.current[index - 1]?.current;
                    const input = inputRef?.current;
                    // Focus the previous chip or the input
                    (previousChip || input)?.focus();
                };

                if (!chipRefs.current[index]) {
                    chipRefs.current[index] = React.createRef<HTMLElement>();
                }
                let ref: React.Ref<HTMLElement> | undefined = chipRefs.current[index];

                if (index === value.length - 1) {
                    // Set ref for the last chip
                    ref = mergeRefs([ref, lastChipRef]);
                }

                const customChip = renderChip?.(v);
                const props = isComponentType(Chip, customChip) ? (customChip.props as ChipProps) : undefined;

                return (
                    <Tooltip
                        key={id}
                        label={`${typeof props?.children === 'string' ? props.children : name} - ${translateKey(
                            GLOBAL.REMOVE,
                        )}`}
                        closeMode="hide"
                        ariaLinkMode="aria-labelledby"
                    >
                        <Chip
                            {...get({ element: 'chip', action: id })}
                            {...props}
                            after={<Icon icon={mdiClose} />}
                            className={element('chip', [props?.className])}
                            size="s"
                            ref={ref as any}
                            onClick={onClick}
                            onKeyDown={onKeyDown}
                            theme={theme}
                        >
                            {props?.children || name}
                        </Chip>
                    </Tooltip>
                );
            })}
        </ChipGroup>
    );
};
