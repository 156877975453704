import isNil from 'lodash/isNil';

import { getMediaContent } from '@lumapps/medias/utils';
import { ENGINES, SEARCH_RESULT_TYPES } from '@lumapps/search/constants';
import { SEARCH_SOURCE_TYPES } from '@lumapps/search/keys/sourceTypes';
import { BaseSearchResult, SharePointSearchResult } from '@lumapps/search/types';
import { TranslationAPI } from '@lumapps/translations';
import { getExtensionFromName } from '@lumapps/utils/file/getExtensionFromName';
import { decodeURIComponentSafe } from '@lumapps/utils/string/uriComponent';

import { getIconFromType } from '../../helpers';
import { props as contentMetadataProps, getMetadataTimestampLabel } from '../ContentMetadata/props';
import { MediaResultProps } from './MediaResult';

export interface MediaResultPropsOptions {
    translate: TranslationAPI['translate'];
    engine?: ENGINES;
    isAuthorVisible?: boolean;
}

const lumappsProps = (result: BaseSearchResult, baseProps: any, options: any) => {
    const { url, name, snippet, onClick, flag, quickViewUrl, onQuickViewClick } = baseProps;
    const bProps = {
        url,
        name,
        snippet,
        mimeType: '',
        image: undefined,
        flag,
        quickViewUrl,
        onQuickViewClick,
    };

    const { media } = result;

    if (!media) {
        return bProps;
    }

    const { translate } = options;
    const metadata = contentMetadataProps(result, options);
    const iconName = getIconFromType(media.mimeType, getExtensionFromName(name));
    /**
     * Need to firstly decode the URL to avoid to encode twice %
     * And then encode it to have a valid url
     */
    const encodedUrl = encodeURI(decodeURIComponentSafe(media.url || ''));
    return {
        ...bProps,
        instanceDetails: media?.instanceDetails,
        metadata,
        thumbnail: media.thumbnail,
        url: encodedUrl,
        mimeType: media.mimeType,
        onClick,
        position: result.position,
        id: media.uid,
        iconName,
        type: SEARCH_RESULT_TYPES.MEDIA,
        kind: translate(SEARCH_SOURCE_TYPES.MEDIA),
    };
};

const sharepointProps = (result: SharePointSearchResult, baseProps: any, options: any): MediaResultProps => {
    const { translate } = options;
    const content = getMediaContent(result, translate);
    /**
     * If we are currently using the old sharepoint endpoint (/list not /search)
     * sometimes the name is not in the content but in the root result.
     */
    const { name, fileId, mimeType, type, url } = content;
    const resultName = !isNil(name) ? name : result.name;
    const translatedResultName = translate(resultName);

    const sharepointType = result.properties?.type || mimeType;
    const iconName = getIconFromType(sharepointType, getExtensionFromName(translatedResultName));
    const metadataLabel = getMetadataTimestampLabel(
        {
            updateTime: result.updatedAt,
            createTime: result.createdAt,
        },
        { useUpdateTime: true, translate },
    );

    return {
        id: fileId || result.uid,
        iconName,
        mimeType: mimeType || type,
        name: translatedResultName,
        metadata: {
            metadata: [metadataLabel],
        },
        url: url || '',
        onClick: baseProps.onClick,
        onQuickViewClick: baseProps.onClick,
    };
};

const engineProps: Record<ENGINES, any> = {
    [ENGINES.DRIVE]: null,
    [ENGINES.LUMAPPS]: lumappsProps,
    [ENGINES.COVEO]: lumappsProps,
    [ENGINES.LUMWORK]: null,
    [ENGINES.ZENDESK]: null,
    [ENGINES.SHAREPOINT]: sharepointProps,
    [ENGINES.MICROSOFT]: lumappsProps,
};

const props = (result: BaseSearchResult | SharePointSearchResult, baseProps: any, options: MediaResultPropsOptions) => {
    const { engine = ENGINES.LUMAPPS } = options;
    const propsGeneratorForEngine = engineProps[engine];

    return propsGeneratorForEngine ? propsGeneratorForEngine(result, baseProps, options) : {};
};

export { props };
