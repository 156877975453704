export const WIDGET_HTML_TYPE = 'html';

/** Classname of an html widget 'block' in legacy (used in the widget summary for generation)
 *
 * **WARNING**: When the widget html will use the react BlockHtml, an update of the classname
 * needs to be done on the widget summary
 */
export const LEGACY_BLOCK_HTML_CLASSNAME = 'widget-html__content';

/** Classname of an html widget in edition in legacy (used in the widget summary for generation)
 *
 * **WARNING**: When the widget html will use a react EditableWidgetHtml, an update of the classname
 * needs to be done on the widget summary
 */
export const LEGACY_EDITION_HTML_CLASSNAME = 'widget-html__wysiwyg';

export const BLOCK_HTML_CLASSNAME = 'block-html';

/**
 * Contains :
 *  - Default list of fraola tags (https://froala.com/wysiwyg-editor/docs/options/#htmlAllowedTags)
 *  - Some needed tags
 */
export const HTML_ALLOWED_TAGS = [
    'a',
    'abbr',
    'address',
    'area',
    'article',
    'aside',
    'audio',
    'b',
    'base',
    'bdi',
    'bdo',
    'blockquote',
    'br',
    'button',
    'canvas',
    'caption',
    'cite',
    'code',
    'col',
    'colgroup',
    'datalist',
    'dd',
    'del',
    'details',
    'dfn',
    'dialog',
    'div',
    'dl',
    'dt',
    'em',
    'embed',
    'fieldset',
    'figcaption',
    'figure',
    'footer',
    'form',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'header',
    'hgroup',
    'hr',
    'i',
    'iframe',
    'img',
    'input',
    'ins',
    'kbd',
    'keygen',
    'label',
    'legend',
    'li',
    'link',
    'main',
    'map',
    'mark',
    'menu',
    'menuitem',
    'meter',
    'nav',
    'noscript',
    'object',
    'ol',
    'optgroup',
    'option',
    'output',
    'p',
    'param',
    'pre',
    'progress',
    'queue',
    'rp',
    'rt',
    'ruby',
    's',
    'samp',
    'script',
    'style',
    'section',
    'select',
    'small',
    'source',
    'span',
    'strike',
    'strong',
    'sub',
    'summary',
    'sup',
    'table',
    'tbody',
    'td',
    'textarea',
    'tfoot',
    'th',
    'thead',
    'time',
    'tr',
    'track',
    'u',
    'ul',
    'var',
    'video',
    'wbr',
    'altGlyph',
    'altGlyphDef',
    'altGlyphItem',
    'animate',
    'animateColor',
    'animateMotion',
    'animateTransform',
    'circle',
    'clipPath',
    'color-profile',
    'cursor',
    'defs',
    'desc',
    'discard',
    'ellipse',
    'feBlend',
    'feColorMatrix',
    'feComponentTransfer',
    'feComposite',
    'feConvolveMatrix',
    'feDiffuseLighting',
    'feDisplacementMap',
    'feDistantLight',
    'feDropShadow',
    'feFlood',
    'feFuncA',
    'feFuncB',
    'feFuncG',
    'feFuncR',
    'feGaussianBlur',
    'feImage',
    'feMerge',
    'feMergeNode',
    'feMorphology',
    'feOffset',
    'fePointLight',
    'feSpecularLighting',
    'feSpotLight',
    'feTile',
    'feTurbulence',
    'filter',
    'font',
    'font-face',
    'font-face-format',
    'font-face-name',
    'font-face-src',
    'font-face-uri',
    'foreignObject',
    'g',
    'glyph',
    'glyphRef',
    'hatch',
    'hatchpath',
    'hkern',
    'image',
    'line',
    'linearGradient',
    'marker',
    'mask',
    'mesh',
    'meshgradient',
    'meshpatch',
    'meshrow',
    'metadata',
    'missing-glyph',
    'mpath',
    'path',
    'pattern',
    'polygon',
    'polyline',
    'radialGradient',
    'rect',
    'set',
    'solidcolor',
    'stop',
    'svg',
    'switch',
    'symbol',
    'text',
    'textPath',
    'title',
    'tref',
    'tspan',
    'use',
    'view',
    'vkern',
    'twitterwidget',
    'tableau-viz',
    'data',
    'q',
    'rb',
    'rtc',
] as const;

/**
 * Contains :
 *  - Default list of fraola attributes (https://froala.com/wysiwyg-editor/docs/options/#htmlAllowedAttrs)
 *  - Some needed attributes
 */
export const HTML_ALLOWED_ATTRIBUTES = [
    'accept',
    'accept-charset',
    'accesskey',
    'action',
    'align',
    'allowfullscreen',
    'allowtransparency',
    'alt',
    'async',
    'autocomplete',
    'autofocus',
    'autoplay',
    'autosave',
    'background',
    'bgcolor',
    'border',
    'charset',
    'cellpadding',
    'cellspacing',
    'checked',
    'cite',
    'class',
    'color',
    'cols',
    'colspan',
    'content',
    'contenteditable',
    'contextmenu',
    'controls',
    'coords',
    'data',
    'data-.*',
    'datetime',
    'default',
    'defer',
    'dir',
    'dirname',
    'disabled',
    'download',
    'draggable',
    'dropzone',
    'enctype',
    'for',
    'form',
    'formaction',
    'frameborder',
    'headers',
    'height',
    'hidden',
    'high',
    'href',
    'hreflang',
    'http-equiv',
    'icon',
    'id',
    'ismap',
    'itemprop',
    'keytype',
    'kind',
    'label',
    'lang',
    'language',
    'list',
    'loop',
    'low',
    'max',
    'maxlength',
    'media',
    'method',
    'min',
    'mozallowfullscreen',
    'multiple',
    'muted',
    'name',
    'novalidate',
    'open',
    'optimum',
    'pattern',
    'ping',
    'placeholder',
    'playsinline',
    'poster',
    'preload',
    'pubdate',
    'radiogroup',
    'readonly',
    'rel',
    'required',
    'reversed',
    'rows',
    'rowspan',
    'sandbox',
    'scope',
    'scoped',
    'scrolling',
    'seamless',
    'selected',
    'shape',
    'size',
    'sizes',
    'span',
    'src',
    'srcdoc',
    'srclang',
    'srcset',
    'start',
    'step',
    'summary',
    'spellcheck',
    'style',
    'tabindex',
    'target',
    'title',
    'type',
    'translate',
    'usemap',
    'value',
    'valign',
    'webkitallowfullscreen',
    'width',
    'wrap',
    'accent-height',
    'accumulate',
    'additive',
    'alignment-baseline',
    'allowReorder',
    'alphabetic',
    'amplitude',
    'arabic-form',
    'ascent',
    'attributeName',
    'attributeType',
    'autoReverse',
    'azimuth',
    'baseFrequency',
    'baseline-shift',
    'baseProfile',
    'bbox',
    'begin',
    'bias',
    'by',
    'calcMode',
    'cap-height',
    'clip',
    'clipPathUnits',
    'clip-path',
    'clip-rule',
    'color-interpolation',
    'color-interpolation-filters',
    'color-profile',
    'color-rendering',
    'contentScriptType',
    'contentStyleType',
    'cursor',
    'cx',
    'cy',
    'd',
    'decelerate',
    'descent',
    'diffuseConstant',
    'direction',
    'display',
    'divisor',
    'dominant-baseline',
    'dur',
    'dx',
    'dy',
    'edgeMode',
    'elevation',
    'enable-background',
    'end',
    'exponent',
    'externalResourcesRequired',
    'fill',
    'fill-opacity',
    'fill-rule',
    'filter',
    'filterRes',
    'filterUnits',
    'flood-color',
    'flood-opacity',
    'font-family',
    'font-size',
    'font-size-adjust',
    'font-stretch',
    'font-style',
    'font-variant',
    'font-weight',
    'format',
    'from',
    'fr',
    'fx',
    'fy',
    'g1',
    'g2',
    'glyph-name',
    'glyph-orientation-horizontal',
    'glyph-orientation-vertical',
    'glyphRef',
    'gradientTransform',
    'gradientUnits',
    'hanging',
    'horiz-adv-x',
    'horiz-origin-x',
    'ideographic',
    'image-rendering',
    'in',
    'in2',
    'intercept',
    'k',
    'k1',
    'k2',
    'k3',
    'k4',
    'kernelMatrix',
    'kernelUnitLength',
    'kerning',
    'keyPoints',
    'keySplines',
    'keyTimes',
    'lengthAdjust',
    'letter-spacing',
    'lighting-color',
    'limitingConeAngle',
    'local',
    'marker-end',
    'marker-mid',
    'marker-start',
    'markerHeight',
    'markerUnits',
    'markerWidth',
    'mask',
    'maskContentUnits',
    'maskUnits',
    'mathematical',
    'mode',
    'numOctaves',
    'offset',
    'opacity',
    'operator',
    'order',
    'orient',
    'orientation',
    'origin',
    'overflow',
    'overline-position',
    'overline-thickness',
    'panose-1',
    'paint-order',
    'path',
    'pathLength',
    'patternContentUnits',
    'patternTransform',
    'patternUnits',
    'pointer-events',
    'points',
    'pointsAtX',
    'pointsAtY',
    'pointsAtZ',
    'preserveAlpha',
    'preserveAspectRatio',
    'primitiveUnits',
    'r',
    'radius',
    'referrerPolicy',
    'refX',
    'refY',
    'rendering-intent',
    'repeatCount',
    'repeatDur',
    'requiredExtensions',
    'requiredFeatures',
    'restart',
    'result',
    'rotate',
    'rx',
    'ry',
    'scale',
    'seed',
    'shape-rendering',
    'slope',
    'spacing',
    'specularConstant',
    'specularExponent',
    'speed',
    'spreadMethod',
    'startOffset',
    'stdDeviation',
    'stemh',
    'stemv',
    'stitchTiles',
    'stop-color',
    'stop-opacity',
    'strikethrough-position',
    'strikethrough-thickness',
    'string',
    'stroke',
    'stroke-dasharray',
    'stroke-dashoffset',
    'stroke-linecap',
    'stroke-linejoin',
    'stroke-miterlimit',
    'stroke-opacity',
    'stroke-width',
    'surfaceScale',
    'systemLanguage',
    'tableValues',
    'targetX',
    'targetY',
    'text-anchor',
    'text-decoration',
    'text-rendering',
    'textLength',
    'to',
    'transform',
    'u1',
    'u2',
    'underline-position',
    'underline-thickness',
    'unicode',
    'unicode-bidi',
    'unicode-range',
    'units-per-em',
    'v-alphabetic',
    'v-hanging',
    'v-ideographic',
    'v-mathematical',
    'values',
    'vector-effect',
    'version',
    'vert-adv-y',
    'vert-origin-x',
    'vert-origin-y',
    'viewBox',
    'viewTarget',
    'visibility',
    'widths',
    'word-spacing',
    'writing-mode',
    'x',
    'x-height',
    'x1',
    'x2',
    'xChannelSelector',
    'xlink:actuate',
    'xlink:arcrole',
    'xlink:href',
    'xlink:role',
    'xlink:show',
    'xlink:title',
    'xlink:type',
    'xml:base',
    'xml:lang',
    'xml:space',
    'xmlns',
    'xmlns:xlink',
    'y',
    'y1',
    'y2',
    'yChannelSelector',
    'z',
    'zoomAndPan',
    'ng-click',
    'onclick',
    'ui-sref',
    'ng-href',
    'role',
    'loading',
    'aria-autocomplete',
    'aria-checked',
    'aria-current',
    'aria-disabled',
    'aria-errormessage',
    'aria-expanded',
    'aria-haspopup',
    'aria-hidden',
    'aria-invalid',
    'aria-label',
    'aria-level',
    'aria-modal',
    'aria-multiline',
    'aria-multiselectable',
    'aria-orientation',
    'aria-placeholder',
    'aria-pressed',
    'aria-readonly',
    'aria-required',
    'aria-selected',
    'aria-sort',
    'aria-valuemax',
    'aria-valuemin',
    'aria-valuenow',
    'aria-valuetext',
    'aria-live',
    'aria-relevant',
    'aria-atomic',
    'aria-busy',
    'aria-activedescendant',
    'aria-colcount',
    'aria-colindex',
    'aria-colspan',
    'aria-controls',
    'aria-describedby',
    'aria-details',
    'aria-errormessage',
    'aria-flowto',
    'aria-labelledby',
    'aria-owns',
    'aria-posinset',
    'aria-rowcount',
    'aria-rowindex',
    'aria-rowspan',
    'aria-setsize',
] as const;

/**
 * Contains :
 *  - Default list of fraola allowed empty tags (https://froala.com/wysiwyg-editor/docs/options/#htmlAllowedEmptyTags)
 *  - Some needed empty tags
 */
export const HTML_ALLOWED_EMPTY_TAGS = [
    '.fa',
    '.fr-emoticon',
    '.fr-inner',
    'a',
    'altGlyph',
    'altGlyphDef',
    'altGlyphItem',
    'animate',
    'animateColor',
    'animateMotion',
    'animateTransform',
    'circle',
    'clipPath',
    'color-profile',
    'cursor',
    'defs',
    'desc',
    'discard',
    'div',
    'ellipse',
    'feBlend',
    'feColorMatrix',
    'feComponentTransfer',
    'feComposite',
    'feConvolveMatrix',
    'feDiffuseLighting',
    'feDisplacementMap',
    'feDistantLight',
    'feDropShadow',
    'feFlood',
    'feFuncA',
    'feFuncB',
    'feFuncG',
    'feFuncR',
    'feGaussianBlur',
    'feImage',
    'feMerge',
    'feMergeNode',
    'feMorphology',
    'feOffset',
    'fePointLight',
    'feSpecularLighting',
    'feSpotLight',
    'feTile',
    'feTurbulence',
    'filter',
    'font',
    'font-face',
    'font-face-format',
    'font-face-name',
    'font-face-src',
    'font-face-uri',
    'foreignObject',
    'g',
    'glyph',
    'glyphRef',
    'hatch',
    'hatchpath',
    'hkern',
    'i',
    'iframe',
    'image',
    'line',
    'linearGradient',
    'marker',
    'mask',
    'mesh',
    'meshgradient',
    'meshpatch',
    'meshrow',
    'metadata',
    'missing-glyph',
    'mpath',
    'object',
    'path',
    'pattern',
    'polygon',
    'polyline',
    'radialGradient',
    'rect',
    'script',
    'set',
    'solidcolor',
    'span',
    'stop',
    'style',
    'svg',
    'switch',
    'symbol',
    'tableau-viz',
    'text',
    'textPath',
    'textarea',
    'title',
    'tref',
    'tspan',
    'twitterwidget',
    'use',
    'video',
    'view',
    'vkern',
] as const;
