import React from 'react';

import { useDataAttributes } from '@lumapps/data-attributes';
import { Menu } from '@lumapps/lumx-menu/components/Menu';
import { Link as RouterLink } from '@lumapps/router';
import { useTranslate } from '@lumapps/translations';

import { BROADCAST_ICON } from '../../constants';
import { BROADCASTS } from '../../keys';
import { broadcastRoutes } from '../../routes';

export interface BroadcastSettingMenuProps {
    /** whether the user is allowed to access this page */
    isAllowed?: boolean;
    /** scope for data-ids */
    scope: string;
}

/**
 * The broadcast entry in the front-office cogwheel (settings)
 *
 * @param BroadcastSettingMenuProps
 * @returns BroadcastSettingMenu
 */
export const BroadcastSettingMenu: React.FC<BroadcastSettingMenuProps> = ({ isAllowed, scope }) => {
    const { translateKey } = useTranslate();

    const { get } = useDataAttributes(scope);

    if (!isAllowed) {
        return null;
    }

    return (
        <Menu.Item
            icon={BROADCAST_ICON}
            as={RouterLink}
            to={broadcastRoutes.getDefaultRoute()}
            {...get({ element: 'access-broadcast' })}
        >
            {translateKey(BROADCASTS.TITLE)}
        </Menu.Item>
    );
};

BroadcastSettingMenu.displayName = 'BroadcastSettingMenu';
