import React from 'react';

import { margin, useClassnames } from '@lumapps/classnames';
import { mdiFilterVariant, mdiMenuDown } from '@lumapps/lumx/icons';
import { Icon, Chip, Size, ChipProps, Badge, ColorPalette, Text, Theme } from '@lumapps/lumx/react';
import { useTranslate, GLOBAL } from '@lumapps/translations';

import { useFilterLabel } from '../../hooks/useFilterLabel';

import './index.scss';

export interface FilterTriggerProps {
    /** override the label displayed for the chip that will open the dropdown  */
    label?: string;
    /** whether the dropdown is open or not */
    isFilterDropdownOpen?: boolean;
    /** callback for setting whether the dropdown is open or not */
    setIsFilterDropdownOpen: (isFilterDropdownOpen: boolean, event?: any) => void;
    /** whether the dropdown is disabled or not */
    isDisabled?: boolean;
    /** list of selected filters */
    selectedFilter?: string[];
    /** props to be passed down to the dropdown trigger */
    triggerProps?: Partial<ChipProps>;
    /** reference for the trigger */
    anchorRef: React.RefObject<any>;
    /** theme */
    theme?: Theme;
}

/**
 * Component to be used if you want to display the Filters Trigger as loading.
 */
export const LoadingFilterTrigger: React.FC = () => {
    const { translateKey } = useTranslate();

    return (
        <Chip
            before={<Icon icon={mdiFilterVariant} size={Size.xs} />}
            isDisabled
            aria-label={translateKey(GLOBAL.FILTERS)}
        >
            {translateKey(GLOBAL.FILTERS)}
        </Chip>
    );
};

const CLASSNAME = 'filter-trigger';

/**
 * Component that displays the name of the filter as well as the values selected for it. Upon clikcing this
 * component, the filter associated to it should be triggered or displayed.
 * @family Filters
 * @param FilterTriggerProps
 * @returns FilterTrigger
 */
export const FilterTrigger: React.FC<FilterTriggerProps> = ({
    label,
    setIsFilterDropdownOpen,
    isFilterDropdownOpen = false,
    selectedFilter = [],
    triggerProps,
    isDisabled = false,
    anchorRef,
    theme = Theme.light,
}) => {
    const toggleDropdown = (event: any) => {
        event.persist();

        setIsFilterDropdownOpen(!isFilterDropdownOpen, event);
    };
    const { element } = useClassnames(CLASSNAME);
    const isSelected = selectedFilter.length > 0;
    const { baseFilterLabel, filtersLabel } = useFilterLabel({
        label,
        selectedFilter,
    });

    return (
        <Chip
            theme={theme}
            before={<Icon icon={mdiFilterVariant} size={Size.xs} />}
            after={selectedFilter.length === 0 ? <Icon icon={mdiMenuDown} size={Size.xs} /> : null}
            isSelected={isSelected}
            onClick={toggleDropdown}
            onAfterClick={toggleDropdown}
            className={element('trigger')}
            isDisabled={isDisabled}
            aria-expanded={isFilterDropdownOpen}
            {...triggerProps}
            ref={anchorRef}
            aria-label={filtersLabel}
        >
            <Text as="span" className={selectedFilter.length > 0 ? margin('right') : undefined}>
                {baseFilterLabel}
            </Text>

            {isSelected ? (
                <Badge color={ColorPalette.primary}>
                    <Text as="span">{selectedFilter.length}</Text>
                </Badge>
            ) : null}
        </Chip>
    );
};
