import { get as getConstants } from '@lumapps/constants';
import { isUserAllowed } from '@lumapps/permissions';
import { createSelector } from '@lumapps/redux/reselect';
import { BaseStore } from '@lumapps/redux/types';

import { WidgetStyle, STYLE_TYPES, StyleState } from '../types';

const constants = getConstants();

const isStyleAllowed = isUserAllowed();

const internalStylesSelector = (state: BaseStore) => state.style || state.styles || {};

const stylesSelector = createSelector(internalStylesSelector, (styles) => styles);

const getStyleProperties = createSelector(stylesSelector, (style: StyleState) => {
    const instance = style[STYLE_TYPES.INSTANCE];
    const parent = style[STYLE_TYPES.PARENT];

    /**
     * If there is a parent style defined, it means that the nav bar inheritance feature
     * is enabled and that we need to return the parent styles instead of the instance ones,
     * since those are the styles for the inherited nav rather than the main one.
     */
    if (parent && constants.isLegacyContext) {
        return parent.properties;
    }

    return instance?.properties;
});

const getInstanceColorPalette = createSelector(getStyleProperties, (properties: any) => properties?.colors);

const getChildStyleProperties = createSelector(stylesSelector, (style: StyleState) => {
    const childStyle = style[STYLE_TYPES.CHILD];

    return childStyle?.properties;
});

const widgetStylesSelector = createSelector(
    stylesSelector,
    (style: any) => style?.widget as Record<string, WidgetStyle>,
);

export { isStyleAllowed, getStyleProperties, widgetStylesSelector, getChildStyleProperties, getInstanceColorPalette };
