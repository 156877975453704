import { TOPICS, queueEvent, Event, queueBatchedEvents } from '@lumapps/metric/analytics';

import { sendTracking } from './api';
import {
    LumappsTrackEventPayload,
    LumappsTrackingActionType,
    LumappsTrackingEvent,
    LumappsTrackingEventContext,
} from './types';
import { getEventBaseData } from './utils';

export const lumappsTrackingEventHandler = (events: Event[]) => {
    for (const event of events) {
        const data = event.payload as LumappsTrackingEvent;
        sendTracking(data);
    }
};

const fillAndSendLumappsTrackingEvent = (
    eventActions: LumappsTrackingActionType[],
    overrideContext: Partial<Pick<LumappsTrackingEventContext, 'locale' | 'referrer'>> = {},
    isBatched: boolean = false,
) => {
    const eventBaseData = getEventBaseData();

    const data: Record<string, LumappsTrackingEvent[]> = {
        events: eventActions.map((eventAction) => ({
            ...eventBaseData,
            action: eventAction,
            ...overrideContext,
        })),
    };

    if (isBatched) {
        queueBatchedEvents({ payload: data, topic: TOPICS.TRACKING_FRONT }, lumappsTrackingEventHandler);
    } else {
        queueEvent({ payload: data, topic: TOPICS.TRACKING_FRONT }, lumappsTrackingEventHandler);
    }
};

export const lumappsAnalyticsPlugin = {
    /* Everything else below this is optional depending on your plugin requirements */
    track: ({ payload }: { payload: LumappsTrackEventPayload | LumappsTrackEventPayload[] }) => {
        const actions = Array.isArray(payload) ? payload.map((p) => p.action) : [payload.action];
        fillAndSendLumappsTrackingEvent(actions);
    },
    trackAndBatch: ({ payload }: { payload: LumappsTrackEventPayload }) => {
        const actions = Array.isArray(payload) ? payload.map((p) => p.action) : [payload.action];
        fillAndSendLumappsTrackingEvent(actions, {}, true);
    },
};
