import { DetailedDirectoryEntryBlockProps } from '@lumapps/directories-entries/types';
import { ObjectValues } from '@lumapps/utils/types/ObjectValues';

import { ASK_AI_REASON_TYPES } from './constants';

/** Parameters to give the "ask/search" endpoint */
export interface AskSearchParams {
    /** The query to search for */
    query: string;
    /** The id on which to search */
    siteId: string;
    /**
     * The languages to search in.
     * Will be sent as Accepted-languages header
     */
    acceptLanguageHeader?: string;
    /**
     * Unique ID to identify each search.
     * This ID can be used to link multiple search queries together (ex: omnisearch and ask ai)
     */
    traceIdHeader?: string;
}

export enum AskAiDocumentTypes {
    POST = 'post',
    CONTENT = 'content',
    COMMUNITY = 'community',
    DIRECTORY_ENTRY = 'directoryEntry',
    DIRECTORY_ENTRY_MICROAPP = 'directoryentry_microapp',
    MEDIA = 'media',
    USER = 'user',
    GENERIC_DOCUMENT = 'genericDocument',
}

export enum AskAiDirectoryEntryType {
    DIRECTORY_ENTRY_LINK = 'directoryentry_link',
    DIRECTORY_ENTRY_MICROAPP = 'directoryentry_microapp',
}

export interface AskAiSource {
    /** id of the source */
    id: string;
    /** order the source in frontend */
    sourceIndex: number;
    /** title of the source */
    title: string;
    /** absolute URL needed even for LumApps content */
    url: string;
    /** type of document */
    type: AskAiDocumentTypes;
    // Si generic Document alors on a besoin d'un mimeType
    mimeType?: string;
    /** Used for custom content type contents to have their custom icon */
    icon?: string;
}
/**
 * Answer given for the UserCard
 */
export interface UserBestAnswer {
    id: string;
    fullName?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    jobTitle?: string;
    locationName?: string;
    department?: string;
    /** whether the user is already followed */
    isFollowed?: boolean;
}

/** Best answer for image */
export interface ImageBestAnswer {
    /** url of the image */
    url: string;
    /** title of the image */
    title: string;
    /** description of the image */
    description?: string;
    /** alt text for the image */
    altText?: string;
}

/** Best answer for directory entry */
export interface DirectoryEntryBestAnswer {
    /** title of the directory entry */
    name: string;
    /** microapp or link */
    directoryEntryType: AskAiDirectoryEntryType;
    /** directory entry id */
    id: string;
    /** parent directory id */
    directoryId: string;
    /** link of the directory entry */
    url: string;
    /** resource id of the microapp */
    resourceId?: string;
    /** thumbnail of the directory entry */
    thumbnail?: string;
    /** mostly used for description */
    description?: string;
    /** tags of the entry */
    tags?: DetailedDirectoryEntryBlockProps['tags'];
    /** metadata of the entry */
    metadata?: DetailedDirectoryEntryBlockProps['metadata'];
}

/** Types of actionable item that can be used in a rule */
export enum ACTIONABLE_ITEM_TYPES {
    MICRO_APP = 'micro_app',
    USER_CARD = 'user',
    IMAGES = 'images',
    DIRECTORY_ENTRY = 'directory_entry',
}

export interface MicroAppActionableItemPayload {
    type: ACTIONABLE_ITEM_TYPES.MICRO_APP;
    payload: {
        id: string;
    };
}

export interface UserCardActionableItemPayload {
    type: ACTIONABLE_ITEM_TYPES.USER_CARD;
    payload: UserBestAnswer;
}
export interface DirectoryEntryActionableItemPayload {
    type: ACTIONABLE_ITEM_TYPES.DIRECTORY_ENTRY;
    payload: DirectoryEntryBestAnswer;
}

/** payload for image actionable item */
export interface ImagesActionableItemPayload {
    type: ACTIONABLE_ITEM_TYPES.IMAGES;
    payload: { images: ImageBestAnswer[] };
}

export type ActionableItemPayload =
    | MicroAppActionableItemPayload
    | UserCardActionableItemPayload
    | ImagesActionableItemPayload
    | DirectoryEntryActionableItemPayload;

/**
 * Infos of the connector associated to an Ask AI Source
 */
export interface AskAiSourceConnector {
    id: string;
    name: string;
    providerType: string;
}

/**
 * Object received with an Ask AI answer when a source needs the user to Open Authorize (OAuth)
 */
export interface AskAiAuthorizationError {
    /** Error code */
    code: string;
    /** URI of the OAuth flow */
    connectUri: string;
    /** Connector associated to the source which has an authorization error */
    sourceConnector: AskAiSourceConnector;
}

/**
 * Answer given by the "ask/search" endpoint.
 */
export interface AskSearchBestAnswer {
    /** The description of the best answer */
    answer?: string;
    /** query used for the answer */
    query?: string;
    /** whether the answer is a rule set by human moderator */
    isHumanCurated?: boolean;
    /** Payload for the actionableItem to display */
    actionableItem?: ActionableItemPayload;
    /** Questions that could be related to the answer */
    relatedQuestions?: string[];
    /** list of the sources use to generate the response */
    sources?: AskAiSource[];
    /** List of authorization errors */
    authErrors?: AskAiAuthorizationError[];
}

export interface EmptyStates {
    /** Icon used for the empty state */
    icon: string;
    /** Subtitle displayed below the icon */
    subtitle: string;
    /** description used for the icon */
    description: string;
}

/** AskAI reaction */
export enum AskAiFeedbackTypes {
    LIKED = 'like',
    DISLIKED = 'dislike',
}

/** AskAi Detailed negative feedback possible reasons */
export type AskAiFeedbackReasonTypes = ObjectValues<typeof ASK_AI_REASON_TYPES>;

/** AskAi positive Feedback */
export interface AskAiPositiveFeedback {
    type: AskAiFeedbackTypes.LIKED;
}

/** AskAi negative detailed feedback */
export interface AskAiNegativeFeedback {
    type: AskAiFeedbackTypes.DISLIKED;
    /** The reason the feedback is negative, from a given list  */
    reason?: AskAiFeedbackReasonTypes;
    /** An optional comment to explain why the feedback is negative. */
    comment?: string;
}

/** AskAI detailed feedback  */
export type AskAiDetailedFeedback = AskAiPositiveFeedback | AskAiNegativeFeedback;
