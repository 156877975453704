export enum NOTIFICATIONS_CENTER {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=392431904
     */
    ARTICLE_NEW = 'NOTIFICATIONS_CENTER.ARTICLE_NEW',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453299
     */
    COMMUNITY_NEW_ACCESS_REQUEST_DESCRIPTION = 'NOTIFICATION_COMMUNITY_NEW_ACCESS_REQUEST_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453300
     */
    COMMUNITY_NEW_CREDENTIAL_DESCRIPTION = 'NOTIFICATION_COMMUNITY_NEW_CREDENTIAL_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453301
     */
    CONTENT_EXPIRATION_DESCRIPTION = 'NOTIFICATION_CONTENT_EXPIRATION_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=447851498
     */
    CONTENT_MENTION_WITH_TITLE_DESCRIPTION = 'NOTIFICATIONS_CENTER.CONTENT_MENTION_WITH_TITLE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=496741079
     */
    CONTENT_MENTION_WITH_SITE_NAME = 'NOTIFICATIONS_CENTER.CONTENT_MENTION_WITH_SITE_NAME',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453310
     */
    CONTENT_REFUSED_DESCRIPTION = 'NOTIFICATION_CONTENT_REFUSED_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453311
     */
    CONTENT_UPDATE_DESCRIPTION = 'NOTIFICATION_CONTENT_UPDATE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453312
     */
    CONTENT_VALIDATED_DESCRIPTION = 'NOTIFICATION_CONTENT_VALIDATED_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453313
     */
    CONTENT_WORKFLOW_DESCRIPTION = 'NOTIFICATION_CONTENT_WORKFLOW_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=392379100
     */
    EVENT_NEW = 'NOTIFICATIONS_CENTER.EVENT_NEW',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=392462125
     */
    EVENT_NEW_WITHOUT_TITLE = 'NOTIFICATIONS_CENTER.EVENT_NEW_WITHOUT_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=482548675
     */
    LEARNING_CONGRATULATION = 'NOTIFICATIONS_CENTER.LEARNING_CONGRATULATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=570664372
     */
    LEARNING_COURSE_BEGINNING = 'NOTIFICATIONS_CENTER.LEARNING_COURSE_BEGINNING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=570675995
     */
    LEARNING_COURSE_ENDING_TODAY = 'NOTIFICATIONS_CENTER.LEARNING_COURSE_ENDING_TODAY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=570911321
     */
    LEARNING_COURSE_ENDING_SOON = 'NOTIFICATIONS_CENTER.LEARNING_COURSE_ENDING_SOON',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=482525534
     */
    LEARNING_REMINDER = 'NOTIFICATIONS_CENTER.LEARNING_REMINDER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=523617411
     */
    LEARNING_DUEL = 'NOTIFICATIONS_CENTER.LEARNING_DUEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=523854824
     */
    LEARNING_DUEL_DEFAULT = 'NOTIFICATIONS_CENTER.LEARNING_DUEL_DEFAULT',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453321
     */
    POST_NEW_DESCRIPTION = 'NOTIFICATION_POST_NEW_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453322
     */
    POST_REPORT_NEW_DESCRIPTION = 'NOTIFICATION_POST_REPORT_NEW_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453324
     */
    POST_STATUS_UPDATE_DESCRIPTION = 'NOTIFICATION_POST_STATUS_UPDATE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453325
     */
    POST_UPDATE_DESCRIPTION = 'NOTIFICATION_POST_UPDATE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453355
     */
    NOTIFICATIONS = 'NOTIFICATIONS',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453356
     */
    NOTIFICATIONS_SETTINGS = 'NOTIFICATIONS_SETTINGS',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13454618
     */
    POST_MODERATION_DECLINE_DESCRIPTION = 'NOTIFICATION_POST_MODERATION_DECLINE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13454619
     */
    POST_MODERATION_DELETE_MORE_DESCRIPTION = 'NOTIFICATION_POST_MODERATION_DELETE_MORE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13454620
     */
    POST_MODERATION_DELETE_ONE_DESCRIPTION = 'NOTIFICATION_POST_MODERATION_DELETE_ONE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13454621
     */
    POST_MODERATION_DELETE_SPAMMER_DESCRIPTION = 'NOTIFICATION_POST_MODERATION_DELETE_SPAMMER_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=14675351
     */
    SOCIAL_ADVOCACY_NAME = 'ADMIN.FEATURES.SOCIAL_ADVOCACY.NAME',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=23302964
     */
    CONTENT_EXPIRED_DESCRIPTION = 'NOTIFICATION.CONTENT.EXPIRED.DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=23302971
     */
    CONTENT_NEW_DESCRIPTION = 'NOTIFICATION.CONTENT.NEW.DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=34388792
     */
    COMMUNITY_EXTENDED_SERVICE_MANAGEMENT_DESCRIPTION = 'NOTIFICATION.COMMUNITY.EXTENDED.SERVICE.MANAGEMENT.DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=54408167
     */
    SOCIAL_ADVOCACY_PROGRAMS_NEW_SHAREABLE_CONTENT_DESCRIPTION = 'NOTIFICATION.SOCIAL_ADVOCACY.PROGRAMS.NEW_SHAREABLE_CONTENT.DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=54437051
     */
    SOCIAL_ADVOCACY_PROGRAMS_NEW_SHAREABLE_IMAGE_DESCRIPTION = 'NOTIFICATION.SOCIAL_ADVOCACY.PROGRAMS.NEW_SHAREABLE_IMAGE.DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=54442730
     */
    SOCIAL_ADVOCACY_PROGRAMS_USER_PROMOTED_AMB_DESCRIPTION = 'NOTIFICATION.SOCIAL_ADVOCACY.PROGRAMS.USER_PROMOTED.AMB.DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=54447117
     */
    SOCIAL_ADVOCACY_PROGRAMS_USER_PROMOTED_POM_DESCRIPTION = 'NOTIFICATION.SOCIAL_ADVOCACY.PROGRAMS.USER_PROMOTED.POM.DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=600080668
     */
    NO_NEW_NOTIFICATION = 'FRONT.NOTIFICATIONS.NO_NEW_NOTIFICATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=54964508
     */
    ERROR_MESSAGE = 'FRONT.NOTIFICATIONS.ERROR_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=54964581
     */
    REFRESH_BUTTON = 'FRONT.NOTIFICATIONS.REFRESH_BUTTON',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=55144288
     */
    CONTENT_LIKE_DESCRIPTION = 'NOTIFICATION.CONTENT.LIKE.DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=55144343
     */
    CONTENT_POST_LIKE_DESCRIPTION = 'NOTIFICATION.CONTENT.POST.LIKE.DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=59492739
     */
    CLEAR_ALL_CONFIRM_DESCRIPTION = 'FRONT.NOTIFICATIONS.CLEAR_ALL.CONFIRM_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=62176797
     */
    NO_UNREAD_NOTIFICATIONS = 'NOTIFICATIONS.NO_UNREAD_NOTIFICATIONS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=62180846
     */
    MORE_THAN_X_NOTIFICATIONS_UNREAD = 'NOTIFICATIONS.MORE_THAN_X_NOTIFICATIONS_UNREAD',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=62180904
     */
    X_UNREAD_NOTIFICATIONS = 'NOTIFICATION.X_UNREAD_NOTIFICATIONS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=62180951
     */
    X_UNREAD_NOTIFICATION = 'NOTIFICATION.X_UNREAD_NOTIFICATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=62751864
     */
    FILTER_GROUP_ARIA = 'FRONT.NOTIFICATIONS.FILTER_GROUP.ARIA',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=62751924
     */
    FILTER_ALL = 'FRONT.NOTIFICATIONS.FILTER.ALL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=62751932
     */
    FILTER_UNREAD = 'FRONT.NOTIFICATIONS.FILTER.UNREAD',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=62764237
     */
    MARK_ALL_AS_READ = 'FRONT.NOTIFICATIONS.MARK_ALL_AS_READ',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=63244890
     */
    DELETE_ALL_DIALOG_TITLE = 'FRONT.NOTIFICATIONS.DELETE_ALL.DIALOG.TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=66224462
     */
    CONTENT_POST_LIKE_WITHOUT_TITLE_DESCRIPTION = 'NOTIFICATION.CONTENT.POST.LIKE.WITHOUT.TITLE.DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=66224795
     */
    POST_MENTION_WITH_TITLE_DESCRIPTION = 'NOTIFICATION.POST.MENTION.WITH.TITLE.DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=66224797
     */
    POST_MENTION_WITHOUT_TITLE_DESCRIPTION = 'NOTIFICATION.POST.MENTION.WITHOUT.TITLE.DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=72193918
     */
    POST_NEW_WITH_TITLE_DESCRIPTION = 'NOTIFICATION.POST.NEW.WITH.TITLE.DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=72194164
     */
    POST_STATUS_UPDATE_WITH_TITLE_DESCRIPTION = 'NOTIFICATION.POST.STATUS.UPDATE.WITH.TITLE.DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=72194169
     */
    POST_UPDATE_WITH_TITLE_DESCRIPTION = 'NOTIFICATION.POST.UPDATE.WITH.TITLE.DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=150396731
     */
    COMMENT_REPORT_CREATED_ONE_DESCRIPTION = 'NOTIFICATION.COMMENT_REPORT_CREATED_ONE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=158447556
     */
    POST_REPORT_CREATED_ONE_DESCRIPTION = 'NOTIFICATION.POST_REPORT_CREATED_ONE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=158450618
     */
    POST_REPORT_CREATED_MORE_DESCRIPTION = 'NOTIFICATION.POST_REPORT_CREATED_MORE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=158452334
     */
    POST_REPORT_CREATED_WITH_TITLE_ONE_DESCRIPTION = 'NOTIFICATION.POST_REPORT_CREATED_WITH_TITLE_ONE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=158455387
     */
    POST_REPORT_CREATED_WITH_TITLE_MORE_DESCRIPTION = 'NOTIFICATION.POST_REPORT_CREATED_WITH_TITLE_MORE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=158459268
     */
    POST_REPORT_REVIEWED_DESCRIPTION = 'NOTIFICATION.POST_REPORT_REVIEWED_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=158459489
     */
    POST_REPORT_REVIEWED_WITH_TITLE_DESCRIPTION = 'NOTIFICATION.POST_REPORT_REVIEWED_WITH_TITLE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=158459726
     */
    POST_REPORT_ACCEPTED_DESCRIPTION = 'NOTIFICATION.POST_REPORT_ACCEPTED_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=158459964
     */
    POST_REPORT_ACCEPTED_WITH_TITLE_DESCRIPTION = 'NOTIFICATION.POST_REPORT_ACCEPTED_WITH_TITLE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=150396752
     */
    COMMENT_REPORT_CREATED_MORE_DESCRIPTION = 'NOTIFICATION.COMMENT_REPORT_CREATED_MORE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=150396861
     */
    COMMENT_REPORT_CREATED_POST_ONE_DESCRIPTION = 'NOTIFICATION.COMMENT_REPORT_CREATED_POST_ONE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=150398099
     */
    COMMENT_REPORT_CREATED_POST_MORE_DESCRIPTION = 'NOTIFICATION.COMMENT_REPORT_CREATED_POST_MORE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=150404057
     */
    COMMENT_REPORT_CREATED_POST_WITH_TITLE_ONE_DESCRIPTION = 'NOTIFICATION.COMMENT_REPORT_CREATED_POST_WITH_TITLE_ONE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=150405069
     */
    COMMENT_REPORT_CREATED_POST_WITH_TITLE_MORE_DESCRIPTION = 'NOTIFICATION.COMMENT_REPORT_CREATED_POST_WITH_TITLE_MORE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=150409380
     */
    COMMENT_REPORT_REVIEWED_DESCRIPTION = 'NOTIFICATION.COMMENT_REPORT_REVIEWED_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=150409379
     */
    COMMENT_REPORT_REVIEWED_POST_DESCRIPTION = 'NOTIFICATION.COMMENT_REPORT_REVIEWED_POST_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=150409152
     */
    COMMENT_REPORT_REVIEWED_POST_WITH_TITLE_DESCRIPTION = 'NOTIFICATION.COMMENT_REPORT_REVIEWED_POST_WITH_TITLE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=150409550
     */
    COMMENT_REPORT_ACCEPTED_DESCRIPTION = 'NOTIFICATION.COMMENT_REPORT_ACCEPTED_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=150410438
     */
    COMMENT_REPORT_ACCEPTED_POST_DESCRIPTION = 'NOTIFICATION.COMMENT_REPORT_ACCEPTED_POST_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=150410466
     */
    COMMENT_REPORT_ACCEPTED_POST_WITH_TITLE_DESCRIPTION = 'NOTIFICATION.COMMENT_REPORT_ACCEPTED_POST_WITH_TITLE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=165014738
     */
    CONTENT_REPORT_CREATED_ONE_DESCRIPTION = 'NOTIFICATION.CONTENT_REPORT_CREATED_ONE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=165014759
     */
    CONTENT_REPORT_CREATED_MORE_DESCRIPTION = 'NOTIFICATION.CONTENT_REPORT_CREATED_MORE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=165014779
     */
    CONTENT_REPORT_REVIEWED_DESCRIPTION = 'NOTIFICATION.CONTENT_REPORT_REVIEWED_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=165014791
     */
    CONTENT_REPORT_ACCEPTED_DESCRIPTION = 'NOTIFICATION.CONTENT_REPORT_ACCEPTED_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=63098430
     */
    MARK_ALL_AS_READ_SUCCESS = 'FRONT.NOTIFICATIONS.MARK_ALL_AS_READ.SUCCESS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=63271116
     */
    DELETE_ALL_SUCCESS = 'FRONT.NOTIFICATIONS.DELETE_ALL.SUCCESS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=245541567
     */
    EVENT_UPDATED = 'NOTIFICATION.EVENT_UPDATED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=245541500
     */
    EVENT_INVITATION_CREATED = 'NOTIFICATION.EVENT_INVITATION_CREATED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=392489173
     */
    COMMENT_MENTION = 'NOTIFICATIONS_CENTER.COMMENT_MENTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=392354027
     */
    COMMENT_MENTION_WITHOUT_TITLE = 'NOTIFICATIONS_CENTER.COMMENT_EVENT_MENTION_WITHOUT_TITLE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=66224788
     */
    COMMENT_MENTION_MULTIPLE = 'NOTIFICATION.COMMENT.POST.MENTION.DESCRIPTION_OTHERS',
}
